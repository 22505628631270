import { createModalContext } from ".";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Link, useFetcher } from "react-router-dom";
import { objectToFormData } from "@/lib/query";
import { CompaniesDTO } from "@/api/definitions";

export interface IRemoveOriginModalProps {
  closeModal(): void;
  companyId: CompaniesDTO["id"];
  removeSite: string;
  sites: string[];
}

export function RemoveOriginModal({
  closeModal,
  sites,
  companyId,
  removeSite,
}: IRemoveOriginModalProps) {
  const fetcher = useFetcher();
  return (
    <AlertDialog open onOpenChange={closeModal}>
      <AlertDialogContent className="bg-card border-border text-foreground">
        <AlertDialogHeader>
          <AlertDialogTitle>
            Êtes-vous sûre de vouloir supprimer ce site ?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Plus aucun de vos widgets ne sera disponible sur le site suivant:
            <br />
            <Link to={removeSite} className="underline">
              {removeSite}
            </Link>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={closeModal}>Annuler</AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              fetcher.submit(
                objectToFormData({
                  id: companyId,
                  authorizedOrigins: sites,
                }),
                {
                  encType: "multipart/form-data",
                  method: "PUT",
                  action: "/app/settings/sites?index",
                },
              );
            }}
          >
            Confirmer
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export const [useRemoveOriginModal, RemoveOriginProvider, RemoveOriginContext] =
  createModalContext(RemoveOriginModal);
