import { impersonateUser } from "@/api/authentication";
import { CompaniesDTO } from "@/api/definitions";
import { listMutationLogs } from "@/api/mutation_log";
import { listUsers } from "@/api/users";
import { ActionsMenuContent, ActionsMenuItem } from "@/components/actions-menu";
import { DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";
import { ExtractResult } from "@/lib/cache";
import { copyText } from "@/lib/copy";
import { objectToFormData } from "@/lib/query";
import { cn } from "@/lib/utils";
import { useDeleteUserModal } from "@/modals/delete-user";
import {
  Building2,
  CornerDownRight,
  Edit,
  Hash,
  LayoutGrid,
  LogIn,
  Trash,
  User,
} from "lucide-react";
import * as React from "react";
import { useFetcher } from "react-router-dom";

export interface ILogActionsProps {
  log: ExtractResult<typeof listMutationLogs>[number];
}

export default function LogActions({ log }: ILogActionsProps) {
  const navigate = useSmartNavigate();
  return (
    <ActionsMenuContent className="w-56" align="start" side="left">
      <ActionsMenuItem
        onClick={() => copyText(log.id)}
        className="cursor-pointer"
      >
        <Hash className="mr-2 h-4 w-4" />
        <span>Copier l'ID</span>
      </ActionsMenuItem>
      <ActionsMenuItem className="cursor-pointer">
        <CornerDownRight className="mr-2 h-4 w-4" />
        <span>Voir le payload</span>
      </ActionsMenuItem>
      {log.user_id ? (
        <ActionsMenuItem
          className="cursor-pointer"
          onClick={() => navigate(`/app/admin/users?ids=${log.user_id}`)}
        >
          <CornerDownRight className="mr-2 h-4 w-4" />
          <span>Voir l'utilisateur</span>
        </ActionsMenuItem>
      ) : null}
      <ActionsMenuItem className="cursor-pointer">
        <CornerDownRight className="mr-2 h-4 w-4" />
        <span>Voir la resource</span>
      </ActionsMenuItem>
    </ActionsMenuContent>
  );
}
