import * as React from "react";

interface AbortEffect {
  (
    signal: AbortSignal,
    controller?: AbortController,
  ): (() => void) | void | Promise<void>;
}
export function useAbortEffect(
  fn: AbortEffect,
  deps: React.DependencyList = [],
) {
  return React.useEffect(() => {
    const controller = new AbortController();
    const callback = fn(controller.signal, controller);
    if (callback && "then" in callback) callback.then(() => {}, console.error);
    return () => {
      controller.abort("useAbortEffect");
      if (typeof callback === "function") callback();
    };
  }, deps);
}
