import * as React from "react";
import { Navigate, Route } from "react-router-dom";
import { SingleAdvertisementLoader } from "./loader";
import SingleAdvertisementLayout from "./layout";
import AdvertisementAnalyticsRouter from "./analytics";
import AdvertisementContentRouter from "./content";
import AdvertisementSchedulesrouter from "./schedules";

export interface ISingleAdvertisementRouterProps {}

export default function SingleAdvertisementRouter(
  props: ISingleAdvertisementRouterProps,
) {
  return (
    <Route
      id="single-advertisement-route"
      loader={SingleAdvertisementLoader}
      Component={SingleAdvertisementLayout}
    >
      <Route index element={<Navigate to="content" replace />} />
      <Route path="analytics">{AdvertisementAnalyticsRouter({})}</Route>
      <Route path="content">{AdvertisementContentRouter({})}</Route>
      <Route path="schedules">{AdvertisementSchedulesrouter({})}</Route>
    </Route>
  );
}
