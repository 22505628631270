import { TableHead } from "@/components/ui/table";
import { ColumnsDef, ColumnsState } from "@/hooks/useColumns";

export type SchedulesTableColumnName =
  | "id"
  | "image"
  | "title"
  | "location"
  | "diffusion"
  | "dates"
  | "sponsored"
  | "paused";

export const SchedulesTableColumns: ColumnsDef<SchedulesTableColumnName> = {
  id: {
    value: "id",
    label: "Id",
    header: () => <TableHead className="w-20">#ID</TableHead>,
  },
  image: {
    value: "image",
    label: "Image",
    header: () => (
      <TableHead className="w-20 first:w-24 first:px-4 px-0">Image</TableHead>
    ),
  },
  title: {
    value: "title",
    label: "Titre",
    header: () => <TableHead>Titre</TableHead>,
  },
  dates: {
    value: "dates",
    label: "Dates de l'annonce",
    header: () => <TableHead>Dates de l'annonce</TableHead>,
  },
  location: {
    value: "location",
    label: "Lieu",
    header: () => <TableHead>Lieu</TableHead>,
  },
  diffusion: {
    value: "diffusion",
    label: "Dates de diffusion",
    header: () => <TableHead>Dates de diffusion</TableHead>,
  },
  sponsored: {
    value: "sponsored",
    label: "Sponsorisé",
    header: () => <TableHead>Sponsorisé</TableHead>,
  },
  paused: {
    value: "paused",
    label: "Actif",
    header: () => <TableHead>Actif</TableHead>,
  },
};
export const defaultSchedulesTableColumns: ColumnsState<SchedulesTableColumnName> =
  {
    id: { visible: true, sorting: null },
    image: { visible: true, sorting: null },
    title: { visible: true, sorting: null },
    dates: { visible: false, sorting: null },
    location: { visible: false, sorting: null },
    diffusion: { visible: true, sorting: null },
    sponsored: { visible: true, sorting: null },
    paused: { visible: true, sorting: null },
  };
