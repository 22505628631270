import { objectFromFormData } from "@/lib/query";
import { ActionFunctionArgs } from "react-router-dom";
import * as z from "@/lib/zod";
import { forgotPassword } from "@/api/authentication";

export interface IForgotPasswordActionData {}
export async function ForgotPasswordAction({
  request,
}: ActionFunctionArgs): Promise<IForgotPasswordActionData> {
  console.log("ForgotPasswordAction");
  if (request.method === "POST") {
    const { email } = z
      .object({ email: z.string().email() })
      .parse(objectFromFormData(await request.formData()));
    console.log("email");
    await forgotPassword(email, request.signal);
  }
  return {};
}
