import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import * as React from "react";
import { Button } from "@/components/ui/button";
import { useAsyncValue, useFetcher } from "react-router-dom";
import FormState from "@/components/form-state";
import { FullRealEstateAdvertisementDTO } from "@/api/definitions";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { ISingleAdvertisementLoaderData } from "../../loader";

export interface IAdvertisementImmoCardProps {}

export default function AdvertisementImmoCard(
  props: IAdvertisementImmoCardProps,
) {
  const advertisement = useAsyncValue() as Awaited<
    ISingleAdvertisementLoaderData["advertisement"]
  > &
    FullRealEstateAdvertisementDTO;
  const fetcher = useFetcher();
  const [surface, setSurface] = React.useState(advertisement.surface);
  const [rooms, setRooms] = React.useState(advertisement.rooms);
  const [price, setPrice] = React.useState(advertisement.price);
  return (
    <Card className="enter">
      <fetcher.Form
        method="PUT"
        action="/app/advertisements/[id]/content?index"
      >
        <FormState
          data={{
            id: advertisement.id,
            type: advertisement.type,
            surface,
            rooms,
            price,
          }}
        />
        <CardHeader>
          <CardTitle>Caractéristiques</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6 pb-10 border-b border-b-border">
          <div className="space-y-4">
            <Label>Surface</Label>
            <Input
              value={surface}
              disabled={!advertisement.isOwned}
              onChange={(e) => setSurface(e.target.value)}
            />
          </div>
          <div className="space-y-4">
            <Label>Pièces</Label>
            <Input
              value={rooms}
              disabled={!advertisement.isOwned}
              onChange={(e) => setRooms(e.target.value)}
            />
          </div>
          <div className="space-y-4">
            <Label>Valeur</Label>
            <Input
              value={price}
              disabled={!advertisement.isOwned}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </CardContent>
        <CardFooter className="flex justify-end py-4">
          <Button
            type="submit"
            size="sm"
            disabled={
              !advertisement.isOwned ||
              (surface === advertisement.surface &&
                price === advertisement.price &&
                rooms === advertisement.rooms)
            }
          >
            Confirmer
          </Button>
        </CardFooter>
      </fetcher.Form>
    </Card>
  );
}
