import { useState } from "react";

export function useHistory<T>(initialValue: T | (() => T)) {
  const [history, setHistory] = useState<{
    past: T[];
    present: T;
    future: T[];
  }>(
    typeof initialValue === "function"
      ? () => ({
          past: [],
          present: (initialValue as Function)(),
          future: [],
        })
      : {
          past: [],
          present: initialValue,
          future: [],
        },
  );

  const set = (newPresent: T) => {
    setHistory(({ past, present }) => ({
      past: [...past, present],
      present: newPresent,
      future: [],
    }));
  };

  const undo = () => {
    setHistory(({ past, present, future }) => {
      if (past.length === 0) return { past, present, future };
      const previous = past[past.length - 1];
      return {
        past: past.slice(0, past.length - 1),
        present: previous,
        future: [present, ...future],
      };
    });
  };

  const redo = () => {
    setHistory(({ past, present, future }) => {
      if (future.length === 0) return { past, present, future };
      const next = future[0];
      return {
        past: [...past, present],
        present: next,
        future: future.slice(1),
      };
    });
  };
  const canUndo = history.past.length > 0;
  const canRedo = history.future.length > 0;

  return { state: history.present, set, undo, redo, canUndo, canRedo };
}
