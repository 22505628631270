export function removeDuplicate<
  S extends string,
  T extends { [key in S]: any },
>(arr: T[], property: S) {
  const buffer = new Set<T[S]>();
  return arr.filter((item) => {
    if (buffer.has(item[property])) return false;
    buffer.add(item[property]);
    return true;
  });
}

interface GroupByPredicateFunction<T extends any> {
  (item: T): string | number | boolean;
}

export function groupBy<
  T extends Record<string, unknown>,
  S extends Extract<keyof T, string> | GroupByPredicateFunction<T>,
>(arr: T[], predicate: S) {
  type V =
    S extends GroupByPredicateFunction<T>
      ? ReturnType<S>
      : T[Extract<S, keyof T>];
  return arr.reduce((acc: Map<V, T[]>, cur: T) => {
    const key = (
      typeof predicate === "function"
        ? predicate(cur)
        : cur[predicate as keyof T]
    ) as V;
    return acc.set(key, (acc.get(key) ?? []).concat(cur));
  }, new Map<V, T[]>());
}

export function chunkArray<T extends any[]>(arr: T, n: number): T[] {
  const result = [] as T[];
  for (let i = 0; i < arr.length; i += n) {
    result.push(arr.slice(i, i + n) as T);
  }
  return result;
}

export function withId<
  S extends string | undefined,
  T extends { [key in V]: any },
  V extends string = S extends undefined ? "id" : S,
>(value: T[V], property?: S) {
  return (e: T) => e[(property ?? "id") as V] === value;
}

export function hasSameContent<T>(arr1: T[], arr2: T[]) {
  return (
    arr1.length === arr2.length &&
    arr1.every((item1) => arr2.find((item2) => Object.is(item1, item2)))
  );
}
