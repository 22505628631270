import * as React from "react";
import { createModalContext } from ".";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useFetcher } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useCache } from "@/hooks/use-cache";
import Awaited from "@/components/awaited";
import { getScheduleById } from "@/api/schedules";
import { ADVERTISEMENT_TYPE, ROTATION_STRATEGY } from "@/api/definitions";
import { displayDates } from "@/lib/date";
import { Info, Loader } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import FormState from "@/components/form-state";
import { ExtractResult } from "@/lib/cache";
import { Skeleton } from "@/components/ui/skeleton";
import { dependsOn, join, wait } from "@/lib/promise";
import ScheduleFormItem, {
  NewScheduleItem,
} from "@/components/schedule-form-item";
import { listOwnedWidgets } from "@/api/widgets";
import { withId } from "@/lib/array";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AdvertisementBuilderSchedulesSchema } from "@/pages/app/advertisements/new/[type]/(blocks)/schemas";
import { keys } from "@/lib/entries";

export interface ICreateWidgetModalProps {
  closeModal(): void;
  scheduleId: number;
}

export interface IUpdateScheduleModalContentProps {
  closeModal(): void;
  schedule: ExtractResult<typeof getScheduleById>;
  widgets: ExtractResult<typeof listOwnedWidgets>;
}

export default function UpdateScheduleModalContent({
  schedule: { advertisement, ...schedule },
  closeModal,
  widgets,
}: IUpdateScheduleModalContentProps) {
  const advertisementStart =
    advertisement.type !== ADVERTISEMENT_TYPE.EVENT
      ? undefined
      : new Date(
          advertisement.dates.reduce(
            (acc, cur) => Math.min(acc, +cur.from),
            Infinity,
          ),
        );
  const advertisementEnd =
    advertisement.type !== ADVERTISEMENT_TYPE.EVENT
      ? undefined
      : new Date(
          advertisement.dates.reduce((acc, cur) => Math.max(acc, +cur.to), 0),
        );
  const form = useForm({
    mode: "all",
    resolver: zodResolver(AdvertisementBuilderSchedulesSchema),
    defaultValues: {
      widgetId: schedule.widget_id,
      from: schedule.from,
      to: schedule.to,
      clic_goal: schedule.clic_goal,
      impression_goal: schedule.impression_goal,
      sponsored: schedule.rotation_strategy !== ROTATION_STRATEGY.FILLER,
    } as NewScheduleItem,
  });
  const fetcher = useFetcher();
  React.useEffect(() => {
    if (fetcher.data && "id" in fetcher.data) closeModal();
  }, [fetcher.data]);
  if (fetcher.data && "id" in fetcher.data) return null;

  return (
    <>
      <div className="space-x-4 w-full border border-border py-3 px-4 rounded-sm flex items-center">
        <img
          src={advertisement.main_image}
          className="w-auto h-10 rounded-[3px] object-cover"
        />
        <div className="flex flex-col w-full max-w-[200px]">
          <div className="text-sm w-full truncate">{advertisement.title}</div>
          <div className="text-sm text-muted-foreground w-full truncate">
            {advertisement.type === ADVERTISEMENT_TYPE.EVENT &&
              displayDates(advertisementStart, advertisementEnd) + " | "}
            {advertisement.location_name}
          </div>
        </div>
      </div>
      <div className="enter animation-fast space-y-4 w-full">
        <ScheduleFormItem
          className="mb-4"
          variant="sm"
          data={form.watch()}
          widgets={widgets.results.filter(withId(schedule.widget_id))}
          setData={(val) => {
            keys(val).forEach((kyrie) => form.setValue(kyrie, val[kyrie]));
            form.trigger();
          }}
        />
      </div>
      <DialogFooter className="enter animation-fast">
        <fetcher.Form
          action="/app/widgets/:widgetId/schedules?index"
          method="PUT"
        >
          <FormState data={{ ...form.watch(), id: schedule.id }} />
          <Button type="submit">Confirmer</Button>
        </fetcher.Form>
      </DialogFooter>
    </>
  );
}

export function UpdateScheduleModal({
  closeModal,
  scheduleId,
}: ICreateWidgetModalProps) {
  const scheduleQuery = useCache(getScheduleById, scheduleId);
  const widgetsQuery = useCache(listOwnedWidgets);
  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent className="min-h-[472px] flex flex-col bg-card border-border text-foreground max-w-sm">
        <DialogHeader className="space-y-4 enter animation-fast">
          <DialogTitle>Mettre à jour la programmation</DialogTitle>
          <DialogDescription>
            Programmez votre annonce sur une plage de date et avec un objectif
            de performance.{" "}
            <Tooltip>
              <TooltipTrigger asChild>
                <Info className="w-4 h-4 inline" />
              </TooltipTrigger>
              <TooltipContent>
                L'annonce sera retirée automatiquement lorsque l'une des
                conditions sera remplie.
              </TooltipContent>
            </Tooltip>
          </DialogDescription>
        </DialogHeader>
        <Awaited
          resolve={join(scheduleQuery, widgetsQuery).then(
            dependsOn(wait("1 second")),
          )}
          fallback={
            <>
              <div className="enter animation-fast space-x-4 w-full border border-border py-3 px-4 rounded-sm flex items-center">
                <Skeleton className="w-20 h-10 rounded-[3px]" />
                <div className="flex flex-col gap-1 pt-0.5 justify-between w-full max-w-[200px]">
                  <Skeleton className="w-40 h-3.5" />
                  <Skeleton className="w-40 h-2.5" />
                </div>
              </div>
              <div className="enter animation-fast w-full h-full grow flex items-center justify-center">
                <Loader className="w-6 h-6 text-muted-foreground animate-spin" />
              </div>
            </>
          }
          render={([schedule, widgets]) => (
            <UpdateScheduleModalContent
              schedule={schedule}
              widgets={widgets}
              closeModal={closeModal}
            />
          )}
        />
      </DialogContent>
    </Dialog>
  );
}

export const [
  useUpdateScheduleModal,
  UpdateScheduleProvider,
  UpdateScheduleContext,
] = createModalContext(UpdateScheduleModal);
