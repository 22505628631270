import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import * as React from "react";
import { useAsyncValue } from "react-router-dom";
import { ISingleAdvertisementLoaderData } from "../../loader";
import { Input } from "@/components/ui/input";
import dayjs from "@/lib/date";
import { Button } from "@/components/ui/button";
import { CopyIcon } from "lucide-react";
import { copyText } from "@/lib/copy";

export interface IAdvertisementAnnexeCardProps {}

export default function AdvertisementAnnexeCard(
  props: IAdvertisementAnnexeCardProps,
) {
  const advertisement = useAsyncValue() as Awaited<
    ISingleAdvertisementLoaderData["advertisement"]
  >;
  return (
    <Card className="enter">
      <CardHeader>
        <CardTitle>Annexe</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="space-y-2">
          <Label
            htmlFor="rotation_active"
            className="font-medium text-muted-foreground"
          >
            Identifiant
          </Label>
          <div className="flex items-center gap-2">
            <Input disabled value={advertisement.id} />
            <Button
              size="icon"
              variant="outline"
              className="shrink-0"
              onClick={() => copyText(advertisement.id)}
            >
              <CopyIcon className="w-4 h-4" />{" "}
            </Button>
          </div>
        </div>
        <div className="space-y-2">
          <Label
            htmlFor="rotation_active"
            className="font-medium text-muted-foreground"
          >
            Date de création
          </Label>
          <Input
            disabled
            type="datetime-local"
            className="dark:placeholder:text-foreground/50"
            value={dayjs(advertisement.createdAt).format("YYYY-MM-DD[T]HH:mm")}
          />
        </div>
        <div className="space-y-2">
          <Label
            htmlFor="rotation_active"
            className="font-medium text-muted-foreground"
          >
            Dernière modification
          </Label>
          <Input
            disabled
            type="datetime-local"
            className="dark:placeholder:text-foreground/50"
            value={dayjs(advertisement.updatedAt).format("YYYY-MM-DD[T]HH:mm")}
          />
        </div>
      </CardContent>
    </Card>
  );
}
