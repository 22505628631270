import { withToast } from "@/lib/with-toast";
import { ActionFunctionArgs, redirect } from "react-router-dom";
import { AdvertisementDraftSchema, ROTATION_STRATEGY } from "@/api/definitions";
import { createAdvertisement } from "@/api/advertisements";
import { useDraftStore } from "../layout";
import { toast } from "sonner";
import * as z from "@/lib/zod";
import { createSchedule } from "@/api/schedules";
import { listOwnedWidgets } from "@/api/widgets";
import { timer } from "@/lib/timer";
import dayjs from "@/lib/date";
import { ScheduleStatus } from "@/pages/app/widgets/[id]/schedules/loader";

export interface IAdvertisementBuilderActionData {}
export async function AdvertisementBuilderAction({
  request,
}: ActionFunctionArgs): Promise<IAdvertisementBuilderActionData> {
  const body = await request.formData();
  if (request.method === "POST") {
    const response: undefined | string | Error = await withToast(
      async () => {
        const draft = z
          .json(AdvertisementDraftSchema)
          .parse((body.get("draft") as string) ?? "");

        const advertisementId = await createAdvertisement(
          draft,
          request.signal,
        );

        timer(useDraftStore.reset, "100 ms").trigger();

        const schedules = draft.schedules;

        if (!schedules?.length)
          return `/app/advertisements/${advertisementId}/content`;

        const schedulesCreation = await Promise.allSettled(
          schedules.map((payload) =>
            createSchedule({
              widget_id: payload.widgetId,
              advertisement_version_id: advertisementId,
              from: payload.from,
              to: payload.to,
              rotation_strategy: payload.sponsored
                ? ROTATION_STRATEGY.ROTATE
                : ROTATION_STRATEGY.FILLER,
            }),
          ),
        );

        const schedulesError = schedulesCreation
          .map((res, index) => {
            if (res.status === "fulfilled") return null;
            const payload = draft.schedules![index];
            return [payload, res.reason] as const;
          })
          .filter(Boolean);

        if (schedulesError.length) {
          const widgets = await listOwnedWidgets(request.signal);
          const errorWidgetIds = new Set(
            schedulesError.map((e) => e![0].widgetId),
          );
          const errorWidgets = widgets.results.filter((w) =>
            errorWidgetIds.has(w.id),
          );
          toast.error(
            `Echec de la programmation sur les widgets ${errorWidgets}. Veuillez réessayer ultérieurement`,
          );
        }

        try {
          // urgence, peu pas tester donc tryc
          if (schedules.length > 1)
            return `/app/advertisements/${advertisementId}/schedules`;

          const isFuture = dayjs(schedules[0].from).isAfter(dayjs());

          return `/app/widgets/${schedules[0].widgetId}/schedules?status=${
            isFuture ? ScheduleStatus.FUTURE : ScheduleStatus.ACTIVE
          }`;
        } catch (e) {
          return `/app/advertisements/${advertisementId}/schedules`;
        }
      },
      {
        loading: "Création en cours...",
        success: "Création réussie",
        error: "Echec de la création.",
      },
    ).catch((e) =>
      typeof response === "string" ? new Error(e) : (e as Error),
    );
    if (typeof response === "string") {
      console.log("CREATION RESPONSE", response);
      window.open(response, "_self");
      throw redirect(response);
    }
    console.error(response);
    console.log("Error creating advertisement");
  }
  return {};
}
