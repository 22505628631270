import { captureException } from "@sentry/react";
import React from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export function RootErrorPage() {
  const error = useRouteError();
  console.error(error);
  React.useEffect(() => {
    captureException(error);
  }, [error]);
  if (isRouteErrorResponse(error))
    return (
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error.statusText || error.status}</i>
        </p>
      </div>
    );
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
    </div>
  );
}
