import {
  ContextMenuContent,
  ContextMenuTrigger,
  ContextMenu,
  ContextMenuItem,
} from "@/components/ui/context-menu";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import * as React from "react";

const ActionsMenuContext = React.createContext<ActionsMenuContextType>({
  mode: null,
  setMode() {},
});

export function ActionsMenuProvider(props: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const [mode, setMode] = React.useState<ContextType["mode"]>(null);
  return (
    <ActionsMenuContext.Provider value={{ mode, setMode }}>
      {props.children}
    </ActionsMenuContext.Provider>
  );
}
export function withActionMenu<T extends React.ComponentType<any>>(
  Component: T,
) {
  return function (props: React.ComponentPropsWithoutRef<typeof Component>) {
    return (
      <ActionsMenuProvider>
        <Component {...(props as any) /* 😡 */} />
      </ActionsMenuProvider>
    );
  } as typeof Component;
}

export function useActionsMenuContext() {
  const data = React.useContext<ActionsMenuContextType>(ActionsMenuContext);
  if (!data)
    throw new Error(
      `useActionsMenuContext must be used inside of ${ActionsMenuContext.displayName} Provider`,
    );
  return data;
}

function ActionsMenuComponent<PropsType extends Record<string, unknown>>({
  Actions,
  data,
  children,
}: ActionsMenuContextProviderProps<PropsType>) {
  const { mode, setMode } = useActionsMenuContext();

  return (
    <ContextMenu onOpenChange={(open) => setMode(open ? "context" : null)}>
      <DropdownMenu onOpenChange={(open) => setMode(open ? "dropdown" : null)}>
        <ContextMenuTrigger
          asChild
          className={cn(mode === "context" && "ring ring-offset-2 ring-accent")}
        >
          {children}
        </ContextMenuTrigger>
        <Actions {...data} close={() => setMode(null)} />
      </DropdownMenu>
    </ContextMenu>
  );
}

export const ActionsMenu = withActionMenu(ActionsMenuComponent);

export type ContextType = { mode: "dropdown" | "context" | null };
export type ActionsMenuContextType = ContextType & {
  setMode(mode: ContextType["mode"]): void;
};
export type ActionsMenuContextProviderProps<T extends Record<string, unknown>> =
  {
    data: T;
    Actions: React.ComponentType<T & { close?(): void }>;
    children: React.ReactNode | React.ReactNode[];
  };

function createActionsMenuComponent<
  D extends JSX.IntrinsicAttributes,
  C extends JSX.IntrinsicAttributes,
>(
  dropdownVersion: React.ExoticComponent<D>,
  contextVersion: React.ExoticComponent<C>,
  displayName: string,
) {
  const fn = function (props: D & C) {
    const { mode } = useActionsMenuContext();
    const Component = React.useMemo(() => {
      if (!mode) return () => null;
      if (mode === "dropdown") return dropdownVersion;
      return contextVersion;
    }, [mode]);
    return <Component {...(props as D & C)} />;
  };
  fn.displayName = displayName;
  return fn;
}

export const ActionsMenuContent = createActionsMenuComponent(
  DropdownMenuContent,
  ContextMenuContent,
  "ActionsMenuContent",
);
export const ActionsMenuItem = createActionsMenuComponent(
  DropdownMenuItem,
  ContextMenuItem,
  "ActionsMenuItem",
);
