import { WIDGET_TYPE, WidgetsDTO } from "@/api/definitions";
import * as React from "react";
import { Card, CardContent } from "./ui/card";
import { Link, useNavigate } from "react-router-dom";
import {
  ExternalLink,
  Eye,
  MousePointerClick,
  TrendingUp,
  X,
} from "lucide-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";
import { Skeleton } from "./ui/skeleton";
import { formatBigNumbers, formatPercent, formatUrl } from "@/lib/format";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";

export interface IWidgetCardProps
  extends Pick<WidgetsDTO, "id" | "name" | "type"> {
  origin?: string | null;
  impressions?: number;
  clics?: number;
}

const IconForWidgetType: Record<WIDGET_TYPE, string> = {
  [WIDGET_TYPE.CAROUSEL]: "/img/carousel-icon.svg",
  [WIDGET_TYPE.PAGEVIEW]: "/img/pageview-icon.svg",
};
export default function WidgetCard(props: IWidgetCardProps) {
  const navigate = useSmartNavigate();
  const targetUrl = `/app/widgets/${props.id}`;
  return (
    <Card
      className="cursor-pointer hover:border-foreground/50 enter animation-fast"
      onClick={() => navigate(targetUrl)}
    >
      <CardContent className="p-6 pb-5 grid grid-cols-1 gap-6">
        <div className="flex items-center gap-4">
          <img
            className="h-9 w-9 rounded-full object-cover aspect-square"
            src={IconForWidgetType[props.type]}
          />
          <div className="grid gap-1">
            <Link to={targetUrl}>
              <p className="text-base font-semibold leading-none cursor-pointer">
                {props.name}
              </p>
            </Link>
            {props.origin ? (
              <p className="text-muted-foreground flex gap-1 items-center overflow-hidden">
                <ExternalLink className="w-3 h-3" />
                <Link
                  to={props.origin}
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                  className="text-xs text-muted-foreground truncate max-w-[330px] hover:underline cursor-pointer"
                >
                  {formatUrl(props.origin)}
                </Link>
              </p>
            ) : (
              <Tooltip>
                <TooltipTrigger asChild>
                  <p className="text-xs text-red-500 flex gap-1 items-center hover:underline">
                    <X className="w-3 h-3" /> Inactif
                  </p>
                </TooltipTrigger>
                <TooltipContent>
                  Votre widget n'a été répertorié sur aucun site durant les 30
                  derniers jours.
                </TooltipContent>
              </Tooltip>
            )}
          </div>
          {/*<div className="ml-auto">
            <Link to={`${targetUrl}/edit`}>
              <Button variant="ghost" size="icon" className="-mr-2.5 -mt-2.5">
                <MoreHorizontal className="w-5 h-5" />
              </Button>
            </Link>
          </div>*/}
        </div>
        <div className="flex justify-evenly w-full gap-3" title="Dernières 24h">
          <div className="flex flex-col items-center">
            <p className="text-xs text-muted-foreground">
              Impressions <span className="sm:hidden md:inline">(24h)</span>
            </p>
            <p className="font-medium flex items-center justify-center gap-1 text-xl">
              {formatBigNumbers(props.impressions)}
              <Eye className="w-4 h-4 text-muted-foreground" />
            </p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-xs text-muted-foreground">
              Clics <span className="sm:hidden md:inline">(24h)</span>
            </p>
            <p className="font-medium flex items-center justify-center gap-1 text-xl">
              {formatBigNumbers(props.clics)}
              <MousePointerClick className="w-4 h-4 text-muted-foreground" />
            </p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-xs text-muted-foreground">
              CTR <span className="sm:hidden md:inline">(24h)</span>
            </p>
            <p className="font-medium flex items-center justify-center gap-1 text-xl">
              {formatPercent(
                props.clics !== undefined && props.impressions !== undefined
                  ? (100 * props.clics) / (props.impressions || 1)
                  : undefined,
              )}
              <TrendingUp className="w-4 h-4 text-muted-foreground" />
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export interface IWidgetSkeletonCardProps {}

WidgetCard.skeleton = function (props: IWidgetSkeletonCardProps) {
  return (
    <Card className="opacity-50">
      <CardContent className="p-6 pb-5 grid grid-cols-1 gap-6">
        <div className="flex items-center gap-4">
          <Skeleton className="h-9 w-9 rounded-full" />
          <div className="grid gap-1">
            <Skeleton className="h-3.5 w-24" />
            <Skeleton className="h-3 w-40" />
          </div>
          <div className="ml-auto h-10"></div>
        </div>
        <div className="grid grid-cols-3 gap-3">
          <div className="flex flex-col items-center">
            <Skeleton className="h-3.5 w-28" />
            <Skeleton className="h-6 w-16 mt-1 mx-auto" />
          </div>
          <div className="flex flex-col items-center">
            <Skeleton className="h-3.5 w-28" />
            <Skeleton className="h-6 w-16 mt-1 mx-auto" />
          </div>
          <div className="flex flex-col items-center">
            <Skeleton className="h-3.5 w-28" />
            <Skeleton className="h-6 w-16 mt-1 mx-auto" />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
