import * as React from "react";
import { Navigate, useLoaderData } from "react-router-dom";
import { INewDesignLoaderData } from "./loader";

export interface INewDesignEntryProps {}

export default function NewDesignEntry(props: INewDesignEntryProps) {
  const data = useLoaderData() as INewDesignLoaderData;
  return <Navigate to={`/app/design/${data.customizationId}`} replace />;
}
