import * as React from "react";
import { Route } from "react-router-dom";
import WidgetsPage from "./page";
import { WidgetsLoader } from "./loader";
import SingleWidgetRouter from "./[id]";
import { WidgetsAction } from "./action";

export interface IWidgetsRouterProps {}

export default function WidgetsRouter(props: IWidgetsRouterProps) {
  return (
    <Route>
      <Route
        index
        action={WidgetsAction}
        Component={WidgetsPage}
        loader={WidgetsLoader}
      />
      <Route path=":widgetId">{SingleWidgetRouter({})}</Route>
    </Route>
  );
}
