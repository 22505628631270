import * as React from "react";
import { useLoaderData } from "react-router-dom";
import {
  ADVERTISEMENT_STATUS,
  AdvertisementStatusSchema,
  IAdvertisementsLoaderData,
} from "./loader";
import Awaited from "@/components/awaited";
import TabSelector from "@/components/tab-selector";
import { ADVERTISEMENT_TYPE, AdvertisementTypeSchema } from "@/api/definitions";
import { useRouteNavigation } from "@/hooks/use-route-navigation";
import CheckboxDropdown from "@/components/checkbox-dropdown";
import { entries, fromEntries } from "@/lib/entries";
import { useColumns } from "@/hooks/useColumns";
import { columnsForType } from "./(blocks)/columns";
import { Button } from "@/components/ui/button";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCaption,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import SmartPagination from "@/components/smart-pagination";
import AdvertisementRow from "./(blocks)/advertisement-row";
import { omit, pick } from "@/lib/pick";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import { ContentForType, listAdvertisements } from "@/api/advertisements";
import CreateAdvertisementButton from "@/components/create-advertisement-button";
import { useRevalidateCache } from "@/hooks/use-invalidate";
import { IntegerState, useSearchState } from "@/hooks/use-search-state";
import { normalize } from "@/lib/format";
import { useRenderTrack } from "@/hooks/use-render-track";

const typeTabList = fromEntries(
  entries(ContentForType).map(([k, v]) => [
    k,
    { Icon: v.icon, label: v.shortLabel },
  ]),
);

const statusTabList = {
  [ADVERTISEMENT_STATUS.ONGOING]: { label: "En cours" },
  [ADVERTISEMENT_STATUS.ENDED]: { label: "Passées" },
  [ADVERTISEMENT_STATUS.ARCHIVED]: { label: "Archivées" },
};

const limit = 10;
const placeholderRows = Array.from({ length: limit }, () =>
  crypto.randomUUID(),
);

export interface IAdvertisementsPageProps {}

export default function AdvertisementsPage(props: IAdvertisementsPageProps) {
  const navigation = useRouteNavigation("/app/advertisements");
  // useRevalidateCache(listAdvertisements);
  const data = useLoaderData() as IAdvertisementsLoaderData;

  const [setType, type] = useSearchState<ADVERTISEMENT_TYPE>({
    key: "type",
    defaultValue: ADVERTISEMENT_TYPE.EVENT,
    // @ts-ignore
    parse: (v) => AdvertisementTypeSchema.parse(v),
    middleware(s) {
      s.set("offset", "0");
    },
  });
  const [setStatus, status] = useSearchState<ADVERTISEMENT_STATUS>({
    key: "status",
    defaultValue: ADVERTISEMENT_STATUS.ONGOING,
    // @ts-ignore
    parse: (v) => AdvertisementStatusSchema.parse(v),
    middleware(s) {
      s.set("offset", "0");
    },
  });
  const [setOffset, offset] = useSearchState({
    key: "offset",
    defaultValue: 0,
    ...IntegerState,
  });
  const [setSearch, search] = useSearchState<string>({
    key: "query",
    parse: normalize,
    serialize: normalize,
    delay: 300,
    defaultValue: "",
    middleware(s) {
      s.set("offset", "0");
    },
  });

  React.useEffect(() => {
    if (status !== ADVERTISEMENT_STATUS.ONGOING)
      setStatus(ADVERTISEMENT_STATUS.ONGOING);
  }, [type]);

  const page = offset / limit;
  function setPage(newPage: number) {
    setOffset(newPage * limit);
  }

  const COLUMNS = React.useMemo(() => columnsForType(type), [type]);
  const [columns, columnsState, setColumnState] = useColumns(
    "advertisements",
    COLUMNS,
  );
  const renderAds = React.useMemo(() => {
    return (advertisements: Awaited<(typeof data)["advertisements"]>) => {
      return advertisements.results.map((advertisement) => (
        <AdvertisementRow
          key={advertisement.id}
          advertisement={advertisement}
          columnState={columnsState}
        />
      ));
    };
  }, [columnsState]);
  useRenderTrack([data], "data");
  useRenderTrack([setType, type], "type");
  useRenderTrack([setStatus, status], "status");
  useRenderTrack([setOffset, offset], "offset");
  useRenderTrack([setSearch, search], "search");
  useRenderTrack([COLUMNS], "COLUMNS");
  useRenderTrack([columns], "columns1");
  useRenderTrack([columnsState], "columns2");
  useRenderTrack([setColumnState], "columns3");
  console.log("yolo");
  return (
    <>
      <div className="top-4 bg-background z-20 sticky w-full">
        <div className="max-w-7xl mx-auto flex flex-col flex-wrap gap-4 w-full items-end pb-4 border-b border-b-border">
          <div className="shrink-0 flex w-full gap-6 justify-between">
            <h1 className="text-2xl font-semibold leading-none tracking-tight">
              Mes annonces
            </h1>
            <CreateAdvertisementButton />
          </div>
          <div className="flex justify-between gap-2 w-full">
            {data.enabledTypes.length > 1 ? (
              <TabSelector
                disabled={navigation.state}
                tabs={pick(typeTabList, ...data.enabledTypes)}
                value={type}
                onTabChange={setType}
              />
            ) : (
              <div />
            )}
            <TabSelector
              disabled={navigation.state}
              tabs={
                type === ADVERTISEMENT_TYPE.EVENT
                  ? statusTabList
                  : omit(statusTabList, ADVERTISEMENT_STATUS.ENDED)
              }
              value={status}
              onTabChange={setStatus}
            />
          </div>
          <div className="flex items-center gap-2 w-full">
            <Input
              className="w-96 shrink-0 bg-card"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Rechercher..."
            />
            <Awaited
              loading={navigation.state}
              resolve={data.advertisements}
              fallback={<Skeleton className="h-4 w-20 shrink-0" />}
              render={({ meta: { count } }) => (
                <span className="text-sm text-muted-foreground shrink-0 ">
                  {count} résultat{count !== 1 ? "s" : ""}
                </span>
              )}
            />
            <div className="filler" />
            <CheckboxDropdown
              name="yolo"
              items={Object.values(columns)}
              values={fromEntries(
                entries(columnsState).map(([k, s]) => [k, s.visible]),
              )}
              onChange={(column, visible) =>
                setColumnState({ state: { [column]: { visible } } })
              }
            >
              <Button variant="outline" size="sm" className="border-dashed">
                Colonnes
              </Button>
            </CheckboxDropdown>
          </div>
        </div>
      </div>
      <Awaited
        // loading={navigation.state}
        resolve={data.isEnabledTypes}
        render={(enable) => {
          if (!enable)
            return (
              <div
                className="w-full max-w-7xl rounded-sm mx-auto !mt-8 shrink bg-muted flex items-center justify-center"
                style={{
                  height: `calc(100vh - 64px - 8px - 153px - 32px - 32px)`,
                }}
              >
                Not enabled
              </div>
            );
        }}
      />
      <div className="mx-auto max-w-7xl w-full overflow-auto space-y-4">
        <ScrollArea className="border border-border rounded-md">
          <Table>
            <Awaited
              loading={navigation.state}
              resolve={data.advertisements}
              render={(advertisements) => {
                if (!advertisements.results.length)
                  return (
                    <TableCaption className="mb-4">Aucun résultat</TableCaption>
                  );
                return null;
              }}
            />
            <TableHeader>
              <TableRow>
                {entries(columns)
                  .filter(([key]) => columnsState[key].visible)
                  .map(([_, { value, header: Header }]) => (
                    <Header key={value} />
                  ))}
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <Awaited
                loading={navigation.state}
                resolve={data.advertisements}
                fallback={placeholderRows.map((k) => (
                  <AdvertisementRow.Skeleton
                    key={k}
                    columnState={columnsState}
                  />
                ))}
                render={renderAds}
              />
            </TableBody>
          </Table>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
        <Awaited
          loading={navigation.state}
          resolve={data.advertisements}
          fallback={
            <Skeleton className="h-10 w-[430px] mx-auto max-w-screen shrink" />
          }
        >
          {({ meta: { count } }) => (
            <SmartPagination
              count={count}
              page={page}
              setPage={setPage}
              perPage={limit}
            />
          )}
        </Awaited>
      </div>
    </>
  );
}
