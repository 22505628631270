import { objectFromFormData } from "@/lib/query";
import { ActionFunctionArgs } from "react-router-dom";
import * as z from "@/lib/zod";
import { withToast } from "@/lib/with-toast";
import { PromisePool } from "@supercharge/promise-pool";
import { createBlacklists } from "@/api/blacklists";
import { ms } from "@/lib/timer";

export interface ICatalogActionData {}
export async function CatalogAction({
  request,
}: ActionFunctionArgs): Promise<ICatalogActionData> {
  const body = objectFromFormData(await request.formData());
  if (request.method === "DELETE") {
    await withToast(
      async () => {
        const payload = z
          .object({
            advertisementId: z.string(),
            widgetIds: z.oneOrMore(z.string()),
          })
          .parse(body);
        const { errors } = await PromisePool.for(payload.widgetIds)
          .withConcurrency(5)
          .withTaskTimeout(ms("5 seconds"))
          .process((id) => createBlacklists(id, [payload.advertisementId]));
        if (errors.length) {
          errors.forEach(({ raw }) => console.error(raw));
          throw errors;
        }
      },
      {
        loading: "Traitement en cours, vous pouvez fermer cette popup.",
        error: "Une erreur est survenue, veuillez réessayer ultérieurement.",
        success: "Opération réussie",
      },
    );
  }
  return {};
}
