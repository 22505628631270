import * as React from "react";
import {
  useFetcher,
  useLoaderData,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { IWidgetSchedulesLoaderData, ScheduleStatus } from "./loader";
import Awaited from "@/components/awaited";
import { Button } from "@/components/ui/button";
import RefPortal from "@/components/ref-portal";
import {
  CalendarClock,
  CalendarOff,
  LifeBuoy,
  Plus,
  Radio,
} from "lucide-react";
import TabSelector from "@/components/tab-selector";
import {
  Table,
  TableBody,
  TableCaption,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import CheckboxDropdown from "@/components/checkbox-dropdown";
import {
  defaultSchedulesTableColumns,
  SchedulesTableColumns,
} from "./(blocks)/columns";
import ScheduleRow from "./(blocks)/schedule-row";
import { useRouteNavigation } from "@/hooks/use-route-navigation";
import { useColumns } from "@/hooks/useColumns";
import { entries, fromEntries } from "@/lib/entries";
import SmartPagination from "@/components/smart-pagination";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { useRevalidateCache } from "@/hooks/use-invalidate";
import { listSchedules } from "@/api/schedules";
import { omit } from "@/lib/pick";
import { TabLayoutContent } from "@/components/tab-layout";
import { useSearchState } from "@/hooks/use-search-state";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";
import { ISingleWidgetLoaderData } from "../loader";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export interface IWidgetSchedulesPageProps {}

const tabList = {
  [ScheduleStatus.PAST]: { Icon: CalendarOff, label: "Terminées" },
  [ScheduleStatus.ACTIVE]: { Icon: Radio, label: "Actives" },
  [ScheduleStatus.FUTURE]: { Icon: CalendarClock, label: "Programmées" },
};

const placeholderRows = Array.from({ length: 8 }, () => crypto.randomUUID());

export default function WidgetSchedulesPage(props: IWidgetSchedulesPageProps) {
  const { widgetId } = useParams();
  useRevalidateCache(listSchedules, ([params]) =>
    params.widgetIds.includes(widgetId!),
  );
  const { widget } = useRouteLoaderData(
    "single-widget-route",
  ) as ISingleWidgetLoaderData;
  const data = useLoaderData() as IWidgetSchedulesLoaderData;
  const [setTab, tab] = useSearchState({
    key: "status",
    defaultValue: ScheduleStatus.ACTIVE,
  });
  const [columns, columnsState, setColumnState] = useColumns(
    `widget-schedules-${tab}`,
    ...(tab === ScheduleStatus.PAST
      ? ([
          omit(SchedulesTableColumns, "paused"),
          omit(defaultSchedulesTableColumns, "paused"),
        ] as const)
      : ([SchedulesTableColumns, defaultSchedulesTableColumns] as const)),
  );
  const [page, setPage] = React.useState(0);
  const navigation = useRouteNavigation();
  React.useEffect(() => {
    setPage(0);
  }, [navigation.state]);
  const navigate = useSmartNavigate();

  const fetcher = useFetcher();
  return (
    <TabLayoutContent size="full-width">
      <RefPortal selector="#tab-layout-head-outlet" key="catalogue-button">
        <Button
          className="enter animation-fast animation-small"
          onClick={() => navigate(`/app/catalog?defaultWidget=${widgetId}`)}
        >
          <Plus className="w-5 h-5 mr-2" /> Accéder au catalogue
        </Button>
        <Awaited
          resolve={widget}
          render={({ id }) => (
            <fetcher.Form
              method="POST"
              action="/app/widgets/:widgetId/configuration?index"
            >
              <input type="hidden" name="widgetId" value={id} />
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="outline"
                    size="icon"
                    disabled={fetcher.state !== "idle"}
                    className="enter animation-fast animation-small"
                  >
                    <LifeBuoy className="w-5 h-5" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  Forcer l'actualisation du widget
                </TooltipContent>
              </Tooltip>
            </fetcher.Form>
          )}
        />
      </RefPortal>
      <div className="flex justify-between flex-wrap w-full items-center">
        <div className="flex items-center gap-2">
          <TabSelector
            className="enter"
            disabled={navigation.state}
            tabs={tabList}
            value={tab}
            onTabChange={setTab}
          />
          <Awaited
            loading={navigation.state}
            resolve={data.schedules}
            render={({ meta: { count } }) => (
              <span className="text-xs">
                {count} résultat{count !== 1 ? "s" : ""}
              </span>
            )}
          />
        </div>
        <div>
          <CheckboxDropdown
            items={Object.values(columns)}
            values={fromEntries(
              entries(columnsState).map(([k, s]) => [k, s.visible]),
            )}
            onChange={(column, visible) =>
              setColumnState({ state: { [column]: { visible } } })
            }
          >
            <Button variant="outline" size="sm" className="border-dashed enter">
              Colonnes
            </Button>
          </CheckboxDropdown>
        </div>
      </div>
      <ScrollArea className="border border-border rounded-md enter animation-fast">
        <Table>
          <Awaited
            loading={navigation.state}
            resolve={data.schedules}
            render={(schedules) => {
              if (!schedules.results.length)
                return (
                  <TableCaption className="mb-4">Aucun résultat</TableCaption>
                );
              return null;
            }}
          />
          <TableHeader>
            <TableRow>
              {entries(columns)
                .filter(([key]) => columnsState[key].visible)
                .map(([_, { value, header: Header }]) => (
                  <Header key={value} />
                ))}
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <Awaited
              loading={navigation.state}
              resolve={data.schedules}
              fallback={placeholderRows.map((key) => (
                <ScheduleRow.Skeleton key={key} columnState={columnsState} />
              ))}
              render={(schedules) => (
                <Awaited
                  loading={navigation.state}
                  resolve={data.advertisements}
                  fallback={schedules.results
                    .slice(page * 10, (page + 1) * 10)
                    .map((schedule) => (
                      <ScheduleRow
                        key={schedule.id}
                        schedule={schedule}
                        columnState={columnsState}
                      />
                    ))}
                  render={(ads) =>
                    schedules.results
                      .slice(page * 10, (page + 1) * 10)
                      .map((schedule) => {
                        const advertisement = ads.results.find(
                          (a) => a.id === schedule.advertisement_version_id,
                        );
                        return (
                          <ScheduleRow
                            key={schedule.id}
                            schedule={schedule}
                            columnState={columnsState}
                            location_name={advertisement?.location_name}
                            dates={
                              advertisement && "dates" in advertisement
                                ? advertisement.dates
                                : null
                            }
                          />
                        );
                      })
                  }
                />
              )}
            />
          </TableBody>
        </Table>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
      <Awaited loading={navigation.state} resolve={data.schedules}>
        {({ meta: { count } }) => (
          <SmartPagination count={count} page={page} setPage={setPage} />
        )}
      </Awaited>
    </TabLayoutContent>
  );
}
