import { getCurrentUser } from "@/api/authentication";
import { SafeUsersDTO } from "@/api/definitions";
import { LoaderFunctionArgs, defer, redirect } from "react-router-dom";

export interface IUserSettingsLoaderData {
  user: Promise<SafeUsersDTO>;
}

export async function UserSettingsLoader({ request }: LoaderFunctionArgs) {
  return defer({
    user: getCurrentUser(request.signal).then((res) => {
      if (!res) throw redirect("/app");
      return res.user;
    }),
  } satisfies IUserSettingsLoaderData);
}
