import * as React from "react";
import { Navigate, Route } from "react-router-dom";
import { SingleWidgetLoader } from "./loader";
import SingleWidgetLayout from "./layout";
import WidgetConfigurationRouter from "./configuration";
import WidgetAnalyticsRouter from "./analytics";
import WidgetBlacklistsRouter from "./blacklists";
import WidgetSchedulesRouter from "./schedules";

export interface ISingleWidgetRouterProps {}

export default function SingleWidgetRouter(props: ISingleWidgetRouterProps) {
  return (
    <Route
      id="single-widget-route"
      Component={SingleWidgetLayout}
      loader={SingleWidgetLoader}
    >
      <Route index element={<Navigate to="analytics" replace />} />
      <Route path="analytics">{WidgetAnalyticsRouter({})}</Route>
      <Route path="blacklists">{WidgetBlacklistsRouter({})}</Route>
      <Route path="configuration">{WidgetConfigurationRouter({})}</Route>
      <Route path="schedules">{WidgetSchedulesRouter({})}</Route>
    </Route>
  );
}
