import * as React from "react";
import { Input } from "./ui/input";
import { toast } from "sonner";
import { Button } from "./ui/button";
import { cn } from "@/lib/utils";
import { omit } from "@/lib/pick";

export type IImageInputProps = Omit<
  React.ComponentPropsWithoutRef<"div">,
  "file" | "onChange"
> &
  (
    | { file: string; onChange?: undefined; btnLabel?: undefined }
    | {
        file: string | undefined | null;
        onChange(file: string): void;
        btnLabel?: string;
      }
  );

export default function ImageInput(props: IImageInputProps) {
  const types = ["image/jpeg", "image/jpg", "image/png", "image/webp"];
  return (
    <div
      {...omit(props, "file", "onChange", "btnLabel")}
      className={cn(
        "relative overflow-hidden",
        props.file ? "w-full" : "w-fit",
        props.className,
      )}
    >
      {props.onChange ? (
        <Input
          type="file"
          title=""
          className="w-full h-full opacity-0 cursor-default p-0 absolute inset-0 peer z-20"
          accept={types.join(",")}
          multiple={false}
          onChange={(e) => {
            const targetFile = e.target.files?.[0];
            if (!targetFile) return;
            if (!types.includes(targetFile.type)) {
              toast.error(
                `Echec du chargement de l'image: Le fichier doit être une image au format jpg, png ou webp.`,
              );
              return;
            }
            if (targetFile.size < 1024 || targetFile.size > 1024 ** 5) {
              toast.error(
                `Echec du chargement de l'image: Le fichier doit faire entre 1Ko et 5Mo.`,
              );
              return;
            }
            props.onChange(URL.createObjectURL(targetFile));
          }}
        />
      ) : null}
      {props.file ? (
        <>
          <img
            src={props.file}
            className="w-full absolute inset-0 z-0 aspect-[2] object-cover object-center rounded-md border border-border"
          />
          <img
            src={props.file}
            className="w-full aspect-[2] relative z-10 object-contain backdrop-blur-md object-center rounded-md border border-border"
          />
        </>
      ) : props.onChange ? (
        <Button className="peer-hover:opacity-50 cursor-default">
          {props.btnLabel ?? "Ajouter une image"}
        </Button>
      ) : null}
    </div>
  );
}
