import * as React from "react";
import {
  useLoaderData,
  useNavigate,
  useRouteLoaderData,
  useSearchParams,
} from "react-router-dom";
import { IAdvertisementAnalyticsLoaderData } from "./loader";
import { useRouteNavigation } from "@/hooks/use-route-navigation";
import {
  AdvertisementAnalyticsFiltersDTO,
  AdvertisementAnalyticsFiltersSchema,
} from "@/api/definitions";
import { ITimePeriod, useTimePeriods } from "@/hooks/use-time-periods";
import { parseDate } from "@/lib/date";
import { fromEntries, keys } from "@/lib/entries";
import { objectFromSearchParams } from "@/lib/query";
import dayjs, { ConfigType } from "@/lib/date";
import Awaited from "@/components/awaited";
import DateRangeSelector from "@/components/date-range-selector";
import RefPortal from "@/components/ref-portal";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { Download, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import ImpressionChart from "./(blocks)/impressions";
import ClicChart from "./(blocks)/clics";
import CheckboxDropdown from "@/components/checkbox-dropdown";
import { withId } from "@/lib/array";
import { pick } from "@/lib/pick";
import * as z from "@/lib/zod";
import { TabLayoutContent } from "@/components/tab-layout";
import { useAbortEffect } from "@/hooks/use-abort-effect";
import { ISingleAdvertisementLoaderData } from "../loader";
import { formatPercent } from "@/lib/format";

export interface IAdvertisementAnalyticsPageProps {}

export default function AdvertisementAnalyticsPage(
  props: IAdvertisementAnalyticsPageProps,
) {
  const navigation = useRouteNavigation();
  const data = useLoaderData() as IAdvertisementAnalyticsLoaderData;
  const adsData = useRouteLoaderData(
    "single-advertisement-route",
  ) as ISingleAdvertisementLoaderData;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  useAbortEffect(
    (signal) => {
      adsData.advertisement.then((res) => {
        if (signal.aborted) return;
        if (!res.isOwned) navigate("../content", { replace: true });
      });
    },
    [adsData.advertisement],
  );
  const periods = useTimePeriods();

  const [widgetIds, dates] = React.useMemo(() => {
    const parsed = z
      .searchParams(AdvertisementAnalyticsFiltersSchema, "safeParse")
      .parse(searchParams);
    const { widgetId, from, to } = parsed.success
      ? parsed.data
      : ({} as AdvertisementAnalyticsFiltersDTO);
    const dates = [
      parseDate(from, periods["24h"].value[0]),
      parseDate(to, periods["24h"].value[1]),
    ] as [ConfigType, ConfigType];
    return [(widgetId ?? []) as string[], dates] as const;
  }, [pick(objectFromSearchParams(searchParams), "from", "to", "widgetId")]);

  function setWidgets(newValue: string[]) {
    setSearchParams(
      (prev) => {
        prev.delete("widgetId");
        for (const id of newValue) prev.append("widgetId", id);
        return prev.toString();
      },
      { replace: true },
    );
  }

  function setDates(newValue: [ConfigType, ConfigType]) {
    setSearchParams(
      (prev) => {
        prev.set("from", dayjs(newValue[0]).startOf("day").toISOString());
        prev.set("to", dayjs(newValue[1]).endOf("day").toISOString());
        return prev.toString();
      },
      { replace: true },
    );
  }

  return (
    <TabLayoutContent>
      <RefPortal selector="#tab-layout-head-outlet">
        <div className="enter animation-small animation-fast">
          {widgetIds.length ? (
            <Button
              variant="ghost"
              className="my-auto"
              size="icon"
              onClick={() => setWidgets([])}
            >
              <X className="w-3 h-3" />
            </Button>
          ) : null}
          <Awaited
            resolve={data.widgets}
            render={(widgets) => {
              const values = fromEntries(
                widgets.results.map((widget) => {
                  const isIncluded = widgetIds.includes(widget.id);
                  return [widget.id, isIncluded];
                }),
              );
              const items = widgets.results.map((widget) => {
                return { value: widget.id, label: widget.name };
              });
              return (
                <CheckboxDropdown
                  items={items}
                  values={values}
                  onChange={(_, __, newValue) => {
                    const list = keys(newValue).filter((s) => newValue[s]);
                    if (list.length === widgets.results.length) setWidgets([]);
                    setWidgets(list);
                  }}
                  searchEnabled
                >
                  <Button variant="outline">
                    {!widgetIds.length
                      ? "Tous les widgets"
                      : widgetIds.length === 1
                        ? widgets.results.find(withId(widgetIds[0]))?.name
                        : `${widgetIds.length} widgets sélectionnés`}
                  </Button>
                </CheckboxDropdown>
              );
            }}
          />
        </div>
        <DateRangeSelector
          className="enter animation-small animation-fast"
          periods={Object.values(periods) as ITimePeriod[]}
          value={dates}
          setValue={setDates}
          disabled={navigation.state !== "idle"}
        />
        <Button
          className="enter animation-small animation-fast"
          variant="secondary"
          size="icon"
          disabled={navigation.state !== "idle"}
        >
          <Download className="h-5 w-5" />
        </Button>
      </RefPortal>
      <Card className="enter">
        <CardHeader className="flex items-center gap-2 border-b border-b-border sm:flex-row space-y-0 py-0 pr-0">
          <div className="grid flex-1 gap-1 text-center sm:text-left py-4">
            <CardTitle>Impressions</CardTitle>
          </div>
          <div className="flex flex-col justify-center items-end gap-1 border-l border-border px-6 py-4 text-right w-64 shrink sm:px-8 sm:py-6">
            <span className="text-sm text-muted-foreground">Total</span>
            <span className="text-lg font-bold leading-none sm:text-3xl">
              <Awaited
                resolve={data.analytics}
                fallback={<Skeleton className="w-16 h-5" />}
                render={(analytics) =>
                  analytics.data.reduce((acc, cur) => acc + cur.impressions, 0)
                }
              />
            </span>
          </div>
        </CardHeader>
        <CardContent className="px-2 pt-4 sm:px-6 sm:pt-6">
          <Awaited
            resolve={data.analytics}
            fallback={<Skeleton className="w-full h-[250px]" />}
          >
            <ImpressionChart />
          </Awaited>
        </CardContent>
      </Card>
      <Card className="enter">
        <CardHeader className="flex items-center gap-2 border-b border-b-border sm:flex-row space-y-0 py-0 pr-0">
          <div className="grid flex-1 gap-1 text-center sm:text-left py-4">
            <CardTitle>Clics</CardTitle>
          </div>
          <div className="flex flex-col justify-center items-end gap-1 border-l border-border px-6 py-4 text-right w-64 shrink sm:px-8 sm:py-6">
            <span className="text-sm text-muted-foreground">CTR</span>
            <span className="text-lg font-bold leading-none sm:text-3xl">
              <Awaited
                resolve={data.analytics}
                fallback={<Skeleton className="w-16 h-5" />}
                render={(analytics) =>
                  formatPercent(
                    (100 *
                      analytics.data.reduce((acc, cur) => acc + cur.clics, 0)) /
                      (analytics.data.reduce(
                        (acc, cur) => acc + cur.impressions,
                        0,
                      ) || 1),
                  )
                }
              />
            </span>
          </div>
          <div className="flex flex-col justify-center items-end gap-1 border-l border-border px-6 py-4 text-right w-64 shrink sm:px-8 sm:py-6">
            <span className="text-sm text-muted-foreground">Total</span>
            <span className="text-lg font-bold leading-none sm:text-3xl">
              <Awaited
                resolve={data.analytics}
                fallback={<Skeleton className="w-16 h-5" />}
                render={(analytics) =>
                  analytics.data.reduce((acc, cur) => acc + cur.clics, 0)
                }
              />
            </span>
          </div>
        </CardHeader>
        <CardContent className="px-2 pt-4 sm:px-6 sm:pt-6">
          <Awaited
            resolve={data.analytics}
            fallback={<Skeleton className="w-full h-[250px]" />}
          >
            <ClicChart />
          </Awaited>
        </CardContent>
      </Card>
    </TabLayoutContent>
  );
}
