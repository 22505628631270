import { getCurrentUser } from "@/api/authentication";
import { listInvites } from "@/api/invites";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface IOnboardingCompanyLoaderLoaderData {
  invites: ReturnType<typeof listInvites>;
}

export async function OnboardingCompanyLoaderLoader({
  request,
}: LoaderFunctionArgs) {
  const session = await getCurrentUser(request.signal);
  if (!session) throw redirect("/auth");
  if (session.company) throw redirect("/app");
  return defer({
    invites: listInvites(request.signal),
  } satisfies IOnboardingCompanyLoaderLoaderData);
}
