import * as React from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button, ButtonProps } from "./ui/button";
import { cn } from "@/lib/utils";
import { LucideIcon } from "lucide-react";

export interface ISearchSelectOptions {
  value: string;
  label: string;
  icon?: LucideIcon;
  children?: ISearchSelectOptions[];
}
export interface ISearchSelectProps
  extends Omit<
    ButtonProps,
    "options" | "placeholder" | "name" | "children" | "selected" | "onSelect"
  > {
  options: ISearchSelectOptions[];
  placeholder?: React.ReactNode;
  name?: string;
  children?: React.ReactNode;
  selected?: string;
  onSelect?: (value: string) => void;
}

export default function SearchSelect({
  name,
  options,
  placeholder,
  children,
  selected,
  onSelect,
  ...props
}: ISearchSelectProps) {
  const [open, setOpen] = React.useState(false);
  const [selectedOptions, setSelectedOptions] =
    React.useState<ISearchSelectOptions | null>(null);
  React.useEffect(() => {
    const match = options.find((s) => s.value === selected);
    if (!match) return;
    setSelectedOptions(match);
  }, [selected]);
  React.useEffect(() => {
    if (!selectedOptions || !onSelect) return;
    onSelect(selectedOptions.value);
  }, [selectedOptions]);
  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          {children || (
            <Button
              variant="outline"
              size="sm"
              {...props}
              className={cn(
                "w-[180px] justify-start min-h-[40px]",
                props.className,
              )}
            >
              {selectedOptions ? (
                <>
                  {selectedOptions.icon ? (
                    <selectedOptions.icon className="mr-2 h-4 w-4 shrink-0" />
                  ) : null}
                  {selectedOptions.label}
                </>
              ) : (
                <span className="text-muted-foreground">{placeholder}</span>
              )}
            </Button>
          )}
        </PopoverTrigger>
        <PopoverContent tabIndex={0} className="p-0 pointer-events-auto z-50">
          <Command className="pointer-events-auto">
            <CommandInput
              className="pointer-events-auto"
              placeholder="Rechercher..."
              autoFocus
            />
            <CommandList className="pointer-events-auto">
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => (
                  <OptionsGroup
                    key={option.value}
                    {...option}
                    setOpen={setOpen}
                    setSelectedOptions={setSelectedOptions}
                    selectedOptions={selectedOptions}
                    options={options.map(flatOptions).flat()}
                  />
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {name && selectedOptions ? (
        <input type="hidden" name={name} value={selectedOptions.value} />
      ) : null}
    </>
  );
}

function OptionsGroup({
  value,
  label,
  icon: Icon,
  children,
  options,
  selectedOptions,
  setSelectedOptions,
  setOpen,
}: ISearchSelectOptions & {
  options: ISearchSelectOptions[];
  selectedOptions: ISearchSelectOptions | null;
  setSelectedOptions: React.Dispatch<
    React.SetStateAction<ISearchSelectOptions | null>
  >;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <>
      <CommandItem
        key={value}
        value={value}
        keywords={[label]}
        onSelect={(value) => {
          setSelectedOptions(
            options.find((priority) => priority.value == value) || null,
          );
          setOpen(false);
        }}
      >
        {Icon ? (
          <Icon
            className={cn(
              "mr-2 h-4 w-4",
              value === selectedOptions?.value ? "opacity-100" : "opacity-40",
            )}
          />
        ) : null}
        <span>{label}</span>
      </CommandItem>
      {children?.length ? (
        <CommandGroup>
          {children.map((o) => (
            <OptionsGroup
              key={o.value}
              {...o}
              setOpen={setOpen}
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions}
              options={options}
            />
          ))}
        </CommandGroup>
      ) : null}
    </>
  );
}

function flatOptions({
  children,
  ...option
}: ISearchSelectOptions): ISearchSelectOptions[] {
  return [option, ...(children ?? []).map(flatOptions).flat()];
}
