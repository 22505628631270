import { objectToSearchParams } from "@/lib/query";
import { API_BASE, logErrorResponse } from "./common";
import {
  ADVERTISEMENT_TYPE,
  AdvertisementDraftDTO,
  AdvertisementSummarySchema,
  CoordinatesSchema,
  FullAdvertisementDTO,
  FullAdvertisementSchema,
  FullEventAdvertisementDTO,
  ListAdvertisementsFiltersDTO,
  ListAdvertisementsFiltersSchema,
  ListResponseDTO,
  ListResponseSchema,
  UpdateAdvertisementDTO,
} from "./definitions";
import { cache } from "@/lib/cache";
import { Briefcase, Building, PartyPopper } from "lucide-react";
import { pick } from "@/lib/pick";
import { z } from "zod";
import { chunkArray } from "@/lib/array";
import { deepMergeAll, keys } from "@/lib/entries";
import { toast } from "sonner";
import dayjs from "dayjs";

export const listAdvertisements = cache(async function _listAdvertisements(
  params: ListAdvertisementsFiltersDTO,
  signal?: AbortSignal,
): Promise<ListResponseDTO<FullAdvertisementDTO>> {
  if (params.ids && params.ids.length > 20) {
    const chunks = await Promise.all(
      chunkArray(params.ids, 20).map((ids) =>
        _listAdvertisements.call(this, { ...params, ids }, signal),
      ),
    );
    return deepMergeAll(...chunks);
  }
  const query = objectToSearchParams(params);
  const response = await fetch(`${API_BASE}/advertisements?${query}`, {
    headers: {
      // "disable-admin": "true",
    },
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return ListResponseSchema(
    FullAdvertisementSchema,
    ListAdvertisementsFiltersSchema,
  ).parse(data);
}, "5 minutes");

export const getAdvertisementById = cache(async function getAdvertisementById(
  advertisementId: string,
  signal?: AbortSignal,
) {
  const response = await fetch(
    `${API_BASE}/advertisements/${advertisementId}`,
    {
      credentials: "include",
      signal,
    },
  );
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return FullAdvertisementSchema.parse(data);
}, "5 minutes");

export const getAdvertisementSummary = cache(
  async function getAdvertisementSummary(
    advertisementId: string,
    signal?: AbortSignal,
  ) {
    const response = await fetch(
      `${API_BASE}/advertisements/${advertisementId}/summary`,
      {
        credentials: "include",
        signal,
      },
    );
    if (!response.ok) throw await logErrorResponse(response);
    const data = await response.json();
    return AdvertisementSummarySchema.parse(data);
  },
  "1 second",
);

export async function createAdvertisement(
  draft: AdvertisementDraftDTO,
  signal?: AbortSignal,
) {
  const [coordinate, image] = await Promise.all([
    createCoordinates(draft, signal),
    uploadImage(draft, signal),
  ]);

  const response = await fetch(`${API_BASE}/advertisements`, {
    method: "POST",
    credentials: "include",
    signal,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      type: draft.type,
      title: draft.title,
      description: draft.description || null,
      main_image: image,
      url: draft.url,
      landscape_image: image,
      is_main_version: false,
      coordinates_id: coordinate.id,
      location_name: draft.location_name,
      // @ts-ignore
      surface: draft.surface,
      // @ts-ignore
      rooms: draft.rooms,
      // @ts-ignore
      price: draft.price,
      // @ts-ignore
      company_name: draft.company_name,
      // @ts-ignore
      categories: draft.categories,
      // @ts-ignore
      dates: draft.dates,
    }),
  });
  if (!response.ok) throw await logErrorResponse(response);
  listAdvertisements.invalidate(([p]) => p.type === draft.type);
  const data = await response.json();
  return z
    .object({ advertisementVersion: z.object({ id: z.string() }) })
    .parse(data).advertisementVersion.id;
}

export async function createCoordinates(
  payload: Pick<
    AdvertisementDraftDTO,
    "address" | "longitude" | "latitude" | "city"
  >,
  signal?: AbortSignal,
) {
  const response = await fetch(`${API_BASE}/coordinates`, {
    method: "POST",
    credentials: "include",
    signal,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      pick(payload, "address", "longitude", "latitude", "city"),
    ),
  });
  if (!response.ok) {
    if (response.status === 400)
      setTimeout(
        () => toast.error("Vous devez choisir une adresse en France."),
        1_000,
      );
    throw await logErrorResponse(response);
  }
  const data = await response.json();
  return CoordinatesSchema.parse(data);
}

export async function uploadImage(
  payload: Pick<AdvertisementDraftDTO, "image">,
  signal?: AbortSignal,
) {
  try {
    const blob = await fetch(payload.image).then((r) => r.blob());
    const presignedUrlResponse = await fetch(
      `${API_BASE}/object-storage/pre-signed?size=${blob.size}`,
      {
        method: "POST",
        credentials: "include",
        signal,
      },
    );
    if (!presignedUrlResponse.ok) throw presignedUrlResponse;
    const url = await presignedUrlResponse.text();
    console.log({ url });
    const uploadResponse = await fetch(
      new Request(url, {
        method: "PUT",
        signal,
        body: blob,
      }),
    ).catch((err) => {
      console.error(err);
      throw err;
    });
    if (!uploadResponse.ok) {
      throw await logErrorResponse(uploadResponse);
    }
    return new URL(url).pathname.slice(1);
  } catch (e) {
    setTimeout(
      () => toast.error("Veuillez renseigner l'image à nouveau"),
      1_000,
    );
    try {
      toast.error(
        `Image invalide. ${(e as Error).name} ${payload.image?.slice(0, 300)}`,
      );
    } catch (_) {
      toast.error(`Image invalide.`);
    }
    throw e;
  }
}

export async function updateAdvertisement(
  id: string,
  payload: UpdateAdvertisementDTO,
  signal?: AbortSignal,
) {
  if (keys(payload).filter((k) => k !== "type").length === 0) return true;
  const response = await fetch(`${API_BASE}/advertisements/${id}`, {
    method: "PUT",
    credentials: "include",
    signal,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) throw await logErrorResponse(response);
  getAdvertisementById.invalidate(([_id]) => id === _id);
  getAdvertisementSummary.invalidate(([_id]) => id === _id);
  listAdvertisements.invalidate();
  return true;
}
export async function addEventAdvertisementDates(
  advertisementId: string,
  dates: { from: Date; to: Date }[],
  signal?: AbortSignal,
) {
  if (!dates.length) return true;
  console.log({ dates });
  const response = await fetch(
    `${API_BASE}/advertisements/${advertisementId}/dates`,
    {
      method: "POST",
      credentials: "include",
      signal,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dates }),
    },
  );
  if (!response.ok) throw await logErrorResponse(response);
  getAdvertisementById.invalidate(([_id]) => advertisementId === _id);
  getAdvertisementSummary.invalidate(([_id]) => advertisementId === _id);
  listAdvertisements.invalidate();
  return true;
}
export async function addEventAdvertisementCategories(
  advertisementId: string,
  category_ids: number[],
  signal?: AbortSignal,
) {
  if (!category_ids.length) return true;
  const response = await fetch(
    `${API_BASE}/advertisements/${advertisementId}/categories`,
    {
      method: "POST",
      credentials: "include",
      signal,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ category_ids }),
    },
  );
  if (!response.ok) throw await logErrorResponse(response);
  getAdvertisementById.invalidate(([_id]) => advertisementId === _id);
  getAdvertisementSummary.invalidate(([_id]) => advertisementId === _id);
  listAdvertisements.invalidate();
  return true;
}
export async function deleteEventAdvertisementDates(
  advertisementId: string,
  eventDateId: number,
  signal?: AbortSignal,
) {
  const response = await fetch(
    `${API_BASE}/advertisements/${advertisementId}/dates/${eventDateId}`,
    {
      method: "DELETE",
      credentials: "include",
      signal,
    },
  );
  if (!response.ok) throw await logErrorResponse(response);
  getAdvertisementById.invalidate(([_id]) => advertisementId === _id);
  getAdvertisementSummary.invalidate(([_id]) => advertisementId === _id);
  listAdvertisements.invalidate();
  return true;
}
export async function removeEventAdvertisementCategory(
  advertisementId: string,
  categoryId: number,
  signal?: AbortSignal,
) {
  const response = await fetch(
    `${API_BASE}/advertisements/${advertisementId}/categories/${categoryId}`,
    {
      method: "DELETE",
      credentials: "include",
      signal,
    },
  );
  if (!response.ok) throw await logErrorResponse(response);
  getAdvertisementById.invalidate(([_id]) => advertisementId === _id);
  getAdvertisementSummary.invalidate(([_id]) => advertisementId === _id);
  listAdvertisements.invalidate();
  return true;
}

export async function duplicateAdvertisement(id: string, signal?: AbortSignal) {
  const response = await fetch(`${API_BASE}/advertisements/${id}/duplicate`, {
    method: "POST",
    credentials: "include",
    signal,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  const version = z
    .object({
      advertisementVersion: z.object({
        id: z.string(),
        type: z.nativeEnum(ADVERTISEMENT_TYPE),
      }),
    })
    .parse(data).advertisementVersion;
  listAdvertisements.invalidate(([p]) => p.type === version.type);
  return version.id;
}

export const ContentForType = {
  [ADVERTISEMENT_TYPE.EVENT]: {
    icon: PartyPopper,
    label: "Annonce événementielle",
    shortLabel: "Événement",
    miniLabel: "Event",
    new: "Nouvelle annonce événementielle",
  },
  [ADVERTISEMENT_TYPE.REAL_ESTATE]: {
    icon: Building,
    label: "Annonce immobilière",
    shortLabel: "Immobilier",
    miniLabel: "Immo",
    new: "Nouvelle annonce immobilière",
  },
  [ADVERTISEMENT_TYPE.JOB]: {
    icon: Briefcase,
    label: "Offre d'emploi",
    shortLabel: "Emploi",
    miniLabel: "Emploi",
    new: "Nouvelle offre d'emploi",
  },
} as const;

export function isEventAdvertisement(
  advertisement: FullAdvertisementDTO,
): advertisement is FullEventAdvertisementDTO {
  return advertisement.type === ADVERTISEMENT_TYPE.EVENT;
}
export function hasFutureDates(advertisement: FullAdvertisementDTO) {
  if (!isEventAdvertisement(advertisement)) return true;
  return !!advertisement.dates?.find((d) => dayjs().isAfter(d.to));
}
