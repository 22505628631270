import { createModalContext } from ".";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Form, useNavigation } from "react-router-dom";
import FormState from "@/components/form-state";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

export interface IInviteUserModalProps {
  closeModal(): void;
  companyId: number;
}

export function InviteUserModal({
  closeModal,
  companyId,
}: IInviteUserModalProps) {
  const navigation = useNavigation();
  return (
    <Dialog open onOpenChange={closeModal} modal={false}>
      <div className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
      <DialogContent className="bg-card border-border text-foreground">
        <DialogHeader>
          <DialogTitle>Inviter un utilisateur</DialogTitle>
          <DialogDescription>
            Inviter un nouvel utilisateur à rejoindre votre équipe
          </DialogDescription>
        </DialogHeader>
        <Form
          method="POST"
          action="/app/settings/team?index"
          className="space-y-4"
        >
          <div className="space-y-2">
            <Label>Adresse email</Label>
            <Input type="email" name="email" />
          </div>
          {/* <div className="space-y-2">
            <RadioGroup></RadioGroup>
          </div> */}
          <FormState data={{ companyId, policyId: 1 }} />
          <DialogFooter>
            <Button type="submit" disabled={navigation.state !== "idle"}>
              Confirmer
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export const [useInviteUserModal, InviteUserProvider, InviteUserContext] =
  createModalContext(InviteUserModal);
