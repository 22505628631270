import {
  TabLayout,
  TabLayoutBreadcrumb,
  TabLayoutTitle,
} from "@/components/tab-layout";
import { Building2, FileClock, User, Workflow } from "lucide-react";
import * as React from "react";

export interface IAdminLayoutProps {}

export default function AdminLayout(props: IAdminLayoutProps) {
  return (
    <TabLayout
      tabs={{
        actions: { label: "Actions", Icon: Workflow },
        companies: { label: "Entreprises", Icon: Building2 },
        users: { label: "Utilisateurs", Icon: User },
        logs: { label: "Logs", Icon: FileClock },
      }}
      defaultTab="actions"
    >
      <TabLayoutTitle title="Admin" />
      <TabLayoutBreadcrumb base={{ label: "Admin", url: "/app/admin" }} />
    </TabLayout>
  );
}
