import * as React from "react";
import { createModalContext } from ".";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Form, useSearchParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useCache } from "@/hooks/use-cache";
import Awaited from "@/components/awaited";
import {
  AdminUpdateCompanySchema,
  ADVERTISEMENT_TYPE,
  SUBSCRIPTION_TYPE,
} from "@/api/definitions";
import { Loader } from "lucide-react";
import FormState from "@/components/form-state";
import { ExtractResult } from "@/lib/cache";
import { hasSameContent } from "@/lib/array";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getCompanyById } from "@/api/companies";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { capitalize } from "@/lib/format";
import { ContentForType } from "@/api/advertisements";
import { Switch } from "@/components/ui/switch";
import { expandRegister } from "@/lib/form";
import DatePicker from "@/components/ui/date-picker";
import { q } from "@/lib/query";

export interface ICreateWidgetModalProps {
  closeModal(): void;
  companyId: number;
}

export interface IUpdateCompanyModalContentProps {
  closeModal(): void;
  company: ExtractResult<typeof getCompanyById>;
}

export default function UpdateCompanyModalContent({
  company,
  closeModal,
}: IUpdateCompanyModalContentProps) {
  const form = useForm({
    mode: "all",
    resolver: zodResolver(AdminUpdateCompanySchema),
    // defaultValues: { authorizedOrigins: company.authorizedOrigins }
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "authorizedOrigins",
  });

  React.useEffect(() => {
    // TS bug if we set it as defaultValue above
    if (
      !hasSameContent(
        form.getValues("authorizedOrigins"),
        company.authorizedOrigins,
      )
    )
      company.authorizedOrigins.forEach((url) => append(url));
  }, []);
  return (
    <>
      <div className="enter animation-fast space-y-4 w-full">
        <div className="space-y-2">
          <Label>Nom</Label>
          <Input defaultValue={company.name} {...form.register("name")} />
        </div>
        <div className="space-y-2">
          <Label>Abonnement</Label>
          <Select
            defaultValue={company.subscription}
            {...expandRegister(form, "subscription")}
          >
            <SelectTrigger>
              <SelectValue placeholder="Choisir un abonnement" />
            </SelectTrigger>
            <SelectContent>
              {Object.values(SUBSCRIPTION_TYPE).map((type) => (
                <SelectItem value={type}>{capitalize(type)}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="space-y-2">
          <Label>Fin de période d'essai</Label>
          <DatePicker
            date={(form.getValues("trialEnd") as Date) || company.trialEnd}
            onDateChange={(date) => form.setValue("trialEnd", date)}
          />
        </div>
        <div className="space-y-4">
          <Label>Type d'annonce</Label>
          <div className="grid grid-cols-3 gap-2">
            <div className="gap-2 grid">
              <Label>
                {ContentForType[ADVERTISEMENT_TYPE.EVENT].miniLabel}
              </Label>
              <Switch
                defaultChecked={company.eventEnabled}
                {...expandRegister(form, "eventEnabled")}
              />
            </div>
            <div className="gap-2 grid">
              <Label>
                {ContentForType[ADVERTISEMENT_TYPE.REAL_ESTATE].miniLabel}
              </Label>
              <Switch
                defaultChecked={company.immoEnabled}
                {...expandRegister(form, "immoEnabled")}
              />
            </div>
            <div className="gap-2 grid">
              <Label>{ContentForType[ADVERTISEMENT_TYPE.JOB].miniLabel}</Label>
              <Switch
                defaultChecked={company.jobEnabled}
                {...expandRegister(form, "jobEnabled")}
              />
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <Label>Sites</Label>
          <div className="space-y-2">
            {fields.map((field, index) => (
              <div key={field.id} className="flex items-center space-x-2">
                <Input
                  {...form.register(`authorizedOrigins.${index}`)}
                  placeholder="Url du site"
                />
                <Button
                  type="button"
                  variant="destructive"
                  onClick={() => remove(index)}
                >
                  Supprimer
                </Button>
              </div>
            ))}
            <Button
              type="button"
              variant="secondary"
              size="sm"
              onClick={() => append("")}
            >
              Ajouter un site
            </Button>
          </div>
        </div>
      </div>
      <DialogFooter className="enter animation-fast">
        <Form action={q("/app/admin/companies?index")} method="PUT">
          <FormState data={{ ...form.watch(), id: company.id }} />
          <Button type="submit">Confirmer</Button>
        </Form>
      </DialogFooter>
    </>
  );
}

export function UpdateCompanyModal({
  closeModal,
  companyId,
}: ICreateWidgetModalProps) {
  const companyQuery = useCache(getCompanyById, companyId);
  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent className="flex flex-col bg-card border-border text-foreground max-w-sm">
        <DialogHeader className="space-y-4 enter animation-fast">
          <DialogTitle>Modifier l'entreprise</DialogTitle>
        </DialogHeader>
        <Awaited
          resolve={companyQuery}
          fallback={
            <>
              <div className="enter animation-fast w-full h-full grow flex items-center justify-center">
                <Loader className="w-6 h-6 text-muted-foreground animate-spin" />
              </div>
            </>
          }
          render={(company) => (
            <UpdateCompanyModalContent
              company={company}
              closeModal={closeModal}
            />
          )}
        />
      </DialogContent>
    </Dialog>
  );
}

export const [
  useUpdateCompanyModal,
  UpdateCompanyProvider,
  UpdateCompanyContext,
] = createModalContext(UpdateCompanyModal);
