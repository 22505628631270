import * as React from "react";
import DatePicker from "./ui/date-picker";
import { Button } from "./ui/button";
import dayjs, { ConfigType, formatRanges } from "@/lib/date";
import { ArrowRight, X } from "lucide-react";
import { cn } from "@/lib/utils";

export interface IDateRangePickerProps
  extends Omit<React.ComponentPropsWithoutRef<"div">, "onChange"> {
  onChange(newValue: { from: Date; to: Date }): void;
  remove?(): void;
  value: {
    from: ConfigType;
    to: ConfigType;
  };
  disabled?: boolean;
  time?: boolean;
}

export const DateRangePicker = React.forwardRef<
  HTMLDivElement,
  IDateRangePickerProps
>(function DateRangePicker(
  { disabled, onChange, remove, value, time = true, ...props },
  ref,
) {
  const from = dayjs(value.from).toDate();
  const to = dayjs(value.to).toDate();
  return (
    <div
      {...props}
      ref={ref}
      className={cn(props.className, "py-3 flex items-center w-full")}
      title={formatRanges(from, to)}
    >
      {remove ? (
        <Button
          type="button"
          size="icon"
          variant="ghost"
          className="mr-0.5 shrink-0"
          onClick={remove}
        >
          <X className="w-4 h-4" />
        </Button>
      ) : null}
      <div className="grid grid-cols-[1fr_auto_1fr] w-full gap-2">
        <DatePicker
          date={from}
          time={time}
          pickerDisabled={disabled}
          onDateChange={(newDate) =>
            onChange({ from: newDate, to: newDate >= to ? newDate : to })
          }
        />
        <ArrowRight className="w-3 h-3 my-auto" />
        <DatePicker
          date={to}
          time={time}
          pickerDisabled={disabled}
          disabled={{ before: from }}
          onDateChange={(newDate) =>
            onChange({ from: newDate <= from ? newDate : from, to: newDate })
          }
        />
      </div>
    </div>
  );
});

export default DateRangePicker;
