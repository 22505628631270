import dayjs, { ConfigType } from "@/lib/date";
import * as React from "react";
import { DateRange } from "react-day-picker";

export function useCalendarParams(
  value: [ConfigType, ConfigType],
  setValue: (value: [ConfigType, ConfigType]) => void,
) {
  const [calendarParams, setCalendarParams] = React.useState<DateRange>();

  React.useEffect(() => {
    setCalendarParams(
      value[1]
        ? {
            from: dayjs(value[0]).toDate(),
            to: dayjs(value[1]).toDate(),
          }
        : { from: undefined },
    );
  }, [JSON.stringify(value)]);

  React.useEffect(() => {
    if (calendarParams?.from && calendarParams?.to)
      setValue([calendarParams.from, calendarParams.to]);
  }, [JSON.stringify(calendarParams)]);

  return [calendarParams, setCalendarParams] as const;
}
