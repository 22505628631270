import { entries } from "@/lib/entries";
import * as React from "react";

export interface IFormStateProps {
  data: Record<
    string,
    | string
    | number
    | boolean
    | Date
    | undefined
    | null
    | (string | number | boolean | Date | undefined | null)[]
  >;
}

export default function FormState({ data }: IFormStateProps) {
  return (
    <>
      {entries(data).map(([key, value]) => {
        if (value === undefined) return null;
        if (Array.isArray(value))
          return value.filter(Boolean).map((v) => {
            if (v === undefined) return null;
            return (
              <input
                type="hidden"
                key={`${key}:${v + ""}`}
                name={key}
                value={v + ""}
              />
            );
          });
        return (
          <input
            type="hidden"
            key={`${key}:${value + ""}`}
            name={key}
            value={value + ""}
          />
        );
      })}
    </>
  );
}
