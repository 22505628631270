import { getCurrentUser } from "@/api/authentication";
import { LoaderFunctionArgs, redirect } from "react-router-dom";

export interface IAuthLoaderData {}

export async function AuthLoader({
  request,
}: LoaderFunctionArgs): Promise<IAuthLoaderData> {
  const user = await getCurrentUser(request.signal);
  if (user?.company) throw redirect("/app");
  return {};
}
