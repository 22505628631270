import * as React from "react";
import { Route } from "react-router-dom";
import { AdminUsersLoader } from "./loader";
import { AdminUsersAction } from "./action";
import AdminUsersPage from "./page";

export interface IAdminUsersRouterProps {}

export default function AdminUsersRouter(props: IAdminUsersRouterProps) {
  return (
    <Route>
      <Route
        index
        loader={AdminUsersLoader}
        action={AdminUsersAction}
        Component={AdminUsersPage}
      />
    </Route>
  );
}
