import { listCompanyCustomizations } from "@/api/customizations";
import { CustomizationDTO } from "@/api/definitions";
import { withId } from "@/lib/array";
import { LoaderFunctionArgs } from "react-router-dom";

export interface ISingleDesignLoaderData {
  customizations: CustomizationDTO[];
  customization: CustomizationDTO;
}

export async function SingleDesignLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const id = params.customizationId;
  if (!id) throw new Error("customization not found");
  const customizations = await listCompanyCustomizations(request.signal);
  const customization = customizations.find(withId(+id));
  if (!customization) throw new Error("customization not found");
  return {
    customizations,
    customization,
  } satisfies ISingleDesignLoaderData;
}
