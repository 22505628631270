import ImageInput from "@/components/image-input";
import * as React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { useAsyncValue, useFetcher } from "react-router-dom";
import { ISingleAdvertisementLoaderData } from "../../loader";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { useOptimistic } from "@/hooks/use-optimistic";
import FormState from "@/components/form-state";
import { pick } from "@/lib/pick";
import { cn } from "@/lib/utils";

export interface IAdvertisementImagesCardProps {}

export default function AdvertisementImagesCard(
  props: IAdvertisementImagesCardProps,
) {
  const advertisement = useAsyncValue() as Awaited<
    ISingleAdvertisementLoaderData["advertisement"]
  >;
  const [image, setImage] = useOptimistic<string | null>(null);
  const fetcher = useFetcher();
  return (
    <Card className="enter">
      <fetcher.Form
        method="PUT"
        action="/app/advertisements/[id]/content?index"
      >
        <FormState data={{ image, ...pick(advertisement, "id", "type") }} />
        <CardHeader>
          <CardTitle>Images</CardTitle>
        </CardHeader>
        <CardContent className="space-y-10 pb-10 border-b border-b-border">
          <div className="flex flex-col items-center w-full gap-4">
            <Label>Format Paysage (principale)</Label>
            {!image ? <ImageInput file={advertisement.main_image} /> : null}
            {advertisement.isOwned ? (
              <>
                {image ? <ImageInput file={image} onChange={setImage} /> : null}
                <ImageInput
                  btnLabel="Choisir une image"
                  file={null}
                  onChange={setImage}
                />
              </>
            ) : null}
          </div>
          {/* Need backend update before showing to user */}
          {/*
        <div className="gap-4 flex flex-col items-center">
          <Label>Format Portrait</Label>
          <img
            src={advertisement.portrait_image ?? ""}
            className="h-[200px] w-[100px] bg-muted rounded-sm object-cover"
          />
        </div>
        <div className="gap-4 flex flex-col items-center">
          <Label>Format Carré</Label>
          <img
            src={advertisement.square_image ?? ""}
            className="h-[200px] w-[200px] bg-muted rounded-sm object-cover"
          />
        </div>
        */}
        </CardContent>
        <CardFooter
          className={cn(
            "flex justify-end py-4",
            advertisement.isOwned || "hidden",
          )}
        >
          <Button
            type="submit"
            size="sm"
            disabled={!image || image === advertisement.main_image}
          >
            Confirmer
          </Button>
        </CardFooter>
      </fetcher.Form>
    </Card>
  );
}
