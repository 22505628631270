import { listAdvertisements } from "@/api/advertisements";
import { ADVERTISEMENT_SORTING, ADVERTISEMENT_TYPE } from "@/api/definitions";
import { LoaderFunctionArgs, defer, redirect } from "react-router-dom";
import * as z from "@/lib/zod";
import { getCurrentUser } from "@/api/authentication";
import { extractEnabledAdvertisementTypes } from "@/api/companies";
import dayjs from "@/lib/date";

export interface ICatalogLoaderData {
  isEnabledTypes: boolean;
  enabledTypes: ADVERTISEMENT_TYPE[];
  advertisements: ReturnType<typeof listAdvertisements>;
}

export const CatalogLoaderQueryParamsSchema = z
  .object({
    type: z.nativeEnum(ADVERTISEMENT_TYPE).default(ADVERTISEMENT_TYPE.EVENT),
    query: z
      .string()
      .transform((s) => (s.length < 2 ? undefined : s))
      .optional(),
    limit: z.coerce.number().default(50),
    offset: z.coerce.number().default(0),
    zoneId: z.oneOrMore(z.coerce.number().int()).optional(),
    categories: z.oneOrMore(z.coerce.number().int()).optional(),
    dates: z
      .tuple([
        z.dateString().optional().default(dayjs().endOf("day").toDate()),
        z
          .dateString()
          .optional()
          .default(
            dayjs().endOf("day").add(3, "months").endOf("month").toDate(),
          ),
      ])
      .optional()
      .default([
        dayjs().endOf("day").toDate(),
        dayjs().endOf("day").add(3, "months").endOf("month").toDate(),
      ]),
  })
  .transform((q) => ({
    ...q,
    from: q.dates[0],
    to: q.dates[1],
  }));

const typeNotEnableResponse = defer({
  isEnabledTypes: false,
  enabledTypes: [],
  advertisements: Promise.resolve({
    results: [],
    filters: {},
    meta: { count: 0 },
  }),
} satisfies ICatalogLoaderData);

export async function CatalogLoader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const query = await z
    .searchParams(CatalogLoaderQueryParamsSchema, "safeParse")
    .parseAsync(url.searchParams);
  if (!query.success) {
    console.log("query.error", query.error);
    throw redirect(url.pathname);
  }
  const session = await getCurrentUser(request.signal);
  if (!session) throw redirect("/auth");
  if (!session?.company?.id) throw redirect("/onboarding");
  const { type, ...filters } = query.data;
  if (!extractEnabledAdvertisementTypes(session.company).includes(type)) {
    return typeNotEnableResponse;
  }
  return defer({
    isEnabledTypes: true,
    enabledTypes: [ADVERTISEMENT_TYPE.EVENT],
    advertisements: listAdvertisements(
      {
        type: ADVERTISEMENT_TYPE.EVENT,
        is_main_advertisement: true,
        archived: false,
        ...filters,
        sort: ADVERTISEMENT_SORTING.CLOSEST,
      },
      request.signal,
    ),
  } satisfies ICatalogLoaderData);
}
