import * as React from "react";
import { createModalContext } from ".";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Form } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useCache } from "@/hooks/use-cache";
import { getCurrentUser } from "@/api/authentication";
import Awaited from "@/components/awaited";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { IconForWidgetType, WIDGET_TYPE } from "@/api/definitions";
import { listOwnedWidgets } from "@/api/widgets";
import { canCreateWidget, PlanUpgradeError } from "@/api/authorization";
import { useLimitReachedModal } from "./limit-reached";
import { join } from "@/lib/promise";

export interface ICreateWidgetModalProps {
  closeModal?(triggerCloseEvent?: boolean): void;
}

export function CreateWidgetModal({ closeModal }: ICreateWidgetModalProps) {
  const currentUser = useCache(getCurrentUser);
  const isAuthorized = useCache(canCreateWidget).catch((e) => e);
  const limitReachedModal = useLimitReachedModal();
  return (
    <Dialog open onOpenChange={() => closeModal?.()}>
      <Awaited
        resolve={join(currentUser, isAuthorized)}
        render={([session, authRes]) => {
          if (!session) return null;
          if (authRes instanceof PlanUpgradeError) {
            limitReachedModal.openModal({ error: authRes });
            closeModal?.();
          }
          return (
            <DialogContent className="bg-card border-border text-foreground">
              <DialogHeader>
                <DialogTitle>Nouveau widget</DialogTitle>
                <DialogDescription>
                  Créez un nouvel espace publicitaire pour votre site.
                </DialogDescription>
              </DialogHeader>
              <Form action="/app/widgets?index" method="POST">
                <div className="space-y-4 w-full">
                  <input
                    type="hidden"
                    name="source"
                    value={session.company?.name.replace(/\W+/gim, "-")}
                  />
                  <input
                    type="hidden"
                    name="source_id"
                    value={`${session.company?.name.replace(/\W+/gim, "-")}-${session.company?.id}`}
                  />
                  <div className="space-y-2">
                    <Label>Nom</Label>
                    <Input name="name" defaultValue="Mon widget" />
                  </div>
                  <div className="space-y-2">
                    <Label>Format</Label>
                    <p className="text-muted-foreground text-xs leading-normal">
                      Choisissez le format carousel responsive sur une ligne ou
                      l'agenda avec sa pagination et ses outils de recherche.
                    </p>
                    <RadioGroup
                      name="type"
                      defaultValue={WIDGET_TYPE.CAROUSEL}
                      className="flex gap-px flex-wrap"
                    >
                      <RadioGroupItem
                        id="carousel"
                        value={WIDGET_TYPE.CAROUSEL}
                        asChild
                      >
                        <Button
                          variant="ghost"
                          className="flex flex-col items-center h-min gap-2 px-4 pt-3 pb-2 w-32 border-transparent border-2 data-[state=checked]:border-accent"
                        >
                          <img
                            src={IconForWidgetType[WIDGET_TYPE.CAROUSEL]}
                            className="w-10 h-10"
                          />
                          <span className="text-sm font-medium text-muted-foreground">
                            Carousel
                          </span>
                        </Button>
                      </RadioGroupItem>
                      <RadioGroupItem
                        id="pageview"
                        value={WIDGET_TYPE.PAGEVIEW}
                        asChild
                      >
                        <Button
                          variant="ghost"
                          className="flex flex-col items-center h-min gap-2 px-4 pt-3 pb-2 w-32 border-transparent border-2 data-[state=checked]:border-accent"
                        >
                          <img
                            src={IconForWidgetType[WIDGET_TYPE.PAGEVIEW]}
                            className="w-10 h-10"
                          />
                          <span className="text-sm font-medium text-muted-foreground">
                            Agenda
                          </span>
                        </Button>
                      </RadioGroupItem>
                    </RadioGroup>
                  </div>
                </div>
                <DialogFooter>
                  <Button type="submit">Confirmer</Button>
                </DialogFooter>
              </Form>
            </DialogContent>
          );
        }}
      />
    </Dialog>
  );
}

export const [useCreateWidgetModal, CreateWidgetProvider, CreateWidgetContext] =
  createModalContext(CreateWidgetModal);
