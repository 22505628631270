import * as React from "react";
import { IWidgetAnalyticsLoaderData } from "../loader";
import { Link, useAsyncValue } from "react-router-dom";
import { formatUrl } from "@/lib/format";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";

export interface IOriginsChartProps {}

export default function OriginsChart(props: IOriginsChartProps) {
  const { impression_per_origin } = useAsyncValue() as Awaited<
    IWidgetAnalyticsLoaderData["analytics"]
  >;
  const height = (impression_per_origin.length + 1) * 48;
  return (
    <ScrollArea className="max-h-[320px]" style={{ height }}>
      <Table className="h-full">
        <TableHeader>
          <TableRow>
            <TableHead className="text-right w-[120px]">Impressions</TableHead>
            <TableHead>Url d'origine</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {impression_per_origin.map((row) => {
            return (
              <TableRow className="h-12">
                <TableCell className="text-right text-blue-500 w-[120px] source-code-pro-font">
                  {row.impressions}
                </TableCell>
                <TableCell>
                  <Link
                    to={row.origin}
                    target="_blank"
                    className="hover:underline"
                  >
                    {formatUrl(row.origin)}
                  </Link>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ScrollBar orientation="vertical" />
    </ScrollArea>
  );
}
