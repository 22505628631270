import { getCurrentUser } from "@/api/authentication";
import { listCategories } from "@/api/categories";
import { extractEnabledAdvertisementTypes } from "@/api/companies";
import { ADVERTISEMENT_TYPE } from "@/api/definitions";
import { LoaderFunctionArgs, defer, redirect } from "react-router-dom";
import { toast } from "sonner";
import * as z from "@/lib/zod";
import { listOwnedWidgets } from "@/api/widgets";

export interface IAdvertisementBuilderLoaderData {
  type: ADVERTISEMENT_TYPE;
  categories: ReturnType<typeof listCategories>;
  widgets: ReturnType<typeof listOwnedWidgets>;
}

export async function AdvertisementBuilderLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const currentType = z.nativeEnum(ADVERTISEMENT_TYPE).safeParse(params.type);
  if (!currentType.success) throw redirect("/app/advertisements/new");
  const session = await getCurrentUser(request.signal);
  if (!session) throw redirect("/app");
  const enabledTypes = extractEnabledAdvertisementTypes(session.company);
  if (!enabledTypes.includes(currentType.data)) {
    toast.info("Débloquez ce type d'annonces dans vos Paramètres > Abonnement");
    throw redirect("/app/advertisements/new");
  }

  return defer({
    type: currentType.data,
    categories: listCategories(request.signal),
    widgets: listOwnedWidgets(request.signal),
  } satisfies IAdvertisementBuilderLoaderData);
}
