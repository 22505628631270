import * as React from "react";
import { getCurrentUser } from "@/api/authentication";
import { SessionDTO } from "@/api/definitions";
import { LoaderFunctionArgs, Outlet, redirect } from "react-router-dom";
import { useInterval } from "@/hooks/use-interval";
import { useRevalidateCache } from "@/hooks/use-invalidate";
import ms from "ms";

export interface IAuthGuardLoaderData extends SessionDTO {}

export async function AuthGuardLoader({
  request,
}: LoaderFunctionArgs): Promise<IAuthGuardLoaderData> {
  const session = await getCurrentUser(request.signal);
  if (!session) throw redirect("../auth");
  return session;
}

export interface IAuthGuardComponentProps {}

export function AuthGuardComponent(props: IAuthGuardComponentProps) {
  useRevalidateCache(getCurrentUser);
  useInterval(async () => {
    try {
      const res = await getCurrentUser();
      if (!res) throw {};
    } catch (e) {
      getCurrentUser.invalidate();
    }
  }, "10 minutes");
  return <Outlet />;
}
