import * as React from "react";
import { useAsyncValue, useLoaderData } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCaption,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { entries } from "@/lib/entries";
import { BlacklistsTableColumns } from "./columns";
import { useColumns } from "@/hooks/useColumns";
import { useSet } from "@/hooks/use-set";
import { IWidgetBlacklistsLoaderData } from "../loader";
import BlacklistsRow from "./blacklists-row";
import SmartPagination from "@/components/smart-pagination";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import Awaited from "@/components/awaited";
import { Skeleton } from "@/components/ui/skeleton";
import { IUseSearchParams, useDocuments, useSearch } from "@/hooks/use-search";
import {
  AdvertisementVersionDTO,
  FullAdvertisementDTO,
} from "@/api/definitions";
import { cn } from "@/lib/utils";
import { normalize } from "@/lib/format";

export interface IBlacklistsTableProps {}

const searchConfig = {
  fields: ["title", "url", "description"],
  storeFields: ["id", "title"],
  boost: {
    title: 5,
    description: 3,
    url: 1,
  },
} as IUseSearchParams<
  Pick<AdvertisementVersionDTO, "id" | "title" | "description" | "url">
>;

export default function BlacklistsTable(props: IBlacklistsTableProps) {
  const _blacklists = useAsyncValue() as Awaited<
    IWidgetBlacklistsLoaderData["blacklists"]
  >;
  const [columns, columnsState] = useColumns(
    "widget-blacklists",
    BlacklistsTableColumns,
  );
  const [page, setPage] = React.useState(0);
  const checks = useSet<string>();
  const [allChecked, setAllChecked] = React.useState(false);
  const [query, setQuery] = React.useState<string | undefined>();
  const documents = useDocuments(_blacklists, ({ advertisement: a }) => ({
    id: a.id,
    title: normalize(a.title),
    description: normalize(a.description ?? ""),
    url: normalize(a.url),
  }));
  const search = useSearch(documents, searchConfig);
  const blacklists = React.useMemo(() => {
    const results = search(normalize(query ?? ""));
    console.log("search", { query, results });
    if (results === null) return _blacklists;
    return results
      .map((r) => _blacklists.find((b) => b.advertisement_version_id === r.id))
      .filter(Boolean) as typeof _blacklists;
  }, [query, search, _blacklists]);

  React.useEffect(() => {
    if (!allChecked) checks.clear();
    else
      blacklists.forEach((item) => checks.add(item.advertisement_version_id));
  }, [allChecked]);
  function onCheckedChange(key: string) {
    return function (value: boolean) {
      const exists = checks.has(key);
      if (value === exists) return;
      checks[value ? "add" : "delete"](key);
    };
  }
  return (
    <div className="flex justify-between flex-wrap w-full items-center gap-4">
      <div className="flex items-center gap-4 w-full">
        <Input
          type="search"
          placeholder="Rechercher..."
          className="w-80 shrink-0 enter animation-small"
          value={query ?? ""}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
        <span className="text-xs shrink-0">
          {blacklists.length} résultat{blacklists.length !== 1 ? "s" : ""}
        </span>
        {checks.size ? (
          <Badge className="text-xs shrink-0">{checks.size} sélectionnés</Badge>
        ) : null}
        <div className="filler" />
        <div>
          <Button
            className={cn(
              "enter animation-small",
              checks.size || "enter-opacity-50",
            )}
            disabled={!checks.size}
            size="sm"
            onClick={() => alert("Delete blacklists")}
          >
            Supprimer
          </Button>
        </div>
      </div>
      <ScrollArea className="border border-border rounded-md w-full enter animation-fast">
        <Table>
          {blacklists.length ? null : (
            <TableCaption className="mb-4">Aucun résultat</TableCaption>
          )}
          <TableHeader>
            <TableRow>
              {entries(columns)
                .filter(([key]) => columnsState[key].visible)
                .map(([_, { value, header: Header }]) => (
                  <Header
                    key={value}
                    allChecked={allChecked}
                    toggleAllChecked={() => setAllChecked((v) => !v)}
                  />
                ))}
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {blacklists.slice(page * 10, (page + 1) * 10).map((blacklist) => (
              <BlacklistsRow
                key={blacklist.advertisement_version_id}
                blacklist={blacklist}
                checked={checks.has(blacklist.advertisement_version_id)}
                onCheckedChange={onCheckedChange(
                  blacklist.advertisement_version_id,
                )}
                columnState={columnsState}
              />
            ))}
          </TableBody>
        </Table>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
      <SmartPagination
        count={blacklists.length}
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

const placeholderRows = Array.from({ length: 8 }, () => crypto.randomUUID());

BlacklistsTable.Skeleton = function () {
  const [columns, columnsState] = useColumns(
    "widget-blacklists",
    BlacklistsTableColumns,
  );
  return (
    <div className="flex justify-between flex-wrap w-full items-center gap-4">
      <div className="flex items-center gap-4 w-full">
        <Skeleton className="w-80 shrink-0 h-10" />
        <div className="filler" />
        <Button disabled size="sm">
          Supprimer
        </Button>
      </div>
      <ScrollArea className="border border-border rounded-md w-full">
        <Table>
          <TableHeader>
            <TableRow>
              {entries(columns).map(([_, { value, header: Header }]) => (
                <Header
                  key={value}
                  allChecked={false}
                  toggleAllChecked={() => {}}
                />
              ))}
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {placeholderRows.map((key) => (
              <BlacklistsRow.Skeleton key={key} columnState={columnsState} />
            ))}
          </TableBody>
        </Table>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </div>
  );
};
