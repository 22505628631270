import * as React from "react";
import { Route } from "react-router-dom";
import ForgotPasswordPage from "./page";
import { ForgotPasswordAction } from "./action";

export interface IForgotPasswordRouterProps {}

export default function ForgotPasswordRouter(
  props: IForgotPasswordRouterProps,
) {
  return (
    <Route>
      <Route
        index
        action={ForgotPasswordAction}
        Component={ForgotPasswordPage}
      />
    </Route>
  );
}
