import { ADVERTISEMENT_TYPE } from "@/api/definitions";
import { TableHead } from "@/components/ui/table";
import { ColumnsDef } from "@/hooks/useColumns";
import { omit } from "@/lib/pick";

export type AdvertisementsTableColumnName =
  | "id"
  | "image"
  | "title"
  | "location"
  | "widgets"
  | "entreprise"
  | "dates"
  | "categories";

export const AdvertisementsTableColumns: ColumnsDef<AdvertisementsTableColumnName> =
  {
    id: {
      value: "id",
      label: "Id",
      header: () => <TableHead className="w-20">#ID</TableHead>,
    },
    image: {
      value: "image",
      label: "Image",
      header: () => (
        <TableHead className="w-20 first:w-24 first:px-4 px-0">Image</TableHead>
      ),
    },
    title: {
      value: "title",
      label: "Titre",
      header: () => <TableHead>Titre</TableHead>,
    },
    location: {
      value: "location",
      label: "Lieu",
      header: () => <TableHead>Lieu</TableHead>,
    },
    widgets: {
      value: "widgets",
      label: "Widgets",
      header: () => <TableHead>Widgets</TableHead>,
    },
    entreprise: {
      value: "entreprise",
      label: "Entreprise",
      header: () => <TableHead>Entreprise</TableHead>,
    },
    dates: {
      value: "dates",
      label: "Dates",
      header: () => <TableHead className="min-w-[192px]">Dates</TableHead>,
    },
    categories: {
      value: "categories",
      label: "Catégories",
      header: () => <TableHead>Catégories</TableHead>,
    },
  } as const;

export function columnsForType(type: ADVERTISEMENT_TYPE) {
  switch (type) {
    case ADVERTISEMENT_TYPE.EVENT:
      return omit(
        AdvertisementsTableColumns,
        "entreprise",
      ) as ColumnsDef<AdvertisementsTableColumnName>;
    case ADVERTISEMENT_TYPE.REAL_ESTATE:
      return omit(
        AdvertisementsTableColumns,
        "dates",
        "categories",
        "entreprise",
      ) as ColumnsDef<AdvertisementsTableColumnName>;
    case ADVERTISEMENT_TYPE.JOB:
      return omit(
        AdvertisementsTableColumns,
        "dates",
        "categories",
      ) as ColumnsDef<AdvertisementsTableColumnName>;
    default:
      return omit(
        AdvertisementsTableColumns,
      ) as ColumnsDef<AdvertisementsTableColumnName>;
  }
}
