import { getAdvertisementSummary } from "@/api/advertisements";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface IAdvertisementContentLoaderData {
  summary: ReturnType<typeof getAdvertisementSummary>;
}

export async function AdvertisementContentLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  if (!params.advertisementId) throw new Error("No advertisement selected");
  return defer({
    summary: getAdvertisementSummary(params.advertisementId, request.signal),
  } satisfies IAdvertisementContentLoaderData);
}
