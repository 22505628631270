import * as z from "@/lib/zod";
import { API_BASE, logErrorResponse } from "./common";
import {
  AdvertisementVersionDTO,
  AdvertisementVersionSchema,
  CreateScheduleDTO,
  FullAdvertisementDTO,
  FullAdvertisementSchema,
  InvalidTimeRangeSchema,
  ListResponseDTO,
  ListResponseSchema,
  ListSchedulesFiltersDTO,
  ROTATION_STRATEGY,
  SchedulesDTO,
  SchedulesSchema,
  UpdateScheduleDTO,
} from "./definitions";
import dayjs from "@/lib/date";
import { objectToSearchParams } from "@/lib/query";
import { cache } from "@/lib/cache";
import { deepMergeAll } from "@/lib/entries";
import { removeDuplicate, withId } from "@/lib/array";
import { chunkArray } from "@/lib/array";
import { toast } from "sonner";

export const listSchedules = cache(async function _listSchedules(
  filters: ListSchedulesFiltersDTO,
  signal?: AbortSignal,
): Promise<
  ListResponseDTO<SchedulesDTO & { advertisement: AdvertisementVersionDTO }>
> {
  if (filters.widgetIds.length > 20) {
    const chunks = await Promise.all(
      chunkArray(filters.widgetIds, 20).map((widgetIds) =>
        _listSchedules.call(
          this,
          {
            ...filters,
            widgetIds,
          },
          signal,
        ),
      ),
    );
    return deepMergeAll(...chunks);
  }
  if (filters.advertisementIds && filters.advertisementIds.length > 20) {
    const chunks = await Promise.all(
      chunkArray(filters.advertisementIds, 20).map((advertisementIds) =>
        _listSchedules.call(
          this,
          {
            ...filters,
            advertisementIds,
          },
          signal,
        ),
      ),
    );
    return deepMergeAll(...chunks);
  }
  const query = objectToSearchParams(filters);
  const response = await fetch(`${API_BASE}/schedules?${query}`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  const schedules = await ListResponseSchema(
    SchedulesSchema.merge(
      z.object({ advertisement: AdvertisementVersionSchema }),
    ),
  ).parseAsync(data);
  return {
    ...schedules,
    results: schedules.results.sort((a, b) => {
      if (filters.sort === "desc") {
        return dayjs(a.to).isBefore(b.to) ? 1 : -1;
      }
      const aIsActive = dayjs().isBetween(a.from, a.to);
      const bIsActive = dayjs().isBetween(b.from, b.to);
      if (aIsActive !== bIsActive) return aIsActive ? -1 : 1;
      if (aIsActive) return a.order - b.order;
      return dayjs(a.to).isBefore(b.to) ? -1 : 1;
    }),
  };
}, "5 minutes");

export const getScheduleById = cache(async function getScheduleById(
  scheduleId: number,
  signal?: AbortSignal,
) {
  const response = await fetch(`${API_BASE}/schedules/${scheduleId}`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return SchedulesSchema.merge(
    z.object({ advertisement: FullAdvertisementSchema }),
  ).parse(data);
}, "30 seconds");

export async function createSchedule(payload: CreateScheduleDTO) {
  if ("from" in payload && payload.from)
    payload.from = dayjs(payload.from).startOf("day").toDate();
  if ("to" in payload && payload.to)
    payload.to = dayjs(payload.to).endOf("day").toDate();
  const response = await fetch(`${API_BASE}/schedules`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) await handleCreateScheduleError(response);
  listSchedules.invalidate(([params]) => {
    if (
      params.widgetIds.includes(payload.widget_id) ||
      params.advertisementIds?.includes(payload.advertisement_version_id)
    )
      return true;
    return false;
  });
  const data = await response.json();
  return SchedulesSchema.parse(data);
}
async function handleCreateScheduleError(response: Response): Promise<never> {
  if (response.status === 400) {
    const body = await response.json();
    const invalidTimeRangeError = InvalidTimeRangeSchema.safeParse(body);
    if (invalidTimeRangeError.success) {
      setTimeout(() => toast.error("Les dates ne sont pas valides"), 0);
    }
  }
  throw new Error("Couldn't create schedule");
}
export async function updateSchedule(
  scheduleId: SchedulesDTO["id"],
  payload: UpdateScheduleDTO,
) {
  if ("from" in payload && payload.from)
    payload.from = dayjs(payload.from).startOf("day").toDate();
  if ("to" in payload && payload.to)
    payload.to = dayjs(payload.to).endOf("day").toDate();
  const response = await fetch(`${API_BASE}/schedules/${scheduleId}`, {
    credentials: "include",
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) throw new Error("Couldn't create widget");
  const data = await response.json();
  const updatedSchedule = SchedulesSchema.parse(data);
  listSchedules.invalidate((_, response) => {
    if (!response) return false;
    if ("from" in payload || "to" in payload) return true;
    return !!response.results.find(withId(scheduleId));
  });
  getScheduleById.invalidate(([id]) => id === scheduleId);
  return updatedSchedule;
}
export async function deleteSchedule(scheduleId: SchedulesDTO["id"]) {
  const response = await fetch(`${API_BASE}/schedules/${scheduleId}`, {
    credentials: "include",
    method: "DELETE",
  });
  if (!response.ok) throw new Error("Couldn't create widget");
  listSchedules.invalidate((_, response) => {
    if (!response) return false;
    return !!response.results.find(withId(scheduleId));
  });
  getScheduleById.invalidate(([id]) => id === scheduleId);
}
export async function rotateAllSchedules() {
  try {
    const response = await fetch(`${API_BASE}/schedules/admin/rotate`, {
      method: "POST",
      body: "{}",
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    });
    return response.ok;
  } catch (e) {
    return false;
  }
}

export function reorderSchedules<T extends SchedulesDTO>(schedules: T[]) {
  const schedulesByStrategy = schedules.reduce(
    (acc, schedule) => {
      acc[schedule.rotation_strategy].push(schedule);
      return acc;
    },
    { rotate: [], fixed: [], filler: [] } as Record<ROTATION_STRATEGY, T[]>,
  );

  schedulesByStrategy.rotate.sort((a, b) => a.order - b.order);
  schedulesByStrategy.filler.sort((a, b) => a.order - b.order);

  const updatedSchedules = [
    ...schedulesByStrategy.rotate,
    ...schedulesByStrategy.filler,
  ];
  schedulesByStrategy.fixed
    .sort((a, b) => a.order - b.order)
    .forEach((schedule) => {
      updatedSchedules.splice(schedule.order, 0, schedule);
    });

  return updatedSchedules.map((s, i) => ({ ...s, order: i }));
}
