import { deleteCompany, updateCompany } from "@/api/companies";
import { AdminUpdateCompanySchema } from "@/api/definitions";
import { objectFromFormData } from "@/lib/query";
import { withToast } from "@/lib/with-toast";
import { ActionFunctionArgs } from "react-router-dom";
import * as z from "zod";

export interface IAdminCompanyActionData {}
export async function AdminCompanyAction({
  request,
}: ActionFunctionArgs): Promise<IAdminCompanyActionData> {
  const body = await request.formData();
  console.log("app/admin/companies action");
  if (request.method === "PUT") {
    console.log("app/admin/companies action PUT");
    await withToast(
      async () => {
        const { id, ...payload } = AdminUpdateCompanySchema.and(
          z.object({ id: z.coerce.number() }),
        ).parse(objectFromFormData(body));
        console.log("app/admin/companies action PUT payload", id, payload);
        await updateCompany(id, payload);
      },
      {
        loading: "Modification en cours",
        success: "Entreprise modifiée",
        error: "Échec de la modification",
      },
    );
  }
  if (request.method === "DELETE") {
    await withToast(
      async () => {
        const { id } = z
          .object({ id: z.coerce.number() })
          .parse(objectFromFormData(body));
        await deleteCompany(id);
      },
      {
        loading: "Suppression en cours",
        success: "Entreprise supprimée",
        error: "Impossible de supprimer l'entreprise",
      },
    );
  }
  return {};
}
