import ImageInput from "@/components/image-input";
import {
  StepLayout,
  StepLayoutActions,
  StepLayoutHeader,
  StepLayoutNextAction,
  StepLayoutPreviousAction,
  StepLayoutSubtitle,
  StepLayoutTitle,
} from "@/components/step-layout";
import { Label } from "@/components/ui/label";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useDraft } from "../../layout";
import { AdvertisementBuilderImagesSchema } from "./schemas";
import { IStepProps } from "./step-props";

export default function AdvertisementBuilderImages({
  previous,
  next,
}: IStepProps) {
  const [{ draft }] = useDraft();
  const form = useForm({
    mode: "onChange",
    resolver: zodResolver(AdvertisementBuilderImagesSchema),
    defaultValues: {
      image: draft.image ?? "",
    },
  });
  return (
    <StepLayout>
      <StepLayoutHeader>
        <StepLayoutTitle>
          Quels visuels souhaitez-vous afficher ?
        </StepLayoutTitle>
        <StepLayoutSubtitle>
          Téléchargez les images de l'annonce.
        </StepLayoutSubtitle>
      </StepLayoutHeader>
      <div className="space-y-2">
        <Label>Format paysage *</Label>
        <p className="text-muted-foreground text-sm">
          Téléchargez une image en format paysage en jpeg.
          <br />
          Les dimensions recommandées sont 640x320.
        </p>
        <ImageInput
          file={form.getValues("image")}
          onChange={(file) => {
            form.setValue("image", file);
            form.trigger().then(() => console.log(form.getValues("image")));
          }}
        />
      </div>
      <StepLayoutActions>
        <StepLayoutPreviousAction previous={previous}>
          Retour
        </StepLayoutPreviousAction>
        <StepLayoutNextAction form={form} next={next}>
          Continuer
        </StepLayoutNextAction>
      </StepLayoutActions>
    </StepLayout>
  );
}
