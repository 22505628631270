import * as React from "react";
import { objectToFormData } from "@/lib/query";
import { useDraft } from "../../layout";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ADVERTISEMENT_TYPE,
  AdvertisementDraftSchema,
} from "@/api/definitions";
import { useTextSize } from "@/hooks/use-text-size";
import { Label } from "@/components/ui/label";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { AlertTriangle, Plus, X } from "lucide-react";
import { WIDGET_CARD_TITLE_MINIMUM_WIDTH } from "@/api/common";
import { cn } from "@/lib/utils";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { StepLayoutTitle } from "@/components/step-layout";
import { Map, Marker } from "@vis.gl/react-google-maps";
import { useAutoComplete } from "@/hooks/use-auto-complete";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import DateRangePicker from "@/components/date-range-picker";
import SearchSelect from "@/components/search-select";
import { capitalize } from "@/lib/format";
import { withId } from "@/lib/array";
import { useAsyncValue, useNavigation, useFetcher } from "react-router-dom";
import { awaited } from "@/components/awaited";
import { IAdvertisementBuilderLoaderData } from "../loader";
import dayjs from "dayjs";
import { join } from "@/lib/promise";
import ScheduleFormItem, {
  NewScheduleItem,
} from "@/components/schedule-form-item";
import { ms } from "@/lib/timer";
import ImageInput from "@/components/image-input";

export interface IAdvertisementBuilderSimplifiedViewProps {}

export default awaited<
  IAdvertisementBuilderLoaderData,
  IAdvertisementBuilderSimplifiedViewProps
>(
  (d) => join(d.widgets, d.categories),
  function AdvertisementBuilderSimplifiedView(
    props: IAdvertisementBuilderSimplifiedViewProps,
  ) {
    const [{ draft, step: _step }, setDraft] = useDraft();
    const [widgets, allCategories] = useAsyncValue() as [
      Awaited<IAdvertisementBuilderLoaderData["widgets"]>,
      Awaited<IAdvertisementBuilderLoaderData["categories"]>,
    ];
    const form = useForm({
      mode: "onTouched",
      resolver: zodResolver(AdvertisementDraftSchema),
      defaultValues: {
        title: draft.title ?? "",
        description: draft.description ?? "",
        url: draft.url ?? "",
        address: draft.address ?? "",
        type: draft.type,
        location_name: draft.location_name ?? "",
        city: draft.city ?? "",
        longitude: draft.longitude ?? 7.2411405,
        latitude: draft.latitude ?? 43.689104,
        dates: draft.dates?.length
          ? draft.dates
          : [{ from: new Date(), to: new Date() }],
        categories: (draft.categories || []) as number[],
        company_name: draft.company_name,
        price: draft.price,
        rooms: draft.rooms,
        surface: draft.surface,
        image: draft.image ?? "",
        schedules:
          draft.schedules ||
          ([
            {
              widgetId: widgets.results[0].id,
              from: dayjs().add(1, "day").toDate(),
              to: dayjs().add(1, "day").toDate(),
              clic_goal: null,
              impression_goal: null,
              sponsored: true,
            },
          ] as NewScheduleItem[]),
      },
    });
    const titleSize = useTextSize({
      content: form.watch("title"),
      transform(str) {
        return str
          .split(" ")
          .filter((word) => word.length)
          .map((s) => s[0].toUpperCase() + s.slice(1))
          .join(" ");
      },
    });
    const autoCompleteRef = useAutoComplete({
      onPlaceSelect(results) {
        if (!results.geometry) {
          return toast.error("Veuillez sélectionner une adresse valide");
        }
        form.setValue(
          "location_name",
          results.name ||
            results.formatted_address?.split(",")[0] ||
            form.getValues("location_name") ||
            "",
        );
        form.setValue(
          "address",
          results.formatted_address ?? form.getValues("address"),
        );
        form.setValue(
          "latitude",
          results.geometry.location?.lat() ?? form.getValues("latitude"),
        );
        form.setValue(
          "longitude",
          results.geometry.location?.lng() ?? form.getValues("longitude"),
        );
        form.setValue("city", results.vicinity ?? form.getValues("city"));
        form.trigger();
      },
      types: ["geocode", "establishment"],
      fields: ["formatted_address", "geometry", "name", "vicinity"],
    });
    const { ref: addressRef, ...addressProps } = form.register("address");
    const datesFieldArray = useFieldArray({
      control: form.control,
      name: "dates",
    });
    const schedulesFieldArray = useFieldArray({
      control: form.control,
      name: "schedules",
    });
    const [openedField, setOpenedField] = React.useState<string>();
    React.useEffect(() => {
      if (schedulesFieldArray.fields.length === 1) return;
      setOpenedField(schedulesFieldArray.fields.at(-1)?.id);
    }, [schedulesFieldArray.fields.length]);
    const navigation = useNavigation();
    const fetcher = useFetcher();
    async function submit() {
      await form.trigger();
      if (form.formState.isValid) {
        setDraft((d) => {
          Object.assign(d.draft, form.getValues());
        });
        fetcher.submit(
          objectToFormData({
            draft: JSON.stringify(Object.assign({}, draft, form.getValues())),
          }),
          {
            method: "POST",
            encType: "multipart/form-data",
            action: "/app/advertisements/new/[type]?index",
          },
        );
      } else {
        console.log("form.formState.errors", form.formState.errors);
        Object.values(form.formState.errors).map(
          (err) =>
            err &&
            toast.error(
              typeof err.message === "string"
                ? err.message
                : String(err.message),
            ),
        );
      }
    }
    const errors = form.formState.errors;
    return (
      <div className="space-y-4 w-full max-w-xl mx-auto">
        <StepLayoutTitle className="text-center">
          Nouvelle annonce
        </StepLayoutTitle>
        <div className="space-y-4">
          <div className="space-y-2">
            <Label className="justify-between flex w-full items-end min-h-[20px]">
              <span>Titre *</span>
              {titleSize > WIDGET_CARD_TITLE_MINIMUM_WIDTH ? (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <AlertTriangle className=" text-yellow-600 w-5 h-5 inline ml-2" />
                  </TooltipTrigger>
                  <TooltipContent side="bottom">
                    <p>
                      En raison de sa longueur, ce titre peut être tronqué sur
                      certains écrans.
                    </p>
                  </TooltipContent>
                </Tooltip>
              ) : null}
            </Label>
            <div className="relative">
              <Input
                className={cn(
                  titleSize > WIDGET_CARD_TITLE_MINIMUM_WIDTH &&
                    "!outline-yellow-600",
                  errors.title && "border-red-500",
                )}
                required
                {...form.register("title")}
              />
              <div className="pointer-events-none bottom-2 right-2 absolute text-xs text-muted-foreground">
                {form.getValues().title.length}
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <Label>Description</Label>
            <Textarea
              className={cn(
                "min-h-[80px] max-h-[256px]",
                errors.description && "border-red-500",
              )}
              {...form.register("description")}
            />
          </div>
          <div className="space-y-3">
            <Label>URL de redirection *</Label>
            <Input
              type="url"
              className={cn(errors.url && "border-red-500")}
              required
              {...form.register("url")}
            />
          </div>
        </div>
        <div className="space-y-4">
          <div className="space-y-2">
            <Label>Adresse *</Label>
            <Input
              className={cn(errors.address && "border-red-500")}
              required
              {...addressProps}
              ref={(input) => {
                addressRef(input);
                // @ts-ignore
                autoCompleteRef.current = input;
              }}
              placeholder="Saisir une adresse..."
            />
          </div>
          <div className="space-y-2">
            <Label>Lieu *</Label>
            <Input
              className={cn(errors.location_name && "border-red-500")}
              required
              {...form.register("location_name")}
              placeholder="Nom du lieu"
            />
          </div>
          <Map
            defaultZoom={15}
            defaultCenter={{
              lat: form.getValues("latitude"),
              lng: form.getValues("longitude"),
            }}
            key={`${form.getValues("latitude")};${form.getValues("longitude")}`}
            disableDefaultUI
            className="w-full h-64 rounded-sm overflow-hidden"
          >
            <Marker
              position={{
                lat: form.getValues("latitude"),
                lng: form.getValues("longitude"),
              }}
            />
          </Map>
        </div>
        <div
          className="space-y-4"
          hidden={draft.type !== ADVERTISEMENT_TYPE.EVENT}
        >
          <Label>Dates *</Label>
          <div className="space-y-2">
            {datesFieldArray.fields.map((field, index, { length }) => {
              return (
                <DateRangePicker
                  key={field.id}
                  {...form.register(`dates.${index}`)}
                  remove={
                    length > 1 ? () => datesFieldArray.remove(index) : undefined
                  }
                  onChange={(newDates) => {
                    form.setValue(`dates.${index}`, newDates);
                    form.trigger();
                  }}
                  value={form.getValues(`dates.${index}`)}
                />
              );
            })}
          </div>
          <div>
            <Button
              variant="secondary"
              onClick={() =>
                datesFieldArray.append({ from: new Date(), to: new Date() })
              }
            >
              Ajouter une date
            </Button>
          </div>
        </div>
        <div
          className="space-y-2"
          hidden={draft.type !== ADVERTISEMENT_TYPE.EVENT}
        >
          <Label>Catégories *</Label>
          <div className="flex gap-3 flex-wrap items-center">
            {form.getValues("categories").map((value) => {
              const category = allCategories.find(withId(value))!;
              return (
                <Button
                  className="cursor-pointer"
                  onClick={() => {
                    form.setValue(
                      "categories",
                      (form.getValues("categories") ?? []).filter(
                        (c) => c !== value,
                      ),
                    );
                    form.trigger();
                  }}
                >
                  {capitalize(category.name)} <X className="w-4 h-4 ml-1.5" />
                </Button>
              );
            })}
          </div>

          <SearchSelect
            key="add-category"
            onSelect={(categoryId) => {
              form.setValue(
                "categories",
                (form.getValues("categories") ?? []).concat(+categoryId),
              );
              form.trigger();
            }}
            options={allCategories
              .filter(
                (c) => !(form.getValues("categories") ?? []).includes(c.id),
              )
              .map(({ id, name }) => ({
                value: id + "",
                label: capitalize(name.toLowerCase()),
              }))}
          >
            <Button variant="secondary" type="button">
              Ajouter une catégorie
            </Button>
          </SearchSelect>
        </div>
        <div
          hidden={draft.type !== ADVERTISEMENT_TYPE.JOB}
          className="space-y-2"
        >
          <Label>
            <span>Employeur *</span>
          </Label>
          <Input
            className={cn(errors.company_name && "border-red-500")}
            required
            minLength={3}
            {...form.register("company_name")}
          />
        </div>
        <div
          hidden={draft.type !== ADVERTISEMENT_TYPE.REAL_ESTATE}
          className="space-y-2"
        >
          <div className="space-y-2">
            <Label>
              <span>Valeur *</span>
            </Label>
            <p className="text-xs text-muted-foreground">
              Renseignez "NC" ou "Non communiqué" si vous n'avez pas le prix du
              bien.
            </p>
            <Input
              className={cn(errors.price && "border-red-500")}
              required
              {...form.register("price")}
            />
          </div>
          <div className="space-y-2">
            <Label>
              <span>Surface *</span>
            </Label>
            <Input
              className={cn(errors.surface && "border-red-500")}
              required
              {...form.register("surface")}
            />
          </div>
          <div className="space-y-2">
            <Label>
              <span>Nombre de pièces *</span>
            </Label>
            <Input
              className={cn(errors.rooms && "border-red-500")}
              required
              {...form.register("rooms")}
            />
          </div>
        </div>
        <div className="space-y-4">
          <div>
            <Label>Image</Label>
          </div>
          <div className="space-y-2">
            <Label>Format paysage *</Label>
            <p className="text-muted-foreground text-sm">
              Téléchargez une image en format paysage en jpeg.
              <br />
              Les dimensions recommandées sont 640x320.
            </p>
            <ImageInput
              file={form.watch("image")}
              onChange={(file) => {
                form.setValue("image", file);
                form.trigger();
              }}
            />
          </div>
        </div>
        <div className="space-y-4">
          <div>
            <Label>Programmation</Label>
          </div>
          <div className="space-y-2">
            {schedulesFieldArray.fields.map((field, index) => {
              const values = form.getValues(`schedules.${index}`);
              return (
                <ScheduleFormItem
                  key={field.id}
                  isOpen={openedField === field.id}
                  setOpen={(newOpen) =>
                    setOpenedField(newOpen ? field.id : undefined)
                  }
                  maxDate={draft.dates?.reduce(
                    (acc, cur) => (dayjs(acc).isBefore(cur.to) ? cur.to : acc),
                    new Date(Date.now() + ms("52 w")),
                  )}
                  data={values}
                  widgets={widgets.results}
                  setData={(val) => {
                    form.setValue(`schedules.${index}`, val);
                    form.trigger();
                  }}
                  remove={() => schedulesFieldArray.remove(index)}
                />
              );
            })}
          </div>
          <Button
            onClick={() => {
              let from,
                to = (from = dayjs().add(1, "day").toDate()),
                widgetId = widgets.results[0].id;
              if (schedulesFieldArray.fields.length) {
                const lastRow = form.watch(
                  `schedules.${schedulesFieldArray.fields.length - 1}`,
                );
                from = lastRow.from;
                to = lastRow.to;
                widgetId = lastRow.widgetId;
              }
              schedulesFieldArray.append({
                widgetId: widgetId,
                from: from,
                to: to,
                impression_goal: null,
                clic_goal: null,
                sponsored: true,
              });
            }}
          >
            <Plus className="w-4 h-4 mr-2" />
            Ajouter une programmation
          </Button>
        </div>
        <Button
          disabled={form.formState.isValidating || navigation.state !== "idle"}
          className="w-full"
          size="lg"
          onClick={submit}
        >
          Valider
        </Button>
      </div>
    );
  },
);
