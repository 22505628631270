import { awaited } from "@/components/awaited";
import {
  StepLayout,
  StepLayoutActions,
  StepLayoutHeader,
  StepLayoutNextAction,
  StepLayoutPreviousAction,
  StepLayoutSubtitle,
  StepLayoutTitle,
} from "@/components/step-layout";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useAsyncValue } from "react-router-dom";
import { useDraft } from "../../layout";
import { IAdvertisementBuilderLoaderData } from "../loader";
import { AdvertisementBuilderJobSchema } from "./schemas";
import { IStepProps } from "./step-props";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";

export function AdvertisementBuilderJob({ next, previous }: IStepProps) {
  const [{ draft }] = useDraft();
  const form = useForm({
    mode: "onChange",
    resolver: zodResolver(AdvertisementBuilderJobSchema),
    defaultValues: {
      company_name: draft.company_name,
    },
  });

  const errors = form.formState.errors;
  return (
    <StepLayout>
      <StepLayoutHeader>
        <StepLayoutTitle>Qui recrute ?</StepLayoutTitle>
        <StepLayoutSubtitle>
          Précisez l'employeur ou l'organisme qui recrute des candidats pour ce
          poste.
        </StepLayoutSubtitle>
      </StepLayoutHeader>
      <div className="space-y-2">
        <Label>
          <span>Employeur *</span>
        </Label>
        <Input
          className={cn(errors.company_name && "border-red-500")}
          required
          minLength={3}
          {...form.register("company_name")}
        />
      </div>
      <StepLayoutActions>
        <StepLayoutPreviousAction previous={previous}>
          Retour
        </StepLayoutPreviousAction>
        <StepLayoutNextAction form={form} next={next}>
          Continuer
        </StepLayoutNextAction>
      </StepLayoutActions>
    </StepLayout>
  );
}
