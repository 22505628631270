import { useAbortEffect } from "@/hooks/use-abort-effect";
import { usePersist } from "@/hooks/use-persist";
import { createContext, useContext, useEffect, useState } from "react";

type Theme = "dark" | "light" | "system";

type ThemeProviderProps = {
  children: React.ReactNode;
  defaultTheme?: Theme;
  storageKey?: string;
};

type ThemeProviderState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

const initialState: ThemeProviderState = {
  theme: "system",
  setTheme: () => null,
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
  children,
  defaultTheme = "system",
  storageKey = "vite-ui-theme",
  ...props
}: ThemeProviderProps) {
  const { get, watch } = usePersist<Theme>(storageKey);
  const [theme, setTheme] = useState<Theme>(get(defaultTheme));
  watch(theme);

  useAbortEffect(
    (signal) => {
      const root = window.document.documentElement;

      if (theme !== "system") {
        root.classList.remove("light", "dark");
        return root.classList.add(theme);
      }

      const useSystemTheme = (e: { matches: boolean }) => {
        root.classList.remove("light", "dark");
        root.classList.add(e.matches ? "dark" : "light");
      };

      const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      mediaQuery.addEventListener("change", useSystemTheme, { signal });
      useSystemTheme(mediaQuery);
    },
    [theme],
  );

  return (
    <ThemeProviderContext.Provider {...props} value={{ theme, setTheme }}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (context === undefined)
    throw new Error("useTheme must be used within a ThemeProvider");

  return context;
};
