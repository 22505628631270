import Awaited from "@/components/awaited";
import CheckboxDropdown from "@/components/checkbox-dropdown";
import RefPortal from "@/components/ref-portal";
import { Button } from "@/components/ui/button";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCaption,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useRouteNavigation } from "@/hooks/use-route-navigation";
import { useColumns } from "@/hooks/useColumns";
import { withId } from "@/lib/array";
import { entries, fromEntries } from "@/lib/entries";
import ScheduleRow from "@/pages/app/widgets/[id]/schedules/(blocks)/schedule-row";
import { Eye, Plus } from "lucide-react";
import { useLoaderData, useParams, useRouteLoaderData } from "react-router-dom";
import { ISingleAdvertisementLoaderData } from "../loader";
import { AdvertisementSchedulesTableColumns } from "./(blocks)/columns";
import { IAdvertisementSchedulesLoaderData } from "./loader";
import { TabLayoutContent } from "@/components/tab-layout";
import { useCreateScheduleModal } from "@/modals/create-schedule";
import { useRevalidateCache } from "@/hooks/use-invalidate";
import { listSchedules } from "@/api/schedules";

export interface IAdvertisementSchedulesPageProps {}

const placeholderRows = Array.from({ length: 8 }, () => crypto.randomUUID());

export default function AdvertisementSchedulesPage(
  props: IAdvertisementSchedulesPageProps,
) {
  const { advertisement } = useRouteLoaderData(
    "single-advertisement-route",
  ) as ISingleAdvertisementLoaderData;
  const data = useLoaderData() as IAdvertisementSchedulesLoaderData;
  const navigation = useRouteNavigation();
  const [columns, columnsState, setColumnState] = useColumns(
    "advertisement-schedules",
    AdvertisementSchedulesTableColumns,
  );
  const createScheduleModal = useCreateScheduleModal();
  const params = useParams();
  useRevalidateCache(listSchedules, ([{ advertisementIds }]) =>
    advertisementIds?.includes(params.advertisementId as string),
  );
  return (
    <TabLayoutContent>
      <RefPortal selector="#tab-layout-head-outlet">
        <Awaited
          resolve={advertisement}
          fallback={
            <Button
              size="sm"
              className="enter animation-fast animation-small enter-opacity-50"
              disabled
            />
          }
          render={(ad) => (
            <Button
              size="sm"
              className="enter animation-fast animation-small"
              disabled={navigation.state !== "idle"}
              onClick={() =>
                createScheduleModal.openModal({ advertisementId: ad.id })
              }
            >
              <Plus className="h-4 w-4 mr-2" />
              Programmer
            </Button>
          )}
        />
      </RefPortal>
      <div className="flex justify-between flex-wrap gap-3 w-full items-center">
        <div className="flex items-center gap-2">
          <Awaited
            resolve={advertisement}
            render={(ad) => {
              if (!ad.is_main_version || !ad.isOwned) return null;
              return (
                <div className="px-4 py-2 flex gap-3 justify-start items-center border border-border rounded-md">
                  <Eye className="w-4 h-4" />
                  <p className="leading-normal text-sm">
                    Votre annonce est publique. Elle peut être présente dans des
                    widgets qui ne vous appartienent pas.
                  </p>
                </div>
              );
            }}
          />
        </div>
        <CheckboxDropdown
          items={Object.values(columns)}
          values={fromEntries(
            entries(columnsState).map(([k, s]) => [k, s.visible]),
          )}
          onChange={(column, visible) =>
            setColumnState({ state: { [column]: { visible } } })
          }
        >
          <Button variant="outline" size="sm" className="border-dashed">
            Colonnes
          </Button>
        </CheckboxDropdown>
      </div>
      <ScrollArea className="border border-border rounded-md">
        <Table>
          <Awaited
            loading={navigation.state}
            resolve={data.schedules}
            render={(schedules) => {
              if (!schedules.results.length)
                return (
                  <TableCaption className="mb-4">
                    Vous n'avez aucune programmation pour cette annonce.
                  </TableCaption>
                );
              return null;
            }}
          />
          <TableHeader>
            <TableRow>
              {entries(columns)
                .filter(([key]) => columnsState[key].visible)
                .map(([_, { value, header: Header }]) => (
                  <Header key={value} />
                ))}
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <Awaited
              loading={navigation.state}
              resolve={data.widgetsAndSchedules}
              fallback={placeholderRows.map((key) => (
                <ScheduleRow.Skeleton columnState={columnsState} key={key} />
              ))}
              render={([widgets, schedules]) =>
                schedules.results.map((schedule) => (
                  <ScheduleRow
                    key={schedule.id}
                    widget={widgets.results.find(withId(schedule.widget_id))}
                    schedule={schedule}
                    columnState={columnsState}
                  />
                ))
              }
            />
          </TableBody>
        </Table>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </TabLayoutContent>
  );
}
