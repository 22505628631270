import { ContentForType } from "@/api/advertisements";
import { ADVERTISEMENT_TYPE } from "@/api/definitions";
import { HeaderBreadCrumb } from "@/components/header";
import * as React from "react";
import { Link } from "react-router-dom";
import { useDraft } from "./layout";

export interface INewAdvertisementPageProps {}

export default function NewAdvertisementPage(
  props: INewAdvertisementPageProps,
) {
  const [{ draft }, setDraft] = useDraft();
  if (draft.type)
    setDraft((d) => {
      delete d.draft.type;
    });
  return (
    <div className="w-full h-[calc(100vh_-_104px)] space-y-6 shrink flex flex-col justify-center items-center">
      <HeaderBreadCrumb chunks="Nouvelle annonce" />
      <h4 className="font-semibold text-lg">Choisir un type d'annonce</h4>
      <div className="grid grid-cols-7 grid-rows-2 gap-4 w-[600px]">
        <Link
          to={`/app/advertisements/new/${ADVERTISEMENT_TYPE.EVENT}`}
          className="cursor-pointer text-lg font-semibold flex justify-center items-center col-span-4 row-span-2 bg-blue-500 rounded-md bg-card hover:bg-primary/10"
        >
          {ContentForType[ADVERTISEMENT_TYPE.EVENT].label}
        </Link>
        <Link
          to={`/app/advertisements/new/${ADVERTISEMENT_TYPE.REAL_ESTATE}`}
          className="cursor-pointer text-lg font-semibold flex justify-center items-center col-span-3 rounded-md aspect-[6/4] bg-card hover:bg-primary/10"
        >
          {ContentForType[ADVERTISEMENT_TYPE.REAL_ESTATE].label}
        </Link>
        <Link
          to={`/app/advertisements/new/${ADVERTISEMENT_TYPE.JOB}`}
          className="cursor-pointer text-lg font-semibold flex justify-center items-center col-span-3 rounded-md bg-card hover:bg-primary/10"
        >
          {ContentForType[ADVERTISEMENT_TYPE.JOB].label}
        </Link>
      </div>
    </div>
  );
}
