import {
  CachedFunction,
  InvalidatePredicate,
  InvalidationEvent,
} from "@/lib/cache";
import * as React from "react";
import { useRevalidator } from "react-router-dom";
import { useAbortEffect } from "./use-abort-effect";
import { timer } from "@/lib/timer";

export function useRevalidateCache<TParams extends unknown[], TResult>(
  fn: CachedFunction<TParams, TResult>,
  predicate: InvalidatePredicate<TParams, TResult> = () => true,
): undefined {
  const revalidator = useRevalidator();
  useAbortEffect((signal) => {
    const { trigger } = timer(() => {
      console.log("REVALIDATE");
      revalidator.revalidate();
    }, "1 second");
    fn.events.addEventListener(
      "invalidation",
      (_event) => {
        const event = _event as InvalidationEvent<TParams, TResult>;
        if (predicate(event.detail.params, event.detail.result)) trigger();
      },
      { signal },
    );
  });
}
export function useRevalidateCaches(...fns: CachedFunction<[], any>[]) {
  const revalidator = useRevalidator();
  useAbortEffect((signal) => {
    const { trigger } = timer(() => revalidator.revalidate(), "1 second");
    fns.forEach((fn) => {
      fn.events.addEventListener("invalidation", trigger, { signal });
    });
  });
}
