import * as z from "@/lib/zod";
import { API_BASE, logErrorResponse } from "./common";
import { AdvertisementVersionSchema, BlackListsSchema } from "./definitions";
import { cache } from "@/lib/cache";
import ms from "ms";

export interface IListBlacklistsParams {
  ongoing?: boolean;
}

export const listBlacklists = cache(async function listBlacklists(
  widgetId: string,
  { ongoing }: IListBlacklistsParams,
  signal?: AbortSignal,
) {
  const query = new URLSearchParams();
  if (ongoing !== undefined) query.set("ongoing", ongoing + "");

  const response = await fetch(
    `${API_BASE}/widgets/${widgetId}/blacklists?${query}`,
    {
      credentials: "include",
      signal,
    },
  );
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return BlackListsSchema.merge(
    z.object({
      advertisement: AdvertisementVersionSchema,
    }),
  )
    .array()
    .parse(data);
}, ms("5 minutes"));

export async function createBlacklists(
  widgetId: string,
  advertisementIds: string[],
) {
  const response = await fetch(`${API_BASE}/widgets/${widgetId}/blacklists`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ advertisementIds }),
  });
  if (!response.ok) throw await logErrorResponse(response);
  listBlacklists.invalidate(([wId]) => wId === widgetId);
  return true;
}
