import * as React from "react";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useOptimistic } from "@/hooks/use-optimistic";
import { cn } from "@/lib/utils";

export interface ITabVisual {
  Icon?: React.ExoticComponent<{ className: string }>;
  label?: string;
}
export interface ITabSelectorProps<T extends string>
  extends Omit<
    React.ComponentPropsWithoutRef<typeof Tabs> &
      React.ComponentPropsWithoutRef<typeof SelectTrigger>,
    "disabled"
  > {
  value: T;
  onTabChange: (value: T) => void;
  tabs: Partial<Record<T, ITabVisual>>;
  disabled?: string | boolean;
}

export default function TabSelector<T extends string>({
  value,
  onTabChange,
  tabs,
  disabled,
  ...props
}: ITabSelectorProps<T>) {
  const tabsList = Object.entries(tabs).filter(Boolean) as [T, ITabVisual][];
  const [currentTab, setCurrentTab, _, tabChangePending] = useOptimistic<T>(
    value,
    onTabChange,
  );
  return (
    <>
      <Tabs
        value={currentTab}
        onValueChange={
          setCurrentTab as ITabSelectorProps<string>["onTabChange"]
        }
        {...props}
        className={cn(
          "hidden sm:flex border border-border rounded-lg dark:border-none",
          props.className,
        )}
      >
        <TabsList>
          {tabsList.map(([value, { label, Icon }]) => (
            <TabsTrigger
              value={value}
              key={value}
              className="flex items-center gap-2"
              disabled={
                tabChangePending ||
                disabled === true ||
                (disabled ? disabled !== "idle" : false)
              }
            >
              {Icon ? <Icon className="w-4 h-4" /> : null}
              {label}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
      <Select
        value={currentTab}
        onValueChange={
          setCurrentTab as ITabSelectorProps<string>["onTabChange"]
        }
        {...props}
        disabled={
          tabChangePending ||
          disabled === true ||
          (disabled ? disabled !== "idle" : false)
        }
      >
        <SelectTrigger className="flex sm:hidden w-56">
          <SelectValue placeholder="Aller à..." />
        </SelectTrigger>
        <SelectContent>
          {tabsList.map(([value, { label, Icon }]) => (
            <SelectItem value={value} key={value}>
              <p className="flex items-center gap-2">
                {Icon ? <Icon className="w-4 h-4" /> : null}
                {label}
              </p>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </>
  );
}
