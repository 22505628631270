import { cache } from "@/lib/cache";
import { API_BASE, logErrorResponse } from "./common";
import { CategoriesSchema } from "./definitions";
import ms from "ms";

export const listCategories = cache(async function listCategories(
  signal?: AbortSignal,
) {
  const response = await fetch(`${API_BASE}/categories`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return CategoriesSchema.array().parse(data);
}, ms("1 hour"));
