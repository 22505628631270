import * as React from "react";
import {
  useLoaderData,
  Outlet,
  useFetcher,
  useAsyncValue,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { ISingleWidgetLoaderData } from "./loader";
import Awaited from "@/components/awaited";
import { Skeleton } from "@/components/ui/skeleton";
import {
  ArrowDown,
  BarChart,
  CalendarDays,
  Check,
  ChevronDown,
  EyeOff,
  IterationCw,
  Pen,
  Settings2,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { TabsList } from "@/lib/tabs";
import { WidgetsDTO } from "@/api/definitions";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { ms } from "@/lib/timer";
import { useOptimistic } from "@/hooks/use-optimistic";
import {
  TabLayout,
  TabLayoutBreadcrumb,
  TabLayoutTitle,
} from "@/components/tab-layout";
import SearchSelect from "@/components/search-select";
import { join } from "@/lib/promise";

export interface ISingleWidgetLayoutProps {}

// const tabList =  satisfies TabsList<string>;

export default function SingleWidgetLayout(props: ISingleWidgetLayoutProps) {
  const data = useLoaderData() as ISingleWidgetLoaderData;
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  return (
    <TabLayout
      tabs={{
        analytics: { Icon: BarChart, label: "Performances" },
        schedules: { Icon: CalendarDays, label: "Programmation" },
        blacklists: { Icon: EyeOff, label: "Blacklist" },
        configuration: { Icon: Settings2, label: "Configuration" },
      }}
      defaultTab="configuration"
    >
      <TabLayoutTitle>
        <Awaited
          fallback={<Skeleton className="w-80 h-6" />}
          resolve={data.widget}
        >
          <NameEdit />
        </Awaited>
      </TabLayoutTitle>
      <TabLayoutBreadcrumb base={{ label: "Widgets", url: "/app/widgets" }}>
        <Awaited
          fallback={<Skeleton className="w-20 h-3 max-xs:hidden" />}
          resolve={data.widget}
          render={(current) => (
            <Awaited
              fallback={
                <span className="flex items-center gap-2">{current.name}</span>
              }
              resolve={data.widgets}
              render={(list) => {
                // widget.name
                return (
                  <SearchSelect
                    selected={current.id}
                    options={list.results.map((w) => ({
                      label: w.name,
                      value: w.id,
                    }))}
                    onSelect={(id) =>
                      navigate(pathname.replace(current.id, id))
                    }
                  >
                    <span className="flex items-center gap-2 cursor-pointer">
                      {current.name}
                      <ChevronDown className="w-4 h-4" />
                    </span>
                  </SearchSelect>
                );
              }}
            />
          )}
        />
      </TabLayoutBreadcrumb>
      <Outlet />
    </TabLayout>
  );
}

function NameEdit() {
  const widget = useAsyncValue() as WidgetsDTO;
  const [name, setName] = useOptimistic(widget.name);
  const [editActive, setEditActive] = React.useState(false);
  const fetcher = useFetcher();
  const ref = React.useRef<HTMLInputElement>(null);
  React.useEffect(() => {
    if (editActive) {
      const input = ref.current;
      if (!input) return;
      input.focus();
    } else {
      const timeout = setTimeout(() => setName(widget.name), ms("1 second"));
      return () => clearTimeout(timeout);
    }
  }, [editActive, widget.name]);
  return (
    <fetcher.Form
      action="/app/widgets/:widgetId/configuration?index"
      method="PUT"
      className="shrink-0 flex items-center gap-3"
    >
      <input type="hidden" name="widgetId" value={widget.id} />
      <div className="relative">
        <span className="whitespace-pre px-5 text-2xl h-11 font-semibold leading-none tracking-tight opacity-0">
          {widget.name + name.slice(widget.name.length)}
        </span>
        <Input
          name="name"
          onFocus={() => setEditActive(true)}
          onBlur={() =>
            setTimeout(() => {
              setEditActive(false);
            }, ms("200 ms"))
          }
          className={cn(
            "absolute inset-0 -top-2 text-2xl font-semibold leading-none tracking-tight disabled:opacity-100 cursor-default",
            editActive || "border-none",
          )}
          value={name}
          ref={ref}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <Button className={cn("shrink-0", editActive || "hidden")} size="icon">
        <Check className="w-4 h-4" />
      </Button>
      <Button
        className={cn("shrink-0", widget.name !== name || "hidden")}
        variant="ghost"
        type="reset"
        size="icon"
        onClick={() => {
          setName(widget.name);
        }}
      >
        <IterationCw className="w-4 h-4" />
      </Button>
      <Button
        className={cn("shrink-0", editActive && "hidden")}
        variant="ghost"
        size="icon"
        type="button"
        onClick={() => setEditActive(true)}
      >
        <Pen className="w-4 h-4 text-mued-foreground" />
      </Button>
    </fetcher.Form>
  );
}
