import { getCurrentUser } from "@/api/authentication";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface IApiSettingsLoaderData {
  userId: number;
}

export async function ApiSettingsLoader({}: LoaderFunctionArgs) {
  const session = await getCurrentUser();
  if (!session) throw redirect("/");
  return { userId: session.user.id } satisfies IApiSettingsLoaderData;
}
