import { toast } from "sonner";
import { timer } from "./timer";
import { logErrorResponse } from "@/api/common";

export async function withToast<T extends unknown>(
  fn: () => T,
  messages: Partial<Record<"loading" | "success" | "error", string>> = {},
) {
  let loadingToast: number | string | null = null;
  const { clear, trigger } = timer(() => {
    loadingToast = toast.loading(messages.loading ?? `Mise à jour en cours...`);
  }, "2 seconds");
  try {
    trigger();
    const result = await fn();
    console.log("withToast", result);
    toast.success(messages.success ?? `Mise à jour réussie !`);
    return result;
  } catch (err) {
    if (err !== false) {
      // if (err instanceof Response) await logErrorResponse(err);
      console.error(err);
      toast.error(messages.error ?? `Échec de la mise à jour.`);
      throw err;
    }
  } finally {
    clear();
    if (loadingToast) toast.dismiss(loadingToast);
  }
}
