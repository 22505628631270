import {
  AdvertisementVersionDTO,
  EventDatesDTO,
  SchedulesDTO,
  WidgetsDTO,
} from "@/api/definitions";
import {
  EyeIcon,
  MoreHorizontal,
  MousePointerClickIcon,
  Pause,
} from "lucide-react";
import { TableCell, TableRow } from "@/components/ui/table";
import dayjs, { displayDates } from "@/lib/date";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ROTATION_STRATEGY } from "@/api/definitions";
import { Switch } from "@/components/ui/switch";
import ScheduleActions from "./schedule-actions";
import { Skeleton } from "@/components/ui/skeleton";
import { ColumnsState } from "@/hooks/useColumns";
import { SchedulesTableColumnName } from "./columns";
import { cn } from "@/lib/utils";
import { ContentForType } from "@/api/advertisements";
import { useViewSummaryModal } from "@/modals/view-summary";
import { useUpdateScheduleModal } from "@/modals/update-schedule";
import { Link, useFetcher } from "react-router-dom";
import { objectToFormData } from "@/lib/query";
import { AdvertisementSchedulesTableColumnName } from "@/pages/app/advertisements/[id]/schedules/(blocks)/columns";
import { useRevalidateCache } from "@/hooks/use-invalidate";
import { listSchedules } from "@/api/schedules";
import { withId } from "@/lib/array";
import { useModifierKeys } from "@/hooks/use-modifier-keys";
import { DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { ActionsMenu } from "@/components/actions-menu";
import { formatBigNumbers } from "@/lib/format";
import AdvertisementDatesCell from "@/components/advertisement-dates-cell";

export interface IScheduleRowProps {
  schedule: SchedulesDTO & { advertisement: AdvertisementVersionDTO };
  widget?: WidgetsDTO;
  location_name?: string;
  dates?: EventDatesDTO[] | null | undefined;
  columnState: Partial<
    ColumnsState<
      SchedulesTableColumnName | AdvertisementSchedulesTableColumnName
    >
  >;
}

export default function ScheduleRow({
  widget,
  schedule,
  location_name,
  columnState,
  dates,
}: IScheduleRowProps) {
  const Icon = ContentForType[schedule.advertisement.type].icon;
  const isSponsored = schedule.rotation_strategy !== ROTATION_STRATEGY.FILLER;
  const hasEnded = dayjs().isAfter(schedule.to);
  const summaryModal = useViewSummaryModal();
  const updateScheduleModal = useUpdateScheduleModal();
  const fetcher = useFetcher();
  useRevalidateCache(
    listSchedules,
    (_, response) => !!response?.results.find(withId(schedule.id)),
  );
  const { ctrl, cmd } = useModifierKeys();
  if (Number.isNaN(+schedule.id)) return null;
  return (
    <ActionsMenu
      Actions={ScheduleActions}
      data={{ schedule, url: schedule.advertisement.url }}
    >
      <TableRow
        className={cn(
          "min-h-[72px]",
          !schedule.enabled && !hasEnded && "opacity-50",
        )}
      >
        <TableCell className="font-medium" hidden={!columnState.id?.visible}>
          #{schedule.id}
        </TableCell>
        <TableCell hidden={!columnState.widget?.visible}>
          {widget ? (
            <Link to={`/app/widgets/${widget.id}`} className="hover:underline">
              {widget.name}
            </Link>
          ) : null}
        </TableCell>
        <TableCell
          className={cn(columnState.id?.visible ? "px-0" : "pl-4")}
          hidden={!columnState.image?.visible}
        >
          <img
            src={schedule.advertisement.main_image}
            alt={schedule.advertisement.title}
            className="aspect-[2] w-20 rounded-[4px] object-cover"
          />
        </TableCell>
        <TableCell
          className="max-w-[320px] truncate"
          title={schedule.advertisement.title}
          hidden={!columnState.title?.visible}
        >
          <Tooltip>
            <TooltipTrigger>
              <Icon className="h-4 w-4 hover:bg-foreground hover:text-background rounded-sm mr-1 inline relative -top-0.5" />
            </TooltipTrigger>
            <TooltipContent>
              {ContentForType[schedule.advertisement.type].label}
            </TooltipContent>
          </Tooltip>
          {!schedule.enabled && !hasEnded ? (
            <Tooltip>
              <TooltipTrigger>
                <Pause className="h-4 w-4 hover:bg-foreground hover:text-background rounded-sm mr-1 inline relative -top-0.5" />
              </TooltipTrigger>
              <TooltipContent>
                Cette annonce a été désactivée et n'apparait pas dans le widget.
              </TooltipContent>
            </Tooltip>
          ) : null}
          <span
            className="cursor-pointer"
            onClick={() => {
              if (ctrl || cmd)
                open(`/app/advertisements/${schedule.advertisement.id}`);
              else
                summaryModal.openModal({
                  advertisementId: schedule.advertisement.id,
                  canGoToDetails: true,
                });
            }}
          >
            {schedule.advertisement.title}
          </span>
        </TableCell>
        <TableCell hidden={!columnState.rank?.visible}>
          {Math.max(schedule.order, 0) + 1}
        </TableCell>
        <AdvertisementDatesCell dates={dates} columnState={columnState.dates} />
        <TableCell
          className="max-w-[196px] truncate"
          title={location_name}
          hidden={!columnState.location?.visible}
        >
          {location_name}
        </TableCell>
        <TableCell
          hidden={!columnState.diffusion?.visible}
          className={cn(hasEnded || "hover:underline cursor-pointer")}
          onClick={() =>
            hasEnded ||
            updateScheduleModal.openModal({
              scheduleId: schedule.id,
            })
          }
        >
          {displayDates(schedule.from, schedule.to)}
          <br />
          {(schedule.impression_goal || schedule.clic_goal) && (
            <span
              className="text-muted-foreground text-xs flex items-center"
              title={`Objectif : ${formatBigNumbers(schedule.impression_goal)} impressions, ${formatBigNumbers(schedule.clic_goal)} clics`}
            >
              Objectif : <EyeIcon className="w-3 h-3 ml-1" />
              {formatBigNumbers(schedule.impression_goal)} /
              <MousePointerClickIcon className="w-3 h-3 ml-1" />
              {formatBigNumbers(schedule.clic_goal)}
            </span>
          )}
        </TableCell>
        <TableCell hidden={!columnState.sponsored?.visible}>
          <Switch
            checked={isSponsored}
            disabled={hasEnded}
            onCheckedChange={(value) => {
              if (fetcher.state !== "idle") return;
              fetcher.submit(
                objectToFormData({
                  id: schedule.id,
                  rotation_strategy: value
                    ? ROTATION_STRATEGY.ROTATE
                    : ROTATION_STRATEGY.FILLER,
                }),
                {
                  method: "PUT",
                  action: "/app/widgets/:widgetId/schedules?index",
                  encType: "multipart/form-data",
                },
              );
            }}
          />
        </TableCell>
        <TableCell hidden={!columnState.paused?.visible}>
          <Switch
            disabled={hasEnded}
            checked={schedule.enabled}
            onCheckedChange={(value) => {
              if (fetcher.state !== "idle") return;
              fetcher.submit(
                objectToFormData({
                  id: schedule.id,
                  enabled: value,
                }),
                {
                  method: "PUT",
                  action: "/app/widgets/:widgetId/schedules?index",
                  encType: "multipart/form-data",
                },
              );
            }}
          />
        </TableCell>
        <TableCell className="flex justify-end">
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="icon">
              <MoreHorizontal />
            </Button>
          </DropdownMenuTrigger>
        </TableCell>
      </TableRow>
    </ActionsMenu>
  );
}

ScheduleRow.Skeleton = function ({
  columnState,
}: Pick<IScheduleRowProps, "columnState">) {
  return (
    <TableRow className="min-h-[72px]">
      <TableCell className="font-medium" hidden={!columnState.id?.visible}>
        #<Skeleton className="w-full" />
      </TableCell>
      <TableCell
        className={cn(columnState.id?.visible ? "px-0" : "pl-4")}
        hidden={!columnState.image?.visible}
      >
        <Skeleton className="aspect-[2] w-20 rounded-[4px]" />
      </TableCell>
      <TableCell hidden={!columnState.widget?.visible}>
        <Skeleton className="w-40 h-6" />
      </TableCell>
      <TableCell
        className="max-w-[320px] truncate"
        hidden={!columnState.title?.visible}
      >
        <Skeleton className="w-[320px] h-6" />
      </TableCell>

      <TableCell hidden={!columnState.rank?.visible}>
        <Skeleton className="w-20 h-6" />
      </TableCell>
      <TableCell
        className="max-w-[196px] truncate"
        hidden={!columnState.location?.visible}
      >
        <Skeleton className="w-full h-6" />
      </TableCell>
      <TableCell hidden={!columnState.dates?.visible}>
        <Skeleton className="w-28 h-6" />
      </TableCell>
      <TableCell hidden={!columnState.sponsored?.visible}>
        <Skeleton className="rounded-full h-6 w-11" />
      </TableCell>
      <TableCell hidden={!columnState.paused?.visible}>
        <Skeleton className="rounded-full h-6 w-11" />
      </TableCell>
      <TableCell className="flex justify-end"></TableCell>
    </TableRow>
  );
};
