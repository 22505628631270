import { CheckIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { PricingsDTO } from "@/api/definitions";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";

interface IPremiumCardProps {
  plan: PricingsDTO;
}

const PremiumCard: React.FunctionComponent<IPremiumCardProps> = ({ plan }) => {
  const navigate = useNavigate();
  const isHighlited = plan.name === "Standard";
  function checkout() {
    if (plan.cta!.external) return window.open(plan.cta!.link);
    navigate(plan.cta!.link);
  }
  return (
    <div
      className={cn(
        "relative flex-1 flex items-stretch flex-col rounded-xl border mt-6 sm:mt-0 bg-card",
        isHighlited ? "border-accent" : "border-border",
      )}
    >
      <div className="p-8 space-y-4 border-b border-border text-center">
        <span className=" font-semibold">{plan.name}</span>
        <div className=" text-3xl font-semibold">
          €{plan.price}{" "}
          <span className="text-xl text-muted-foreground font-normal">
            /mois
          </span>
        </div>
        <p className="text-sm text-muted-foreground min-h-[20px]">
          {plan.description}
        </p>
      </div>
      <div className="p-8 flex flex-col justify-between h-full">
        <ul className="space-y-3">
          {plan.features.map((feature) => (
            <li key={feature.label} className="flex items-center gap-5 text-sm">
              <CheckIcon className="w-5 h-5" />
              {feature.label}
            </li>
          ))}
        </ul>
        <div className={cn("pt-8", plan.price > 0 || "hidden")}>
          {plan.cta && (
            <Button
              className="w-full"
              children={plan.cta.label}
              variant="accent"
              onClick={checkout}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PremiumCard;
