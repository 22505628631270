import * as React from "react";
import { Route } from "react-router-dom";
import NewAdvertisementPage from "./page";
import { NewAdvertisementLoader } from "./loader";
import AdvertisementBuilderRouter from "./[type]";
import NewAdvertisementLayout from "./layout";

export interface INewAdvertisementRouterProps {}

export default function NewAdvertisementRouter(
  props: INewAdvertisementRouterProps,
) {
  return (
    <Route Component={NewAdvertisementLayout}>
      <Route
        index
        loader={NewAdvertisementLoader}
        Component={NewAdvertisementPage}
      />
      <Route path=":type">{AdvertisementBuilderRouter({})}</Route>
    </Route>
  );
}
