import * as React from "react";
import { FetcherWithComponents, useFetcher } from "react-router-dom";
import { DropdownMenuItem } from "./ui/dropdown-menu";
import { Button } from "./ui/button";
import { entries } from "@/lib/entries";
import { cn } from "@/lib/utils";
import FormState, { IFormStateProps } from "./form-state";
import { ActionsMenuItem } from "./actions-menu";

export interface IDropdownFormProps {
  children: React.ReactNode | React.ReactNode[];
  formProps?: React.ComponentPropsWithoutRef<
    FetcherWithComponents<any>["Form"]
  >;
  buttonProps?: React.ComponentPropsWithoutRef<"button">;
  menuItemProps?: Omit<
    React.ComponentPropsWithoutRef<typeof DropdownMenuItem>,
    "asChild"
  >;
  data?: IFormStateProps["data"];
}

export default function DropdownForm({
  children,
  formProps = {},
  buttonProps = {},
  menuItemProps = {},
  data = {},
}: IDropdownFormProps) {
  const fetcher = useFetcher();
  return (
    <fetcher.Form {...formProps}>
      <ActionsMenuItem {...menuItemProps} asChild>
        <button
          {...buttonProps}
          className={cn("w-full cursor-pointer", buttonProps?.className)}
          type="submit"
        >
          {children}
          <FormState data={data} />
        </button>
      </ActionsMenuItem>
    </fetcher.Form>
  );
}
