import { HeaderBreadCrumb } from "@/components/header";
import * as React from "react";
import { Outlet } from "react-router-dom";

export interface IAdvertisementsLayoutProps {}

export default function AdvertisementsLayout(
  props: IAdvertisementsLayoutProps,
) {
  console.log("AdvertisementsLayout");
  return (
    <>
      <HeaderBreadCrumb
        chunks={[{ label: "Mes annonces", url: "/app/advertisements" }]}
      />
      <Outlet />
    </>
  );
}
