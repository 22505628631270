import * as React from "react";
import { Route } from "react-router-dom";
import SingleDesignRouter from "./[customizationId]";
import DesignLayout from "./layout";
import { AppDesignLoader } from "./loader";
import AppDesignEntry from "./page";
import NewDesignRouter from "./new";
import { AppDesignAuthGuardLoader } from "./auth-guard";

export interface IDesignRouterProps {}

export default function DesignRouter(props: IDesignRouterProps) {
  return (
    <Route Component={DesignLayout} loader={AppDesignAuthGuardLoader}>
      <Route index loader={AppDesignLoader} Component={AppDesignEntry} />
      <Route path="new">{NewDesignRouter({})}</Route>
      <Route path=":customizationId">{SingleDesignRouter({})}</Route>
    </Route>
  );
}
