import { createModalContext } from ".";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Form, useNavigation } from "react-router-dom";
import FormState from "@/components/form-state";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { useCache } from "@/hooks/use-cache";
import { listOwnedWidgets } from "@/api/widgets";
import { useSearchState } from "@/hooks/use-search-state";
import Awaited from "@/components/awaited";
import { Loader, XCircle } from "lucide-react";
import SearchSelect from "@/components/search-select";
import { useSet } from "@/hooks/use-set";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

export interface ICreateBlacklistModalProps {
  closeModal(): void;
  advertisementId: string;
}

export function CreateBlacklistModal({
  closeModal,
  advertisementId,
}: ICreateBlacklistModalProps) {
  const widgetsQuery = useCache(listOwnedWidgets);
  const [_, defaultWidgetId] = useSearchState<string | undefined>({
    key: "defaultWidget",
  });
  const selectedWidgets = useSet([defaultWidgetId].filter(Boolean) as string[]);
  const navigation = useNavigation();
  return (
    <Dialog open onOpenChange={closeModal} modal={false}>
      <div className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
      <DialogContent className="bg-card border-border text-foreground">
        <DialogHeader>
          <DialogTitle>Blacklister l'annonce</DialogTitle>
          <DialogDescription>
            L'annonce ne remontera dans aucun des flux associés aux widgets.
          </DialogDescription>
        </DialogHeader>
        <Form method="DELETE" action="/app/catalog?index" className="space-y-4">
          <Awaited
            resolve={widgetsQuery}
            fallback={<Loader className="w-4 h-5 animate-spin" />}
            key={Array.from(selectedWidgets).join()}
            render={({ results: widgets }) => {
              const options = widgets.filter((w) => !selectedWidgets.has(w.id));
              const selected = widgets.filter((w) => selectedWidgets.has(w.id));
              return (
                <div className="flex flex-col gap-2">
                  <Label>Widgets</Label>
                  <div className="w-full max-h-32 overflow-y-auto flex flex-wrap gap-3">
                    {selected.map((w) => (
                      <Button
                        type="button"
                        variant="accent"
                        size="sm"
                        onClick={() => selectedWidgets.delete(w.id)}
                      >
                        <XCircle className="w-4 h-4 mr-2" />
                        {w.name}
                      </Button>
                    ))}
                  </div>
                  <SearchSelect
                    className="w-fit"
                    placeholder={
                      <>
                        <MagnifyingGlassIcon className="w-4 h-4 mr-2" /> Ajouter
                        un widget ...
                      </>
                    }
                    options={options.map((w) => ({
                      value: w.id,
                      label: w.name,
                    }))}
                    onSelect={(value) => selectedWidgets.add(value)}
                  />
                </div>
              );
            }}
          />
          <FormState
            data={{ advertisementId, widgetIds: Array.from(selectedWidgets) }}
          />
          <DialogFooter>
            <Awaited
              resolve={widgetsQuery}
              render={({ results: widgets }) => (
                <Button
                  variant="link"
                  className="text-xs text-muted-foreground"
                  type="button"
                  disabled={navigation.state !== "idle"}
                  onClick={(e) => {
                    widgets.forEach((o) => selectedWidgets.add(o.id));
                    setTimeout(
                      () =>
                        (e.target as HTMLButtonElement)
                          .closest("form")
                          ?.requestSubmit(),
                      100,
                    );
                  }}
                >
                  Ajouter à tous mes widgets
                </Button>
              )}
            />
            <Button
              type="submit"
              disabled={navigation.state !== "idle" || !selectedWidgets.size}
            >
              Confirmer
            </Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export const [
  useCreateBlacklistModal,
  CreateBlacklistProvider,
  CreateBlacklistContext,
] = createModalContext(CreateBlacklistModal);
