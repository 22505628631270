import { updateCustomization } from "@/api/customizations";
import { CustomizationSchema } from "@/api/definitions";
import { objectFromFormData } from "@/lib/query";
import { withToast } from "@/lib/with-toast";
import { ActionFunctionArgs } from "react-router-dom";
import { z } from "zod";

export interface ISingleDesignActionData {}
export async function SingleDesignAction({
  request,
}: ActionFunctionArgs): Promise<ISingleDesignActionData> {
  const body = objectFromFormData(await request.formData());
  if (request.method === "PUT") {
    await withToast(() => {
      const { customizationId, ...payload } = CustomizationSchema.pick({
        name: true,
        content: true,
      })
        .partial()
        .and(z.object({ customizationId: z.coerce.number() }))
        .parse(body);
      return updateCustomization(customizationId, payload);
    });
  }
  return {};
}
