import {
  StepLayout,
  StepLayoutActions,
  StepLayoutHeader,
  StepLayoutNextAction,
  StepLayoutPreviousAction,
  StepLayoutSubtitle,
  StepLayoutTitle,
} from "@/components/step-layout";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useAutoComplete } from "@/hooks/use-auto-complete";
import { cn } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Map, Marker } from "@vis.gl/react-google-maps";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { useDraft } from "../../layout";
import { AdvertisementBuilderLocationSchema } from "./schemas";
import { IStepProps } from "./step-props";

export default function AdvertisementBuilderLocation({
  previous,
  next,
}: IStepProps) {
  const [{ draft }] = useDraft();
  const form = useForm({
    mode: "onTouched",
    resolver: zodResolver(AdvertisementBuilderLocationSchema),
    defaultValues: {
      address: draft.address ?? "",
      location_name: draft.location_name ?? "",
      city: draft.city ?? "",
      longitude: draft.longitude ?? 7.2411405,
      latitude: draft.latitude ?? 43.689104,
    },
  });
  console.log(form.getValues());
  const errors = form.formState.errors;
  const autoCompleteRef = useAutoComplete({
    onPlaceSelect(results) {
      if (!results.geometry) {
        return toast.error("Veuillez sélectionner une adresse valide");
      }
      console.log("results", results);
      form.setValue(
        "location_name",
        results.name ||
          results.formatted_address?.split(",")[0] ||
          form.getValues("location_name") ||
          "",
      );
      form.setValue(
        "address",
        results.formatted_address ?? form.getValues("address"),
      );
      form.setValue(
        "latitude",
        results.geometry.location?.lat() ?? form.getValues("latitude"),
      );
      form.setValue(
        "longitude",
        results.geometry.location?.lng() ?? form.getValues("longitude"),
      );
      form.setValue("city", results.vicinity ?? form.getValues("city"));
      form.trigger();
    },
    types: ["geocode", "establishment"],
    fields: ["formatted_address", "geometry", "name", "vicinity"],
  });
  const { ref: addressRef, ...addressProps } = form.register("address");
  return (
    <StepLayout>
      <StepLayoutHeader>
        <StepLayoutTitle>Où aura lieu votre événement ?</StepLayoutTitle>
        <StepLayoutSubtitle>Détails de la localisation.</StepLayoutSubtitle>
      </StepLayoutHeader>
      <div className="space-y-2">
        <Label>Adresse *</Label>
        <Input
          className={cn(errors.address && "border-red-500")}
          required
          {...addressProps}
          ref={(input) => {
            addressRef(input);
            // @ts-ignore
            autoCompleteRef.current = input;
          }}
          placeholder="Saisir une adresse..."
        />
      </div>
      <div className="space-y-2">
        <Label>Lieu *</Label>
        <Input
          className={cn(errors.location_name && "border-red-500")}
          required
          {...form.register("location_name")}
          placeholder="Nom du lieu"
        />
      </div>
      <Map
        defaultZoom={15}
        defaultCenter={{
          lat: form.getValues("latitude"),
          lng: form.getValues("longitude"),
        }}
        key={`${form.getValues("latitude")};${form.getValues("longitude")}`}
        disableDefaultUI
        className="w-full h-64 rounded-sm overflow-hidden"
      >
        <Marker
          position={{
            lat: form.getValues("latitude"),
            lng: form.getValues("longitude"),
          }}
        />
      </Map>
      <StepLayoutActions>
        <StepLayoutPreviousAction previous={previous}>
          Retour
        </StepLayoutPreviousAction>
        <StepLayoutNextAction form={form} next={next}>
          Continuer
        </StepLayoutNextAction>
      </StepLayoutActions>
    </StepLayout>
  );
}
