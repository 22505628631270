import { listAdvertisements } from "@/api/advertisements";
import { createWidget, listWidgetsWithMetaData } from "@/api/widgets";
import { deepMergeAll } from "@/lib/entries";
import dayjs from "@/lib/date";
import { LoaderFunctionArgs, defer, redirect } from "react-router-dom";
import { getCurrentUser } from "@/api/authentication";
import { extractEnabledAdvertisementTypes } from "@/api/companies";
import { normalize } from "@/lib/format";
import { CreateWidgetSchema } from "@/api/definitions";

export interface IAppLoaderData {
  session: NonNullable<Awaited<ReturnType<typeof getCurrentUser>>>;
  widgets: ReturnType<typeof listWidgetsWithMetaData>;
  advertisements: ReturnType<typeof listAdvertisements>;
}

export async function AppLoader({ request }: LoaderFunctionArgs) {
  const session = await getCurrentUser(request.signal);
  if (!session) throw redirect("/auth");
  if (!session?.company?.id) throw redirect("/onboarding");
  return defer({
    widgets: listWidgetsWithMetaData({}, request.signal).then(async (res) => {
      if (res.results.length) return res;
      await createWidget(
        CreateWidgetSchema.parse({
          source: normalize(session.company!.name),
          source_id: normalize(
            session.company!.name + crypto.randomUUID(),
          ).slice(0, 16),
        }),
      );
      return listWidgetsWithMetaData({}, request.signal);
    }),
    session,
    advertisements: Promise.all(
      extractEnabledAdvertisementTypes(session.company).map((type) =>
        listAdvertisements(
          {
            type,
            limit: 4,
            archived: false,
            from: dayjs().startOf("day").toDate(),
            company_id: session.company!.id,
          },
          request.signal,
        ),
      ),
    )
      .then((res) => ({
        ...deepMergeAll(...res),
        meta: { count: res.reduce((acc, cur) => acc + cur.meta.count, 0) },
      }))
      .then((res) => {
        res.results.sort((a, b) => {
          return dayjs(a.updatedAt).isBefore(b.updatedAt) ? 1 : -1;
        });
        return res;
      }),
  } satisfies IAppLoaderData);
}
