import { objectFromFormData } from "@/lib/query";
import { withToast } from "@/lib/with-toast";
import { ActionFunctionArgs, redirect } from "react-router-dom";
import * as z from "@/lib/zod";
import { duplicateAdvertisement } from "@/api/advertisements";

export interface IAdvertisementsActionData {}
export async function AdvertisementsAction({
  request,
}: ActionFunctionArgs): Promise<IAdvertisementsActionData> {
  const body = objectFromFormData(await request.formData());
  if (request.method === "POST") {
    const newAdvertisementId = await withToast(
      () => {
        const { id } = z.object({ id: z.string() }).parse(body);
        return duplicateAdvertisement(id, request.signal);
      },
      {
        success: "Duplication réussie",
        loading: `Duplication en cours...`,
        error: `Échec de la duplication.`,
      },
    );
    throw redirect(`/app/advertisements/${newAdvertisementId}/content`);
  }
  return {};
}
