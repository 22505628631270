import { impersonateUser } from "@/api/authentication";
import { CompaniesDTO } from "@/api/definitions";
import { listUsers } from "@/api/users";
import { ActionsMenuContent, ActionsMenuItem } from "@/components/actions-menu";
import { DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";
import { ExtractResult } from "@/lib/cache";
import { copyText } from "@/lib/copy";
import { objectToFormData } from "@/lib/query";
import { cn } from "@/lib/utils";
import { useDeleteUserModal } from "@/modals/delete-user";
import {
  Building2,
  Edit,
  Hash,
  LayoutGrid,
  LogIn,
  Trash,
  User,
} from "lucide-react";
import * as React from "react";
import { useFetcher } from "react-router-dom";

export interface IUserActionsProps {
  user: ExtractResult<typeof listUsers>[number];
}

export default function UserActions({ user }: IUserActionsProps) {
  const navigate = useSmartNavigate();
  const fetcher = useFetcher();
  const deleteUserModal = useDeleteUserModal();
  return (
    <ActionsMenuContent className="w-56" align="start" side="left">
      <ActionsMenuItem
        onClick={() => copyText(user.id)}
        className="cursor-pointer"
      >
        <Hash className="mr-2 h-4 w-4" />
        <span>Copier l'ID</span>
      </ActionsMenuItem>
      <ActionsMenuItem
        onClick={() => impersonateUser(user.id)}
        className="cursor-pointer"
      >
        <LogIn className="mr-2 h-4 w-4" />
        <span>Se connecter</span>
      </ActionsMenuItem>
      <DropdownMenuSeparator />
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={() => navigate("/app/admin/companies?ids=" + user.companyId)}
      >
        <Building2 className="mr-2 h-4 w-4" />
        <span>Voir l'Entreprise</span>
      </ActionsMenuItem>
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={() => navigate("/app/admin/users?companyId=" + user.companyId)}
      >
        <User className="mr-2 h-4 w-4" />
        <span>Collaborateurs</span>
      </ActionsMenuItem>
      <DropdownMenuSeparator />
      <ActionsMenuItem className={cn("cursor-pointer")}>
        <Edit className="mr-2 h-4 w-4" />
        <span>Modifier</span>
      </ActionsMenuItem>
      <ActionsMenuItem
        className={cn(
          "cursor-pointer bg-destructive text-destructive-foreground",
        )}
        onClick={() => deleteUserModal.openModal({ userId: user.id })}
      >
        <Trash className="mr-2 h-4 w-4" />
        Supprimer
      </ActionsMenuItem>
    </ActionsMenuContent>
  );
}
