import { TableHead } from "@/components/ui/table";
import { ColumnsDef } from "@/hooks/useColumns";
import { Checkbox } from "@/components/ui/checkbox";

export type BlacklistsTableColumnName = "checked" | "image" | "title";

export interface HeaderColumnCheckboxType {
  allChecked: boolean;
  toggleAllChecked: () => void;
}

export const BlacklistsTableColumns: ColumnsDef<
  BlacklistsTableColumnName,
  HeaderColumnCheckboxType
> = {
  checked: {
    value: "checked",
    label: "",
    header(props) {
      return (
        <TableHead className="w-20">
          <Checkbox
            className="relative top-0.5"
            checked={props.allChecked}
            onCheckedChange={props.toggleAllChecked}
          />
        </TableHead>
      );
    },
  },
  image: {
    value: "image",
    label: "Image",
    header: () => <TableHead className="w-20 first:px-4 px-0">Image</TableHead>,
  },
  title: {
    value: "title",
    label: "Titre",
    header: () => <TableHead>Titre</TableHead>,
  },
};
