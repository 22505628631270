import * as React from "react";
import { createModalContext } from ".";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Form } from "react-router-dom";
import { Button } from "@/components/ui/button";
import FormState from "@/components/form-state";

export interface IDeleteWidgetModalProps {
  closeModal?(triggerCloseEvent?: boolean): void;
  widgetId: string;
}

const DESIRED_INPUT_VALUE = "supprimer mon widget";
export function DeleteWidgetModal({
  closeModal,
  widgetId,
}: IDeleteWidgetModalProps) {
  const [inputValue, setInputValue] = React.useState("");
  return (
    <Dialog open onOpenChange={() => closeModal?.()}>
      <DialogContent className="bg-card border-border text-foreground">
        <DialogHeader>
          <DialogTitle>Supprimer mon widget</DialogTitle>
          <DialogDescription>
            En supprimant le widget, vous n'y aurez plus accès et il ne
            s'affichera plus sur vos sites.
          </DialogDescription>
          <DialogDescription>
            Vous n'aurez plus accès aux programmations et aux performances de
            celles-ci, mais les liens de partage resteront valides jusqu'à
            expiration.
          </DialogDescription>
        </DialogHeader>
        <Form action="/app/widgets/[id]/configuration?index" method="DELETE">
          <div className="space-y-4 w-full">
            <p className="px-4 py-2 bg-destructive/40 text-[#ff565f] text-sm leading-6 tracking-wide rounded-md">
              <b>Attention</b> : Cette action est irréversible.
            </p>
            <div className="space-y-2">
              <Label>
                Saisissez <b>supprimer mon widget</b> ci-dessous:
              </Label>
              <Input
                name="name"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </div>
            <DialogFooter className="sm:justify-between">
              <Button
                type="submit"
                variant="destructive"
                disabled={inputValue !== DESIRED_INPUT_VALUE}
              >
                Confirmer
              </Button>
              <Button
                type="button"
                className="min-w-[128px]"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  closeModal?.();
                }}
              >
                Annuler
              </Button>
            </DialogFooter>
            <FormState data={{ widgetId }} />
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export const [useDeleteWidgetModal, DeleteWidgetProvider, DeleteWidgetContext] =
  createModalContext(DeleteWidgetModal);
