import * as React from "react";
import { Route } from "react-router-dom";
import { AppLoader } from "./loader";
import AppLayout from "./layout";
import Dashboard from "./page";
import WidgetsRouter from "./widgets";
import AdvertisementsRouter from "./advertisements";
import { AppErrorPage } from "./error";
import { AuthGuardComponent, AuthGuardLoader } from "./auth-guard";
import SettingsRouter from "./settings";
import CatalogRouter from "./catalog";
import DesignRouter from "./design";
import AdminRouter from "./admin";

export interface IAppRouterProps {}

export default function AppRouter(props: IAppRouterProps) {
  return (
    <Route
      id="session"
      errorElement={<AppErrorPage />}
      loader={AuthGuardLoader}
      Component={AuthGuardComponent}
    >
      <Route Component={AppLayout}>
        <Route index Component={Dashboard} loader={AppLoader} />
        <Route path="widgets">{WidgetsRouter({})}</Route>
        <Route path="advertisements">{AdvertisementsRouter({})}</Route>
        <Route path="help" />
        <Route path="catalog">{CatalogRouter({})}</Route>
        <Route path="settings">{SettingsRouter({})}</Route>
        <Route path="admin">{AdminRouter({})}</Route>
      </Route>
      <Route path="design">{DesignRouter({})}</Route>
    </Route>
  );
}
