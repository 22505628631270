import { getCurrentUser } from "@/api/authentication";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface IOnboardingLoaderData {}

export async function OnboardingLoader({ request }: LoaderFunctionArgs) {
  console.log("OnboardingLoader");
  const session = await getCurrentUser(request.signal);
  console.log("OnboardingLoader session", session);
  if (!session) throw redirect("/auth");
  return {} as IOnboardingLoaderData;
}
