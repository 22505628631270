import { ActionFunctionArgs, redirect } from "react-router-dom";
import {
  CreateScheduleSchema,
  SchedulesSchema,
  UpdateScheduleSchema,
} from "@/api/definitions";
import {
  createSchedule,
  deleteSchedule,
  updateSchedule,
} from "@/api/schedules";
import { withToast } from "@/lib/with-toast";
import { objectFromFormData } from "@/lib/query";
import * as z from "@/lib/zod";
import { listOwnedWidgets } from "@/api/widgets";
import { toast } from "sonner";

export interface ISchedulesActionData {}
export async function SchedulesAction({
  request,
}: ActionFunctionArgs): Promise<ISchedulesActionData> {
  const body = objectFromFormData(await request.formData());
  if (request.method === "POST") {
    const result = await withToast(async () => {
      try {
        const schedules = z
          .json(CreateScheduleSchema.array())
          .parse(body.schedules);
        const results = await Promise.allSettled(schedules.map(createSchedule));
        const errors = results
          .map((res, index) => {
            if (res.status === "fulfilled") return null;
            const payload = schedules![index];
            return [payload, res.reason] as const;
          })
          .filter(Boolean);
        if (!errors.length) {
          if (body.noRedirect || schedules.length !== 1)
            return window.location.href; //reload page
          const createdSchedule = (
            results[0] as PromiseFulfilledResult<
              Awaited<ReturnType<typeof createSchedule>>
            >
          ).value;
          let active = false;
          if (createdSchedule.from < new Date()) active = true;
          return `/app/widgets/${createdSchedule.widget_id}/schedules?status=${active ? "active" : "future"}`;
        }
        const widgets = await listOwnedWidgets(request.signal);
        const errorWidgetIds = new Set(errors.map((e) => e![0].widget_id));
        const errorWidgets = widgets.results
          .filter((w) => errorWidgetIds.has(w.id))
          .map((w) => w.name)
          .join('"');
        console.log({ errorWidgets });
        toast.error(
          `Echec de la programmation sur les widgets "${errorWidgets}".`,
        );
      } catch (e) {
        toast.error(
          `Echec de la programmation de l'annonce. Veuillez réessayer ultérieurement`,
        );
      }
      throw false;
    });
    if (result) throw redirect(result);
  } else if (request.method === "PUT") {
    await withToast(
      () => {
        const { id, ...payload } = UpdateScheduleSchema.and(
          SchedulesSchema.pick({ id: true }),
        ).parse(body);
        return updateSchedule(id, payload);
      },
      { success: "Programmation mise à jour !" },
    );
  } else if (request.method === "DELETE") {
    await withToast(
      () => {
        const { id } = SchedulesSchema.pick({ id: true }).parse(body);
        return deleteSchedule(id);
      },
      {
        loading: "Suppression en cours...",
        error: "Échec de la suppression.",
        success:
          'Programmaion annulée. Si la diffusion avait commencée, vous pouvez la retrouver dans l\'onglet "Terminées".',
      },
    );
  }
  return {};
}
