import { listMutationLogs } from "@/api/mutation_log";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";
import * as z from "@/lib/zod";
import { ListMutationLogSchema } from "@/api/definitions";

export interface IMutationLogLoaderData {
  logs: ReturnType<typeof listMutationLogs>;
}

const QuerySchema = ListMutationLogSchema.and(
  z
    .object({
      dates: z
        .tuple([z.dateString(), z.dateString()])
        .rest(z.dateString())
        .optional(),
    })
    .transform((d) => {
      const [from, to] = d.dates?.sort() ?? [];
      const res = {} as any;
      if (from) res.from = from;
      if (to) res.to = to;
      return res;
    })
    .optional(),
);

export async function MutationLogLoader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const query = z
    .searchParams(QuerySchema, "safeParse")
    .parse(url.searchParams);
  if (!query.success) throw redirect(url.pathname);
  if (query.success) console.log("query.data", query.data);
  return defer({
    logs: listMutationLogs(query.data, request.signal),
  } satisfies IMutationLogLoaderData);
}
