import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { objectFromSearchParams, objectToSearchParams } from "@/lib/query";
import { LoaderIcon } from "lucide-react";
import * as React from "react";
import { Form, Link, useNavigation, useSearchParams } from "react-router-dom";
import { toast } from "sonner";

export interface IRegisterPageProps {}

export default function RegisterPage(props: IRegisterPageProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const error = searchParams.get("error");
  const time = searchParams.get("time");
  const navigation = useNavigation();
  React.useEffect(() => {
    if (!error || Number.isNaN(+error)) return;
    // toast.error(messageForRegisterErrorType[+error as RegisterErrorType]);
    setSearchParams((s) =>
      objectToSearchParams({ ...objectFromSearchParams(s), error: undefined }),
    );
  }, [error, time]);
  return (
    <div className="mx-auto grid w-[350px] gap-6">
      <div className="grid gap-2 text-center">
        <h1 className="text-3xl font-bold">Inscription</h1>
        <p className="text-balance text-muted-foreground">
          Créer un compte gratuitement en quelques secondes et intégrer votre
          premier widget sur votre site, dès maintenant 👊🏻
        </p>
      </div>
      <Form method="POST" className="grid gap-4">
        <div className="grid grid-cols-2 gap-x-3 gap-y-2">
          <Label htmlFor="lastname">Nom</Label>
          <Label htmlFor="firstname">Prénom</Label>
          <Input
            id="lastname"
            name="lastname"
            type="text"
            defaultValue={searchParams.get("lastname") ?? ""}
            placeholder=""
            required
          />
          <Input
            id="firstname"
            name="firstname"
            type="firstname"
            defaultValue={searchParams.get("firstname") ?? ""}
            placeholder=""
            required
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="email">Adresse email</Label>
          <Input
            id="email"
            name="email"
            type="email"
            defaultValue={searchParams.get("email") ?? ""}
            autoComplete="email"
            placeholder="m@example.com"
            required
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="password">Mot de passe</Label>
          <Input
            id="password"
            name="password"
            type="password"
            required
            placeholder="••••••••"
            autoComplete="new-password"
          />
        </div>
        <Button
          type="submit"
          disabled={navigation.state !== "idle"}
          className="w-full space-x-2"
        >
          {navigation.state !== "idle" ? (
            <LoaderIcon className="w-4 h-4 animate-spin" />
          ) : (
            <div className="w-4 h-4" />
          )}
          <span>S'inscrire</span>
          <div className="w-4 h-4" />
        </Button>
      </Form>
      <div className="mt-4 text-center text-sm">
        Déjà un compte ?{" "}
        <Link to="/auth/login" className="underline">
          Connectez-vous
        </Link>
      </div>
    </div>
  );
}
