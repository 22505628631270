/// <reference types="@welldone-software/why-did-you-render" />
import "./lib/sentry";
import "./lib/date";
import "./lib/polyfills";
import "./lib/hotjar";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import RootRouter from "./pages";
import { TooltipProvider } from "./components/ui/tooltip";
import { Toaster } from "@/components/ui/sonner";
import { ThemeProvider } from "./components/ui/theme-provider";
import { APIProvider } from "@vis.gl/react-google-maps";
import * as Sentry from "@sentry/react";

const routes = createRoutesFromElements(RootRouter({}));
console.log({ routes });

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(routes, {
  basename: import.meta.env.BASE_URL,
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <TooltipProvider disableHoverableContent>
        <APIProvider
          apiKey={import.meta.env.VITE_GOOGLE_API_KEY}
          onLoad={() => console.log("Maps API has loaded.")}
        >
          <RouterProvider router={router} />
        </APIProvider>
      </TooltipProvider>
      <Toaster richColors closeButton />
    </ThemeProvider>
  </React.StrictMode>,
);
