import { getCurrentUser } from "@/api/authentication";
import Awaited from "@/components/awaited";
import { TabLayoutBreadcrumb, TabLayoutContent } from "@/components/tab-layout";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useRevalidateCache } from "@/hooks/use-invalidate";
import * as React from "react";
import { Link, useLoaderData, useRouteLoaderData } from "react-router-dom";
import { ISettingsLoaderData } from "../loader";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Alert, AlertTitle } from "@/components/ui/alert";
import { ISubscriptionSettingsLoaderData } from "./loader";
import PremiumCard from "./(blocks)/premium-card";

export interface ISubscriptionSettingsPageProps {}

export default function SubscriptionSettingsPage(
  props: ISubscriptionSettingsPageProps,
) {
  useRevalidateCache(getCurrentUser);
  const settingsData = useRouteLoaderData(
    "settings-loader",
  ) as ISettingsLoaderData;
  const data = useLoaderData() as ISubscriptionSettingsLoaderData;
  return (
    <TabLayoutContent size="full-width">
      <TabLayoutBreadcrumb>Abonnement</TabLayoutBreadcrumb>
      <Card className="max-w-sm">
        <CardHeader>
          <CardDescription>Plan actuel</CardDescription>
          <Awaited
            resolve={settingsData.session}
            render={({ company }) => (
              <CardTitle>Plan {company!.subscription}</CardTitle>
            )}
          />
        </CardHeader>
        <CardFooter className="pb-4 pt-0">
          <Link to="mailto:axel@konnectz.io">
            <Button variant="outline">Modifier mon abonnement</Button>
          </Link>
        </CardFooter>
      </Card>
      <Card>
        <CardHeader className="text-center py-12">
          <CardTitle>Profitez de la pleine puissance de Konnectz</CardTitle>
          <CardDescription>
            Accédez aux options de diffusion et aux fonctionnalités de
            publication et de programmation avancées.
          </CardDescription>
        </CardHeader>
        <CardContent className="justify-center gap-6 sm:grid lg:grid-cols-2 xl:grid-cols-3 sm:space-y-0 mx-auto sm:max-w-3xl lg:max-w-5xl duration-1000 delay-300 opacity-1">
          <Awaited
            resolve={data.pricings}
            render={(pricings) =>
              pricings.map((plan) => (
                <PremiumCard plan={plan} key={plan.name} />
              ))
            }
          />
        </CardContent>
        <CardFooter />
      </Card>
    </TabLayoutContent>
  );
}
