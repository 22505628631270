import { logout } from "@/api/authentication";
import { ActionFunctionArgs, redirect } from "react-router-dom";

export interface IAuthActionData {}
export async function AuthAction({
  request,
}: ActionFunctionArgs): Promise<IAuthActionData> {
  if (request.method === "DELETE") {
    await logout(request.signal);
    throw redirect("/auth/login");
  }
  return {};
}
