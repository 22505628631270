import { ActionFunctionArgs, redirect } from "react-router-dom";
import * as z from "@/lib/zod";
import { objectFromFormData, objectToSearchParams } from "@/lib/query";
import { getCurrentUser, register, RegisterError } from "@/api/authentication";

export interface IRegisterActionData {}
export async function RegisterAction({
  request,
}: ActionFunctionArgs): Promise<IRegisterActionData> {
  try {
    const payload = RegisterParamsSchema.parse(
      objectFromFormData(await request.formData()),
    );
    await register(
      payload.email,
      payload.password,
      payload.firstname,
      payload.lastname,
      request.signal,
    );
    getCurrentUser.invalidate();
    throw redirect("/onboarding");
  } catch (e) {
    if (e instanceof RegisterError) {
      console.log("login error", e.type);
      throw redirect(
        "./?" + objectToSearchParams({ time: Date.now(), ...e.toObject() }),
      );
    }
    throw e;
  }
}
export const RegisterParamsSchema = z.object({
  email: z.string(),
  password: z.string(),
  firstname: z.string(),
  lastname: z.string(),
});
