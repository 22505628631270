import * as React from "react";
import {
  Area,
  AreaChart,
  AreaProps,
  CartesianGrid,
  LabelList,
  XAxis,
} from "recharts";

import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { keys } from "@/lib/entries";

export interface ICustomChartProps<
  T extends Record<string, string | number | Date>,
  X extends Extract<keyof T, string>,
  S extends Exclude<Extract<keyof T, string>, X>,
> {
  config: ChartConfig<S>;
  xAxis: X;
  data: T[];
  formatter: (value: T[X]) => string;
  areas: {
    [k in S]: Omit<React.SVGProps<SVGElement>, "type" | "points" | "ref"> &
      Omit<AreaProps, "dataKey" | "ref">;
  };
}

export default function CustomChart<
  T extends Record<string, string | number | Date>,
  X extends Extract<keyof T, string>,
  S extends Exclude<Extract<keyof T, string>, X>,
>({ config, xAxis, data, formatter, areas }: ICustomChartProps<T, X, S>) {
  const id = `grad-${React.useId()}`;
  return (
    <ChartContainer
      config={{
        ...config,
        [xAxis]: {
          label: xAxis,
        },
      }}
      className="aspect-auto h-[250px] w-full"
    >
      <AreaChart data={data}>
        <defs>
          {keys(config).map((k) => (
            <linearGradient
              key={k}
              id={`fill-${k}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="5%"
                stopColor={`var(--color-${k})`}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={`var(--color-${k})`}
                stopOpacity={0.1}
              />
            </linearGradient>
          ))}
        </defs>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey={xAxis}
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          minTickGap={32}
          tickFormatter={formatter}
        />
        <ChartTooltip
          cursor={false}
          content={
            <ChartTooltipContent
              labelKey={xAxis}
              labelFormatter={(_, [{ payload }]) => {
                return formatter(payload[xAxis]);
              }}
              indicator="dot"
            />
          }
        />
        {keys(config).map((k) => (
          <Area
            key={k}
            dataKey={k}
            fill={`url(#fill-${k})`}
            stroke={`var(--color-${k})`}
            {...areas[k]}
          />
        ))}
      </AreaChart>
    </ChartContainer>
  );
}
