import { getCurrentUser } from "@/api/authentication";
import {
  createEmptyCustomization,
  listCompanyCustomizations,
} from "@/api/customizations";
import { CustomizationDTO } from "@/api/definitions";
import { listOwnedWidgets, updateWidget } from "@/api/widgets";
import { withId } from "@/lib/array";
import { dependsOn } from "@/lib/promise";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface IWidgetConfigurationLoaderData {
  customizationsList: ReturnType<typeof listCompanyCustomizations>;
  customization: Promise<CustomizationDTO>;
}

export async function WidgetConfigurationLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  if (!params.widgetId) throw new Error("invalid params object");

  const widgetPromise = listOwnedWidgets(request.signal).then(async (r) => {
    const match = r.results.find(withId(params.widgetId));
    if (!match) throw new Error("Can't access this widget");
    return match;
  });
  const sessionPromise = getCurrentUser(request.signal);
  const customizationsList = listCompanyCustomizations(request.signal);
  return defer({
    customizationsList,
    customization: customizationsList
      .then(dependsOn(sessionPromise))
      .then(dependsOn(widgetPromise))
      .then(async (cust) => {
        const widget = await widgetPromise;
        const session = (await sessionPromise)!;
        const matchingCustomization = cust.find(withId(widget.customization));
        if (matchingCustomization) return matchingCustomization;
        if (!cust.length) {
          cust.push(
            await createEmptyCustomization({
              company_id: widget.company_id ?? session.company!.id,
            }),
          );
        }
        const customization = cust.at(-1)!;
        await updateWidget(widget.id, {
          customization: customization.id,
        });
        return customization;
      }),
  } satisfies IWidgetConfigurationLoaderData);
}
