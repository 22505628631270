import * as React from "react";
import { Route } from "react-router-dom";
import SingleDesignPage from "./page";
import { SingleDesignLoader } from "./loader";
import { SingleDesignAction } from "./action";

export interface ISingleDesignRouterProps {}

export default function SingleDesignRouter(props: ISingleDesignRouterProps) {
  return (
    <Route
      index
      loader={SingleDesignLoader}
      action={SingleDesignAction}
      Component={SingleDesignPage}
    />
  );
}
