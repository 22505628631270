import { listCategories } from "@/api/categories";
import { getZoneTree, listZones } from "@/api/zones";
import { useRevalidateCaches } from "@/hooks/use-invalidate";
import * as React from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";

export interface IRootLayoutProps {}

export default function RootLayout(props: IRootLayoutProps) {
  useRevalidateCaches(listCategories, getZoneTree, listZones);
  return (
    <>
      <Outlet />
      <ScrollRestoration />
    </>
  );
}
