import * as React from "react";
import { Route } from "react-router-dom";
import { AdvertisementContentLoader } from "./loader";
import AdvertisementContentPage from "./page";
import { AdvertisementContentAction } from "./action";

export interface IAdvertisementContentRouterProps {}

export default function AdvertisementContentRouter(
  props: IAdvertisementContentRouterProps,
) {
  return (
    <Route>
      <Route
        index
        loader={AdvertisementContentLoader}
        action={AdvertisementContentAction}
        Component={AdvertisementContentPage}
      />
    </Route>
  );
}
