import * as React from "react";
import { CatalogLoader } from "./loader";
import CatalogPage from "./page";
import { Route } from "react-router-dom";
import { CatalogAction } from "./action";

export interface ICatalogRouterProps {}

export default function CatalogRouter(props: ICatalogRouterProps) {
  return (
    <Route
      index
      loader={CatalogLoader}
      action={CatalogAction}
      Component={CatalogPage}
    />
  );
}
