import * as React from "react";
import { Route } from "react-router-dom";
import AdminAcionsPage from "./page";
import { AdminActionsLoader } from "./loader";

export interface IAdminAcionsRouterProps {}

export default function AdminAcionsRouter(props: IAdminAcionsRouterProps) {
  return (
    <Route>
      <Route index loader={AdminActionsLoader} Component={AdminAcionsPage} />
    </Route>
  );
}
