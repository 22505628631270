import * as React from "react";

export function useRenderTrack(deps: any[], label?: string) {
  // const lastValue = React.useRef<any>([]);
  // if (deps.flat().some((d, i) => !Object.is(d, lastValue.current[i]))) {
  //   console.log("new render:run", label, deps.flat(), lastValue.current);
  // }
  // lastValue.current = deps.flat();
  React.useEffect(() => console.log("new render", label, deps), deps.flat());
  // React.useLayoutEffect(
  //   () => console.log("new render:layout", label),
  //   deps.flat(),
  // );
  // React.useInsertionEffect(
  //   () => console.log("new render:insert", label),
  //   deps.flat(),
  // );
}
