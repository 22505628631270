import { toast } from "sonner";
import { ms } from "./timer";

export async function copyText(
  value: string | number,
  message = "Texte copié !",
) {
  await navigator.clipboard.writeText(value + "");
  toast.success(message, { duration: ms("1 s") });
}
