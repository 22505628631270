import * as React from "react";
import { Route } from "react-router-dom";
import AdvertisementAnalyticsPage from "./page";
import { AdvertisementAnalyticsLoader } from "./loader";

export interface IAdvertisementAnalyticsRouterProps {}

export default function AdvertisementAnalyticsRouter(
  props: IAdvertisementAnalyticsRouterProps,
) {
  return (
    <Route>
      <Route
        index
        Component={AdvertisementAnalyticsPage}
        loader={AdvertisementAnalyticsLoader}
      />
    </Route>
  );
}
