import { getCurrentUser, login, LoginError } from "@/api/authentication";
import { ActionFunctionArgs, redirect } from "react-router-dom";
import * as z from "@/lib/zod";
import { objectFromFormData, objectToSearchParams } from "@/lib/query";

export interface ILoginActionData {}
export async function LoginAction({
  request,
}: ActionFunctionArgs): Promise<ILoginActionData> {
  try {
    const payload = LoginParamsSchema.parse(
      objectFromFormData(await request.formData()),
    );
    const session = await login(
      payload.email,
      payload.password,
      request.signal,
    );
    getCurrentUser.invalidate();
    if (session.company) throw redirect("/app");
    throw redirect("/onboarding");
  } catch (e) {
    if (e instanceof LoginError) {
      console.log("login error", e.type);
      throw redirect(
        "./?" + objectToSearchParams({ time: Date.now(), ...e.toObject() }),
      );
    }
    throw e;
  }
}
export const LoginParamsSchema = z.object({
  email: z.string(),
  password: z.string(),
});
