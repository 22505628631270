import {
  TabLayout,
  TabLayoutBreadcrumb,
  TabLayoutTitle,
} from "@/components/tab-layout";
import { Building2, Gem, RssIcon, ServerIcon, User } from "lucide-react";

export interface ISettingsLayoutProps {}

export default function SettingsLayout(props: ISettingsLayoutProps) {
  return (
    <TabLayout
      tabs={{
        user: { label: "Mon compte", Icon: User },
        team: { label: "Mon équipe", Icon: Building2 },
        sites: { label: "Sites", Icon: RssIcon },
        // links: { label: "Liens", Icon: ExternalLink },
        subscription: { label: "Abonnement", Icon: Gem },
        api: { label: "Espace Développeur", Icon: ServerIcon },
      }}
      defaultTab="user"
    >
      <TabLayoutTitle title="Paramètres" />
      <TabLayoutBreadcrumb
        base={{ label: "Paramètres", url: "/app/settings" }}
      />
    </TabLayout>
  );
}
