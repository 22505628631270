import * as React from "react";
import { Route } from "react-router-dom";
import RegisterPage from "./page";
import { RegisterAction } from "./action";
import { RegisterErrorPage } from "./error";

export interface IRegisterRootProps {}

export default function RegisterRoot(props: IRegisterRootProps) {
  return (
    <Route>
      <Route
        index
        action={RegisterAction}
        Component={RegisterPage}
        errorElement={<RegisterErrorPage />}
      />
    </Route>
  );
}
