import * as React from "react";
import { IWidgetAnalyticsLoaderData } from "../loader";
import { Link, useAsyncValue } from "react-router-dom";
import { formatPercent, formatUrl, normalize } from "@/lib/format";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { useViewSummaryModal } from "@/modals/view-summary";
import { IUseSearchParams, useDocuments, useSearch } from "@/hooks/use-search";
import { Input } from "@/components/ui/input";
import {
  AdvertisementVersionDTO,
  FullAdvertisementDTO,
} from "@/api/definitions";

export interface IAdvertisementChartProps {}

const searchConfig = {
  fields: ["title", "url", "description"],
  storeFields: ["id", "title"],
  boost: {
    title: 5,
    description: 3,
    url: 1,
  },
} as IUseSearchParams<
  Pick<AdvertisementVersionDTO, "id" | "title" | "description" | "url">
>;

export default function AdvertisementChart(props: IAdvertisementChartProps) {
  const _advertisements = useAsyncValue() as Awaited<
    IWidgetAnalyticsLoaderData["advertisementAnalytics"]
  >;
  const height = _advertisements.length * 56 + 52;
  const [query, setQuery] = React.useState("");
  const documents = useDocuments(_advertisements, (a) => ({
    id: a.id,
    title: normalize(a.title),
    description: normalize(a.description ?? ""),
    url: normalize(a.url),
  }));
  const search = useSearch(documents, searchConfig);
  const advertisements = React.useMemo(() => {
    const results = search(query);
    console.log("search", { query, results });
    if (results === null) return _advertisements;
    return results
      .map((r) =>
        _advertisements.find((b) => b.advertisement_version_id === r.id),
      )
      .filter(Boolean) as typeof _advertisements;
  }, [query, search, _advertisements]);
  const summaryModal = useViewSummaryModal();
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex justify-between items-center gap-4">
          <span>Annonces</span>
          <Input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Rechercher..."
            className="max-w-[280px]"
          />
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="border border-border rounded-md overflow-hidden">
          <ScrollArea className="max-h-[320px]" style={{ height }}>
            <Table className="h-full">
              <TableHeader>
                <TableRow>
                  <TableHead>Annonce</TableHead>
                  <TableHead>Impressions</TableHead>
                  <TableHead>Clics</TableHead>
                  <TableHead className="text-right">CTR</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {advertisements.map((row) => {
                  return (
                    <TableRow className="h-12">
                      <TableCell className="flex gap-4 items-center">
                        <img
                          src={row.main_image}
                          alt={row.title}
                          className="aspect-[2] w-20 rounded-[4px] object-cover"
                        />
                        <span
                          title={row.title}
                          className="cursor-pointer max-w-[320px] truncate"
                          onClick={() => {
                            summaryModal.openModal({
                              advertisementId: row.id,
                              canGoToDetails: true,
                            });
                          }}
                        >
                          {row.title}
                        </span>
                      </TableCell>
                      <TableCell className=" source-code-pro-font text-blue-500">
                        {row.impressions}
                      </TableCell>
                      <TableCell className=" source-code-pro-font text-right text-blue-500">
                        {row.clics}
                      </TableCell>
                      <TableCell className=" source-code-pro-font text-right text-blue-500">
                        {formatPercent(
                          (100 * row.clics) / (row.impressions || 1),
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        </div>
      </CardContent>
    </Card>
  );
}
