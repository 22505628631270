import { createModalContext } from ".";
import { Dialog, DialogContent, DialogFooter } from "@/components/ui/dialog";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useCache } from "@/hooks/use-cache";
import Awaited from "@/components/awaited";
import AdvertisementSummaryCard from "@/components/summary";
import { getAdvertisementSummary } from "@/api/advertisements";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";

export interface IViewSummaryModalProps {
  closeModal?(triggerCloseEvent?: boolean): void;
  advertisementId: string;
  canGoToDetails?: boolean;
}

export function ViewSummaryModal({
  closeModal,
  advertisementId,
  canGoToDetails = true,
}: IViewSummaryModalProps) {
  const advertisementSummary = useCache(
    getAdvertisementSummary,
    advertisementId,
  );
  const navigate = useSmartNavigate();
  return (
    <Dialog open onOpenChange={() => closeModal?.()}>
      <DialogContent className="bg-card border-none px-0 pt-0 text-foreground overflow-hidden">
        <Awaited
          resolve={advertisementSummary}
          fallback={
            <>
              <AdvertisementSummaryCard.Skeleton className="shadow-none border-none bg-transparent h-[457px] w-[512px]" />
              {canGoToDetails ? <DialogFooter className="px-6 h-10" /> : null}
            </>
          }
          render={(summary) => {
            return (
              <>
                <AdvertisementSummaryCard
                  className="shadow-none border-none bg-transparent"
                  summary={summary}
                />
                {canGoToDetails ? (
                  <DialogFooter className="px-6">
                    <Button
                      className="enter"
                      onClick={() =>
                        navigate(
                          `/app/advertisements/${advertisementId}/content`,
                        )
                      }
                    >
                      Détail de l'annonce
                    </Button>
                  </DialogFooter>
                ) : null}
              </>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

export const [useViewSummaryModal, ViewSummaryProvider, ViewSummaryContext] =
  createModalContext(ViewSummaryModal);
