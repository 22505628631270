import * as React from "react";

export interface IMaintenancePageProps {}

export default function MaintenancePage(props: IMaintenancePageProps) {
  return (
    <main className="w-screen h-screen flex items-center justify-center">
      <div className="max-w-xl space-y-4 enter">
        <h1 className="text-3xl font-semibold">
          🛠️ Konnectz est actuellement en maintenance 🛠️
        </h1>
        <h3 className="_text-xl font-medium">
          ⏱️ Fin de la maintenance prévue à 15h00 ⏱️
        </h3>
        <p>
          Nous travaillons activement pour améliorer votre expérience sur notre
          plateforme. Pendant cette période, certaines fonctionnalités
          pourraient être temporairement indisponibles. Nous faisons de notre
          mieux pour rétablir l’accès le plus rapidement possible.
        </p>
        <p>Nous vous remercions pour votre patience et votre compréhension.</p>
        Revenez nous voir bientôt !
      </div>
    </main>
  );
}
