import * as React from "react";
import AdminCompanyPage from "./page";
import { Route } from "react-router-dom";
import { AdminCompanyLoader } from "./loader";
import { AdminCompanyAction } from "./action";

export interface IAdminCompanyRouterProps {}

export default function AdminCompanyRouter(props: IAdminCompanyRouterProps) {
  return (
    <Route>
      <Route
        index
        loader={AdminCompanyLoader}
        action={AdminCompanyAction}
        Component={AdminCompanyPage}
      />
    </Route>
  );
}
