import * as React from "react";
import { Route } from "react-router-dom";
import { UserSettingsLoader } from "./loader";
import { UserSettingsAction } from "./action";
import UserSettingsPage from "./page";

export interface IUserSettingsRouterProps {}

export default function UserSettingsRouter(props: IUserSettingsRouterProps) {
  return (
    <Route>
      <Route
        index
        loader={UserSettingsLoader}
        action={UserSettingsAction}
        Component={UserSettingsPage}
      />
    </Route>
  );
}
