import { Navigation, useLocation, useNavigation } from "react-router-dom";
import * as React from "react";

const IdleState: Navigation = {
  state: "idle",
  location: undefined,
  formMethod: undefined,
  formAction: undefined,
  formEncType: undefined,
  formData: undefined,
};

export function useRouteNavigation(_pathname?: string | RegExp) {
  const location = useLocation();
  const navigation = useNavigation();
  const [state, setState] = React.useState<Navigation>(IdleState);
  React.useEffect(() => {
    console.log("navigation state change");
    if (navigation.state !== "idle") {
      if (
        _pathname instanceof RegExp
          ? _pathname.exec(navigation.location.pathname)
          : !navigation.location.pathname.startsWith(
              _pathname || location.pathname,
            )
      )
        return;
      setState(navigation);
    } else setState(navigation);
  }, [navigation.state]);
  return state;
}
