import { ActionsMenu } from "@/components/actions-menu";
import Awaited from "@/components/awaited";
import { TableRow, TableCell } from "@/components/ui/table";
import dayjs, { formatDate } from "@/lib/date";
import { ColumnsState } from "@/hooks/useColumns";
import { LogTableColumnName } from "./columns";
import LogActions from "./log-actions";
import { useFetcher } from "react-router-dom";
import { listMutationLogs } from "@/api/mutation_log";
import { capitalize } from "@/lib/format";
import { DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { MoreHorizontal } from "lucide-react";

export interface ILogRowProps {
  log: Awaited<ReturnType<typeof listMutationLogs>>[number];
  columnState: ColumnsState<LogTableColumnName>;
}

export default function LogRow({ log, columnState }: ILogRowProps) {
  const fetcher = useFetcher();
  return (
    <ActionsMenu Actions={LogActions} data={{ log }}>
      <TableRow key={log.id}>
        <TableCell
          className="font-light source-code-pro-font"
          hidden={!columnState.id.visible}
        >
          #{log.id}
        </TableCell>
        <TableCell
          hidden={!columnState.created_at.visible}
          className="whitespace-nowrap"
        >
          {dayjs(log.createdAt).toISOString()}
        </TableCell>
        <TableCell hidden={!columnState.type.visible}>{log.type}</TableCell>
        <TableCell
          hidden={!columnState.resource.visible}
          className="whitespace-nowrap max-w-[300px] text-ellipsis overflow-hidden"
        >
          {log.resource}
        </TableCell>
        <TableCell hidden={!columnState.user.visible}>
          {log.user_id ? `#${log.user_id} ${log.user_name}` : "ND"}
        </TableCell>
        <TableCell
          hidden={!columnState.payload.visible}
          className="whitespace-nowrap max-w-[300px] text-ellipsis overflow-hidden"
        >
          {log.payload}
        </TableCell>
        <TableCell className="flex justify-end">
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="icon">
              <MoreHorizontal />
            </Button>
          </DropdownMenuTrigger>
        </TableCell>
      </TableRow>
    </ActionsMenu>
  );
}
