import * as React from "react";
import { Navigate, Route } from "react-router-dom";
import AdminLayout from "./layout";
import AdminCompanyRouter from "./companies";
import { wait } from "@/lib/promise";
import AdminUsersRouter from "./users";
import MutationLogRouter from "./logs";
import AdminAcionsRouter from "./actions";

export interface IAdminRouterProps {}

export default function AdminRouter(props: IAdminRouterProps) {
  return (
    <Route Component={AdminLayout}>
      <Route index element={<Navigate to="actions" replace />} />
      <Route path="companies">{AdminCompanyRouter({})}</Route>
      <Route path="users">{AdminUsersRouter({})}</Route>
      <Route path="logs">{MutationLogRouter({})}</Route>
      <Route path="actions">{AdminAcionsRouter({})}</Route>
    </Route>
  );
}
