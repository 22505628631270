import * as React from "react";
import { AwaitProps } from "react-router-dom";
import CollapsibleGrid from "@/components/collapsible-grid";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import WidgetCard from "@/components/widget-card";
import { Link } from "react-router-dom";
import { listWidgetsWithMetaData } from "@/api/widgets";
import Awaited from "./awaited";
import { useCreateWidgetModal } from "@/modals/create-widget";

export interface IAwaitedWidgetListProps {
  resolve: ReturnType<typeof listWidgetsWithMetaData>;
  max?: number;
}

const placeholderCards = Array.from({ length: 4 }, () => crypto.randomUUID());

export default function AwaitedWidgetList(props: IAwaitedWidgetListProps) {
  const createWidgetModal = useCreateWidgetModal();
  return (
    <CollapsibleGrid
      disable={!props.max}
      title={
        <div className="flex items-center justify-between space-x-6 w-full max-sm:flex-wrap cursor-pointer">
          <h1 className="text-2xl font-semibold leading-none tracking-tight shrink-0">
            Mes widgets
          </h1>
          <div className="w-6 max-sm:hidden h-6 font-semibold flex items-center justify-center text-sm bg-muted rounded-full shrink-0">
            <Awaited
              resolve={props.resolve}
              render={(widgets) => widgets.meta.count}
            />
          </div>
          <Button
            variant="outline"
            type="button"
            className="flex items-center shrink-0"
            onClick={(e) => {
              e.stopPropagation();
              createWidgetModal.openModal();
            }}
          >
            Créer
            <Plus className="ml-2 w-5 h-5" />
          </Button>
          <div className="filler hidden sm:flex" />
          {props.max ? (
            <Link
              to="/app/widgets"
              className="shrink-0 text-muted-foreground text-sm font-medium max-sm:!mr-6"
            >
              Tout afficher
            </Link>
          ) : null}
        </div>
      }
    >
      <Awaited
        fallback={placeholderCards.map((key) => (
          <WidgetCard.skeleton key={key} />
        ))}
        resolve={props.resolve}
        errorElement={<p>Error loading widgets!</p>}
      >
        {(widgets: Awaited<ReturnType<typeof listWidgetsWithMetaData>>) => {
          return widgets.results
            .slice(0, props.max || Infinity)
            .map((widget) => <WidgetCard key={widget.id} {...widget} />);
        }}
      </Awaited>
    </CollapsibleGrid>
  );
}
