import * as React from "react";
import { Navigate, useLoaderData } from "react-router-dom";
import { IAppDesignLoaderData } from "./loader";

export interface IAppDesignEntryProps {}

export default function AppDesignEntry(props: IAppDesignEntryProps) {
  const data = useLoaderData() as IAppDesignLoaderData;
  return <Navigate to={`/app/design/${data.customizationId}`} replace />;
}
