import * as React from "react";
import { useAsyncValue } from "react-router-dom";
import { IWidgetAnalyticsLoaderData } from "../loader";
import CustomChart from "@/components/custom-chart";
import {
  dateFormatterForPeriod,
  getAggregationPeriodForDates,
} from "@/api/analytics";

export interface IImpressionChartProps {}

export default function ImpressionChart(props: IImpressionChartProps) {
  const { timeSerie, period } = useAsyncValue() as Awaited<
    IWidgetAnalyticsLoaderData["analytics"]
  >;
  return (
    <CustomChart
      data={timeSerie}
      config={{
        impressions: {
          label: "Impressions",
          color: "hsl(var(--chart-1))",
        },
        // interactions: {
        //   label: "Interactions",
        //   color: "hsl(var(--chart-2))",
        // },
      }}
      xAxis="hour"
      formatter={
        dateFormatterForPeriod[getAggregationPeriodForDates(...period)]
      }
      areas={{
        impressions: {
          type: "monotone",
        },
        // interactions: {
        //   type: "monotone",
        //   fill: "url(#fillDesktop)",
        //   stroke: "var(--color-interactions)",
        //   stackId: "b",
        // },
      }}
    />
  );
}
