import { TabLayoutContent, TabLayoutBreadcrumb } from "@/components/tab-layout";
import { useLoaderData } from "react-router-dom";
import Awaited from "@/components/awaited";
import { IAdminUsersLoaderData } from "./loader";
import UserRow from "./(blocks)/user-row";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import RefPortal from "@/components/ref-portal";
import CheckboxDropdown from "@/components/checkbox-dropdown";
import { useColumns } from "@/hooks/useColumns";
import { useRouteNavigation } from "@/hooks/use-route-navigation";
import { useRevalidateCache } from "@/hooks/use-invalidate";
import { UserTableColumns } from "./(blocks)/columns";
import { entries, fromEntries } from "@/lib/entries";
import { Skeleton } from "@/components/ui/skeleton";
import { Input } from "@/components/ui/input";
import { IntegerState, useSearchState } from "@/hooks/use-search-state";
import { normalize } from "@/lib/format";
import SmartPagination from "@/components/smart-pagination";
import { listUsers } from "@/api/users";

export interface IAdminUsersPageProps {}

const limit = 20;
export default function AdminUsersPage(props: IAdminUsersPageProps) {
  const data = useLoaderData() as IAdminUsersLoaderData;
  const navigation = useRouteNavigation("/app/admin/users");
  useRevalidateCache(listUsers);
  const [columns, columnsState, setColumnState] = useColumns(
    "users",
    UserTableColumns,
  );
  const [setSearch, search] = useSearchState<string>({
    key: "query",
    parse: normalize,
    serialize: normalize,
    delay: 300,
    defaultValue: "",
    middleware(s) {
      s.delete("offset");
    },
  });
  const [setOffset, offset] = useSearchState({
    key: "offset",
    defaultValue: 0,
    ...IntegerState,
  });
  const page = offset / limit;
  function setPage(newPage: number) {
    setOffset(newPage * limit);
  }
  return (
    <TabLayoutContent size="full-width">
      <TabLayoutBreadcrumb>Utilisateurs</TabLayoutBreadcrumb>
      <RefPortal selector="#tab-layout-head-outlet">
        <Button>Créer un utilisateur</Button>
      </RefPortal>

      <div className="flex items-center gap-2 w-full">
        <Input
          className="w-96 shrink-0 bg-card"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Rechercher..."
        />
        <Awaited
          loading={navigation.state}
          resolve={data.users}
          fallback={<Skeleton className="h-4 w-20 shrink-0" />}
          render={({ length }) => (
            <span className="text-sm text-muted-foreground shrink-0 ">
              {length} résultat{length !== 1 ? "s" : ""}
            </span>
          )}
        />
        <div className="filler" />
        <CheckboxDropdown
          items={Object.values(columns)}
          values={fromEntries(
            entries(columnsState).map(([k, s]) => [k, s.visible]),
          )}
          onChange={(column, visible) =>
            setColumnState({ state: { [column]: { visible } } })
          }
        >
          <Button variant="outline" size="sm" className="border-dashed">
            Colonnes
          </Button>
        </CheckboxDropdown>
      </div>
      <ScrollArea className="overflow-hidden relative border border-border rounded-md">
        <Table>
          <TableHeader>
            <TableRow>
              {entries(columns)
                .filter(([key]) => columnsState[key].visible)
                .map(([_, { value, header: Header }]) => (
                  <Header key={value} />
                ))}
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <Awaited
              resolve={data.users}
              render={(users) =>
                users
                  .slice(offset, offset + limit)
                  .map((user) => (
                    <UserRow
                      key={user.id}
                      user={user}
                      columnState={columnsState}
                    />
                  ))
              }
            />
          </TableBody>
        </Table>
      </ScrollArea>
      <Awaited
        loading={navigation.state}
        resolve={data.users}
        fallback={
          <Skeleton className="h-10 w-[430px] mx-auto max-w-screen shrink" />
        }
      >
        {({ length }) => (
          <SmartPagination
            count={length}
            page={page}
            setPage={setPage}
            perPage={limit}
          />
        )}
      </Awaited>
    </TabLayoutContent>
  );
}
