import * as React from "react";
import * as z from "@/lib/zod";

interface UsePersistGet<T> {
  (defaultValue: T): T;
  (): T | null;
}
const prefix = "cm5bbpwzp1000r987e9tq3uwj";

export function usePersist<T>(_key: string, storage: Storage = localStorage) {
  const key = prefix + ":" + _key;
  //@ts-ignore
  const get: UsePersistGet<T> = (defaultValue?: T) => {
    const value = storage.getItem(key);
    if (!value) return defaultValue;
    const parsed = z.json(z.object({ $value: z.any() })).safeParse(value);
    if (parsed.success) return parsed.data.$value as T;
    if (defaultValue !== undefined)
      storage.setItem(key, JSON.stringify({ $value: defaultValue }));
    return defaultValue;
  };
  return {
    get,
    watch($value: T) {
      React.useEffect(
        () => storage.setItem(key, JSON.stringify({ $value })),
        [$value],
      );
    },
  };
}
console.log(
  "test",
  z.json(z.object({ $value: z.any() })).safeParse(new Error()),
);
