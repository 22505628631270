import { createModalContext } from ".";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useCache } from "@/hooks/use-cache";
import { getCurrentUser } from "@/api/authentication";
import Awaited from "@/components/awaited";
import { Loader } from "lucide-react";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";

export interface ISitesHelpModalProps {
  closeModal?(triggerCloseEvent?: boolean): void;
}

export function SitesHelpModal({ closeModal }: ISitesHelpModalProps) {
  const navigate = useSmartNavigate();
  const session = useCache(getCurrentUser);
  return (
    <Dialog open onOpenChange={() => closeModal?.()}>
      <DialogContent className="bg-card border-border text-foreground">
        <DialogHeader>
          <DialogTitle>Un problème avec un site ?</DialogTitle>
        </DialogHeader>
        <DialogDescription className="space-y-2 leading-relaxed tracking-normal">
          <p>
            Lors de l'ajout d'un site, un période de configuration est
            nécessaire à l'affichage du widget. Cette période ne dépasse
            généralement pas 1h.
          </p>
          {/* <p>
            Si le widget ne fonctionne toujours pas après cela, cliquez sur
            "Débugger" pour lancer une vérification et déterminer le problème.
          </p> */}
          <p>
            Si le widget ne fonctionne toujours pas après cela, contactez-nous.
          </p>
        </DialogDescription>
        <DialogFooter>
          {/* <Button
            onClick={() => {
              navigate("/app/help/debug-site");
              closeModal();
            }}
          >
            Débugger
          </Button> */}
          <Awaited
            resolve={session}
            fallback={<Loader className="w-4 h-4 animate-spin" />}
            render={(session) => {
              const userId = session?.user?.id ?? "";
              return (
                <Link
                  to={`mailto:axel@konnectz.io;emmanuel@timenjoy.fr?subject=${encodeURIComponent("Konnectz - Demande d'aide sur mes sites")}&body=${encodeURIComponent(`\n\n\nRéférence client: ${userId}\n`)}`}
                >
                  <Button>Nous contacter</Button>
                </Link>
              );
            }}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export const [useSitesHelpModal, SitesHelpProvider, SitesHelpContext] =
  createModalContext(SitesHelpModal);
