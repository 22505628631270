import { objectFromFormData } from "@/lib/query";
import { withToast } from "@/lib/with-toast";
import { ActionFunctionArgs, redirect } from "react-router-dom";
import * as z from "@/lib/zod";
import { acceptInvite } from "@/api/invites";
import { createCompany } from "@/api/companies";
import { CreateCompanySchema, CreateWidgetSchema } from "@/api/definitions";
import { createWidget } from "@/api/widgets";
import { normalize } from "@/lib/format";

export interface IOnboardingCompanyActionData {}
export async function OnboardingCompanyAction({
  request,
}: ActionFunctionArgs): Promise<IOnboardingCompanyActionData> {
  const body = objectFromFormData(await request.formData());
  console.log("body", body);
  if (request.method === "PUT") {
    await withToast(async () => {
      const { inviteId } = z
        .object({ inviteId: z.coerce.number() })
        .parse(body);
      await acceptInvite(inviteId);
    });
  }
  if (request.method === "POST") {
    const error = await withToast(async () => {
      const payload = CreateCompanySchema.parse(body);
      const company = await createCompany(payload);
      await createWidget(
        CreateWidgetSchema.parse({
          source: normalize(company.name),
          source_id: normalize(company.name + crypto.randomUUID()).slice(0, 16),
        }),
      );
    }).catch((err) => err);
    console.log("error", error);
    if (error) return {};
  }
  throw redirect("/app");
}
