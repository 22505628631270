import { canCreateCustomizeWidgets } from "@/api/authorization";
import { ExtractResult } from "@/lib/cache";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface IAppDesignAuthGuardLoaderData {
  authorization: ExtractResult<typeof canCreateCustomizeWidgets> | Error;
}

export async function AppDesignAuthGuardLoader({}: LoaderFunctionArgs) {
  const authorization = await canCreateCustomizeWidgets().catch((e) => e);
  console.log("authorization", { authorization });
  return { authorization };
}
