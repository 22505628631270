import { useSearchParams } from "react-router-dom";
import { useOptimistic } from "./use-optimistic";
import React, { SetStateAction } from "react";
import dayjs, { parseDate } from "@/lib/date";
import { useRenderTrack } from "./use-render-track";

export type UseSearchStateParams<T extends any> = (T extends string
  ? {
      parse?(value: string): T;
      serialize?(value: Exclude<T, undefined>): string | null | undefined;
    }
  : {
      parse(value: string): T;
      serialize(value: Exclude<T, undefined>): string | null | undefined;
    }) & {
  key: string;
  defaultValue?: T;
  middleware?(s: URLSearchParams): void;
  delay?: number;
};

const FAILED_TO_PARSE = "$__@failedToParse";
export function useSearchState<T extends any>({
  key,
  defaultValue,
  parse,
  serialize,
  middleware,
  delay,
}: UseSearchStateParams<T>) {
  const [search, setSearchParams] = useSearchParams();
  useRenderTrack([search], "search");
  const parser = parse ?? ((a) => a);
  const currentValue = search
    .getAll(key)
    .map((v) => {
      try {
        return parser(v);
      } catch (e) {
        console.error(e);
        return FAILED_TO_PARSE;
      }
    })
    .filter((v) => v !== FAILED_TO_PARSE) as T[];
  const updateSearchParams = React.useCallback(
    (newValue: T[]) =>
      setSearchParams(
        (s) => {
          console.log("use-search-state", key, newValue);
          s.delete(key);
          newValue
            .filter((v) => v !== undefined)
            .forEach((v) => {
              const serialized =
                (serialize as (value: T) => string)?.(v) ?? String(v);
              s.append(key, serialized);
            });
          middleware?.(s);
          return s;
        },
        { replace: true },
      ),
    [key, search],
  );
  const [value, setValue] = useOptimistic<T[]>(
    (currentValue.length
      ? currentValue
      : [defaultValue].filter((v) => v !== undefined)) as T[],
    updateSearchParams,
    delay,
  );
  const update = React.useCallback(
    (newValue: T | SetStateAction<T[]>) => {
      console.log("update use-searchstate");
      setValue(
        //@ts-ignore
        typeof newValue === "function"
          ? (v) => (newValue as Function)(v).filter((i: T) => i !== undefined)
          : [newValue].flat().filter((v) => v !== undefined),
      );
    },
    [setValue],
  );
  return [update, ...value] as const;
}

export const IntegerState = {
  parse: parseInt,
  serialize: (n: number) => n + "",
};

export const DateState = {
  parse: (v: string) => dayjs(v).toDate(),
  serialize: (v: Date | string | number) => dayjs(v).toISOString(),
};
