import { getCurrentUser } from "@/api/authentication";
import { SessionDTO } from "@/api/definitions";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface ISettingsLoaderData {
  session: Promise<SessionDTO>;
}

export async function SettingsLoader({ request }: LoaderFunctionArgs) {
  return defer({
    session: getCurrentUser(request.signal).then((res) => {
      if (!res) throw redirect("/app");
      return res;
    }),
  } satisfies ISettingsLoaderData);
}
