import { objectFromFormData } from "@/lib/query";
import { ActionFunctionArgs } from "react-router-dom";
import * as z from "@/lib/zod";
import { kickUserFromCompany } from "@/api/companies";
import { withToast } from "@/lib/with-toast";
import { sendInvite } from "@/api/invites";
import { CreateInvitesSchema } from "@/api/definitions";

export interface ITeamSettingsActionData {}
export async function TeamSettingsAction({
  request,
}: ActionFunctionArgs): Promise<ITeamSettingsActionData> {
  const body = objectFromFormData(await request.formData());
  if (request.method === "POST") {
    await withToast(
      () => {
        const payload = CreateInvitesSchema.parse(body);
        sendInvite(payload);
      },
      { success: "Invitation envoyée !" },
    );
  } else if (request.method === "DELETE") {
    await withToast(() => {
      const payload = z
        .object({
          userId: z.coerce.number(),
          companyId: z.coerce.number(),
        })
        .parse(body);
      kickUserFromCompany(payload.userId, payload.companyId);
    });
  }
  return {};
}
