import { LoginErrorType, messageForLoginErrorType } from "@/api/authentication";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { objectFromSearchParams, objectToSearchParams } from "@/lib/query";
import { LoaderIcon } from "lucide-react";
import * as React from "react";
import { Form, Link, useNavigation, useSearchParams } from "react-router-dom";
import { toast } from "sonner";

export interface ILoginPageProps {}

export default function LoginPage(props: ILoginPageProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const error = searchParams.get("error");
  const time = searchParams.get("time");
  const navigation = useNavigation();
  React.useEffect(() => {
    if (!error || Number.isNaN(+error)) return;
    toast.error(messageForLoginErrorType[+error as LoginErrorType]);
    setSearchParams((s) =>
      objectToSearchParams({ ...objectFromSearchParams(s), error: undefined }),
    );
  }, [error, time]);
  return (
    <div className="mx-auto grid w-[350px] gap-6">
      <div className="grid gap-2 text-center">
        <h1 className="text-3xl font-bold">Login</h1>
        <p className="text-balance text-muted-foreground">
          Connectez-vous à votre compte avec votre adresse email
        </p>
      </div>
      <Form method="POST" className="grid gap-4">
        <div className="grid gap-2">
          <Label htmlFor="email">Adresse email</Label>
          <Input
            id="email"
            name="email"
            type="email"
            defaultValue={searchParams.get("email") ?? ""}
            autoComplete="email"
            placeholder="m@example.com"
            required
          />
        </div>
        <div className="grid gap-2">
          <div className="flex items-center">
            <Label htmlFor="password">Mot de passe</Label>
            <Link
              to="/auth/forgot-password"
              className="ml-auto inline-block text-xs underline"
            >
              Mot de passe oublié ?
            </Link>
          </div>
          <Input
            id="password"
            name="password"
            type="password"
            required
            placeholder="••••••••"
            autoComplete="current-password"
          />
        </div>
        <Button
          type="submit"
          disabled={navigation.state !== "idle"}
          className="w-full space-x-2"
        >
          {navigation.state !== "idle" ? (
            <LoaderIcon className="w-4 h-4 animate-spin" />
          ) : (
            <div className="w-4 h-4" />
          )}
          <span>Se connecter</span>
          <div className="w-4 h-4" />
        </Button>
      </Form>
      <div className="mt-4 text-center text-sm">
        Première visite ?{" "}
        <Link to="/auth/register" className="underline">
          Créez un compte
        </Link>
      </div>
    </div>
  );
}
