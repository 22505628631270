import * as React from "react";
import { MenuIcon, PanelsTopLeft } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Menu } from "@/components/menu";
import {
  Sheet,
  SheetHeader,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Link, useLocation } from "react-router-dom";
import { ModeToggle } from "./ui/mode-toggle";
import RefPortal from "./ref-portal";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "./ui/breadcrumb";

export interface IHeaderProps {}

export default function Header(props: IHeaderProps) {
  const [sheetOpen, setSheetOpen] = React.useState(false);
  const location = useLocation();
  React.useEffect(() => {
    setSheetOpen(false);
  }, [location]);
  return (
    <header className="sticky top-0 z-10 w-full bg-background">
      <div className="mx-0 sm:mx-8 flex h-16 items-center">
        <div className="flex items-center space-x-4 lg:space-x-0">
          <Sheet open={sheetOpen} onOpenChange={setSheetOpen}>
            <SheetTrigger className="lg:hidden" asChild>
              <Button className="h-8" variant="outline" size="icon">
                <MenuIcon size={20} />
              </Button>
            </SheetTrigger>
            <SheetContent
              className="sm:w-72 px-3 h-full flex flex-col bg-background"
              side="left"
              onClick={() => setSheetOpen(false)}
              onTouchEnd={() => setSheetOpen(false)}
            >
              <SheetHeader>
                <Button
                  className="flex justify-center items-center pb-2 pt-1"
                  variant="link"
                  asChild
                >
                  <Link
                    to="/app"
                    className="flex items-center hover:!no-underline"
                  >
                    <img
                      src="/img/logo-square.png"
                      className="w-10 h-10 object-contain border border-2 border-background"
                    />
                    <h1 className="font-bold text-lg cursor-pointer">
                      onnectz
                    </h1>
                  </Link>
                </Button>
              </SheetHeader>
              <Menu isOpen />
            </SheetContent>
          </Sheet>
          <Breadcrumb className="font-semibold text-xl">
            <BreadcrumbList id="breadcrumb" />
          </Breadcrumb>
        </div>
        <div className="flex flex-1 items-center space-x-2 justify-end">
          <ModeToggle />
        </div>
      </div>
    </header>
  );
}

export type IHeaderBreadcrumbChunk =
  | string
  | { label: string; url: string }
  | React.ReactElement;
export interface IHeaderBreadCrumbProps {
  chunks:
    | IHeaderBreadcrumbChunk
    | [IHeaderBreadcrumbChunk, ...IHeaderBreadcrumbChunk[]];
}
export function HeaderBreadCrumb({ chunks }: IHeaderBreadCrumbProps) {
  return (
    <RefPortal selector="#breadcrumb">
      {[chunks].flat().map((chunk, i) => {
        if (typeof chunk === "string")
          return (
            <React.Fragment key={chunk}>
              <BreadcrumbSeparator />
              <BreadcrumbItem>{chunk}</BreadcrumbItem>
            </React.Fragment>
          );
        else if ("url" in chunk)
          return (
            <React.Fragment key={chunk.url}>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink to={chunk.url}>{chunk.label}</BreadcrumbLink>
              </BreadcrumbItem>
            </React.Fragment>
          );
        else
          return (
            <React.Fragment key={chunk.key ?? i}>
              <BreadcrumbSeparator />
              <BreadcrumbItem>{chunk}</BreadcrumbItem>
            </React.Fragment>
          );
      })}
    </RefPortal>
  );
}
