import { createModalContext } from ".";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { PlanUpgradeError } from "@/api/authorization";

export interface ILimitReachedModalProps {
  closeModal?(triggerCloseEvent?: boolean): void;
  error: PlanUpgradeError;
}

export function LimitReachedModal({
  closeModal,
  error,
}: ILimitReachedModalProps) {
  return (
    <Dialog open onOpenChange={() => closeModal?.()}>
      <DialogContent className="bg-card border-border text-foreground">
        <DialogHeader>
          <DialogTitle>Limite atteinte</DialogTitle>
          <DialogDescription>
            Vous avez attent les limites de votre abonnement. {error.message}
          </DialogDescription>
        </DialogHeader>
        <div className="w-full flex justify-end">
          <Link to="/app/settings/subscription">
            <Button variant="accent">{error.cta}</Button>
          </Link>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export const [useLimitReachedModal, LimitReachedProvider, LimitReachedContext] =
  createModalContext(LimitReachedModal);
