import { FullAdvertisementDTO } from "@/api/definitions";
import { ColumnsState } from "@/hooks/useColumns";
import * as React from "react";
import { CatalogTableColumnName } from "./columns";
import { TableCell, TableRow } from "@/components/ui/table";
import AdvertisementRow, {
  AdvertisementRowContent,
} from "../../advertisements/(blocks)/advertisement-row";
import { ActionsMenu } from "@/components/actions-menu";
import { useNavigate } from "react-router-dom";
import { DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { MoreHorizontal } from "lucide-react";
import CatalogActions from "./catalog-actions";
import { useModifierKeys } from "@/hooks/use-modifier-keys";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";

export interface ICatalogRowProps {
  advertisement: FullAdvertisementDTO;
  columnState: ColumnsState<CatalogTableColumnName>;
}

export default function CatalogRow({
  advertisement,
  columnState,
}: ICatalogRowProps) {
  const navigate = useSmartNavigate();
  return (
    <ActionsMenu Actions={CatalogActions} data={{ advertisement }}>
      <TableRow
        className="min-h-[72px] cursor-pointer"
        onClick={() =>
          navigate(`/app/advertisements/${advertisement.id}/content`)
        }
      >
        <AdvertisementRowContent
          advertisement={advertisement}
          columnState={columnState}
        />
        <TableCell
          className="flex justify-end h-14"
          onClick={(e) => e.stopPropagation()}
        >
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="icon">
              <MoreHorizontal />
            </Button>
          </DropdownMenuTrigger>
        </TableCell>
      </TableRow>
    </ActionsMenu>
  );
}

CatalogRow.Skeleton = AdvertisementRow.Skeleton;
