export function objectToSearchParams(object: Record<string, unknown>) {
  const query = new URLSearchParams();
  for (const [key, value] of Object.entries(object)) {
    if (value === undefined) continue;
    if (Array.isArray(value))
      value.forEach((v) => query.append(key, stringify(v)));
    else query.set(key, stringify(value));
  }
  return query;
}
export function objectToFormData(object: Record<string, unknown>) {
  const data = new FormData();
  for (const [key, value] of Object.entries(object)) {
    if (value === undefined) continue;
    if (Array.isArray(value))
      value.forEach((v) => data.append(key, stringify(v)));
    else data.set(key, stringify(value));
  }
  return data;
}

function stringify(value: unknown) {
  if (value === undefined) return "";
  if (value instanceof Date) return value.toISOString();
  return value + "";
}

export function objectFromSearchParams(searchParams: URLSearchParams) {
  const obj = {} as any;
  for (const entries of searchParams) {
    const [key, _value] = entries;
    const value = _value === "null" ? null : _value;
    if (key in obj) obj[key] = [obj[key], value].flat();
    else obj[key] = value;
  }
  return obj as Record<string, string | string[]>;
}

export function objectFromFormData(formData: FormData) {
  const obj = {} as any;
  for (const entries of formData) {
    const [key, _value] = entries;
    const value = _value === "null" ? null : _value;
    if (key in obj) obj[key] = [obj[key], value].flat();
    else obj[key] = value;
  }
  return obj as Record<string, string | string[] | File>;
}

export function q(location: string) {
  const locationURL = new URL(location, window.location.href);
  console.log(
    "q",
    window.location.search,
    new URLSearchParams(window.location.search),
  );
  for (const [key, value] of new URLSearchParams(
    window.location.search,
  ).entries()) {
    console.log("q", key, value);
    if (locationURL.searchParams.has(key)) continue;
    if (Array.isArray(value))
      value.forEach((v) => locationURL.searchParams.append(key, stringify(v)));
    else locationURL.searchParams.set(key, stringify(value));
  }
  return locationURL.pathname + locationURL.search;
}
