import { removeDuplicate } from "@/lib/array";
import MiniSearch, { Options, SearchResult } from "minisearch";
import * as React from "react";
import { useRenderTrack } from "./use-render-track";

export interface IUseSearchParams<
  T extends { id: string | number },
  S extends keyof T = keyof T,
> {
  fields: S[];
  storeFields: S[];
  boost?: Partial<Record<S, number>>;
}
export function useSearch<
  T extends { id: string | number },
  S extends keyof T = keyof T,
>(
  documents: T[] | (() => T[]),
  { fields, storeFields, boost = {} }: IUseSearchParams<T, S>,
) {
  const miniSearch = React.useMemo(() => {
    const _miniSearch = new MiniSearch<T>({
      fields,
      storeFields,
      searchOptions: {
        boost,
        prefix: true,
      },
    } as Options<T>);
    _miniSearch.addAll(
      removeDuplicate(
        typeof documents === "function" ? (documents as Function)() : documents,
        "id",
      ),
    );
    return _miniSearch;
  }, [
    JSON.stringify({ fields, storeFields, boost, documents }),
    typeof documents === "function" ? documents : null,
  ]);
  useRenderTrack(
    [JSON.stringify({ fields, storeFields, boost, documents })],
    "miniSearch json",
  );
  useRenderTrack(
    [typeof documents === "function" ? documents : null],
    "miniSearch fn",
  );
  useRenderTrack([miniSearch], "miniSearch");

  return React.useCallback(
    function search(query?: string | undefined | null) {
      try {
        if (!query) return null;
        const results = miniSearch.search(query);
        if (results.length < 2) return results;
        const mean =
          results.reduce((acc, cur) => acc + cur.score, 0) / results.length;
        const variance =
          results.reduce((acc, cur) => acc + Math.pow(cur.score - mean, 2), 0) /
          results.length;
        const standardDeviation = Math.sqrt(variance);
        console.log({ standardDeviation, mean, results });
        if (standardDeviation < mean) return results;
        return results.reduce((acc, cur) => {
          if (!acc[0]) return [cur];
          if (acc.at(-1)!.score - cur.score <= standardDeviation)
            return [...acc, cur];
          return acc;
        }, [] as SearchResult[]);
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    [miniSearch],
  );
}

export function useDocuments<
  T extends object,
  P extends { id: string | number },
>(items: T[], transform: (v: T) => P | P[]) {
  return React.useCallback(
    () => items.flatMap(transform),
    [JSON.stringify(items)],
  );
}
