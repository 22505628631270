import * as React from "react";
import { Route } from "react-router-dom";
import { SubscriptionSettingsLoader } from "./loader";
import { SubscriptionSettingsAction } from "./action";
import SubscriptionSettingsPage from "./page";

export interface ISubscriptionSettingsRouterProps {}

export default function SubscriptionSettingsRouter(
  props: ISubscriptionSettingsRouterProps,
) {
  return (
    <Route
      index
      loader={SubscriptionSettingsLoader}
      action={SubscriptionSettingsAction}
      Component={SubscriptionSettingsPage}
    />
  );
}
