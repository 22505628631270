import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import * as React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import * as z from "@/lib/zod";
import { createStore } from "@/lib/zustand";
import {
  Briefcase,
  Building,
  CalendarDays,
  CalendarRange,
  Fingerprint,
  ImageIcon,
  MapPin,
  Tags,
} from "lucide-react";
import { keys } from "@/lib/entries";
import {
  ADVERTISEMENT_TYPE,
  AdvertisementDraftSchema,
  PartialAdvertisementDraftSchema,
} from "@/api/definitions";
import { omit } from "@/lib/pick";

export const steps = {
  content: {
    title: "Contenu",
    Icon: Fingerprint,
  },
  location: {
    title: "Localisation",
    Icon: MapPin,
  },
  dates: {
    title: "Dates",
    Icon: CalendarDays,
  },
  categories: {
    title: "Catégories",
    Icon: Tags,
  },
  job: {
    title: "Employeur",
    Icon: Briefcase,
  },
  real_estate: {
    title: "Propriété",
    Icon: Building,
  },
  images: {
    title: "Images",
    Icon: ImageIcon,
  },
  schedule: {
    title: "Programmation",
    Icon: CalendarRange,
  },
} as const;

export function stepsForType(type: ADVERTISEMENT_TYPE) {
  if (type === ADVERTISEMENT_TYPE.EVENT)
    return omit(steps, "job", "real_estate");
  if (type === ADVERTISEMENT_TYPE.JOB)
    return omit(steps, "dates", "categories", "real_estate");
  if (type === ADVERTISEMENT_TYPE.REAL_ESTATE)
    return omit(steps, "job", "dates", "categories");
  // safety if we add other types
  return omit(steps, "dates", "categories", "job", "real_estate");
}

export const useDraftStore = createStore(
  `draft-store`,
  z.strictObject({
    draftId: z.string(),
    step: z.enum(
      keys(steps) as [keyof typeof steps, ...(keyof typeof steps)[]],
    ),
    draft: PartialAdvertisementDraftSchema,
  }),
  {
    draftId: "",
    step: keys(steps)[0],
    draft: {},
  },
);

export function useDraft() {
  return [useDraftStore(), useDraftStore.setState] as const;
}

export interface INewAdvertisementLayoutProps {}

export default function NewAdvertisementLayout(
  props: INewAdvertisementLayoutProps,
) {
  const id = React.useMemo(() => crypto.randomUUID(), []);
  const { draft, draftId } = useDraftStore();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (draftId === id || draft.title) return;
    useDraftStore.setState(
      { draftId: id, draft: {}, step: keys(steps)[0] },
      true,
    );
  });
  if (draftId === id) return <Outlet />;

  if (!draft.title) {
    return null;
  }

  return (
    <AlertDialog open>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Poursuivre la création de l'annonce ?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Souhaitez-vous terminer la création de l'annonce "{draft.title}" ?
            Une fois abandonné, le brouillon ne pourra pas être récupéré.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={() => {
              useDraftStore.setState({ draft: {}, draftId: id }, true);
            }}
          >
            Abandonner
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={() => {
              console.log("draft.type", draft.type);
              if (draft.type) navigate(`/app/advertisements/new/${draft.type}`);
              setTimeout(() => {
                useDraftStore.setState((s) => ({
                  ...s,
                  draftId: id,
                  step: keys(steps)[0],
                }));
              });
            }}
          >
            Continuer
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
