import { ListResponseDTO } from "@/api/definitions";
import { removeDuplicate } from "./array";

export function entries<T extends Record<string, unknown>>(object: T) {
  return Object.entries(object) as [keyof T, T[keyof T]][];
}
export function fromEntries<T extends [string, unknown][]>(entries: T) {
  return Object.fromEntries(entries) as Record<T[number][0], T[number][1]>;
}
export function keys<T extends Record<string, unknown>>(object: T) {
  return Object.keys(object) as (keyof T)[];
}
function isObject(data: any): data is Record<string, unknown> {
  return data && typeof data === "object";
}
export function clone<T>(object: T): T {
  if (isObject(object))
    // @ts-ignore
    return fromEntries(entries(object).map(([k, v]) => [k, clone(v)]));
  return object;
}

export function mergeListResponses<T extends { id: string | number }, D>(
  first: ListResponseDTO<T, D>,
  second: ListResponseDTO<T, D>,
): ListResponseDTO<T, D> {
  return {
    meta: { count: first.meta.count + second.meta.count },
    filters: first.filters
      ? { ...first.filters, ...second.filters }
      : undefined,
    results: removeDuplicate([...first.results, ...second.results], "id"),
  };
}
export function deepMergeAll<T extends { id: string | number }, D>(
  ...objects: ListResponseDTO<T, D>[]
): ListResponseDTO<T, D> {
  if (!objects.length)
    return {
      meta: {
        count: 0,
      },
      results: [],
    };
  return objects.reduceRight((acc, cur) => mergeListResponses(acc, cur));
}

Object.assign(window, { entries, fromEntries });
