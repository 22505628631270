import {
  FieldValues,
  UseFormReturn,
  RegisterOptions,
  FieldPath,
} from "react-hook-form";
import { omit } from "./pick";

export function expandRegister<
  A extends FieldValues,
  B,
  C extends FieldValues | undefined,
  D extends FieldPath<A>,
>(form: UseFormReturn<A, B, C>, property: D, options?: RegisterOptions<A, D>) {
  const register = form.register(property, options);
  return omit(
    {
      ...register,
      onValueChange(value: A[D]) {
        form.setValue(property, value);
      },
      onCheckedChange(value: A[D]) {
        form.setValue(property, value);
      },
    },
    "ref",
  );
}
