import { logout } from "@/api/authentication";
import { captureException } from "@sentry/react";
import React from "react";
import {
  isRouteErrorResponse,
  useLocation,
  useNavigate,
  useRouteError,
  useSearchParams,
} from "react-router-dom";
import { toast } from "sonner";

export function AppErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  console.error(error);
  React.useEffect(() => {
    if (searchParams.has("redirect")) {
      if (pathname == "/app") {
        logout().then(() => navigate("/"));
      } else navigate("/app?redirect=false");
    } else {
      navigate(".?redirect=false");
      toast.error("Une erreur est survenue");
    }
  }, []);
  React.useEffect(() => {
    captureException(error);
  }, [error]);
  return null;
  // if (isRouteErrorResponse(error))
  //   return (
  //     <div id="error-page">
  //       <h1>Oops!</h1>
  //       <p>Sorry, an unexpected error has occurred.</p>
  //       <p>
  //         <i>{error.statusText || error.status}</i>
  //       </p>
  //     </div>
  //   );
  // return (
  //   <div id="error-page">
  //     <h1>Oops!</h1>
  //     <p>Sorry, an unexpected error has occurred.</p>
  //   </div>
  // );
}
