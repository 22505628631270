import AwaitedWidgetList from "@/components/awaited-widget-list";
import { HeaderBreadCrumb } from "@/components/header";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import * as React from "react";
import { useLoaderData } from "react-router-dom";
import { IWidgetsLoaderData } from "./loader";
import { useRevalidateCaches } from "@/hooks/use-invalidate";
import { listOwnedWidgets, listWidgetsWithMetaData } from "@/api/widgets";

export interface IWidgetsPageProps {}

export default function WidgetsPage(props: IWidgetsPageProps) {
  const data = useLoaderData() as IWidgetsLoaderData;
  useRevalidateCaches(listOwnedWidgets);
  return (
    <div className="mx-auto grid flex-1 max-w-[59rem] auto-rows-max gap-4">
      <HeaderBreadCrumb chunks={[{ label: "Widgets", url: "/app/widgets" }]} />
      <AwaitedWidgetList resolve={data.widgets} />
    </div>
  );
}
