import { IconForWidgetType, WIDGET_TYPE, WidgetsDTO } from "@/api/definitions";
import * as React from "react";
import {
  Link,
  useAsyncValue,
  useFetcher,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import SearchSelect from "@/components/search-select";
import { PlusIcon } from "lucide-react";
import { IWidgetConfigurationLoaderData } from "../loader";
import Awaited from "@/components/awaited";
import { join } from "@/lib/promise";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";

export interface IWidgetDisplayProps {}

export default function WidgetDisplay(props: IWidgetDisplayProps) {
  const widget = useAsyncValue() as WidgetsDTO;
  const data = useLoaderData() as IWidgetConfigurationLoaderData;
  const fetcher = useFetcher();
  const navigate = useSmartNavigate();
  const [customization, setCustomization] = React.useState(
    widget.customization,
  );
  return (
    <fetcher.Form method="PUT">
      <input type="hidden" name="widgetId" value={widget.id} />
      <Card>
        <CardHeader>
          <CardTitle>Affichage</CardTitle>
        </CardHeader>
        <CardContent className="space-y-2">
          <Label>Format</Label>
          <p className="text-muted-foreground text-xs leading-normal">
            Choisissez le format carousel responsive sur une ligne ou l'agenda
            avec sa pagination et ses outils de recherche.
          </p>
          <RadioGroup
            name="type"
            defaultValue={WIDGET_TYPE.CAROUSEL}
            className="flex gap-px flex-wrap"
          >
            <RadioGroupItem id="carousel" value={WIDGET_TYPE.CAROUSEL} asChild>
              <Button
                variant="ghost"
                className="flex flex-col items-center h-min gap-2 px-4 pt-3 pb-2 w-32 data-[state=checked]:bg-muted data-[state=checked]:border-muted-foreground"
              >
                <img
                  src={IconForWidgetType[WIDGET_TYPE.CAROUSEL]}
                  className="w-10 h-10"
                />
                <span className="text-sm font-medium text-muted-foreground">
                  Carousel
                </span>
              </Button>
            </RadioGroupItem>
            <RadioGroupItem id="pageview" value={WIDGET_TYPE.PAGEVIEW} asChild>
              <Button
                variant="ghost"
                className="flex flex-col items-center h-min gap-2 px-4 pt-3 pb-2 w-32 data-[state=checked]:bg-muted data-[state=checked]:border-muted-foreground"
              >
                <img
                  src={IconForWidgetType[WIDGET_TYPE.PAGEVIEW]}
                  className="w-10 h-10"
                />
                <span className="text-sm font-medium text-muted-foreground">
                  Agenda
                </span>
              </Button>
            </RadioGroupItem>
          </RadioGroup>
        </CardContent>
        <CardContent className="pb-6 border-b border-b-border space-y-2">
          <Label>Design</Label>
          <p className="text-muted-foreground text-xs leading-normal">
            Personnalisez le widget aux couleurs de votre site.
          </p>
          <Awaited
            resolve={join(data.customizationsList, data.customization)}
            render={([list, active]) => (
              <div className="flex items-center gap-2">
                <span className="text-sm leading-normal">Sélectionné :</span>
                <Select
                  name="customization"
                  value={customization + ""}
                  onValueChange={(newValue) => {
                    if (newValue === "new") {
                      navigate("/app/design/new");
                    } else {
                      setCustomization(+newValue);
                    }
                  }}
                >
                  <SelectTrigger className="w-fit">
                    <SelectValue className="w-fit" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem
                        value="new"
                        className="relative text-card mb-1 bg-card-foreground"
                      >
                        <PlusIcon className="w-4 h-4 absolute top-2 left-1.5" />
                        Nouveau design
                      </SelectItem>
                      {list.map((cust) => (
                        <SelectItem key={cust.id} value={cust.id + ""}>
                          {cust.name}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            )}
          />
          <p className="text-muted-foreground text-xs leading-normal hover:underline">
            <Link to={`/app/design/${customization}`}>
              Vous pouvez modifier vos designs dans l'onget "
              <span className="underline">Modèles</span>"
            </Link>
          </p>
        </CardContent>
        <CardFooter className="flex justify-end py-4">
          <Button
            type="submit"
            size="sm"
            // disabled={value === widget.rotation_active}
          >
            Confirmer
          </Button>
        </CardFooter>
      </Card>
    </fetcher.Form>
  );
}
