import Awaited, { awaited } from "@/components/awaited";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  AlertTriangle,
  Check,
  Loader,
  LoaderIcon,
  LogOut,
  MessageCircleQuestion,
} from "lucide-react";
import * as React from "react";
import { Form, useAsyncValue, useNavigation } from "react-router-dom";
import { IOnboardingCompanyLoaderLoaderData } from "./loader";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";
import { useCache } from "@/hooks/use-cache";
import { checkOrigin } from "@/api/companies";
import { useOptimistic } from "@/hooks/use-optimistic";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import FormState from "@/components/form-state";

export interface IOnboardingCompanyPageProps {}

function OnboardingCompanyPage(props: IOnboardingCompanyPageProps) {
  const navigation = useNavigation();
  const invites = useAsyncValue() as Awaited<
    IOnboardingCompanyLoaderLoaderData["invites"]
  >;
  const [authorizedOrigins, setAuthorizedOrigin] = React.useState<string>("");
  const [site, setSite, isTypingSite] = useOptimistic(
    authorizedOrigins,
    setAuthorizedOrigin,
    300,
  );
  const originCheck = useCache(checkOrigin, authorizedOrigins);
  const submitDisabled = isTypingSite || navigation.state !== "idle";
  console.log(
    "disabled",
    submitDisabled,
    isTypingSite,
    navigation.state !== "idle",
  );
  return (
    <div className="mx-auto w-full max-w-lg py-10 min-h-screen flex flex-col items-center justify-center">
      <Card>
        <CardHeader>
          <CardTitle>Bienvenue sur Konnectz</CardTitle>
          <CardDescription>
            Créez ou {invites.length ? "rejoignez" : ""} une entreprise pour
            commencer à utiliser notre service.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          {invites.length ? (
            <div className="space-y-2 border-b border-b-border pb-6 w-full">
              <div>
                <h1 className="text-lg font-semibold">
                  Rejoindre une entreprise
                </h1>
                <Label className="text-sm text-muted-foreground">
                  {invites.length} Invitation{invites.length > 1 ? "s" : ""} en
                  attente
                </Label>
              </div>
              <div className="grid gap-1">
                {invites.map((invite) => {
                  return (
                    <Form
                      key={invite.id}
                      method="PUT"
                      className="flex item-center gap-4 rounded-lg p-2 hover:bg-muted group cursor-pointer"
                    >
                      <div className="rounded-md bg-muted-foreground w-20 h-10 shrink-0" />
                      <div className="grow">
                        <h4 className="cursor-pointer">
                          {invite.company.name}
                        </h4>
                        <p className="cursor-pointer text-xs text-muted-foreground">
                          Par: {invite.inviterEmail}
                        </p>
                      </div>
                      <Button className="opacity-0 group-hover:opacity-100 shrink-0">
                        Rejoindre
                      </Button>
                      <FormState data={{ inviteId: invite.id }} />
                    </Form>
                  );
                })}
              </div>
            </div>
          ) : null}
          <div>
            <h1 className="text-lg font-semibold mb-4">Créer mon entreprise</h1>
            <Form method="POST" className="grid gap-4">
              <div className="space-y-2">
                <Label htmlFor="name">Nom de l'entreprise</Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  placeholder=""
                  required
                />
              </div>
              <div className="space-y-2">
                <Label>Site web</Label>
                <p className="text-muted-foreground text-xs pb-1">
                  Url du site sur lequel vous souhaitez diffuser vos annonces.
                  Vous pourrez rajouter d'autres sites par la suite.
                </p>
                <Input
                  type="url"
                  name="authorizedOrigins"
                  value={site}
                  onChange={(e) => setSite(e.target.value)}
                  placeholder="ex: https://app.konnectz.io"
                />
              </div>
              <Awaited
                resolve={originCheck}
                loading={isTypingSite}
                fallback={
                  site ? (
                    <Alert variant="ghost">
                      <Loader className="h-4 w-4 animate-spin" />
                      <AlertTitle>Vérification en cours</AlertTitle>
                      <AlertDescription>
                        Veuillez patienter pendant que nous vérifions cette url.
                      </AlertDescription>
                    </Alert>
                  ) : null
                }
                render={(result) => {
                  console.log("result", result);
                  if (!authorizedOrigins) return null;
                  if (result === authorizedOrigins)
                    return (
                      <Alert>
                        <Check className="h-4 w-4" />
                        <AlertTitle>Vérification réussie</AlertTitle>
                        <AlertDescription>
                          Vos widgets pourront s'afficher sur ce site.
                        </AlertDescription>
                      </Alert>
                    );
                  if (!result) {
                    return (
                      <Alert variant="destructive">
                        <AlertTriangle className="h-4 w-4" />
                        <AlertTitle>Echec de la vérification</AlertTitle>
                        <AlertDescription>
                          Cette url n'a pas pu être vérifiée par nos services.
                        </AlertDescription>
                      </Alert>
                    );
                  }
                  return (
                    <Alert>
                      <MessageCircleQuestion className="h-4 w-4" />
                      <AlertTitle>Redirection détectée</AlertTitle>
                      <AlertDescription
                        className="text-sm text-muted-foreground"
                        onClick={() => setAuthorizedOrigin(result)}
                      >
                        Voulez-vous dire{" "}
                        <span className="underline text-white cursor-pointer">
                          {result}
                        </span>
                        &nbsp;?
                      </AlertDescription>
                    </Alert>
                  );
                }}
              />

              <Button
                type="submit"
                disabled={submitDisabled}
                className="w-full space-x-2"
              >
                {navigation.state !== "idle" ? (
                  <LoaderIcon className="w-4 h-4 animate-spin" />
                ) : (
                  <div className="w-4 h-4" />
                )}
                <span>Confirmer</span>
                <div className="w-4 h-4" />
              </Button>
            </Form>
          </div>
        </CardContent>
      </Card>
      <Form method="DELETE" action="/auth?index">
        <Button
          variant="link"
          size="sm"
          className="text-xs text-muted-foreground"
        >
          Déconnexion
        </Button>
      </Form>
    </div>
  );
}

export default awaited<
  IOnboardingCompanyLoaderLoaderData,
  IOnboardingCompanyPageProps
>((d) => d.invites, OnboardingCompanyPage);
