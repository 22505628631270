import { WidgetsDTO } from "@/api/definitions";
import * as React from "react";
import { useAsyncValue, useFetcher, useLocation } from "react-router-dom";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Info } from "lucide-react";
import { useOptimistic } from "@/hooks/use-optimistic";
import FormState from "@/components/form-state";

export interface IWidgetRotationProps {}

export default function WidgetRotation(props: IWidgetRotationProps) {
  const widget = useAsyncValue() as WidgetsDTO;
  const [value, setValue] = useOptimistic(widget.rotation_active);
  const fetcher = useFetcher();
  return (
    <Card className="enter animation-fast">
      <fetcher.Form method="PUT" action="?index">
        <input type="hidden" name="widgetId" value={widget.id} />
        <CardHeader>
          <CardTitle>Rotation Automatique</CardTitle>
        </CardHeader>
        <CardContent className="pb-6 border-b border-b-border space-y-6">
          <p className="text-sm leading-6 tracking-wide">
            La rotation automatique permet d'harmoniser la visibilité des
            annonces au sein du carousel. Toutes les 3 heures les annonces en
            fin de carousel sont repositionnées en début de carousel.
          </p>
          <div className="flex items-center gap-3">
            <Switch
              id="rotation_active"
              name="rotation_active"
              defaultChecked={widget.rotation_active}
              checked={value}
              onCheckedChange={setValue}
            />
            <Label htmlFor="rotation_active" className="font-medium">
              {value ? "Activée" : "Désactivée"}
            </Label>
          </div>
          <Alert>
            <Info className="h-4 w-4" />
            <AlertDescription>
              Les annonces sponsorisées seront toujours affichées au début du
              carousel.
            </AlertDescription>
          </Alert>
        </CardContent>
        <CardFooter className="flex justify-end py-4">
          <Button
            type="submit"
            size="sm"
            disabled={value === widget.rotation_active}
          >
            Confirmer
          </Button>
        </CardFooter>
        <FormState data={{ rotation_active: value }} />
      </fetcher.Form>
    </Card>
  );
}
