import { AdvertisementSummaryDTO } from "@/api/definitions";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { capitalize, formatUrl } from "@/lib/format";
import { cn } from "@/lib/utils";
import { ExternalLink } from "lucide-react";
import * as React from "react";
import { Link } from "react-router-dom";
import ImageInput from "@/components/image-input";

export interface IAdvertisementSummaryCardProps
  extends React.ComponentPropsWithoutRef<typeof Card> {
  summary: AdvertisementSummaryDTO;
}

export default function AdvertisementSummaryCard({
  summary,
  className,
  ...props
}: IAdvertisementSummaryCardProps) {
  return (
    <Card {...props} className={cn("enter", className)}>
      <CardHeader>
        <CardTitle>Résumé</CardTitle>
      </CardHeader>
      <CardContent>
        <ImageInput file={summary.picture} />
        <div className="my-1">{capitalize(summary.firstLine)}</div>
        <div className="my-1">{summary.secondLine}</div>
        <div className="my-1">{summary.thirdLine}</div>
        <Link
          to={summary.originLink}
          target="_blank"
          className="text-sm text-blue-500 w-full break-all mt-4 line-clamp-3"
        >
          <ExternalLink className="w-3 h-3 mr-2 inline relative -top-px" />
          {formatUrl(summary.originLink)}
        </Link>
      </CardContent>
    </Card>
  );
}
AdvertisementSummaryCard.Skeleton = function (props: { className?: string }) {
  return (
    <Card className={cn("opacity-50", props.className)}>
      <CardHeader>
        <CardTitle>Résumé</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="w-full h-auto aspect-[2] object-contain rounded-sm mb-4" />
        <div className="my-1">
          <Skeleton className="w-full h-6" />
        </div>
        <div className="my-1">
          <Skeleton className="w-full h-6" />
        </div>
        <div className="my-1">
          <Skeleton className="w-full h-6" />
        </div>
        <Skeleton className="w-full h-6" />
      </CardContent>
    </Card>
  );
};
