import * as React from "react";
import { Route } from "react-router-dom";
import ApiSettingsPage from "./page";
import { ApiSettingsLoader } from "./loader";
import { ApiSettingsAction } from "./action";

export interface IApiSettingsRouterProps {}

export default function ApiSettingsRouter(props: IApiSettingsRouterProps) {
  return (
    <Route>
      <Route
        index
        loader={ApiSettingsLoader}
        action={ApiSettingsAction}
        Component={ApiSettingsPage}
      />
    </Route>
  );
}
