import { TableHead } from "@/components/ui/table";
import { ColumnsDef } from "@/hooks/useColumns";

export type AdvertisementSchedulesTableColumnName =
  | "id"
  | "widget"
  | "rank"
  | "diffusion"
  | "sponsored"
  | "paused";

export const AdvertisementSchedulesTableColumns: ColumnsDef<AdvertisementSchedulesTableColumnName> =
  {
    id: {
      value: "id",
      label: "Id",
      header: () => <TableHead className="w-20">#ID</TableHead>,
    },
    widget: {
      value: "widget",
      label: "Widget",
      header: () => <TableHead>Widget</TableHead>,
    },
    rank: {
      value: "rank",
      label: "Position",
      header: () => <TableHead>Position</TableHead>,
    },
    diffusion: {
      value: "diffusion",
      label: "Diffusion",
      header: () => <TableHead>Diffusion</TableHead>,
    },
    sponsored: {
      value: "sponsored",
      label: "Sponsorisé",
      header: () => <TableHead>Sponsorisé</TableHead>,
    },
    paused: {
      value: "paused",
      label: "Actif",
      header: () => <TableHead>Actif</TableHead>,
    },
  };
