import { listSchedules } from "@/api/schedules";
import { listOwnedWidgets } from "@/api/widgets";
import { ExtractResult } from "@/lib/cache";
import { join } from "@/lib/promise";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface IAdvertisementSchedulesLoaderData {
  schedules: ReturnType<typeof listSchedules>;
  widgets: ReturnType<typeof listOwnedWidgets>;
  widgetsAndSchedules: Promise<
    [
      ExtractResult<typeof listOwnedWidgets>,
      ExtractResult<typeof listSchedules>,
    ]
  >;
}

export async function AdvertisementSchedulesLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  if (!params.advertisementId) throw new Error("No advertisement selected");
  const widgets = listOwnedWidgets(request.signal);
  const schedules = widgets.then((widgets) =>
    listSchedules(
      {
        advertisementIds: [params.advertisementId!],
        widgetIds: widgets.results.map((w) => w.id) as [string, ...string[]],
      },
      request.signal,
    ),
  );
  return defer({
    widgets,
    schedules,
    widgetsAndSchedules: join(widgets, schedules),
  } satisfies IAdvertisementSchedulesLoaderData);
}
