import * as React from "react";
import { Route } from "react-router-dom";
import { SettingsSitesAction } from "./action";
import SettingsSitesPage from "./page";

export interface ISettingsSitesRouterProps {}

export default function SettingsSitesRouter(props: ISettingsSitesRouterProps) {
  return (
    <Route>
      <Route index action={SettingsSitesAction} Component={SettingsSitesPage} />
    </Route>
  );
}
