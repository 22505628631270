import * as React from "react";
import tw from "tailwind-styled-components";
import { Button, ButtonProps } from "./ui/button";
import { UseFormReturn } from "react-hook-form";
import { cn } from "@/lib/utils";
import { useDraft } from "@/pages/app/advertisements/new/layout";
import { toast } from "sonner";
import { useNavigation } from "react-router-dom";

export const StepLayout = tw.div`max-w-2xl mx-auto w-full space-y-6`;

export const StepLayoutHeader = tw.div`space-y-1`;
export const StepLayoutTitle = tw.h1`text-2xl font-bold`;
export const StepLayoutSubtitle = tw.h3`text-sm max-w-sm text-muted-foreground font-medium leading-normal`;
export const StepLayoutActions = tw.div`w-full flex gap-2 items-center justify-end`;

export interface IStepLayoutPreviousActionProps extends ButtonProps {
  previous?(): void;
}

export function StepLayoutPreviousAction({
  previous,
  ...props
}: IStepLayoutPreviousActionProps) {
  if (!previous) return null;
  return (
    <Button
      variant="ghost"
      size="lg"
      {...props}
      onClick={(e) => {
        props.onClick?.(e);
        previous();
      }}
    />
  );
}

export interface IStepLayoutNextActionProps<T extends Record<string, any>>
  extends Omit<ButtonProps, "form"> {
  form: UseFormReturn<T, any, undefined>;
  next(complete: boolean): void;
}

export function StepLayoutNextAction<T extends Record<string, any>>({
  form,
  next,
  ...props
}: IStepLayoutNextActionProps<T>) {
  const [_, setDraft] = useDraft();
  const navigation = useNavigation();
  const completionState = React.useRef<boolean | null>(null);
  React.useEffect(() => {
    const currentCompletionState = completionState.current;
    if (currentCompletionState !== null) {
      completionState.current = null;
      next(currentCompletionState);
    }
  });
  return (
    <Button
      size="lg"
      {...props}
      // not using the disabled props if invalid so we can still click
      // and trigger the toast to show error
      className={cn(props.className, form.formState.isValid || "opacity-50")}
      disabled={
        props.disabled ||
        form.formState.isValidating ||
        navigation.state !== "idle"
      }
      variant="accent"
      onClick={async (e) => {
        props.onClick?.(e);
        await form.trigger();
        completionState.current = null;
        if (form.formState.isValid) {
          setDraft((d) => {
            Object.assign(d.draft, form.getValues());
          });
          completionState.current = true;
        } else {
          console.log("form.formState.errors", form.formState.errors);
          Object.values(form.formState.errors).map(
            (err) =>
              err &&
              toast.error(
                typeof err.message === "string"
                  ? err.message
                  : err.message?.toString(),
              ),
          );
        }
      }}
    />
  );
}

export default {
  StepLayout,
  StepLayoutHeader,
  StepLayoutTitle,
  StepLayoutSubtitle,
  StepLayoutActions,
  StepLayoutPreviousAction,
  StepLayoutNextAction,
};
