import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Switch } from "@/components/ui/switch";
import { useOptimistic } from "@/hooks/use-optimistic";
import * as React from "react";
import { useAsyncValue, useFetcher } from "react-router-dom";
import { ISingleAdvertisementLoaderData } from "../../loader";
import { Label } from "@/components/ui/label";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Info } from "lucide-react";
import { objectToFormData } from "@/lib/query";

export interface IAdvertisementArchivedCardProps {}

export default function AdvertisementArchivedCard(
  props: IAdvertisementArchivedCardProps,
) {
  const advertisement = useAsyncValue() as Awaited<
    ISingleAdvertisementLoaderData["advertisement"]
  >;
  const fetcher = useFetcher();
  const [value, setValue] = useOptimistic(
    !!advertisement.archivedAt,
    (shouldBeArchived, revert) => {
      if (fetcher.state !== "idle") return revert();
      fetcher.submit(
        objectToFormData({ id: advertisement.id, type: advertisement.type }),
        {
          method: shouldBeArchived ? "DELETE" : "POST",
          encType: "multipart/form-data",
          action: "/app/advertisements/[id]/content?index",
        },
      );
    },
  );
  if (!advertisement.isOwned) return null;
  return (
    <Card className="border-destructive enter">
      <CardHeader>
        <CardTitle>Archive</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="flex items-center gap-3">
          <Switch
            id="rotation_active"
            name="rotation_active"
            // className="data-[state=unchecked]:bg-destructive-foreground"
            defaultChecked={!!advertisement.archivedAt}
            checked={value}
            disabled={fetcher.state !== "idle"}
            onCheckedChange={setValue}
          />
          <Label htmlFor="rotation_active" className="font-medium">
            {value ? "Archivé" : "Actif"}
          </Label>
        </div>
        <Alert variant="destructive">
          <Info className="h-4 w-4" />
          <AlertDescription>
            En archivant votre annonce, elle n'apparaitra plus dans aucun
            widget.
          </AlertDescription>
        </Alert>
      </CardContent>
    </Card>
  );
}
