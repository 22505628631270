import { updateCompany } from "@/api/companies";
import { CompaniesSchema, UpdateCompanySchema } from "@/api/definitions";
import { objectFromFormData } from "@/lib/query";
import { withToast } from "@/lib/with-toast";
import { ActionFunctionArgs } from "react-router-dom";

export interface ISettingsSitesActionData {}
export async function SettingsSitesAction({
  request,
}: ActionFunctionArgs): Promise<ISettingsSitesActionData> {
  const body = objectFromFormData(await request.formData());
  if (request.method === "PUT") {
    return (await withToast(() => {
      const { id, ...payload } = UpdateCompanySchema.and(
        CompaniesSchema.pick({ id: true }),
      ).parse(body);
      return updateCompany(id, payload);
    }))!;
  }
  return {};
}
