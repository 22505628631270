type SomeOtherString = string & {};
export function pick<T extends object, S extends keyof T | SomeOtherString>(
  obj: T,
  ...properties: S[]
) {
  const newObj = {} as Pick<T, S & keyof T>;
  for (const prop of properties) {
    // eslint-disable-next-line
    // @ts-ignore
    if (prop in obj) newObj[prop] = obj[prop];
  }
  return newObj;
}
export function omit<T extends object, S extends keyof T | SomeOtherString>(
  obj: T,
  ...properties: S[]
) {
  const newObj = {} as {
    [K in keyof T]: K extends S ? never : T[K];
  };
  // Not using Omit<T, S & keyof T> here cause it completely
  // removes the keys from the type and this is note the desired behavior
  for (const prop in obj) {
    // eslint-disable-next-line
    // @ts-ignore
    if (!properties.includes(prop)) newObj[prop] = obj[prop];
  }
  return newObj;
}
export function deepMerge<S, T extends Partial<S>>(a: S, b: T): S {
  const result: S = { ...a };
  for (const key in b) {
    if (b[key] === undefined) continue;
    // @ts-ignore
    if (!a[key]) a[key] = b[key];
    if (typeof b[key] === "object") {
      // @ts-ignore
      result[key] = deepMerge(a[key], b[key]);
    } else {
      // @ts-ignore
      result[key] = b[key];
    }
  }
  return result as S & T;
}
