import { useState } from "react";
import { useAbortEffect } from "./use-abort-effect";

export interface UseModifierKeysMap
  extends Record<"ctrl" | "alt" | "cmd" | "shift", boolean> {}

export function useModifierKeys(): UseModifierKeysMap {
  const [keys, setKeys] = useState<UseModifierKeysMap>({
    ctrl: false,
    alt: false,
    cmd: false,
    shift: false,
  });

  useAbortEffect((signal) => {
    const updateKeys = (event: KeyboardEvent) =>
      setKeys({
        ctrl: event.ctrlKey,
        alt: event.altKey,
        cmd: event.metaKey,
        shift: event.shiftKey,
      });
    window.addEventListener("keydown", updateKeys, { signal });
    window.addEventListener("keyup", updateKeys, { signal });
  }, []);

  return keys;
}
