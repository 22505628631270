import { ms, StringTime } from "@/lib/timer";
import * as React from "react";

export function useInterval(
  fn: () => void,
  duration: number | StringTime,
  active: boolean = true,
): undefined {
  React.useEffect(() => {
    if (!active) return;
    const interval = setInterval(fn, ms(duration));
    return () => clearInterval(interval);
  }, [fn, duration, active]);
}
