import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, ButtonProps } from "./ui/button";
import { Plus } from "lucide-react";
import { cn } from "@/lib/utils";
import { DropdownMenu } from "@radix-ui/react-dropdown-menu";
import {
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { ADVERTISEMENT_TYPE } from "@/api/definitions";
import { ContentForType } from "@/api/advertisements";
import { useCache } from "@/hooks/use-cache";
import { getCurrentUser } from "@/api/authentication";
import Awaited from "./awaited";
import { extractEnabledAdvertisementTypes } from "@/api/companies";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";
import { useLimitReachedModal } from "@/modals/limit-reached";
import { canCreateAds, PlanUpgradeError } from "@/api/authorization";
import { join } from "@/lib/promise";

export interface ICreateAdvertisementButtonProps
  extends Omit<ButtonProps, "onClick" | "type"> {
  className?: string;
}

export default function CreateAdvertisementButton(
  props: ICreateAdvertisementButtonProps,
) {
  const session = useCache(getCurrentUser);
  const navigate = useSmartNavigate();
  const isAuthorized = useCache(canCreateAds).catch((e) => e);
  const limitReached = useLimitReachedModal();
  return (
    <Awaited
      resolve={join(session, isAuthorized)}
      fallback={
        <Button
          {...props}
          type="button"
          disabled
          className={cn("flex items-center shrink-0", props.className)}
        >
          Créer
          <Plus className="ml-2 w-5 h-5" />
        </Button>
      }
      render={([session, authRes]) => {
        if (!session) {
          return void navigate({ pathname: "/auth/login" });
        }
        if (authRes instanceof PlanUpgradeError)
          return (
            <Button
              {...props}
              type="button"
              className={cn("flex items-center shrink-0", props.className)}
              onClick={() => limitReached.openModal({ error: authRes })}
            >
              Créer
              <Plus className="ml-2 w-5 h-5" />
            </Button>
          );
        const types = extractEnabledAdvertisementTypes(session.company);
        if (types.length === 1)
          return (
            <Button
              {...props}
              type="button"
              className={cn("flex items-center shrink-0", props.className)}
              onClick={() => navigate(`/app/advertisements/new/${types[0]}`)}
            >
              Créer
              <Plus className="ml-2 w-5 h-5" />
            </Button>
          );
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                {...props}
                type="button"
                className={cn("flex items-center shrink-0", props.className)}
              >
                Créer
                <Plus className="ml-2 w-5 h-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuGroup>
                {types.map((type) => {
                  const Icon = ContentForType[type].icon;
                  return (
                    <DropdownMenuItem
                      key={type}
                      className="cursor-pointer justify-between opacity-80"
                      onClick={() =>
                        navigate(`/app/advertisements/new/${type}`)
                      }
                    >
                      <span className="font-medium tracking-wide">
                        {ContentForType[type].shortLabel}
                      </span>
                      <DropdownMenuShortcut className="opacity-100 ml-5">
                        <Icon className="w-4 h-4" />
                      </DropdownMenuShortcut>
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      }}
    />
  );
}
