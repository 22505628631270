import { TableHead } from "@/components/ui/table";
import { ColumnsDef } from "@/hooks/useColumns";

export type LogTableColumnName =
  | "id"
  | "type"
  | "resource"
  | "user"
  | "payload"
  | "created_at";

export const LogTableColumns: ColumnsDef<LogTableColumnName> = {
  id: {
    value: "id",
    label: "Id",
    header: () => <TableHead className="w-20">#ID</TableHead>,
  },
  created_at: {
    value: "created_at",
    label: "Timestamp",
    header: () => <TableHead className="w-max">Timestamp</TableHead>,
  },
  type: {
    value: "type",
    label: "Type",
    header: () => <TableHead>Type</TableHead>,
  },
  resource: {
    value: "resource",
    label: "Resource",
    header: () => <TableHead>Resource</TableHead>,
  },
  user: {
    value: "user",
    label: "Utilisateur",
    header: () => (
      <TableHead className="w-full max-w-[300px]">Utilisateur</TableHead>
    ),
  },
  payload: {
    value: "payload",
    label: "Payload",
    header: () => <TableHead>Payload</TableHead>,
  },
} as const;
