import {
  StepLayout,
  StepLayoutActions,
  StepLayoutHeader,
  StepLayoutNextAction,
  StepLayoutPreviousAction,
  StepLayoutSubtitle,
  StepLayoutTitle,
} from "@/components/step-layout";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useTextSize } from "@/hooks/use-text-size";
import { cn } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { AlertTriangle, InfoIcon } from "lucide-react";
import { useForm } from "react-hook-form";
import { useDraft } from "../../layout";
import { AdvertisementBuilderDetailsSchema } from "./schemas";
import { IStepProps } from "./step-props";
import { WIDGET_CARD_TITLE_MINIMUM_WIDTH } from "@/api/common";

export default function AdvertisementBuilderDetails({
  next,
  previous,
}: IStepProps) {
  const [{ draft }] = useDraft();
  const form = useForm({
    mode: "onTouched",
    resolver: zodResolver(AdvertisementBuilderDetailsSchema),
    defaultValues: {
      title: draft.title ?? "",
      description: draft.description ?? "",
      url: draft.url ?? "",
    },
  });
  const titleSize = useTextSize({
    content: form.getValues("title"),
    transform(str) {
      return str
        .split(" ")
        .filter((word) => word.length)
        .map((s) => s[0].toUpperCase() + s.slice(1))
        .join(" ");
    },
  });
  const errors = form.formState.errors;
  return (
    <StepLayout>
      <StepLayoutHeader>
        <StepLayoutTitle>Quel est votre évènement ?</StepLayoutTitle>
        <StepLayoutSubtitle>
          Informations principales sur l'événement.
        </StepLayoutSubtitle>
      </StepLayoutHeader>
      <div className="space-y-2">
        <Label className="justify-between flex w-full items-end min-h-[20px]">
          <span>Titre *</span>
          {titleSize > WIDGET_CARD_TITLE_MINIMUM_WIDTH ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <AlertTriangle className=" text-yellow-600 w-5 h-5 inline ml-2" />
              </TooltipTrigger>
              <TooltipContent side="bottom">
                <p>
                  En raison de sa longueur, ce titre peut être tronqué sur
                  certains écrans.
                </p>
              </TooltipContent>
            </Tooltip>
          ) : null}
        </Label>
        <div className="relative">
          <Input
            className={cn(
              titleSize > WIDGET_CARD_TITLE_MINIMUM_WIDTH &&
                "!outline-yellow-600",
              errors.title && "border-red-500",
            )}
            required
            {...form.register("title")}
          />
          <div className="pointer-events-none bottom-2 right-2 absolute text-xs text-muted-foreground">
            {form.getValues().title.length}
          </div>
        </div>
      </div>
      <div className="space-y-2">
        <Label>Description</Label>
        <Textarea
          className={cn(
            "min-h-[80px] max-h-[256px]",
            errors.description && "border-red-500",
          )}
          {...form.register("description")}
        />
      </div>
      <div className="space-y-3">
        <Label>URL de redirection *</Label>
        <Input
          type="url"
          className={cn(errors.url && "border-red-500")}
          required
          {...form.register("url")}
        />
      </div>
      <StepLayoutActions>
        <StepLayoutPreviousAction previous={previous}>
          Retour
        </StepLayoutPreviousAction>
        <StepLayoutNextAction form={form} next={next}>
          Continuer
        </StepLayoutNextAction>
      </StepLayoutActions>
    </StepLayout>
  );
}
