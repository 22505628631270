import * as React from "react";
import { AdvertisementVersionDTO, BlackListsDTO } from "@/api/definitions";
import { ColumnsState } from "@/hooks/useColumns";
import { BlacklistsTableColumnName } from "./columns";
import { TableCell, TableRow } from "@/components/ui/table";
import { Briefcase, Building, PartyPopper, Pause, Trash } from "lucide-react";
import { ADVERTISEMENT_TYPE } from "@/api/definitions";
import { Checkbox } from "@/components/ui/checkbox";
import { cn } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { ContentForType } from "@/api/advertisements";

export interface IBlacklistsRowProps {
  columnState: ColumnsState<BlacklistsTableColumnName>;
  blacklist: BlackListsDTO & { advertisement: AdvertisementVersionDTO };
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}

export default function BlacklistsRow({
  columnState,
  blacklist,
  checked,
  onCheckedChange,
}: IBlacklistsRowProps) {
  const Icon = ContentForType[blacklist.advertisement.type].icon;

  return (
    <TableRow className="min-h-[72px]">
      <TableCell className="font-medium" hidden={!columnState.checked.visible}>
        <Checkbox checked={checked} onCheckedChange={onCheckedChange} />
      </TableCell>
      <TableCell
        className={cn(columnState.checked.visible ? "px-0" : "pl-4")}
        hidden={!columnState.image.visible}
      >
        <img
          src={blacklist.advertisement.main_image}
          alt={blacklist.advertisement.title}
          className="aspect-[2] w-20 rounded-[4px] object-cover"
        />
      </TableCell>
      <TableCell
        className="max-w-[320px] truncate"
        title={blacklist.advertisement.title}
        hidden={!columnState.title.visible}
      >
        <Tooltip>
          <TooltipTrigger>
            <Icon className="h-4 w-4 hover:bg-foreground hover:text-background rounded-sm mr-1 inline relative -top-0.5" />
          </TooltipTrigger>
          <TooltipContent>
            {ContentForType[blacklist.advertisement.type].label}
          </TooltipContent>
        </Tooltip>
        {blacklist.advertisement.title}
      </TableCell>
      <TableCell className="flex justify-end">
        <Button variant="secondary" size="icon">
          <Trash className="w-5 h-5" />
        </Button>
      </TableCell>
    </TableRow>
  );
}

BlacklistsRow.Skeleton = function ({
  columnState,
}: Pick<IBlacklistsRowProps, "columnState">) {
  return (
    <TableRow className="min-h-[72px]">
      <TableCell className="font-medium" hidden={!columnState.checked.visible}>
        <Skeleton className="w-4 h-4" />
      </TableCell>
      <TableCell
        className={cn(columnState.checked.visible ? "px-0" : "pl-4")}
        hidden={!columnState.image.visible}
      >
        <Skeleton className="aspect-[2] w-20 rounded-[4px] " />
      </TableCell>
      <TableCell
        className="max-w-[320px] truncate"
        hidden={!columnState.title.visible}
      >
        <Skeleton className="w-80 h-4" />
      </TableCell>
      <TableCell className="flex justify-end">
        <Skeleton className="h-10 w-10" />
      </TableCell>
    </TableRow>
  );
};
