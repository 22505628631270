export type TabsList<T extends string> = Record<
  T,
  { Icon?: React.ExoticComponent; label: string }
>;

export function getCurrentTab<T extends string>(
  tabs: TabsList<T>,
  pathname: string,
  defaultTab: T,
): T {
  for (const path in tabs) if (pathname.includes(path)) return path as T;
  return defaultTab;
}
