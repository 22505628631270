import * as React from "react";
import { cn } from "@/lib/utils";
import { ScrollArea } from "./ui/scroll-area";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";
import { Form, Link, useLocation } from "react-router-dom";
import { LogOut, Minus, SlidersHorizontal } from "lucide-react";
import { Button } from "./ui/button";
import { CollapseMenuButton } from "./collapsible-menu-button";
import { Switch } from "./ui/switch";
import { Label } from "./ui/label";
import {
  Tag,
  Users,
  Settings,
  Bookmark,
  SquarePen,
  LayoutGrid,
  LucideIcon,
  Home,
  Paintbrush,
  LifeBuoy,
  Database,
} from "lucide-react";
import { usePersist } from "@/hooks/use-persist";
import Awaited from "./awaited";
import { useCache } from "@/hooks/use-cache";
import { getCurrentUser } from "@/api/authentication";

type Menu = {
  href: string;
  label: string;
  active: boolean;
  icon: LucideIcon;
};

function getMenuList(pathname: string): Menu[] {
  return [
    {
      href: "/app",
      label: "Dashboard",
      active: pathname === "/app",
      icon: Home,
    },
    {
      href: "/app/widgets",
      label: "Widgets",
      active: pathname.includes("/widgets"),
      icon: LayoutGrid,
    },
    {
      href: "/app/advertisements",
      label: "Annonces",
      active: pathname.includes("/advertisements"),
      icon: SquarePen,
    },
    {
      href: "/app/catalog",
      label: "Catalogue",
      active: pathname === "/app/catalog",
      icon: Database,
    },
    {
      href: "/app/design",
      label: "Modèles",
      active: pathname.includes("/design"),
      icon: Paintbrush,
    },
    // {
    //   href: "/app/help",
    //   label: "Aide",
    //   active: pathname.includes("/help"),
    //   icon: LifeBuoy,
    // },
    {
      href: "/app/settings",
      label: "Paramètres",
      active: pathname.includes("/settings"),
      icon: Settings,
    },
  ];
}

export function Menu({ isOpen = true }) {
  const pathname = useLocation().pathname;
  const { get, watch } = usePersist<boolean>("admin-mode-enabled");
  const [adminModeEnabled, setAdminModeEnabled] = React.useState(get(true));
  watch(adminModeEnabled);
  const menuList = getMenuList(pathname);
  const _session = useCache(getCurrentUser);
  const renderMenuItem = React.useCallback(
    ({ href, label, icon: Icon, active }: Menu) => (
      <li className="w-full" key={href}>
        <Tooltip delayDuration={100}>
          <TooltipTrigger asChild>
            <Button
              variant={active ? "secondary" : "ghost"}
              size="sm"
              className="w-full justify-start h-10 mb-1"
              asChild
            >
              <Link to={href}>
                <span className={cn(isOpen === false ? "" : "mr-4")}>
                  <Icon size={18} />
                </span>
                <p
                  className={cn(
                    "max-w-[200px] truncate",
                    isOpen === false
                      ? "-translate-x-96 opacity-0"
                      : "translate-x-0 opacity-100",
                  )}
                >
                  {label}
                </p>
              </Link>
            </Button>
          </TooltipTrigger>
          {isOpen === false && (
            <TooltipContent side="right">{label}</TooltipContent>
          )}
        </Tooltip>
      </li>
    ),
    [isOpen],
  );
  return (
    <ScrollArea className="[&>div>div[style]]:!block z-40 relative">
      <nav className="mt-8 h-full w-full text-foreground">
        <ul className="flex flex-col min-h-[calc(100vh-48px-36px-16px-32px-4px)] lg:min-h-[calc(100vh-32px-40px-32px-4px)] items-start space-y-1 px-2">
          {menuList.map(renderMenuItem)}
          <Awaited
            resolve={_session}
            render={(session) => {
              if (!session?.isAdmin) return null;
              return renderMenuItem({
                href: "/app/admin",
                label: "Admin",
                active: pathname.includes("/admin"),
                icon: SlidersHorizontal,
              });
            }}
          />
          <li className="w-full grow flex flex-col justify-end">
            <Awaited
              resolve={_session}
              render={(session) => {
                if (!session?.isAdmin) return null;
                return (
                  <div className="flex justify-center items-center gap-2">
                    <Switch
                      checked={adminModeEnabled}
                      onCheckedChange={setAdminModeEnabled}
                    />
                    <Label
                      className={cn(
                        "whitespace-nowrap",
                        isOpen === false ? "opacity-0 hidden" : "opacity-100",
                      )}
                    >
                      Admin mode
                    </Label>
                  </div>
                );
              }}
            />

            <Form method="DELETE" action="/auth?index" className="w-full">
              <Tooltip delayDuration={100}>
                <TooltipTrigger asChild>
                  <Button
                    variant="outline"
                    className="w-full justify-center h-10 mt-5"
                  >
                    <span className={cn(isOpen === false ? "" : "mr-4")}>
                      <LogOut size={18} />
                    </span>
                    <p
                      className={cn(
                        "whitespace-nowrap",
                        isOpen === false ? "opacity-0 hidden" : "opacity-100",
                      )}
                    >
                      Déconnexion
                    </p>
                  </Button>
                </TooltipTrigger>
                {isOpen === false && (
                  <TooltipContent side="right">Déconnexion</TooltipContent>
                )}
              </Tooltip>
            </Form>
          </li>
        </ul>
      </nav>
    </ScrollArea>
  );
}
