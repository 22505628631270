import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import * as React from "react";
import { useAsyncValue, useFetcher } from "react-router-dom";
import { ISingleAdvertisementLoaderData } from "../../loader";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { objectToFormData } from "@/lib/query";
import FormState from "@/components/form-state";
import { cn } from "@/lib/utils";
import { WIDGET_CARD_TITLE_MINIMUM_WIDTH } from "@/api/common";
import { useTextSize } from "@/hooks/use-text-size";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { AlertTriangle, InfoIcon } from "lucide-react";

export interface IAdvertisementDetailsCardProps {}

export default function AdvertisementDetailsCard(
  props: IAdvertisementDetailsCardProps,
) {
  const advertisement = useAsyncValue() as Awaited<
    ISingleAdvertisementLoaderData["advertisement"]
  >;
  const [title, setTitle] = React.useState(advertisement.title);
  const [description, setDescription] = React.useState(
    advertisement.description,
  );
  const [url, setUrl] = React.useState(advertisement.url);
  const titleSize = useTextSize({
    content: title,
    transform(str) {
      return str
        .split(" ")
        .filter((word) => word.length)
        .map((s) => s[0].toUpperCase() + s.slice(1))
        .join(" ");
    },
  });
  const fetcher = useFetcher();
  return (
    <Card className="enter">
      <fetcher.Form
        method="PUT"
        action="/app/advertisements/[id]/content?index"
        onSubmit={(e) => {
          if (!advertisement.isOwned) e.preventDefault();
        }}
      >
        <FormState data={{ id: advertisement.id, type: advertisement.type }} />
        <CardHeader>
          <CardTitle>Détails</CardTitle>
        </CardHeader>
        <CardContent className="pb-6 border-b border-b-border space-y-6">
          <div className="space-y-3">
            <Label className="justify-between flex w-full items-end min-h-[20px]">
              <span>Titre</span>
              {titleSize > WIDGET_CARD_TITLE_MINIMUM_WIDTH ? (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <AlertTriangle className=" text-yellow-600 w-5 h-5 inline ml-2" />
                  </TooltipTrigger>
                  <TooltipContent side="left">
                    <p>
                      En raison de sa longueur, ce titre peut être tronqué sur
                      certains écrans.
                    </p>
                  </TooltipContent>
                </Tooltip>
              ) : null}
            </Label>
            <Input
              name="title"
              value={title}
              disabled={!advertisement.isOwned}
              onChange={(e) => setTitle(e.target.value)}
              className={cn(
                titleSize > WIDGET_CARD_TITLE_MINIMUM_WIDTH &&
                  "!outline-yellow-600",
              )}
            />
          </div>
          <div className="space-y-3">
            <Label>Description</Label>
            <Textarea
              name="description"
              className="max-h-64"
              value={description ?? ""}
              disabled={!advertisement.isOwned}
              onChange={(e) => setDescription(e.target.value ?? null)}
              style={{
                height: Math.max(
                  36 +
                    (advertisement.description ?? "").split("\n").length * 25,
                  80,
                ),
              }}
            />
          </div>
          <div className="space-y-3">
            <Label>URL</Label>
            <Input
              name="url"
              value={url}
              disabled={!advertisement.isOwned}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
        </CardContent>
        <CardFooter
          className={cn(
            "flex justify-end py-4",
            advertisement.isOwned || "hidden",
          )}
        >
          <Button
            type="submit"
            size="sm"
            disabled={
              title === advertisement.title &&
              description === advertisement.description &&
              url === advertisement.url
            }
          >
            Confirmer
          </Button>
        </CardFooter>
      </fetcher.Form>
    </Card>
  );
}

AdvertisementDetailsCard.Skeleton = function () {
  return (
    <Card className="opacity-50">
      <CardHeader>
        <CardTitle>Détails</CardTitle>
      </CardHeader>
      <CardContent className="pb-6 border-b border-b-border space-y-6">
        <div className="space-y-3">
          <Label>Titre</Label>
          <Skeleton className="w-full h-6" />
        </div>
        <div className="space-y-3">
          <Label>Description</Label>
          <Skeleton className="w-full h-20" />
        </div>
        <div className="space-y-3">
          <Label>URL</Label>
          <Skeleton className="w-full h-6" />
        </div>
      </CardContent>
      <CardFooter className="flex justify-end py-4">
        <Skeleton className="w-40 h-10" />
      </CardFooter>
    </Card>
  );
};
