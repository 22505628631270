import { listBlacklists } from "@/api/blacklists";
import { LoaderFunctionArgs, defer } from "react-router-dom";

export interface IWidgetBlacklistsLoaderData {
  blacklists: ReturnType<typeof listBlacklists>;
}

export async function WidgetBlacklistsLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  if (!params.widgetId) throw new Error("No widget selected");
  return defer({
    blacklists: listBlacklists(
      params.widgetId,
      {
        ongoing: true,
      },
      request.signal,
    ),
  } satisfies IWidgetBlacklistsLoaderData);
}
