import { useInterval } from "@/hooks/use-interval";
import * as React from "react";
import { createPortal } from "react-dom";

export interface IRefPortalProps {
  selector: string;
  key?: string;
  children: React.ReactNode | React.ReactNode[];
}

export default function RefPortal(props: IRefPortalProps) {
  const [element, setElement] = React.useState<HTMLElement | null>(
    document.querySelector(props.selector) as HTMLElement | null,
  );
  React.useEffect(() => {
    if (!element) return;

    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        console.log("REF PORTAL MUTATION", props.selector, mutation);
        if (mutation.type !== "childList") continue;
        for (const removedNode of mutation.removedNodes)
          if (element.isEqualNode(removedNode)) {
            setElement(null);
            return;
          }
      }
    });
    observer.observe(element.parentNode!, { childList: true });
    return () => observer.disconnect();
  }, [element]);
  useInterval(
    () => {
      const el = document.querySelector(props.selector) as HTMLElement | null;
      if (el) setElement(el);
    },
    100,
    !element,
  );
  React.useEffect(() => {
    if (element && !element.isConnected)
      setElement(document.querySelector(props.selector) as HTMLElement | null);
  });

  if (!element) return null;

  return createPortal(props.children, element, props.selector);
}
