import {
  AdvertisementDraftBaseSchema,
  UpdateAdvertisementSchema,
  EventDatesSchema,
} from "@/api/definitions";
import { ActionFunctionArgs } from "react-router-dom";
import * as z from "@/lib/zod";
import {
  createCoordinates,
  updateAdvertisement,
  uploadImage,
  addEventAdvertisementDates,
  deleteEventAdvertisementDates,
  removeEventAdvertisementCategory,
  addEventAdvertisementCategories,
} from "@/api/advertisements";
import { withToast } from "@/lib/with-toast";
import { objectFromFormData } from "@/lib/query";
import { omit } from "@/lib/pick";

export interface IAdvertisementContentActionData {}
export async function AdvertisementContentAction({
  request,
}: ActionFunctionArgs): Promise<IAdvertisementContentActionData> {
  const fData = await request.formData();
  const body = objectFromFormData(fData);
  console.log({ fData, body, is_main_version: fData.get("is_main_version") });

  await withToast(async () => {
    const { id, type, ...data } = UpdateAdvertisementSchema.and(
      z.object({ id: z.string() }),
    )
      .and(
        AdvertisementDraftBaseSchema.pick({
          address: true,
          city: true,
          longitude: true,
          latitude: true,
          image: true,
        }).partial(),
      )
      .and(
        z
          .object({
            addedCategories: z.oneOrMore(z.coerce.number()),
            removedCategories: z.oneOrMore(z.coerce.number()),
            removedDates: z.json(EventDatesSchema.pick({ id: true }).array()),
            addedDates: z.json(
              EventDatesSchema.pick({
                from: true,
                to: true,
              }).array(),
            ),
          })
          .partial(),
      )
      .parse(body);
    console.log({ data });
    if (request.method === "POST") {
      await updateAdvertisement(id, { type, archivedAt: null }, request.signal);
    } else if (request.method === "PUT") {
      const [coordinates_id, main_image] = await Promise.all([
        (async () => {
          const coordinatePayload = AdvertisementDraftBaseSchema.pick({
            address: true,
            city: true,
            longitude: true,
            latitude: true,
          }).safeParse(data);
          if (coordinatePayload.success) {
            return (await createCoordinates(coordinatePayload.data)).id;
          }
        })(),
        (async () => {
          const imagePayload = AdvertisementDraftBaseSchema.pick({
            image: true,
          }).safeParse(data);
          if (imagePayload.success) {
            return uploadImage(imagePayload.data);
          }
        })(),
        (async () => {
          if (data.addedDates?.[0])
            await addEventAdvertisementDates(
              id,
              data.addedDates.map((d) => omit(d, "id")),
            );
          if (data.removedDates?.[0])
            await Promise.all(
              data.removedDates.map((d) =>
                deleteEventAdvertisementDates(id, d.id),
              ),
            );
        })(),
        (async () => {
          if (data.removedCategories?.[0])
            await Promise.all(
              data.removedCategories.map((c) =>
                removeEventAdvertisementCategory(id, c),
              ),
            );
          if (data.addedCategories?.[0])
            await addEventAdvertisementCategories(id, data.addedCategories);
        })(),
      ]);

      return updateAdvertisement(
        id,
        omit(
          {
            ...data,
            type,
            coordinates_id,
            main_image,
            landscape_image: main_image,
          },
          "removedDates",
          "addedDates",
          "removedCategories",
          "addedCategories",
        ),
        request.signal,
      );
    } else if (request.method === "DELETE") {
      return updateAdvertisement(
        id,
        { type, archivedAt: new Date() },
        request.signal,
      );
    }
  });
  return {};
}
