import { ListUsersSchema } from "@/api/definitions";
import { listUsers } from "@/api/users";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";
import * as z from "@/lib/zod";

export interface IAdminUsersLoaderData {
  users: ReturnType<typeof listUsers>;
}

export const AdminCompanyLoaderQueryParamsSchema = ListUsersSchema;

export async function AdminUsersLoader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const query = z
    .searchParams(ListUsersSchema, "safeParse")
    .parse(url.searchParams);
  if (!query.success) {
    console.log("query error", query.error);
    throw redirect(url.pathname);
  }
  return defer({
    users: listUsers(query.data, request.signal),
  } satisfies IAdminUsersLoaderData);
}
