import { PricingsDTO, PricingsSchema } from "@/api/definitions";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface ISubscriptionSettingsLoaderData {
  pricings: Promise<PricingsDTO[]>;
}

export async function SubscriptionSettingsLoader({}: LoaderFunctionArgs) {
  return defer({
    pricings: fetch("/pricing.json")
      .then((res) => {
        if (!res.ok) throw res;
        return res.json();
      })
      .then((res) => PricingsSchema.array().parse(res)),
  } satisfies ISubscriptionSettingsLoaderData);
}
