import * as React from "react";
import { useLoaderData } from "react-router-dom";
import { IMutationLogLoaderData } from "./loader";
import Awaited from "@/components/awaited";
import { useRouteNavigation } from "@/hooks/use-route-navigation";
import { useColumns } from "@/hooks/useColumns";
import { listMutationLogs } from "@/api/mutation_log";
import { useRevalidateCache } from "@/hooks/use-invalidate";
import {
  DateState,
  IntegerState,
  useSearchState,
} from "@/hooks/use-search-state";
import { TabLayoutBreadcrumb, TabLayoutContent } from "@/components/tab-layout";
import DateRangeSelector from "@/components/date-range-selector";
import CheckboxDropdown from "@/components/checkbox-dropdown";
import { entries, fromEntries } from "@/lib/entries";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ScrollArea } from "@/components/ui/scroll-area";
import { LogTableColumns } from "./(blocks)/columns";
import LogRow from "./(blocks)/log-row";
import dayjs from "@/lib/date";

export interface IMutationLogPageProps {}

const limit = 50;
export default function MutationLogPage(props: IMutationLogPageProps) {
  const data = useLoaderData() as IMutationLogLoaderData;
  const navigation = useRouteNavigation("/app/logs");
  useRevalidateCache(listMutationLogs);
  const [columns, columnsState, setColumnState] = useColumns(
    "logs",
    LogTableColumns,
  );
  const [setOffset, offset] = useSearchState({
    key: "offset",
    defaultValue: 0,
    ...IntegerState,
  });
  const [setDates, ...dates] = useSearchState({
    key: "dates",
    ...DateState,
    middleware(s) {
      s.delete("offset");
    },
  });
  const [from, to] = dates.sort();
  return (
    <>
      <TabLayoutContent size="full-width">
        <TabLayoutBreadcrumb>Logs</TabLayoutBreadcrumb>
        <div className="flex items-center gap-2 w-full">
          <DateRangeSelector
            value={[from, to]}
            className="min-w-[300px]"
            setValue={(range) => {
              setDates(range.map((d) => dayjs(d).toDate()));
            }}
          />
          {offset ? (
            <span className="text-muted-foreground text-xs">
              Offset:&nbsp;{offset}
            </span>
          ) : null}
          <div className="filler" />
          <CheckboxDropdown
            items={Object.values(columns)}
            values={fromEntries(
              entries(columnsState).map(([k, s]) => [k, s.visible]),
            )}
            onChange={(column, visible) =>
              setColumnState({ state: { [column]: { visible } } })
            }
          >
            <Button variant="outline" size="sm" className="border-dashed">
              Colonnes
            </Button>
          </CheckboxDropdown>
        </div>
        <ScrollArea className="overflow-hidden relative border border-border rounded-md">
          <Table>
            <TableHeader>
              <TableRow>
                {entries(columns)
                  .filter(([key]) => columnsState[key].visible)
                  .map(([_, { value, header: Header }]) => (
                    <Header key={value} />
                  ))}
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <Awaited
                resolve={data.logs}
                render={(logs) =>
                  logs.map((log) => (
                    <LogRow key={log.id} log={log} columnState={columnsState} />
                  ))
                }
              />
            </TableBody>
          </Table>
        </ScrollArea>
        <div className="w-full flex gap-3">
          {offset ? (
            <Button
              className="w-full"
              variant="outline"
              onClick={() => {
                setOffset(offset - limit);
              }}
            >
              Page précédente
            </Button>
          ) : null}
          <Awaited
            resolve={data.logs}
            render={(logs) => {
              if (!logs.length) return null;
              return (
                <Button
                  className="w-full"
                  variant="outline"
                  onClick={() => {
                    setOffset(offset + limit);
                  }}
                >
                  Page suivante
                </Button>
              );
            }}
          />
        </div>
      </TabLayoutContent>
    </>
  );
}
