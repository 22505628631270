import { clone, entries, fromEntries, keys } from "@/lib/entries";
import { usePersist } from "@/hooks/use-persist";
import * as React from "react";
import { timer } from "@/lib/timer";
import { useRenderTrack } from "./use-render-track";

export type ColumnsDef<T extends string, P = {}> = Record<
  T,
  {
    value: T;
    label: string;
    header: React.ComponentType<P>;
    alwaysVisible?: boolean;
  }
>;

export type ColumnState = {
  visible: boolean;
  sorting: "asc" | "desc" | null;
};

export type ColumnsState<T extends string> = Record<T, ColumnState>;

const defaultColumnState: ColumnState = { visible: true, sorting: null };

function createDefaultColumnsState<T extends string, P = {}>(
  shape: ColumnsDef<T, P>,
) {
  return fromEntries(keys(shape).map((k) => [k, defaultColumnState]));
}
export function useColumns<T extends string, P = {}>(
  key: string,
  shape: ColumnsDef<T, P>,
  _defaultState?: ColumnsState<T>,
) {
  const defaultState = React.useMemo(
    () => _defaultState ?? createDefaultColumnsState(shape),
    [shape],
  );
  const { get, watch } = usePersist<ColumnsState<T>>(`use-columns-${key}`);
  const [_state, setState] = React.useReducer(
    React.useMemo(() => reducer(shape), [shape]),
    get(defaultState),
  );
  watch(_state);
  React.useEffect(() => {
    console.log("effect setState");
    setState({ state: get(defaultState), replace: true });
  }, [key]);

  useRenderTrack([shape], "shape");
  useRenderTrack([_state], "_state");
  const state = React.useMemo(() => {
    const shapeKeys = keys(shape);
    const stateKeys = _state ? keys(_state) : [];
    if (
      shapeKeys.length === stateKeys.length &&
      shapeKeys.every((k) => stateKeys.includes(k))
    )
      return _state;
    const stored_state = get(defaultState);
    const stored_stateKeys = stored_state ? keys(stored_state) : [];

    if (
      shapeKeys.length === stored_stateKeys.length &&
      shapeKeys.every((k) => stored_stateKeys.includes(k))
    ) {
      // timer(() => {
      console.log("timer setState");
      setState({ state: stored_state, replace: true });
      // }).trigger();
      return stored_state;
    }

    const newDefaultState = createDefaultColumnsState(shape);
    // timer(() => {
    console.log(
      "timer setState 2",
      shapeKeys,
      stateKeys,
      stored_stateKeys,
      newDefaultState,
    );
    setState({ state: newDefaultState, replace: true });
    // }).trigger();
    return defaultState;
  }, [shape, _state]);
  return [shape, state, setState] as const;
}

function reducer<T extends string, P = {}>(shape: ColumnsDef<T, P>) {
  return function (
    state: ColumnsState<T>,
    {
      state: action,
      replace,
    }: { state: Partial<Record<T, Partial<ColumnState>>>; replace?: boolean },
  ) {
    console.log("reducer");
    if (replace) {
      return action as ColumnsState<T>;
    }
    const newState = clone(state);
    for (const [column, columnState] of entries(action)) {
      if (!columnState) continue;
      newState[column] ??= defaultColumnState;
      for (const [property, value] of entries(columnState))
        switch (property) {
          case "visible":
            if (value || !shape[column].alwaysVisible) {
              newState[column].visible = value as ColumnState["visible"];
            }
            break;
          case "sorting":
            if (value !== undefined)
              newState[column].sorting = value as ColumnState["sorting"];
            break;
        }
    }
    return newState;
  };
}
