import { awaited } from "@/components/awaited";
import SearchSelect from "@/components/search-select";
import {
  StepLayout,
  StepLayoutActions,
  StepLayoutHeader,
  StepLayoutNextAction,
  StepLayoutPreviousAction,
  StepLayoutSubtitle,
  StepLayoutTitle,
} from "@/components/step-layout";
import { Button } from "@/components/ui/button";
import { withId } from "@/lib/array";
import { capitalize } from "@/lib/format";
import { zodResolver } from "@hookform/resolvers/zod";
import { X } from "lucide-react";
import { useForm } from "react-hook-form";
import { useAsyncValue } from "react-router-dom";
import { useDraft } from "../../layout";
import { IAdvertisementBuilderLoaderData } from "../loader";
import { AdvertisementBuilderRealEstateSchema } from "./schemas";
import { IStepProps } from "./step-props";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";

export function AdvertisementBuilderRealEstate({ next, previous }: IStepProps) {
  const [{ draft }] = useDraft();
  const form = useForm({
    mode: "onChange",
    resolver: zodResolver(AdvertisementBuilderRealEstateSchema),
    defaultValues: {
      price: draft.price,
      rooms: draft.rooms,
      surface: draft.surface,
    },
  });

  const errors = form.formState.errors;
  return (
    <StepLayout>
      <StepLayoutHeader>
        <StepLayoutTitle>Informations de base sur ce bien.</StepLayoutTitle>
      </StepLayoutHeader>
      <div className="space-y-2">
        <Label>
          <span>Valeur *</span>
        </Label>
        <p className="text-xs text-muted-foreground">
          Renseignez "NC" ou "Non communiqué" si vous n'avez pas le prix du
          bien.
        </p>
        <Input
          className={cn(errors.price && "border-red-500")}
          required
          {...form.register("price")}
        />
      </div>
      <div className="space-y-2">
        <Label>
          <span>Surface *</span>
        </Label>
        <Input
          className={cn(errors.surface && "border-red-500")}
          required
          {...form.register("surface")}
        />
      </div>
      <div className="space-y-2">
        <Label>
          <span>Nombre de pièces *</span>
        </Label>
        <Input
          className={cn(errors.rooms && "border-red-500")}
          required
          {...form.register("rooms")}
        />
      </div>
      <StepLayoutActions>
        <StepLayoutPreviousAction previous={previous}>
          Retour
        </StepLayoutPreviousAction>
        <StepLayoutNextAction form={form} next={next}>
          Continuer
        </StepLayoutNextAction>
      </StepLayoutActions>
    </StepLayout>
  );
}
