import * as React from "react";
import { useFetcher, useLoaderData, useNavigate } from "react-router-dom";
import { IAdvertisementBuilderLoaderData } from "./loader";
import { HeaderBreadCrumb } from "@/components/header";
import { ContentForType } from "@/api/advertisements";
import { Stepper } from "@/components/stepper";
import { steps as allSteps, stepsForType, useDraft } from "../layout";
import { Card } from "@/components/ui/card";
import { keys } from "@/lib/entries";
import AdvertisementBuilderDetails from "./(blocks)/details";
import { useSet } from "@/hooks/use-set";
import AdvertisementBuilderLocation from "./(blocks)/location";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import AdvertisementBuilderDates from "./(blocks)/dates";
import { AdvertisementBuilderCategories } from "./(blocks)/categories";
import AdvertisementBuilderImages from "./(blocks)/images";
import { AdvertisementBuilderSchedules } from "./(blocks)/schedules";
import { IStepProps } from "./(blocks)/step-props";
import { omit, pick } from "@/lib/pick";
import { ADVERTISEMENT_TYPE } from "@/api/definitions";
import { objectToFormData } from "@/lib/query";
import { AdvertisementBuilderJob } from "./(blocks)/job";
import { AdvertisementBuilderRealEstate } from "./(blocks)/real_estate";
import AdvertisementBuilderSimplifiedView from "./(blocks)/simplified-view";
import { usePersist } from "@/hooks/use-persist";
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";

export interface IAdvertisementBuilderPageProps {}

export default function AdvertisementBuilderPage(
  props: IAdvertisementBuilderPageProps,
) {
  const { type } = useLoaderData() as IAdvertisementBuilderLoaderData;
  const [{ draft, step: _step }, setDraft] = useDraft();
  const steps = stepsForType(type);
  const completedSteps = useSet<keyof typeof steps>();
  const step = _step as keyof typeof steps;
  const stepKeys = keys(steps);
  const currentStepIndex = stepKeys.indexOf(step);
  const navigate = useNavigate();
  const fetcher = useFetcher();

  React.useEffect(() => {
    if (type !== draft.type) navigate(`/app/advertisements/new/${type}`);
    if (!draft.type)
      setDraft((d) => {
        d.draft.type = type;
      });
  }, [type, draft.type]);

  function submit() {
    fetcher.submit(objectToFormData({ draft: JSON.stringify(draft) }), {
      method: "POST",
      encType: "multipart/form-data",
      action: "/app/advertisements/new/[type]?index",
    });
  }
  function nextStep(completed?: boolean) {
    if (completed) completedSteps.add(step);
    if (currentStepIndex < stepKeys.length - 1)
      setDraft({ step: stepKeys[currentStepIndex + 1] });
    else submit();
  }
  const CurrentStepComponent = React.useMemo(() => {
    const componentsMap: Record<
      keyof typeof steps,
      React.FC<IStepProps>
    > = pick(
      {
        content: AdvertisementBuilderDetails,
        location: AdvertisementBuilderLocation,
        dates: AdvertisementBuilderDates,
        categories: AdvertisementBuilderCategories,
        job: AdvertisementBuilderJob,
        real_estate: AdvertisementBuilderRealEstate,
        images: AdvertisementBuilderImages,
        schedule: AdvertisementBuilderSchedules,
      },
      ...stepKeys,
    );
    if (step in componentsMap) return componentsMap[step];
    // safety if for whatever reason
    // the step doesn't match (i.e after code update)
    setDraft({ step: "content" });
    return AdvertisementBuilderDetails;
  }, [step, stepKeys]);
  const { get, watch } = usePersist<boolean>(
    "advertisement-builder-simplified-mode",
  );
  const [simplifiedMode, setSimplifiedMode] = React.useState(get(false));
  watch(simplifiedMode);
  return (
    <>
      <HeaderBreadCrumb chunks={ContentForType[type].new} />

      <Card className="w-full min-h-[calc(100vh_-_104px)] relative p-10 z-20">
        <div className="-mt-10">
          <div className="w-min mx-auto h-10">
            <Button
              size="sm"
              variant="link"
              className="text-xs text-muted-foreground"
              onClick={() => setSimplifiedMode((s) => !s)}
            >
              Utiliser {simplifiedMode ? "le mode guidé" : "la vue simplifiée"}
              <ArrowRight className="ml-1 w-2.5 h-2.5" />
            </Button>
          </div>
          {simplifiedMode ? (
            <AdvertisementBuilderSimplifiedView />
          ) : (
            <div className="w-min mx-auto">
              <Stepper
                steps={steps}
                currentStep={step}
                completedSteps={Array.from(completedSteps)}
                onStepChange={
                  import.meta.env.DEV
                    ? (newStep) => setDraft({ step: newStep })
                    : undefined
                }
                className="w-max min-w-[450px] mb-10 sticky top-10 z-30"
              />

              <CurrentStepComponent
                steps={stepKeys}
                previous={
                  currentStepIndex
                    ? () => setDraft({ step: stepKeys[currentStepIndex - 1] })
                    : undefined
                }
                next={nextStep}
              />
            </div>
          )}
        </div>
      </Card>
    </>
  );
}
