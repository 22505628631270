import * as React from "react";
import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import { ITeamSettingsLoaderData } from "./loader";
import Awaited from "@/components/awaited";
import { TabLayoutBreadcrumb, TabLayoutContent } from "@/components/tab-layout";
import { ISettingsLoaderData } from "../loader";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useRevalidateCache } from "@/hooks/use-invalidate";
import { getCurrentUser } from "@/api/authentication";
import { Button } from "@/components/ui/button";
import { Plus, Trash } from "lucide-react";
import RefPortal from "@/components/ref-portal";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { join } from "@/lib/promise";
import { useKickUserModal } from "@/modals/kick-user";
import { getCompanyUsers } from "@/api/companies";
import { useInviteUserModal } from "@/modals/invite-user";

export interface ITeamSettingsPageProps {}

export default function TeamSettingsPage(props: ITeamSettingsPageProps) {
  useRevalidateCache(getCurrentUser);
  useRevalidateCache(getCompanyUsers);
  const settingsData = useRouteLoaderData(
    "settings-loader",
  ) as ISettingsLoaderData;
  const data = useLoaderData() as ITeamSettingsLoaderData;
  const kickUserModal = useKickUserModal();
  const inviteUserModal = useInviteUserModal();
  return (
    <TabLayoutContent>
      <TabLayoutBreadcrumb>Mon équipe</TabLayoutBreadcrumb>
      <RefPortal selector="#tab-layout-head-outlet">
        <Awaited
          resolve={settingsData.session}
          render={(s) => (
            <Button
              onClick={() =>
                inviteUserModal.openModal({
                  companyId: s.company!.id,
                })
              }
            >
              <Plus className="h-4 w-4 inline mr-2" />
              Inviter un utilisateur
            </Button>
          )}
        />
      </RefPortal>
      <ScrollArea className="border border-border rounded-md">
        <Awaited
          resolve={join(data.users, settingsData.session)}
          render={([
            users,
            {
              user: { policyId },
            },
          ]) => (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Nom</TableHead>
                  <TableHead>Prénom</TableHead>
                  <TableHead>Adresse email</TableHead>
                  <TableHead>Role</TableHead>
                  <TableHead className="text-end" hidden={policyId !== 1}>
                    Actions
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {users.map((user, i) => (
                  <TableRow key={user.email}>
                    <TableCell>{user.lastname}</TableCell>
                    <TableCell>{user.firstname}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {
                        [
                          <Badge
                            variant="outline"
                            className="bg-accent text-accent-foreground border-accent"
                          >
                            Administrateur
                          </Badge>,
                          <Badge className="">Comptable</Badge>,
                          <Badge variant="outline">Créateur</Badge>,
                        ][(user.policyId ?? 0) - 1]
                      }
                    </TableCell>
                    <TableCell
                      className="flex justify-end gap-2"
                      hidden={policyId !== 1}
                    >
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => {
                          kickUserModal.openModal({
                            companyId: user.companyId!,
                            userId: user.id,
                            firstname: user.firstname || undefined,
                            lastname: user.lastname || undefined,
                          });
                        }}
                      >
                        <Trash className="w-4 h-4" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        />
      </ScrollArea>
    </TabLayoutContent>
  );
}
