import dayjs, { ConfigType as _ConfigType, Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import Utc from "dayjs/plugin/utc";
import "dayjs/locale/fr";
import Timezone from "dayjs/plugin/timezone";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { cache } from "./cache";

dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(Utc);
dayjs.extend(Timezone);
dayjs.extend(CustomParseFormat);
dayjs.extend(LocalizedFormat);
dayjs.locale("fr");

export default dayjs;
export type ConfigType = _ConfigType;

export function displayDates(...dates: Parameters<typeof dayjs>[0][]) {
  if (dates.every((d) => dayjs(d).isSame(dates[0], "day")))
    return dayjs(dates[0]).format("D MMM YYYY");
  return dates.map((d) => dayjs(d).format("D MMM YYYY")).join(" - ");
}

export function shouldShowYear(date: _ConfigType, rangeDate?: _ConfigType) {
  const now = dayjs();
  if (!now.isSame(date, "year")) return true;
  if (!rangeDate) return false;
  return (
    !dayjs(rangeDate).isSame(date, "year") || !now.isSame(rangeDate, "year")
  );
}

export function formatDate(_date: _ConfigType, forceYear = false) {
  const date = dayjs(_date);
  return Intl.DateTimeFormat(undefined, {
    localeMatcher: "best fit",
    month: "short",
    day: "numeric",
    year: shouldShowYear(date) || forceYear ? "numeric" : undefined,
  }).format(date.toDate());
}
export function formatDateTime(_date: _ConfigType, forceYear = false) {
  const date = dayjs(_date);
  return Intl.DateTimeFormat(undefined, {
    localeMatcher: "best fit",
    month: "short",
    day: "numeric",
    hour: "numeric",
    hour12: false,
    minute: "numeric",
    year: shouldShowYear(date) || forceYear ? "numeric" : undefined,
  }).format(date.toDate());
}

export function formatRanges(_start: _ConfigType, _end: _ConfigType) {
  const start = dayjs(_start);
  const end = dayjs(_end);
  const isSameDay = start.isSame(end, "date");
  return Intl.DateTimeFormat(undefined, {
    localeMatcher: "best fit",
    month: "short",
    day: "numeric",
    hour: isSameDay ? "numeric" : undefined,
    minute: isSameDay ? "numeric" : undefined,
    hour12: false,
    year: shouldShowYear(start, end) ? "numeric" : undefined,
  }).formatRange(start.toDate(), end.toDate());
}
export const formatRangesAsync = cache(function formatRangesAsync(
  _start: _ConfigType,
  _end: _ConfigType,
  _?: AbortSignal,
) {
  return formatRanges(_start, _end);
}, "1 hour");

export function parseDate<T extends _ConfigType>(
  _value: unknown,
  defaultValue?: T,
) {
  const value = dayjs(_value as _ConfigType);
  return (
    value.isValid()
      ? value
      : defaultValue === undefined
        ? undefined
        : dayjs(defaultValue)
  ) as T extends undefined ? Dayjs | undefined : Dayjs;
}
