import { TabLayoutBreadcrumb, TabLayoutContent } from "@/components/tab-layout";
import * as React from "react";
import { Form, useRouteLoaderData } from "react-router-dom";
import { Input } from "@/components/ui/input";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { ISettingsLoaderData } from "../loader";
import Awaited from "@/components/awaited";
import { useRevalidateCache } from "@/hooks/use-invalidate";
import { getCurrentUser } from "@/api/authentication";
import FormState from "@/components/form-state";

export interface IUserSettingsPageProps {}

export default function UserSettingsPage(props: IUserSettingsPageProps) {
  useRevalidateCache(getCurrentUser);
  const settingsData = useRouteLoaderData(
    "settings-loader",
  ) as ISettingsLoaderData;
  return (
    <TabLayoutContent>
      <TabLayoutBreadcrumb>Mon compte</TabLayoutBreadcrumb>
      <Card className="max-w-2xl">
        <CardHeader>
          <CardTitle>Mes informations</CardTitle>
        </CardHeader>
        <CardContent className="pb-6 border-b border-border">
          <Awaited
            resolve={settingsData.session}
            render={({ user }) => (
              <Form
                method="PUT"
                action="/app/settings/user?index"
                id="infos"
                className="space-y-6"
              >
                <div className="grid grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <Label>Nom</Label>
                    <Input name="lastname" defaultValue={user.lastname ?? ""} />
                  </div>
                  <div className="space-y-2">
                    <Label>Prénom</Label>
                    <Input
                      name="firstname"
                      defaultValue={user.firstname ?? ""}
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <Label>Adresse email</Label>
                  <Input
                    type="email"
                    disabled
                    defaultValue={user.email ?? ""}
                  />
                </div>
                {/* <div className="space-y-2">
            <Label>Role</Label>
            <Input type="text" value={user.email ?? ""} />
          </div> */}
                <FormState data={{ userId: user.id }} />
              </Form>
            )}
          />
        </CardContent>
        <CardFooter className="py-4">
          <Button className="ml-auto" form="infos">
            Modifier
          </Button>
        </CardFooter>
      </Card>
    </TabLayoutContent>
  );
}
