import * as React from "react";

export interface IComposeProps {
  providers: React.FC<{ children: React.ReactNode | React.ReactNode[] }>[];
  children: React.ReactNode | React.ReactNode[];
}

export default function Compose({ providers, children }: IComposeProps) {
  return (
    <>
      {providers.reduceRight((acc, Provider) => {
        return <Provider>{acc}</Provider>;
      }, children)}
    </>
  );
}
