import { wait } from "@/lib/promise";
import { API_BASE, logErrorResponse } from "./common";
import { SessionSchema } from "./definitions";
import { CACHE, cache } from "@/lib/cache";
import * as Sentry from "@sentry/react";
import { toast } from "sonner";
import { withToast } from "@/lib/with-toast";
import { getCompanyUsers } from "./companies";

export const getCurrentUser = cache(async function getCurrentUser(
  signal?: AbortSignal,
) {
  try {
    const response = await fetch(`${API_BASE}/authorization/me`, {
      credentials: "include",
      signal,
    });
    if (!response.ok) return null;
    const data = await response.json();
    const session = SessionSchema.parse(data);
    try {
      const user = session.user;
      Sentry.getCurrentScope()
        .setUser({
          ip: "{{auto}}",
          id: user.id,
          email: user.email,
          username: `${user.firstname} ${user.lastname}`,
          company: session.company?.name,
        })
        .setTag("email", user.email);
    } catch (e) {}
    return session;
  } catch (e) {
    return null;
  }
}, "1 hour");

export async function login(
  email: string,
  password: string,
  signal?: AbortSignal,
) {
  const response = await fetch(`${API_BASE}/authorization/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email.toLowerCase(), password }),
    credentials: "include",
    signal,
  });
  if (response.ok) {
    const data = await response.json();
    return SessionSchema.parse(data);
  }
  await wait(3_000);
  throw new LoginError(
    email,
    response.status === 401
      ? LoginErrorType.WRONG_PASSWORD
      : LoginErrorType.NOT_FOUND,
  );
}
export async function register(
  email: string,
  password: string,
  firstname: string,
  lastname: string,
  signal?: AbortSignal,
) {
  const response = await fetch(`${API_BASE}/authorization/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email.toLowerCase(),
      password,
      firstname,
      lastname,
    }),
    credentials: "include",
    signal,
  });
  if (response.ok) {
    const data = await response.json();
    return SessionSchema.parse(data);
  }
  await wait(3_000);
  throw new RegisterError(
    email,
    firstname,
    lastname,
    response.status === 409
      ? RegisterErrorType.ALREADY_EXISTS
      : RegisterErrorType.UNEXPECTED,
  );
}

export async function forgotPassword(email: string, signal?: AbortSignal) {
  const response = await fetch(`${API_BASE}/authorization/forgot`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email.toLowerCase() }),
    credentials: "include",
    signal,
  });
  if (response.ok) {
    toast.success(
      "Un email de réinitialisation de mot de passe vous a été envoyé.",
    );
    return true;
  }
  if (response.status === 404) {
    toast.error("Aucun compte n'a été trouvé avec cette adresse email.");
    // throw new Error("Aucun compte n'est associé à cette adresse email.");
  } else {
    toast.error("Une erreur est survenue. Veuillez réessayer plus tard.");
  }
  // throw new Error("Une erreur est survenue. Veuillez réessayer plus tard.");
  return false;
}

export async function logout(signal?: AbortSignal) {
  const response = await fetch(`${API_BASE}/authorization/logout`, {
    method: "DELETE",
    credentials: "include",
    signal,
  });
  Sentry.getCurrentScope().setUser(null);
  CACHE.invalidateAll();
  return response.ok;
}

export async function impersonateUser(userId: number, signal?: AbortSignal) {
  return withToast(
    async () => {
      const response = await fetch(`${API_BASE}/authorization/me`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: userId }),
        credentials: "include",
        signal,
      });
      if (!response.ok) throw await logErrorResponse(response);
      const data = await response.json();
      CACHE.invalidateAll();
      open("/app", "_self");
      return SessionSchema.parse(data);
    },
    {
      loading: "Opération en cours",
      success: "Opération réussie",
      error: "Une erreur inattendue s'est produite.",
    },
  );
}
export async function impersonateCompany(
  companyId: number,
  signal?: AbortSignal,
) {
  return withToast(
    async () => {
      const companyUsers = await getCompanyUsers(companyId, signal);
      const user = companyUsers[0];
      if (!user) throw new Error("no user in comany");
      return impersonateUser(user.id);
    },
    {
      loading: "Opération en cours",
      success: "Opération réussie",
      error: "Une erreur inattendue s'est produite.",
    },
  );
}

export enum LoginErrorType {
  NOT_FOUND,
  WRONG_PASSWORD,
}
export enum RegisterErrorType {
  ALREADY_EXISTS,
  UNEXPECTED,
}
export const messageForLoginErrorType: Record<LoginErrorType, string> = {
  [LoginErrorType.NOT_FOUND]: "Adresse email non reconnue.",
  [LoginErrorType.WRONG_PASSWORD]: "Mot de passe incorrect.",
};
export class LoginError extends Error {
  constructor(
    public email: string,
    public type: LoginErrorType,
  ) {
    super("Login failed");
  }
  toObject() {
    return { email: this.email, error: this.type };
  }
}
export class RegisterError extends Error {
  constructor(
    public email: string,
    public firstname: string,
    public lastname: string,
    public type: RegisterErrorType,
  ) {
    super("Login failed");
  }
  toObject() {
    return {
      email: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      error: this.type,
    };
  }
}
