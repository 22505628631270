import {
  EventDatesDTO,
  FullAdvertisementDTO,
  FullEventAdvertisementDTO,
} from "@/api/definitions";
import { ColumnsState } from "@/hooks/useColumns";
import { TableCell } from "@/components/ui/table";
import dayjs, { formatRangesAsync } from "@/lib/date";
import { formatDateTime, formatRanges } from "@/lib/date";
import { CacheComponent } from "@/components/awaited";
import { AdvertisementsTableColumnName } from "@/pages/app/advertisements/(blocks)/columns";
import { SchedulesTableColumnName } from "@/pages/app/widgets/[id]/schedules/(blocks)/columns";
import { CatalogTableColumnName } from "@/pages/app/catalog/(blocks)/columns";

export interface IAdvertisementDatesCellProps {
  dates?: EventDatesDTO[] | undefined | null;
  columnState:
    | (
        | ColumnsState<AdvertisementsTableColumnName>
        | ColumnsState<SchedulesTableColumnName>
        | ColumnsState<CatalogTableColumnName>
      )["dates"]
    | undefined;
}

export default function AdvertisementDatesCell({
  dates,
  columnState,
}: IAdvertisementDatesCellProps) {
  if (!dates) return null;

  const range = getDatesRange(dates);
  if (dates.length < 2)
    return (
      <TableCell hidden={!columnState?.visible} className="whitespace-pre">
        <CacheComponent
          factory={() => formatRangesAsync(range.from, range.to)}
        />
      </TableCell>
    );
  const nextDate = getNextDate(dates);
  return (
    <TableCell
      hidden={!columnState?.visible}
      title={dates.map((d) => formatRanges(d.from, d.to)).join("\n")}
      className="whitespace-pre"
    >
      <CacheComponent factory={() => formatRangesAsync(range.from, range.to)} />{" "}
      (+
      {getNextDates(dates).length - 1})
      <br />
      <span className="text-xs text-muted-foreground">
        next: {formatDateTime(nextDate.from)}
      </span>
    </TableCell>
  );
}

function getNextDate(dates: EventDatesDTO[]) {
  const now = dayjs();
  return dates.reduce((acc, cur) => {
    if (dayjs(cur.from).isBefore(now)) return acc;
    if (dayjs(acc.from).isBefore(now)) return cur;
    if (dayjs(cur.from).isBefore(acc.from)) return cur;
    return acc;
  });
}
function getNextDates(dates: EventDatesDTO[]) {
  const now = dayjs();
  return dates.filter((d) => dayjs(d.to).isAfter(now));
}
function getDatesRange(dates: EventDatesDTO[]) {
  return dates.reduce((acc, cur) => {
    if (dayjs(cur.from).isBefore(acc.from)) acc.from = cur.from;
    if (dayjs(cur.to).isAfter(acc.to)) acc.to = cur.to;
    return acc;
  });
}
