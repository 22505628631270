import * as React from "react";
import { Route } from "react-router-dom";
import { TeamSettingsLoader } from "./loader";
import TeamSettingsPage from "./page";
import { TeamSettingsAction } from "./action";

export interface ITeamSettingsRouterProps {}

export default function TeamSettingsRouter(props: ITeamSettingsRouterProps) {
  return (
    <Route>
      <Route
        index
        loader={TeamSettingsLoader}
        action={TeamSettingsAction}
        Component={TeamSettingsPage}
      />
    </Route>
  );
}
