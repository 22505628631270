import { CardDescription, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { usePersist } from "@/hooks/use-persist";
import { entries } from "@/lib/entries";
import { cn } from "@/lib/utils";
import * as React from "react";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";
import { IAppDesignAuthGuardLoaderData } from "./auth-guard";
import { PlanUpgradeError } from "@/api/authorization";
import { useLimitReachedModal } from "@/modals/limit-reached";
import { useAbortEffect } from "@/hooks/use-abort-effect";

export interface IDesignLayoutProps {}

export default function DesignLayout(props: IDesignLayoutProps) {
  const { authorization } = useLoaderData() as IAppDesignAuthGuardLoaderData;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [scale, setScale] = React.useState(1);
  const { get, watch } = usePersist<number>("design_preview_width");
  const [desiredWidth, setDesiredWidth] = React.useState(get(375));
  watch(desiredWidth);
  React.useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    container.style.setProperty("--tnj-max-width", desiredWidth + "px");
    const handleResize = () => {
      const containerWidth = container.offsetWidth - 20;
      if (containerWidth < desiredWidth) {
        const newScale = containerWidth / desiredWidth;
        setScale(newScale);
      } else {
        setScale(1); // Reset to normal scale if enough space is available
      }
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize); // Recheck on window resize

    return () => window.removeEventListener("resize", handleResize);
  }, [desiredWidth]);
  const limitReached = useLimitReachedModal();
  const navigate = useNavigate();
  useAbortEffect(
    (signal) => {
      if (authorization === true) return;
      if (authorization instanceof PlanUpgradeError) {
        limitReached.onClose(() => navigate(-1), { signal });
        limitReached.openModal({ error: authorization });
      }
    },
    [authorization],
  );
  if (authorization !== true) return null;
  return (
    <section
      className="h-screen w-screen flex flex-col justify-stretch overflow-y-auto"
      ref={containerRef}
    >
      <div
        style={{
          width: desiredWidth,
          zoom: scale, // Apply the zoom based on available space
          transition: "zoom 0.2s ease-in-out", // Smooth transition for zoom
        }}
        className={cn(
          "relative bg-card overflow-scroll pb-20 border border-border shadow-lg w-full h-max max-w-screen-xl mx-auto rounded-xl",
          "my-32",
          desiredWidth < 1024 && "my-24",
          desiredWidth < 450 && "my-20",
        )}
      >
        <div className="z-20 bg-neutral-100 w-full pl-4 pr-1.5 py-1.5 flex items-center gap-4 sticky top-0">
          <div className="bg-neutral-200 rounded-full h-3.5 w-12" />
          <div className="bg-neutral-200 rounded-sm h-5 w-1/2" />
          <div className="filler" />
          <Select
            onValueChange={(v) => setDesiredWidth(+v)}
            value={desiredWidth + ""}
          >
            <SelectTrigger className="w-[100px]">
              <SelectValue className="w-[100px]" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {entries({ mobile: 375, tablet: 768, desktop: 1440 }).map(
                  ([key, value]) => (
                    <SelectItem key={value} value={value + ""}>
                      {key}
                    </SelectItem>
                  ),
                )}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div
          className={cn("pt-10 px-20 space-y-3", desiredWidth < 450 && "px-4")}
        >
          <h1 className="text-xl font-bold leading-none tracking-tight max-w-xs">
            Personnalisez votre widget aux couleurs de votre site
          </h1>
          <CardDescription className="max-w-md">
            Utilisez l'IA pour modifier l'apparence de votre widget, des textes
            aux arrondis, en passant par les couleurs, police et ombres.
          </CardDescription>
        </div>
        <tnj-widget
          id="demo-widget"
          data-selection="Demo-widget"
          data-app_secret="ZuckX6hmdPGkWmIgPVztY"
        />
        <div
          className={cn("pt-10 px-20 space-y-3", desiredWidth < 450 && "px-4")}
        >
          <h1 className="text-xl font-bold leading-none tracking-tight">
            Comment parler à l'IA ?
          </h1>
          <h4 className="mt-3 text-muted-foreground">
            Exprimez-vous normalement. Exemple:
          </h4>
          <ul className="list-disc list-inside">
            <li>Mets le titre en rouge</li>
            <li>Le fond des cartouches en #a2FF43</li>
            <li>Enlève les arrondi et l'ombre</li>
            <li>Titre #545400 texte #e2e2e2 pas d'arrondi</li>
          </ul>
        </div>
      </div>
      <div />
      <div className="z-50 absolute inset-0 p-4 pointer-events-none">
        <Outlet />
      </div>
    </section>
  );
}
