import { UpdateWidgetSchema } from "@/api/definitions";
import { deleteWidget, refreshWidget, updateWidget } from "@/api/widgets";
import { objectFromFormData } from "@/lib/query";
import { withToast } from "@/lib/with-toast";
import { ActionFunctionArgs, redirect } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

export interface IWidgetConfigActionData {}
export async function WidgetConfigAction({
  request,
}: ActionFunctionArgs): Promise<IWidgetConfigActionData> {
  const body = objectFromFormData(await request.formData());
  const { widgetId } = z.object({ widgetId: z.string() }).parse(body);
  if (request.method === "PUT") {
    return (await withToast(() => {
      const payload = UpdateWidgetSchema.parse(body);
      return updateWidget(widgetId, payload);
    }))!;
  } else if (request.method === "POST") {
    await refreshWidget(widgetId).catch(() => {});
    toast.success("Actualisation en cours...");
  } else if (request.method === "DELETE") {
    const result = await withToast(() => deleteWidget(widgetId));
    if (result) throw redirect("/app/widgets");
  }
  return {};
}
