import * as React from "react";
import dayjs, { ConfigType, displayDates } from "@/lib/date";
import { Checkbox } from "./ui/checkbox";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import SearchSelect from "./search-select";
import { useCalendarParams } from "@/hooks/use-calendar-params";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Button } from "./ui/button";
import { Calendar } from "./ui/calendar";
import { cn } from "@/lib/utils";
import { withId } from "@/lib/array";
import { formatBigNumbers } from "@/lib/format";
import { Switch } from "./ui/switch";
import { CheckIcon, RotateCw, TrashIcon, X } from "lucide-react";
import { useOptimistic } from "@/hooks/use-optimistic";
import { AdvertisementBuilderSchedulesSchema } from "@/pages/app/advertisements/new/[type]/(blocks)/schemas";
import * as z from "@/lib/zod";
import { WidgetsDTO } from "@/api/definitions";
import deepEqual from "deep-equal";

export type NewScheduleItem = z.infer<
  typeof AdvertisementBuilderSchedulesSchema
>["schedules"][number];

export interface IScheduleFormItemProps {
  isOpen?: boolean;
  data: NewScheduleItem;
  widgets: WidgetsDTO[];
  setData(data: NewScheduleItem): void;
  remove?(): void;
  maxDate?: ConfigType;
  setOpen?(newOpen: boolean): void;
  variant?: "default" | "sm";
  className?: string;
}

export default function ScheduleFormItem({
  variant = "default",
  data,
  maxDate,
  ...props
}: IScheduleFormItemProps) {
  const savedData = React.useRef(data);
  const dates: [Date, Date] = [data.from, data.to];
  const [calendarParams, setCalendarParams] = useCalendarParams(
    dates,
    ([from, to]) =>
      props.setData({
        ...data,
        from: dayjs(from).startOf("day").toDate(),
        to: dayjs(to).endOf("day").toDate(),
      }),
  );
  const widgetName =
    props.widgets.find(withId(data.widgetId))?.name ?? "Pas de widget";
  const [open, setOpen] = useOptimistic(
    "isOpen" in props ? !!props.isOpen : true,
    props.setOpen,
  );
  React.useEffect(() => {
    if (open) return;
    savedData.current = data;
  }, [open]);

  return (
    <div
      className={cn(
        "enter animation-small animation-fast bg-cardw-full shadow-inner rounded-md border border-border p-1",
        props.className,
      )}
    >
      <div
        onClick={(e) => {
          if ((e.target as HTMLElement).isEqualNode(e.currentTarget))
            setOpen(!open);
        }}
        className={cn(
          "cursor-pointer border border-transparent hover:bg-muted rounded-sm p-3 flex gap-4 items-start text-sm font-medium",
          open && "border border-border",
          variant === "sm" && "flex-wrap",
        )}
      >
        <div
          className={cn(
            "flex flex-col justify-between grow gap-2 pointer-events-none",
            variant === "sm" && "w-full shrink-0",
          )}
        >
          <span className="text-xs text-muted-foreground">Widget</span>
          {widgetName}
        </div>
        <div className="flex flex-col justify-between shrink-0 gap-2 pointer-events-none">
          <span className="text-xs text-muted-foreground">Dates</span>
          {displayDates(data.from, data.to)}
        </div>
        <div className="flex gap-4 items-start pointer-events-none">
          <div
            className="flex flex-col justify-between shrink-0 gap-2 pointer-events-none"
            title="Impressions / Clics"
          >
            <span className="text-xs text-muted-foreground">Performance</span>
            {data.impression_goal
              ? formatBigNumbers(data.impression_goal)
              : "-"}{" "}
            / {data.clic_goal ? formatBigNumbers(data.clic_goal) : "-"}
          </div>
          <div className="flex flex-col justify-between shrink-0 gap-2 pointer-events-none">
            <span className="text-xs text-muted-foreground">Sponsorisé</span>
            <Switch
              className="pointer-events-auto"
              checked={data.sponsored}
              onCheckedChange={(newSponsored) =>
                props.setData({ ...data, sponsored: newSponsored })
              }
            />
          </div>
        </div>
      </div>
      <div
        className={cn(
          "flex flex-col gap-4 box-border justify-start transition-all dutation-400 px-3",
          open
            ? variant === "sm"
              ? "max-h-[264px]"
              : "max-h-[204px]"
            : "max-h-[0px] overflow-hidden",
        )}
      >
        <div className="grid gap-x-4 gap-y-6 grid-cols-1 xs:grid-cols-2 mt-4 pt-3 pb-2">
          <div
            className={cn("relative group", variant === "sm" && "col-span-2")}
          >
            <Label className="absolute rounded-sm -top-2 left-4 bg-card group-hover:-translate-y-2 group-focus-within:-translate-y-2 transition-transform duration-100 px-1 text-xs font-medium pointer-events-none">
              Widget *
            </Label>
            <SearchSelect
              options={props.widgets.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              selected={data.widgetId}
              onSelect={(id) => props.setData({ ...data, widgetId: id })}
              className="w-full"
            />
          </div>
          <div
            className={cn("relative group", variant === "sm" && "col-span-2")}
          >
            <Label className="absolute rounded-sm -top-2 left-4 bg-card group-hover:-translate-y-2 group-focus-within:-translate-y-2 transition-transform duration-100 px-1 text-xs font-medium pointer-events-none">
              Dates *
            </Label>
            <Dialog>
              <DialogTrigger asChild>
                <Button variant="outline" className="w-full justify-start">
                  {displayDates(...dates)}
                </Button>
              </DialogTrigger>
              <DialogContent className="max-w-[unset] w-fit bg-card">
                <DialogTitle hidden>Calendar</DialogTitle>
                <DialogDescription hidden>
                  Select dates from the calendar
                </DialogDescription>
                <Calendar
                  initialFocus
                  mode="range"
                  disabled={
                    maxDate
                      ? [
                          { after: dayjs(maxDate).toDate() },
                          { before: new Date() },
                        ]
                      : [{ before: new Date() }]
                  }
                  numberOfMonths={2}
                  selected={calendarParams}
                  onSelect={setCalendarParams}
                  defaultMonth={new Date()}
                />
                <div className="flex w-full justify-end gap-4">
                  <DialogClose asChild>
                    <Button>Confirmer</Button>
                  </DialogClose>
                </div>
              </DialogContent>
            </Dialog>
          </div>

          <div className="relative group">
            <Input
              className="w-full bg-transparent hover:border-accent focus-visible:border-accent focus-visible:ring-transparent focus-visible:ring-offset-transparent focus-visible:ring-0"
              type="number"
              value={data.impression_goal || undefined}
              placeholder="0"
              step={100_000}
              onChange={(e) =>
                props.setData({ ...data, impression_goal: +e.target.value })
              }
            />
            <Label className="absolute rounded-sm -top-2 left-4 bg-card group-hover:-translate-y-2 group-focus-within:-translate-y-2 transition-transform duration-100 px-1 text-xs font-medium pointer-events-none">
              Impressions
            </Label>
          </div>
          <div className="relative group">
            <Input
              className="w-full bg-transparent hover:border-accent focus-visible:border-accent focus-visible:ring-transparent focus-visible:ring-offset-transparent focus-visible:ring-0"
              type="number"
              value={data.clic_goal || undefined}
              placeholder="0"
              step={10_000}
              onChange={(e) =>
                props.setData({ ...data, clic_goal: +e.target.value })
              }
            />
            <Label className="absolute rounded-sm -top-2 left-4 bg-card group-hover:-translate-y-2 group-focus-within:-translate-y-2 transition-transform duration-100 px-1 text-xs font-medium pointer-events-none">
              Clics
            </Label>
          </div>
        </div>
        <div className="w-full col-span-2 flex justify-end items-center gap-4  pb-3">
          {props.remove ? (
            <Button
              variant="destructive"
              size="sm"
              onClick={props.remove}
              title="Supprimer"
            >
              {variant === "sm" ? (
                <TrashIcon className="w-4 h-4" />
              ) : (
                "Supprimer"
              )}
            </Button>
          ) : null}
          <div className="filler" />
          {deepEqual(data, savedData.current) ? null : (
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setOpen(false)}
              title="Annuler"
            >
              {variant === "sm" ? <RotateCw className="w-4 h-4" /> : "Annuler"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
