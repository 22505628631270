import * as React from "react";
import { Navigate, Route } from "react-router-dom";
import SettingsLayout from "./layout";
import TeamSettingsRouter from "./team";
import UserSettingsRouter from "./user";
import { SettingsLoader } from "./loader";
import SettingsSitesRouter from "./sites";
import ApiSettingsRouter from "./api";
import SubscriptionSettingsRouter from "./subscription";

export interface ISettingsRouterProps {}

export default function SettingsRouter(props: ISettingsRouterProps) {
  return (
    <Route
      id="settings-loader"
      Component={SettingsLayout}
      loader={SettingsLoader}
    >
      <Route index element={<Navigate to="user" replace />} />
      <Route path="user">{UserSettingsRouter({})}</Route>
      <Route path="team">{TeamSettingsRouter({})}</Route>
      <Route path="sites">{SettingsSitesRouter({})}</Route>
      <Route path="api">{ApiSettingsRouter({})}</Route>
      <Route path="subscription">{SubscriptionSettingsRouter({})}</Route>
      <Route path="links" />
    </Route>
  );
}
