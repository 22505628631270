import { AdvertisementDraftSchema } from "@/api/definitions";
import * as z from "@/lib/zod";

export const AdvertisementBuilderDetailsSchema = z.object({
  title: z
    .string({
      errorMap: () => ({ message: `Le titre doit être du texte.` }),
    })
    .trim()
    .min(2, `Le titre doit faire au moins 2 caractères`)
    .max(128, `Le titre doit faire moins de 128 caractères`),
  description: z
    .string()
    .max(4096, `La description doit faire moins de 4096 caractères`)
    .nullish()
    .optional(),
  url: z.string().url(`L'url doit être valide.`),
});

export const AdvertisementBuilderLocationSchema = z.object({
  address: z.string({
    errorMap: () => ({ message: `L'adresse doit être du texte.` }),
  }),
  city: z.string().nullish().optional(),
  location_name: z.string({
    errorMap: () => ({ message: `Le lieu doit être du texte.` }),
  }),
  longitude: z.number(),
  latitude: z.number(),
});

export const AdvertisementBuilderDatesSchema = z.object({
  dates: z
    .object({
      from: z.dateString(),
      to: z.dateString(),
    })
    .array()
    .min(1),
});

export const AdvertisementBuilderCategoriesSchema = z.object({
  categories: z.number().array().min(1),
});

export const AdvertisementBuilderRealEstateSchema = z.object({
  surface: z.coerce.string(),
  price: z.coerce.string(),
  rooms: z.coerce.string(),
});
export const AdvertisementBuilderJobSchema = z.object({
  company_name: z
    .string({
      errorMap: () => ({ message: `Vous devez renseigner l'employeur.` }),
    })
    .trim()
    .min(3, `Le nom de l'employeur ne peut pas faire moins de 3 caractères`),
});

export const AdvertisementBuilderImagesSchema = z.object({
  image: z.string().url(),
});

export const AdvertisementBuilderSchedulesSchema = z.object({
  schedules: z
    .object({
      widgetId: z.string(),
      from: z.dateString(),
      to: z.dateString(),
      impression_goal: z.coerce.number().nullish().optional(),
      clic_goal: z.coerce.number().nullish().optional(),
      sponsored: z.bool(),
    })
    .array(),
});
