import * as React from "react";

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea, ScrollBar } from "./ui/scroll-area";
import { useDocuments, useSearch } from "@/hooks/use-search";
import { Input } from "./ui/input";
import { withId } from "@/lib/array";
import { normalize } from "@/lib/format";
import { useOptimistic } from "@/hooks/use-optimistic";

export interface ICheckboxDropdownItem<T extends string> {
  value: T;
  label: string;
  children?: ICheckboxDropdownItem<T>[];
}

export interface ICheckboxDropdownProps<T extends string> {
  items: ICheckboxDropdownItem<T>[];
  values: Record<T, boolean>;
  onChange: (value: T, checked: boolean, state: Record<T, boolean>) => void;
  children: React.ReactNode;
  searchEnabled?: boolean;
  name?: string;
}

export default function CheckboxDropdown<T extends string>({
  items,
  values,
  onChange,
  children,
  searchEnabled = false,
  name,
  ...props
}: ICheckboxDropdownProps<T>) {
  const documents = useDocuments(items.flatMap(flatOptions), (i) => ({
    id: i.value,
    label: normalize(i.label),
    value: i.value,
  }));
  const minisearch = useSearch(documents, {
    fields: ["id", "label"],
    storeFields: ["id"],
  });
  const [q, setQ] = React.useState("");
  const [search, setSearch] = useOptimistic(q, (v) => setQ(v), 300);
  const displayedItems = React.useMemo(() => {
    const results = minisearch(normalize(q));
    if (!results) return items;
    if (!results[0]) return [];

    const newTree = filterTree(
      { value: "" as T, label: "", children: items },
      (item) => results!.find(withId(item.value)) ?? null,
    );
    return newTree?.children || items;
  }, [q]);
  return (
    <DropdownMenu onOpenChange={() => setSearch("")}>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-56"
        onCloseAutoFocus={(e) => e.preventDefault()}
        onFocusOutside={(e) => e.preventDefault()}
      >
        {searchEnabled ? (
          <Input
            type="search"
            value={search}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            autoFocus
            className="bg-card pl-8"
            placeholder="Rechercher..."
          />
        ) : null}
        <ScrollArea className="overflow-y-auto w-full max-h-[50vh]">
          <div>
            {displayedItems.map((item) => (
              <OptionsGroup
                key={item.value}
                {...item}
                values={values}
                onChange={onChange}
              />
            ))}
          </div>
          <ScrollBar orientation="vertical" />
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function OptionsGroup<T extends string>(
  props: ICheckboxDropdownItem<T> &
    Omit<ICheckboxDropdownProps<T>, "items" | "searchEnabled" | "children"> & {
      // setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    },
) {
  const {
    value,
    label,
    children,

    values,
    onChange,
    // setOpen,
  } = props;
  const endOptions = getDeepestOptions({ value, label, children });
  const allChidrenChecked = endOptions.every((child) => values[child.value]);
  return (
    <DropdownMenuGroup>
      {children?.length ? (
        <DropdownMenuLabel
          onClick={() => {
            let newValues = { ...values };
            endOptions.forEach((opt) => {
              newValues[opt.value] = !allChidrenChecked;
            });
            onChange(endOptions[0].value, !allChidrenChecked, newValues);
          }}
        >
          {label}
        </DropdownMenuLabel>
      ) : (
        <DropdownMenuCheckboxItem
          checked={values[value]}
          tabIndex={1}
          onCheckedChange={(checked) =>
            onChange(value, checked, { ...values, [value]: checked })
          }
        >
          {label}
        </DropdownMenuCheckboxItem>
      )}
      {children?.length
        ? children.map((child) => (
            <OptionsGroup key={child.value} {...props} {...child} />
          ))
        : null}
    </DropdownMenuGroup>
  );
}

function getDeepestOptions<T extends string>({
  children,
  ...option
}: ICheckboxDropdownItem<T>): ICheckboxDropdownItem<T>[] {
  return children?.length ? children.flatMap(getDeepestOptions) : [option];
}
function flatOptions<T extends string>({
  children,
  ...option
}: ICheckboxDropdownItem<T>): ICheckboxDropdownItem<T>[] {
  return [option, ...(children ?? []).map(flatOptions).flat()];
}

function filterTree<T extends string>(
  item: ICheckboxDropdownItem<T>,
  predicate: (item: ICheckboxDropdownItem<T>) => { score: number } | null,
): (ICheckboxDropdownItem<T> & { score: number }) | null {
  const match = predicate(item);
  const matchingChildren =
    item.children
      ?.map((i) => filterTree(i, predicate))
      .filter(Boolean)
      .sort((a, b) => b!.score - a!.score) ?? [];
  if (matchingChildren[0])
    return {
      ...item,
      score: (match?.score ?? 0) + matchingChildren[0].score,
      children: matchingChildren as ICheckboxDropdownItem<T>[],
    };
  if (match) return { ...item, score: match.score };
  return null;
}
