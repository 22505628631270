import * as React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import OnboardingCompanyRouter from "./company";
import { OnboardingLoader } from "./loader";

export interface IOnboardingRouterProps {}

export default function OnboardingRouter(props: IOnboardingRouterProps) {
  return (
    <Route loader={OnboardingLoader}>
      <Route index element={<Navigate to="company" />} />
      <Route path="company">{OnboardingCompanyRouter({})}</Route>
    </Route>
  );
}
