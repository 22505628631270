import { RegisterError } from "@/api/authentication";
import { Navigate, useRouteError } from "react-router-dom";

export function RegisterErrorPage() {
  const error = useRouteError();
  console.error(error);
  if (error instanceof RegisterError) {
    return <Navigate to="." state={error.toObject()} />;
  }
  return (
    <div id="error-page">
      <h1>Oops...!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
    </div>
  );
}
