import { ActionFunctionArgs } from "react-router-dom";

export interface ISubscriptionSettingsActionData {}
export async function SubscriptionSettingsAction({
  request,
}: ActionFunctionArgs): Promise<ISubscriptionSettingsActionData> {
  if (request.method === "POST") {
  }
  return {};
}
