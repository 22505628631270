import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import * as React from "react";
import { useAsyncValue, useFetcher } from "react-router-dom";
import { ISingleAdvertisementLoaderData } from "../../loader";
import { toast } from "sonner";
import { Map, Marker } from "@vis.gl/react-google-maps";
import { useAutoComplete } from "@/hooks/use-auto-complete";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import FormState from "@/components/form-state";
import { pick } from "@/lib/pick";
import deepEqual from "deep-equal";
import { cn } from "@/lib/utils";

export interface IAdvertisementLocationCardProps {}

export default function AdvertisementLocationCard(
  props: IAdvertisementLocationCardProps,
) {
  const advertisement = useAsyncValue() as Awaited<
    ISingleAdvertisementLoaderData["advertisement"]
  >;
  const advertisementData = React.useMemo(
    () => ({
      ...pick(
        advertisement.coordinates,
        "address",
        "longitude",
        "latitude",
        "city",
      ),
      location_name: advertisement.location_name,
    }),
    [advertisement],
  );
  const [data, setData] = React.useState(advertisementData);
  React.useEffect(() => {
    if (deepEqual(data, advertisementData)) return;
    setData(advertisementData);
  }, [advertisementData]);
  const isTouched = !deepEqual(data, advertisementData);
  const fetcher = useFetcher();
  const ref = useAutoComplete({
    onPlaceSelect(results) {
      if (!results.geometry) {
        return toast.error("Veuillez sélectionner une adresse valide");
      }
      setData({
        location_name:
          results.name ||
          results.formatted_address?.split(",")[0] ||
          data.location_name ||
          advertisement.location_name,
        address: results.formatted_address || data.address,
        latitude: results.geometry.location?.lat() || data.latitude,
        longitude: results.geometry.location?.lng() || data.longitude,
        city: results.vicinity || data.city,
      });
    },
    types: ["geocode", "establishment"],
    fields: ["formatted_address", "geometry", "name", "vicinity"],
  });
  return (
    <Card className="enter">
      <CardHeader>
        <CardTitle>Localisation</CardTitle>
      </CardHeader>
      <CardContent className="pb-6 border-b border-b-border space-y-6">
        <div className="space-y-3">
          <Label>Adresse</Label>
          <Input
            disabled={!advertisement.isOwned}
            defaultValue={advertisement.coordinates.address ?? ""}
            onChange={(e) =>
              setData((d) => ({ ...d, address: e.target.value }))
            }
            ref={ref}
          />
        </div>
        <div className="space-y-3">
          <Label>Lieu</Label>
          <Input
            disabled={!advertisement.isOwned}
            onChange={(e) =>
              setData((d) => ({ ...d, location_name: e.target.value }))
            }
            value={isTouched ? data.location_name : undefined}
            defaultValue={advertisement.location_name}
          />
        </div>
        <Map
          defaultZoom={15}
          defaultCenter={{
            lat: data.latitude,
            lng: data.longitude,
          }}
          key={`${data.latitude};${data.longitude}`}
          disableDefaultUI
          className="w-full h-64 rounded-sm overflow-hidden"
        >
          <Marker
            position={{
              lat: data.latitude,
              lng: data.longitude,
            }}
          />
        </Map>
      </CardContent>
      <fetcher.Form
        method="PUT"
        action="/app/advertisements/[id]/content?index"
        className={cn(advertisement.isOwned || "hidden")}
        onSubmit={(e) => {
          if (!advertisement.isOwned) e.preventDefault();
        }}
      >
        <FormState
          data={{ ...data, id: advertisement.id, type: advertisement.type }}
        />
        <CardFooter className="flex justify-end py-4 gap-3">
          {isTouched ? (
            <Button
              type="reset"
              size="sm"
              variant="ghost"
              onClick={() => setData(advertisementData)}
            >
              Annuler
            </Button>
          ) : null}
          <Button type="submit" size="sm" disabled={!isTouched}>
            Confirmer
          </Button>
        </CardFooter>
      </fetcher.Form>
    </Card>
  );
}
AdvertisementLocationCard.Skeleton = function () {
  return (
    <Card className="opacity-50">
      <CardHeader>
        <CardTitle>Localisation</CardTitle>
      </CardHeader>
      <CardContent className="pb-6 border-b border-b-border space-y-6">
        <div className="space-y-3">
          <Label>Adresse</Label>
          <Skeleton className="w-40 h-10" />
        </div>
        <div className="space-y-3">
          <Label>Lieu</Label>
          <Skeleton className="w-full h-6" />
        </div>
        <Skeleton className="w-full h-64" />
      </CardContent>
      <CardFooter className="flex justify-end py-4">
        <Skeleton className="w-40 h-10" />
      </CardFooter>
    </Card>
  );
};
