import { rotateAllSchedules } from "@/api/schedules";
import { regenerateAllWidgets } from "@/api/widgets";
import Awaited from "@/components/awaited";
import { TabLayoutBreadcrumb, TabLayoutContent } from "@/components/tab-layout";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { withToast } from "@/lib/with-toast";
import * as React from "react";
import { useLoaderData } from "react-router-dom";
import { toast } from "sonner";
import { IAdminActionsLoaderData } from "./loader";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { runCrawler } from "@/api/crawlers";
import { capitalize } from "@/lib/format";

export interface IAdminAcionsPageProps {}

export default function AdminAcionsPage(props: IAdminAcionsPageProps) {
  const { crawlers } = useLoaderData() as IAdminActionsLoaderData;
  return (
    <TabLayoutContent>
      <TabLayoutBreadcrumb>Actions</TabLayoutBreadcrumb>
      <div className="grid grid-cols-2 gap-4">
        <Card>
          <CardHeader>
            <CardTitle>Lancer un crawler</CardTitle>
            <CardDescription>
              Récupère des annonces événementielles sur moins de 6 mois.
              <br />
              Ne pas relancer le même crawler plusieurs fois dans la journée
            </CardDescription>
          </CardHeader>
          <Awaited
            resolve={crawlers}
            render={(crawlerNames) => {
              const [selectedCrawler, selectCrawler] = React.useState(
                crawlerNames[0],
              );
              return (
                <CardFooter className="flex gap-4">
                  <Select value={selectedCrawler} onValueChange={selectCrawler}>
                    <SelectTrigger>
                      <SelectValue placeholder="Sélectionner un crawler" />
                    </SelectTrigger>
                    <SelectContent>
                      {crawlerNames.map((name) => (
                        <SelectItem key={name} value={name}>
                          {capitalize(name)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Button
                    className="shrink-0"
                    onClick={async () => {
                      await withToast(() => runCrawler(selectedCrawler), {
                        loading: "Lancement du crawler",
                        success: "Crawler démarré avec succès",
                        error: "Une erreur s'est produite",
                      });
                    }}
                  >
                    Démarrer
                  </Button>
                </CardFooter>
              );
            }}
          />
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Rotation forcée</CardTitle>
            <CardDescription>
              Forcer une rotation des annonces pour tous les widgets.
              <br />
              Prends environ 5 minutes.
            </CardDescription>
          </CardHeader>
          <CardFooter className="flex justify-end">
            <Button
              onClick={async () => {
                await withToast(rotateAllSchedules, {
                  loading: "Envoi de la commande",
                  success: "Rotation démarrée",
                  error: "Une erreur s'est produite",
                });
              }}
            >
              Démarrer
            </Button>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Actualisation forcée</CardTitle>
            <CardDescription>
              Forcer l'actualisation de tous les widgets.
              <br />
              Prends environ 1 minutes.
            </CardDescription>
          </CardHeader>
          <CardFooter className="flex justify-end">
            <Button
              onClick={async () => {
                await withToast(regenerateAllWidgets, {
                  loading: "Envoi de la commande",
                  success: "Actualisation démarrée",
                  error: "Une erreur s'est produite",
                });
              }}
            >
              Démarrer
            </Button>
          </CardFooter>
        </Card>
        <Card>
          <CardHeader>
            <CardTitle>Onboarding accéléré (WIP)</CardTitle>
            <CardDescription>
              Enregistrez un nouvel utilisateur et son entreprise.
            </CardDescription>
          </CardHeader>
          <CardFooter className="flex justify-end">
            <Button disabled>Commencer</Button>
          </CardFooter>
        </Card>
      </div>
    </TabLayoutContent>
  );
}
