import { WidgetsDTO } from "@/api/definitions";
import { getOwnedWidget, listOwnedWidgets } from "@/api/widgets";
import { withId } from "@/lib/array";
import { LoaderFunctionArgs, defer } from "react-router-dom";

export interface ISingleWidgetLoaderData {
  widget: ReturnType<typeof getOwnedWidget>;
  widgets: ReturnType<typeof listOwnedWidgets>;
}

export async function SingleWidgetLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  if (!params.widgetId) throw new Error("invalid params object");

  return defer({
    widget: getOwnedWidget(params.widgetId, request.signal),
    widgets: listOwnedWidgets(request.signal),
  } satisfies ISingleWidgetLoaderData);
}
