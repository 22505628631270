import * as React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { HeaderBreadCrumb, IHeaderBreadcrumbChunk } from "./header";
import { Button } from "./ui/button";
import { ChevronLeft } from "lucide-react";
import { getCurrentTab, TabsList } from "@/lib/tabs";
import TabSelector from "./tab-selector";
import { cn } from "@/lib/utils";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";

export interface ITabLayoutProps<T extends string, K extends T> {
  tabs: TabsList<T>;
  defaultTab: K;
  children: React.ReactElement | React.ReactElement[];
  base?: string;
}

export function TabLayout<T extends string, K extends T>({
  tabs,
  children,
  defaultTab,
  base = ".",
}: ITabLayoutProps<T, K>) {
  const navigate = useSmartNavigate();
  const { pathname } = useLocation();
  function changeTab(newTab: string) {
    navigate(`${base}/${newTab}`, {
      replace: true,
    });
  }
  const childrenArray = React.Children.toArray(children).flat(
    Infinity,
  ) as React.ReactElement[];

  const BreadcrumbSlot = childrenArray.findLast(
    (child) => child.type === TabLayoutBreadcrumb,
  );
  const TitleSlot = childrenArray.findLast(
    (child) => child.type === TabLayoutTitle,
  );
  const ContentSlot = childrenArray.filter(
    (child) => child.type === TabLayoutContent,
  );
  return (
    <div className="grid flex-1 auto-rows-max gap-4 min-h-screen">
      {BreadcrumbSlot}
      <div className="overflow-hidden pb-2 w-full h-fit sticky top-3 -mb-2 z-20">
        <div className="border-b border-b-border w-full shadow bg-background pt-2">
          <div className="flex flex-col gap-4 w-full pb-5 mx-auto max-w-[59rem]">
            {TitleSlot}
            <div className="w-full flex justify-between flex-wrap gap-6">
              <TabSelector
                tabs={tabs}
                value={getCurrentTab(tabs, pathname, defaultTab)}
                onTabChange={changeTab}
              />
              <div id="tab-layout-head-outlet" className="flex gap-3" />
            </div>
          </div>
        </div>
      </div>
      {ContentSlot.length ? ContentSlot : <Outlet />}
    </div>
  );
}

type ITabLayoutBreadcrumbPropsBase = Exclude<
  IHeaderBreadcrumbChunk,
  React.ReactElement
>;
export type ITabLayoutBreadcrumbProps =
  | {
      base:
        | ITabLayoutBreadcrumbPropsBase
        | [ITabLayoutBreadcrumbPropsBase, ...ITabLayoutBreadcrumbPropsBase[]];
      children?:
        | IHeaderBreadcrumbChunk
        | [IHeaderBreadcrumbChunk, ...IHeaderBreadcrumbChunk[]];
    }
  | {
      base?: never;
      children:
        | IHeaderBreadcrumbChunk
        | [IHeaderBreadcrumbChunk, ...IHeaderBreadcrumbChunk[]];
    };

export function TabLayoutBreadcrumb({
  base,
  children,
}: ITabLayoutBreadcrumbProps) {
  return (
    <HeaderBreadCrumb
      chunks={[
        ...((Array.isArray(base) ? base : base ? [base] : []) as [
          ITabLayoutBreadcrumbPropsBase,
          ...ITabLayoutBreadcrumbPropsBase[],
        ]),
        ...(Array.isArray(children) ? children : children ? [children] : []),
      ]}
    />
  );
}

export type ITabLayoutTitleProps = React.ComponentPropsWithoutRef<"div"> & {
  backButton?: boolean;
} & (
    | {
        title: string;
      }
    | {
        children: React.ReactNode | React.ReactNode[];
      }
  );

export function TabLayoutTitle({
  backButton,
  title,
  children,
  ...props
}: ITabLayoutTitleProps) {
  const navigate = useSmartNavigate();
  return (
    <div
      {...props}
      className={cn("flex items-center gap-4 w-full", props.className)}
    >
      {backButton !== false ? (
        <Button
          onClick={() => navigate(-1)}
          variant="outline"
          size="icon"
          className="h-7 w-7 shrink-0"
        >
          <ChevronLeft className="h-4 w-4" />
          <span className="sr-only">Back</span>
        </Button>
      ) : null}
      {title ? (
        <h1 className="text-2xl font-semibold leading-none tracking-tight">
          {title}
        </h1>
      ) : null}
      {children}
    </div>
  );
}

export interface ITabLayoutContentProps
  extends React.ComponentPropsWithoutRef<"div"> {
  size?: "full-width" | "default";
  children: React.ReactNode | React.ReactNode[];
}

export function TabLayoutContent({
  size = "default",
  children,
  ...props
}: ITabLayoutContentProps) {
  return (
    <div
      {...props}
      className={cn(
        "p-1",
        size === "default" && "w-full mx-auto max-w-[59rem] space-y-3",
        size === "full-width" &&
          "mx-auto max-w-7xl w-full overflow-x-hidden overflow-hidden space-y-4",
        props.className,
      )}
    >
      {children}
    </div>
  );
}

export default {
  TabLayout,
  TabLayoutBreadcrumb,
  TabLayoutTitle,
  TabLayoutContent,
};
