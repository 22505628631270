import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { LoaderIcon } from "lucide-react";
import * as React from "react";
import { Form, Link, useNavigation } from "react-router-dom";

export interface IForgotPasswordPageProps {}

export default function ForgotPasswordPage(props: IForgotPasswordPageProps) {
  const navigation = useNavigation();
  return (
    <div className="mx-auto grid w-[350px] gap-6">
      <div className="grid gap-2 text-center">
        <h1 className="text-3xl font-bold">Mot de passe oublié</h1>
        <p className="text-balance text-muted-foreground">
          Saisissez votre adresse email pour réinitialiser votre mot de passe.
        </p>
      </div>
      <Form method="POST" className="grid gap-4">
        <div className="grid gap-2">
          <Label htmlFor="email">Adresse email</Label>
          <Input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            placeholder="m@example.com"
            required
          />
        </div>
        <Button
          type="submit"
          disabled={navigation.state !== "idle"}
          className="w-full space-x-2"
        >
          {navigation.state !== "idle" ? (
            <LoaderIcon className="w-4 h-4 animate-spin" />
          ) : (
            <div className="w-4 h-4" />
          )}
          <span>Envoyer</span>
          <div className="w-4 h-4" />
        </Button>
      </Form>
      <div className="mt-4 text-center text-sm">
        <Link to="/auth/login" className="underline">
          Connexion
        </Link>
        {" - "}
        <Link to="/auth/register" className="underline">
          Inscription
        </Link>
      </div>
    </div>
  );
}
