import * as React from "react";
import dayjs, { ConfigType } from "@/lib/date";

export interface ITimePeriod {
  label: string;
  value: [ConfigType, ConfigType];
}
export function useTimePeriods() {
  return React.useMemo(() => {
    const now = dayjs().add(1, "day").startOf("day");
    const nowEnd = dayjs().endOf("day");
    return {
      now: {
        label: "Aujourd'hui",
        value: [now.subtract(1, "day"), nowEnd],
      },
      "24h": {
        label: "Hier",
        value: [
          dayjs().subtract(1, "day").startOf("day"),
          dayjs().subtract(1, "day").endOf("day"),
        ],
      },
      "7j": {
        label: "7 derniers jours",
        value: [now.subtract(7, "days"), nowEnd],
      },
      "30j": {
        label: "30 derniers jours",
        value: [now.subtract(30, "days"), nowEnd],
      },
    } as const satisfies Record<string, ITimePeriod>;
  }, []);
}
export function useNextTimePeriods() {
  return React.useMemo(() => {
    const now = dayjs().startOf("day");
    const nowEnd = dayjs().endOf("day");
    return {
      now: {
        label: "Aujourd'hui",
        value: [now, nowEnd],
      },
      week: {
        label: "Cette semaine",
        value: [now, nowEnd.endOf("week")],
      },
      "15j": {
        label: "15 prochains jours",
        value: [now, nowEnd.add(15, "days")],
      },
      month: {
        label: "Ce mois-ci",
        value: [now, nowEnd.endOf("month")],
      },
      "90j": {
        label: "3 prochains mois",
        value: [now, nowEnd.add(3, "months")],
      },
      "365j": {
        label: "12 mois",
        value: [now, nowEnd.add(12, "months")],
      },
    } as const satisfies Record<string, ITimePeriod>;
  }, []);
}
