import { FullAdvertisementDTO } from "@/api/definitions";
import { ColumnsState } from "@/hooks/useColumns";
import { AdvertisementsTableColumnName } from "./columns";
import { TableCell, TableRow } from "@/components/ui/table";
import { cn } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Eye, MoreHorizontal } from "lucide-react";
import { capitalize } from "@/lib/format";
import { Skeleton } from "@/components/ui/skeleton";
import { formatRanges } from "@/lib/date";
import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import { IRootLoaderData } from "@/pages/loader";
import Awaited from "@/components/awaited";
import { IAdvertisementsLoaderData } from "../loader";
import { join } from "@/lib/promise";
import AdvertisementActions from "./advertisement-actions";
import { withId } from "@/lib/array";
import { ActionsMenu } from "@/components/actions-menu";
import { DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { CatalogTableColumnName } from "../../catalog/(blocks)/columns";
import { ICatalogLoaderData } from "../../catalog/loader";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";
import React from "react";
import ImageInput from "@/components/image-input";
import AdvertisementDatesCell from "@/components/advertisement-dates-cell";

export interface IAdvertisementRowContentProps {
  advertisement: FullAdvertisementDTO;
  columnState:
    | ColumnsState<AdvertisementsTableColumnName>
    | ColumnsState<CatalogTableColumnName>;
}

export function AdvertisementRowContent({
  advertisement,
  columnState,
}: IAdvertisementRowContentProps) {
  const data = useLoaderData() as
    | IAdvertisementsLoaderData
    | ICatalogLoaderData;
  const rootData = useRouteLoaderData("root") as IRootLoaderData;
  return (
    <>
      <TableCell
        className="font-light source-code-pro-font"
        hidden={!columnState.id.visible}
      >
        #{advertisement.id.slice(0, 4)}
      </TableCell>
      <TableCell
        className={cn("w-20 ", columnState.id.visible ? "px-0" : "pl-4")}
        hidden={!columnState.image.visible}
      >
        {/* <img
          src={advertisement.main_image}
          alt={advertisement.title}
          width="80"
          height="40"
          className="aspect-[2] w-20 h-10 rounded-[4px] object-cover border border-border"
        /> */}
        <ImageInput
          file={advertisement.main_image}
          // alt={advertisement.title}
          // width="80"
          // height="40"
          className="aspect-[2] w-20 h-10"
        />
      </TableCell>
      <TableCell
        className="max-w-[320px] truncate "
        title={advertisement.title}
        hidden={!columnState.title.visible}
      >
        {advertisement.is_main_version ? (
          <Tooltip>
            <TooltipTrigger>
              <Eye className="h-4 w-4 text-muted-foreground hover:bg-foreground hover:text-background rounded-sm mr-1 inline relative -top-0.5" />
            </TooltipTrigger>
            <TooltipContent>
              Cette annonce est visible par les autres utilisateurs de la
              plateforme
            </TooltipContent>
          </Tooltip>
        ) : null}
        {advertisement.title}
      </TableCell>
      <TableCell
        className="max-w-[196px] truncate"
        title={advertisement.location_name}
        hidden={!columnState.location.visible}
      >
        {capitalize(advertisement.location_name)}
      </TableCell>
      <TableCell
        className="truncate max-w-[300px]"
        hidden={!("widgets" in columnState) || !columnState.widgets.visible}
      >
        <Awaited
          resolve={
            "schedules" in data ? join(data.schedules, data.widgets) : []
          }
          fallback={<Skeleton className="h-6 w-20" />}
          render={([schedules, widgets]) => {
            const adsSchedules = schedules?.results.filter(
              (s) => s.advertisement_version_id === advertisement.id,
            );
            if (!adsSchedules?.length)
              return (
                <span className="text-xs text-muted-foreground">
                  Aucune programmation
                </span>
              );
            const adsWidgets = widgets?.results.filter((w) =>
              adsSchedules.find(withId(w.id, "widget_id")),
            );
            if (!adsWidgets?.length)
              return (
                <span className="text-xs text-muted-foreground">
                  Aucune programmation
                </span>
              );
            if (adsWidgets.length === 1)
              return (
                <React.Fragment key={adsWidgets[0].id}>
                  <span className="block truncate overfow-hidden">
                    {adsWidgets[0].name}
                  </span>
                  <span className="block truncate overfow-hidden text-muted-foreground text-xs">
                    {adsSchedules
                      .filter((s) => s.widget_id === adsWidgets[0].id)
                      .map((s) => formatRanges(s.from, s.to))
                      .join(", ")}
                  </span>
                </React.Fragment>
              );
            if (adsWidgets.length === 2)
              return adsWidgets.map((widget) => {
                const ranges = adsSchedules
                  .filter((s) => s.widget_id === widget.id)
                  .map((s) => formatRanges(s.from, s.to));
                return (
                  <span
                    className="block truncate overfow-hidden"
                    key={widget.id}
                  >
                    {widget.name}
                    <span
                      className="text-muted-foreground text-xs"
                      title={ranges.join("\n")}
                    >
                      {" -> "}
                      {ranges.join(", ")}
                    </span>
                  </span>
                );
              });
            return (
              <div title={adsWidgets.map((w) => w.name).join("\n")}>
                <span className="block truncate overfow-hidden">
                  {adsWidgets[0].name}
                </span>
                <span className="block">+{adsWidgets.length - 1} autres</span>
              </div>
            );
          }}
        />
      </TableCell>
      <AdvertisementDatesCell
        dates={"dates" in advertisement ? advertisement.dates : null}
        columnState={columnState.dates}
      />
      <TableCell hidden={!columnState.categories?.visible}>
        <Awaited
          resolve={rootData.categories}
          fallback={<Skeleton className="h-6 w-20" />}
          render={(allCategories) => {
            if (!("categories" in advertisement)) return null;
            return (
              <div className="flex flex-col w-full line-clamp-2">
                {advertisement.categories
                  .map((category) => {
                    const categoryName = allCategories.find(
                      withId(category.category_id),
                    );
                    if (!categoryName) return null;
                    // Maybe add an icon ?
                    return (
                      <span
                        key={category.category_id}
                        className="truncate overflow-hidden"
                      >
                        {capitalize(categoryName.name)}
                      </span>
                    );
                  })
                  .filter(Boolean)
                  .slice(0, 2)}
              </div>
            );
          }}
        />
      </TableCell>
      <TableCell hidden={!columnState.entreprise?.visible}>
        {"company_name" in advertisement ? advertisement.company_name : null}
      </TableCell>
    </>
  );
}

export interface IAdvertisementRowProps {
  advertisement: FullAdvertisementDTO;
  columnState: ColumnsState<AdvertisementsTableColumnName>;
}

export default function AdvertisementRow({
  advertisement,
  columnState,
}: IAdvertisementRowProps) {
  const navigate = useSmartNavigate();
  return (
    <ActionsMenu Actions={AdvertisementActions} data={{ advertisement }}>
      <TableRow
        className="min-h-[72px] cursor-pointer"
        onClick={() => navigate(`/app/advertisements/${advertisement.id}`)}
      >
        <AdvertisementRowContent
          advertisement={advertisement}
          columnState={columnState}
        />
        <TableCell
          className="flex justify-end h-14"
          onClick={(e) => e.stopPropagation()}
        >
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="icon">
              <MoreHorizontal />
            </Button>
          </DropdownMenuTrigger>
        </TableCell>
      </TableRow>
    </ActionsMenu>
  );
}

AdvertisementRow.Skeleton = function ({
  columnState,
}: Pick<IAdvertisementRowContentProps, "columnState">) {
  return (
    <TableRow className="min-h-[72px]">
      <TableCell
        className="font-light source-code-pro-font"
        hidden={!columnState.id.visible}
      >
        #<Skeleton className="w-full h-full" />
      </TableCell>
      <TableCell
        className={cn(columnState.id.visible ? "px-0" : "pl-4")}
        hidden={!columnState.image.visible}
      >
        <Skeleton className="aspect-[2] w-20 rounded-[4px] h-full" />
      </TableCell>
      <TableCell
        className="max-w-[320px] truncate"
        hidden={!columnState.title.visible}
      >
        <Skeleton className="w-[320px] h-6" />
      </TableCell>
      <TableCell
        className="max-w-[196px] truncate"
        hidden={!columnState.location.visible}
      >
        <Skeleton className="w-40 h-6" />
      </TableCell>
      <TableCell
        className="max-w-[196px] truncate "
        hidden={!("widgets" in columnState) || !columnState.widgets?.visible}
      >
        <Skeleton className="h-6 w-20" />
      </TableCell>
      <TableCell hidden={!columnState.dates?.visible}>
        <Skeleton className="w-40 h-6" />
      </TableCell>
      <TableCell hidden={!columnState.categories?.visible}>
        <Skeleton className="h-6 w-20" />
      </TableCell>
      <TableCell hidden={!columnState.entreprise?.visible}>
        <Skeleton className="w-40 h-6" />
      </TableCell>
      <TableCell />
    </TableRow>
  );
};
