import * as React from "react";
import { Route } from "react-router-dom";
import WidgetConfigurationPage from "./page";
import { WidgetConfigAction } from "./action";
import { WidgetConfigurationLoader } from "./loader";

export interface IWidgetConfigurationRouterProps {}

export default function WidgetConfigurationRouter(
  props: IWidgetConfigurationRouterProps,
) {
  return (
    <Route>
      <Route
        index
        action={WidgetConfigAction}
        loader={WidgetConfigurationLoader}
        Component={WidgetConfigurationPage}
      />
    </Route>
  );
}
