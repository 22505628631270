import * as React from "react";
import { Route } from "react-router-dom";
import LoginPage from "./page";
import { LoginAction } from "./action";
import { LoginErrorPage } from "./error";

export interface ILoginRouterProps {}

export default function LoginRouter(props: ILoginRouterProps) {
  return (
    <Route
      index
      action={LoginAction}
      Component={LoginPage}
      errorElement={<LoginErrorPage />}
    />
  );
}
