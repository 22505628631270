import { cache } from "@/lib/cache";
import { AI_BASE, API_BASE, logErrorResponse } from "./common";
import {
  CreateEmptyCustomizationPayloadDTO,
  CustomizationContentDTO,
  CustomizationContentSchema,
  CustomizationDTO,
  CustomizationSchema,
  CustomizationStylesSchema,
} from "./definitions";
import * as z from "@/lib/zod";

export const listCompanyCustomizations = cache(
  async function listCompanyCustomizations(signal?: AbortSignal) {
    const response = await fetch(`${API_BASE}/customization`, {
      credentials: "include",
      signal,
    });
    if (!response.ok) throw await logErrorResponse(response);
    const data = await response.json();
    return CustomizationSchema.array().parse(data);
  },
  "1 hour",
);

export async function aiCustomizationUpdate(
  userPrompt: string[],
  currentCustomization: NonNullable<CustomizationContentDTO["styles"]>,
  signal?: AbortSignal,
): Promise<NonNullable<CustomizationContentDTO["styles"]> | { error: true }> {
  const response = await fetch(`${AI_BASE}`, {
    credentials: "include",
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      userPrompt,
      currentCustomization,
    }),
    signal,
  });
  if (!response.ok || !response.body) throw await logErrorResponse(response);
  const reader = response.body.getReader();
  let data = "";
  const decoder = new TextDecoder();
  while (true) {
    const { value, done } = await reader.read();
    if (done) break;
    data += decoder.decode(value);
  }
  return z
    .json(
      z
        .object({ error: z.boolean().optional() })
        .and(CustomizationStylesSchema),
    )
    .parse(data);
}

export const defaultCustomizationContent: CustomizationContentDTO = {
  version: "4.0.0",
  redirect: true,
  watermark: true,
  styles: {
    gap: 16,
    padding: 48,
    background: "#FFFFFF",
    watermark: {
      fontFamily: "Nunito Sans",
      fontSize: "10px",
      fontWeight: 400,
      color: "#4576bf",
    },
    header: {
      title: "Mon agenda des sorties",
      accentColor: "#7E7E7E",
    },
    card: {
      global: {
        radius: "16px",
        background: "#FFFFFF",
        border: "1px solid #EBEBEB",
        padding: "12px 12px 16px",
        shadow: "0px 0px 10px rgba(0, 0, 0, 0.18)",
      },
      firstLine: {
        color: "black",
        fontFamily: "Nunito Sans",
        fontSize: "16px",
        fontWeight: 800,
      },
      secondLine: {
        color: "#7e7e7e",
        fontFamily: "Nunito Sans",
        fontSize: "14px",
        fontWeight: 600,
      },
      thirdLine: {
        color: "#7e7e7e",
        fontFamily: "Nunito Sans",
        fontSize: "14px",
        fontWeight: 600,
      },
      secondLineIcon: {
        strokeWidth: ".4px",
        strokeColor: "#7e7e7e",
      },
      thirdLineIcon: {
        strokeWidth: ".4px",
        strokeColor: "#7e7e7e",
      },
    },
    arrows: {
      backgroundColor: "#FFFFFF00",
      strokeWidth: "1px",
      strokeColor: "#5d5e5c",
    },
    pagination: {
      primaryBackground: "#FFF",
      secondaryBackground: "#5d5e5c",
      primaryColor: "#5d5e5c",
      secondaryColor: "#FFF",
      fontFamily: "Nunito Sans",
      fontSize: "14px",
      fontWeight: 600,
      radius: "4px",
      padding: "8px",
      gap: 4,
      border: "none",
    },
  },
};
export async function createEmptyCustomization(
  payload: Omit<CreateEmptyCustomizationPayloadDTO, "content">,
) {
  const response = await fetch(`${API_BASE}/customization`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...payload, content: defaultCustomizationContent }),
    credentials: "include",
  });
  if (!response.ok) throw new Error("Couldn't create customization");
  const data = await response.json();
  const newCustomization = CustomizationSchema.array()
    .min(1)
    .max(1)
    .parse(data)[0];
  listCompanyCustomizations.invalidate();
  return newCustomization;
}
export async function updateCustomization(
  id: CustomizationDTO["id"],
  payload: Partial<Pick<CustomizationDTO, "name" | "content" | "company_id">>,
) {
  const response = await fetch(`${API_BASE}/customization/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    credentials: "include",
  });
  if (!response.ok) throw new Error("Couldn't update customization");
  listCompanyCustomizations.invalidate();
  return true;
}
