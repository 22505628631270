import { cache } from "@/lib/cache";
import { API_BASE, logErrorResponse } from "./common";
import {
  CleanInvitesSchema,
  CreateInvitesDTO,
  INVITE_STATUS,
} from "./definitions";
import { getCompanyInvites } from "./companies";
import { getCurrentUser } from "./authentication";

export async function sendInvite({
  email,
  companyId,
  policyId,
}: CreateInvitesDTO) {
  const response = await fetch(`${API_BASE}/invites`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, companyId, policyId }),
    credentials: "include",
  });
  if (!response.ok) throw await logErrorResponse(response);
  getCompanyInvites.invalidate();
  return true;
}

export const listInvites = cache(async function listInvites(
  signal?: AbortSignal,
) {
  const response = await fetch(`${API_BASE}/invites`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return CleanInvitesSchema.array().parse(data);
}, "30 seconds");

export async function acceptInvite(inviteId: number) {
  const response = await fetch(`${API_BASE}/invites/${inviteId}/resolve`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ status: INVITE_STATUS.ACCEPTED }),
    credentials: "include",
  });
  if (!response.ok) throw await logErrorResponse(response);
  listInvites.invalidate();
  getCurrentUser.invalidate();
  return true;
}
