import * as React from "react";
import { Link, Navigate, Route } from "react-router-dom";
import AuthLayout from "./layout";
import { AuthLoader } from "./loader";
import LoginRouter from "./login";
import { AuthAction } from "./action";
import RegisterRoot from "./register";
import ForgotPasswordRouter from "./forgot-password";

export interface IAuthRouterProps {}

export default function AuthRouter(props: IAuthRouterProps) {
  return (
    <Route Component={AuthLayout} loader={AuthLoader}>
      <Route index element={<Navigate to="login" />} action={AuthAction} />
      <Route path="login">{LoginRouter({})}</Route>
      <Route path="forgot-password">{ForgotPasswordRouter({})}</Route>
      {/* <Route
        path="register"
        element={
          <div className="text-center">
            L'inscription n'est pas disponible durant la beta.
            <br />
            <br />
            <Link to="/auth/login" className="underline">
              Se connecter
            </Link>
          </div>
        }
      /> */}
      <Route path="register">{RegisterRoot({})}</Route>
    </Route>
  );
}
