import * as React from "react";
import { Route } from "react-router-dom";
import WidgetSchedulesPage from "./page";
import { WidgetSchedulesLoader } from "./loader";
import { SchedulesAction } from "./action";

export interface IWidgetSchedulesRouterProps {}

export default function WidgetSchedulesRouter(
  props: IWidgetSchedulesRouterProps,
) {
  return (
    <Route>
      <Route
        index
        action={SchedulesAction}
        Component={WidgetSchedulesPage}
        loader={WidgetSchedulesLoader}
      />
    </Route>
  );
}
