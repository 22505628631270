import { useFetcher, useRouteLoaderData } from "react-router-dom";
import { ISingleWidgetLoaderData } from "../loader";
import Awaited from "@/components/awaited";
import WidgetRotation from "./(blocks)/rotation";
import WidgetSnippet from "./(blocks)/snippet";
import WidgetFlux from "./(blocks)/flux";
import { join } from "@/lib/promise";
import { IRootLoaderData } from "@/pages/loader";
import RefPortal from "@/components/ref-portal";
import { Button } from "@/components/ui/button";
import { LifeBuoy } from "lucide-react";
import { TabLayoutContent } from "@/components/tab-layout";
import WidgetDelete from "./(blocks)/delete";
import WidgetDisplay from "./(blocks)/display";

export interface IWidgetConfigurationPageProps {}

export default function WidgetConfigurationPage(
  props: IWidgetConfigurationPageProps,
) {
  const data = useRouteLoaderData(
    "single-widget-route",
  ) as ISingleWidgetLoaderData;
  const rootData = useRouteLoaderData("root") as IRootLoaderData;
  const fetcher = useFetcher();
  return (
    <TabLayoutContent>
      <RefPortal selector="#tab-layout-head-outlet">
        <Awaited
          resolve={data.widget}
          render={({ id }) => (
            <fetcher.Form
              method="POST"
              action="/app/widgets/:widgetId/configuration?index"
            >
              <input type="hidden" name="widgetId" value={id} />
              <Button
                variant="outline"
                disabled={fetcher.state !== "idle"}
                className="enter animation-fast animation-small"
              >
                <LifeBuoy className="w-5 h-5 mr-2" /> Forcer l'actualisation
              </Button>
            </fetcher.Form>
          )}
        />
      </RefPortal>
      <Awaited resolve={data.widget}>
        <WidgetSnippet />
      </Awaited>
      <Awaited
        resolve={join(
          data.widget,
          rootData.categories,
          rootData.zoneTree,
          rootData.zones,
        )}
      >
        <WidgetFlux />
      </Awaited>
      <Awaited resolve={data.widget}>
        <WidgetRotation />
        <WidgetDisplay />
        <WidgetDelete />
      </Awaited>
    </TabLayoutContent>
  );
}
