import * as React from "react";
import { Route } from "react-router-dom";
import { AdvertisementBuilderLoader } from "./loader";
import { AdvertisementBuilderAction } from "./action";
import AdvertisementBuilderPage from "./page";

export interface IAdvertisementBuilderRouterProps {}

export default function AdvertisementBuilderRouter(
  props: IAdvertisementBuilderRouterProps,
) {
  return (
    <Route>
      <Route
        index
        loader={AdvertisementBuilderLoader}
        action={AdvertisementBuilderAction}
        Component={AdvertisementBuilderPage}
      />
    </Route>
  );
}
