import * as React from "react";
import { createModalContext } from ".";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Form } from "react-router-dom";
import FormState from "@/components/form-state";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

export interface IKickUserModalProps {
  closeModal(): void;
  companyId: number;
  userId: number;
  firstname?: string;
  lastname?: string;
}

export function KickUserModal({
  closeModal,
  companyId,
  userId,
  firstname,
  lastname,
}: IKickUserModalProps) {
  const DESIRED_INPUT_VALUE =
    `${firstname} ${lastname}`.trim() || "supprimer l'utilisateur";
  const [inputValue, setInputValue] = React.useState("");
  return (
    <Dialog open onOpenChange={closeModal} modal={false}>
      <div className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
      <DialogContent className="bg-card border-border text-foreground">
        <DialogHeader>
          <DialogTitle>Révoquer l'utilisateur</DialogTitle>
          <DialogDescription>
            Cet utilisateur ne pourra plus accéder à cette équipe et à son
            contenu.
          </DialogDescription>
        </DialogHeader>
        <Form
          method="DELETE"
          action="/app/settings/team?index"
          className="space-y-4"
        >
          <div className="space-y-4 w-full">
            <p className="px-4 py-2 bg-destructive/40 text-[#ff565f] text-sm leading-6 tracking-wide rounded-md">
              <b>Attention</b> : Cette action est irréversible.
            </p>
            <div className="space-y-2">
              <Label>
                Saisissez <b>{DESIRED_INPUT_VALUE}</b> ci-dessous:
              </Label>
              <Input
                name="name"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </div>
            <DialogFooter className="sm:justify-between">
              <Button
                type="submit"
                variant="destructive"
                disabled={inputValue !== DESIRED_INPUT_VALUE}
              >
                Confirmer
              </Button>
              <Button
                type="button"
                className="min-w-[128px]"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  closeModal();
                }}
              >
                Annuler
              </Button>
            </DialogFooter>
            <FormState data={{ userId, companyId }} />
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export const [useKickUserModal, KickUserProvider, KickUserContext] =
  createModalContext(KickUserModal);
