import { FullJobAdvertisementDTO } from "@/api/definitions";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import * as React from "react";
import { useAsyncValue, useFetcher } from "react-router-dom";
import FormState from "@/components/form-state";
import { Label } from "recharts";
import { ISingleAdvertisementLoaderData } from "../../loader";

export interface IAdvertisementJobCardProps {}

export default function AdvertisementJobCard(
  props: IAdvertisementJobCardProps,
) {
  const advertisement = useAsyncValue() as Awaited<
    ISingleAdvertisementLoaderData["advertisement"]
  > &
    FullJobAdvertisementDTO;
  const fetcher = useFetcher();
  const [company_name, setCompanyName] = React.useState(
    advertisement.company_name,
  );
  return (
    <Card className="enter">
      <fetcher.Form
        method="PUT"
        action="/app/advertisements/[id]/content?index"
      >
        <FormState
          data={{
            id: advertisement.id,
            type: advertisement.type,
            company_name,
          }}
        />
        <CardHeader>
          <CardTitle>Employeur</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6 pb-10 border-b border-b-border">
          <div className="space-y-4">
            <Label>Nom de l'employeur</Label>
            <Input
              disabled={!advertisement.isOwned}
              value={company_name}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
        </CardContent>
        <CardFooter
          className={cn(
            "flex justify-end py-4",
            advertisement.isOwned || "hidden",
          )}
        >
          <Button
            type="submit"
            size="sm"
            disabled={
              !advertisement.isOwned ||
              advertisement.company_name === company_name
            }
          >
            Confirmer
          </Button>
        </CardFooter>
      </fetcher.Form>
    </Card>
  );
}
