import * as React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import AuthRouter from "./auth";
import { RootErrorPage } from "./error";
import AppRouter from "./app";
import { RootLoader } from "./loader";
import RootLayout from "./layout";
import MaintenancePage from "./maintenance";
import OnboardingRouter from "./onboarding";

export interface IRootRouterProps {}

export default function RootRouter(props: IRootRouterProps) {
  return (
    <Route path="/" errorElement={<RootErrorPage />}>
      <Route path="maintenance" Component={MaintenancePage} />
      <Route Component={RootLayout} id="root" loader={RootLoader}>
        <Route index element={<Navigate to="auth/login" />} />
        <Route path="auth">{AuthRouter({})}</Route>
        <Route path="onboarding">{OnboardingRouter({})}</Route>
        <Route path="app">{AppRouter({})}</Route>
        <Route path="*" element={<Navigate to="app" />} />
      </Route>
    </Route>
  );
}
