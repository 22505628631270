import * as React from "react";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import dayjs, { formatDate, formatDateTime } from "@/lib/date";
import { Input } from "./input";
import RefPortal from "../ref-portal";

export interface IDatePickerProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof Calendar>,
    "date" | "onDateChange"
  > {
  date: Date | null;
  onDateChange: (date: Date) => void;
  time?: boolean;
  pickerDisabled?: boolean;
}

export default function DatePicker({
  date,
  onDateChange,
  time,
  pickerDisabled,
  ...props
}: IDatePickerProps) {
  const [open, setOpen] = React.useState(false);
  const [currentDate, setCurrentDate] = React.useState(date);
  React.useEffect(() => {
    setCurrentDate(date);
  }, [date]);
  const openRef = React.useRef(open);
  React.useEffect(() => {
    if (open || !openRef.current || !currentDate) return;
    onDateChange(currentDate);
  }, [open]);
  React.useEffect(() => {
    openRef.current = open;
  }, [open]);
  return (
    <Popover open={open} onOpenChange={setOpen}>
      {open && (
        <RefPortal selector="#calendar-portal">
          <div className="fixed inset-0 z-50 bg-black/20 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
        </RefPortal>
      )}
      <PopoverTrigger asChild disabled={pickerDisabled}>
        <Button
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal",
            !date && "text-muted-foreground",
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {currentDate ? (
            time ? (
              formatDateTime(currentDate)
            ) : (
              formatDate(currentDate)
            )
          ) : (
            <span>Choisir date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="datepicker w-auto p-0 pointer-events-auto">
        <Calendar
          initialFocus
          {...props}
          mode="single"
          defaultMonth={currentDate ?? undefined}
          selected={currentDate ?? undefined}
          onSelect={(d) =>
            d &&
            setCurrentDate(
              dayjs(d)
                .hour(dayjs(currentDate).hour())
                .minute(dayjs(currentDate).minute())
                .second(0)
                .toDate(),
            )
          }
        />
        <div
          className={cn(
            "pb-3 pr-5 pl-6 flex gap-6 items-center flex-nowrap",
            time || "hidden",
          )}
        >
          Horaire&nbsp;:
          <Input
            type="time"
            value={dayjs(currentDate).format("HH:mm")}
            onChange={(e) => {
              const [hour, minute] = e.target.value.split(":").map(Number);
              const newDate = dayjs(currentDate)
                .hour(hour)
                .minute(minute)
                .second(0)
                .toDate();
              setCurrentDate(newDate);
            }}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}
