import { impersonateCompany } from "@/api/authentication";
import { CompaniesDTO } from "@/api/definitions";
import { ActionsMenuContent, ActionsMenuItem } from "@/components/actions-menu";
import { DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";
import { copyText } from "@/lib/copy";
import { objectToFormData } from "@/lib/query";
import { cn } from "@/lib/utils";
import { useDeleteCompanyModal } from "@/modals/delete-company";
import { useUpdateCompanyModal } from "@/modals/update-company";
import { Edit, Hash, LayoutGrid, LogIn, Trash, User } from "lucide-react";
import * as React from "react";
import { useFetcher } from "react-router-dom";

export interface ICompanyActionsProps {
  company: CompaniesDTO;
}

export default function CompanyActions({ company }: ICompanyActionsProps) {
  const navigate = useSmartNavigate();
  const fetcher = useFetcher();
  const deleteCompanyModal = useDeleteCompanyModal();
  const updateCompanyModal = useUpdateCompanyModal();
  return (
    <ActionsMenuContent className="w-56" align="start" side="left">
      <ActionsMenuItem
        onClick={() => copyText(company.id)}
        className="cursor-pointer"
      >
        <Hash className="mr-2 h-4 w-4" />
        <span>Copier l'ID</span>
      </ActionsMenuItem>
      <ActionsMenuItem
        onClick={() => impersonateCompany(company.id)}
        className="cursor-pointer"
      >
        <LogIn className="mr-2 h-4 w-4" />
        <span>Se connecter</span>
      </ActionsMenuItem>
      <DropdownMenuSeparator />
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={() => navigate("/app/widgets?company_id=" + company.id)}
      >
        <LayoutGrid className="mr-2 h-4 w-4" />
        <span>Widgets</span>
      </ActionsMenuItem>
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={() => navigate("/app/admin/users?companyId=" + company.id)}
      >
        <User className="mr-2 h-4 w-4" />
        <span>Utilisateurs</span>
      </ActionsMenuItem>
      <DropdownMenuSeparator />
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={() => updateCompanyModal.openModal({ companyId: company.id })}
      >
        <Edit className="mr-2 h-4 w-4" />
        <span>Modifier</span>
      </ActionsMenuItem>
      <ActionsMenuItem
        className={cn(
          "cursor-pointer bg-destructive text-destructive-foreground",
        )}
        onClick={() => deleteCompanyModal.openModal({ companyId: company.id })}
      >
        <Trash className="mr-2 h-4 w-4" />
        Supprimer
      </ActionsMenuItem>
    </ActionsMenuContent>
  );
}
