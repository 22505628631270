import { createModalContext } from ".";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Form } from "react-router-dom";
import { CompaniesDTO } from "@/api/definitions";
import FormState from "@/components/form-state";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";

export interface IAddOriginModalProps {
  closeModal(): void;
  companyId: CompaniesDTO["id"];
  sites: string[];
}

export function AddOriginModal({
  closeModal,
  sites,
  companyId,
}: IAddOriginModalProps) {
  return (
    <Dialog open onOpenChange={closeModal}>
      <DialogContent className="bg-card border-border text-foreground">
        <DialogHeader>
          <DialogTitle>Ajouter un site</DialogTitle>
          <DialogDescription>
            Ajouter un nouveau site sur lequel pourront s'afficher vos widgets.
          </DialogDescription>
        </DialogHeader>
        <Form
          method="PUT"
          action="/app/settings/sites?index"
          className="space-y-4"
        >
          <div className="space-y-2">
            <Label>Url</Label>
            <Input
              type="url"
              name="authorizedOrigins"
              placeholder="ex: https://app.konnectz.io"
            />
          </div>
          <DialogDescription>
            Si l'url de votre site change, vous devrez la renseigner la nouvelle
            url en amont pour éviter toute coupure d'affichage.
          </DialogDescription>
          <FormState data={{ id: companyId, authorizedOrigins: sites }} />
          <DialogFooter>
            <Button type="submit">Confirmer</Button>
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export const [useAddOriginModal, AddOriginProvider, AddOriginContext] =
  createModalContext(AddOriginModal);
