import * as React from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { CalendarDays } from "lucide-react";
import dayjs, { formatRanges } from "@/lib/date";
import { ConfigType } from "@/lib/date";
import { removeDuplicate } from "@/lib/array";
import { DateRange } from "react-day-picker";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ITimePeriod } from "@/hooks/use-time-periods";
import { cn } from "@/lib/utils";
import { useCalendarParams } from "@/hooks/use-calendar-params";

export interface IDateRangeSelectorProps
  extends React.ComponentPropsWithoutRef<"div"> {
  value: [ConfigType, ConfigType];
  setValue: (newValue: [ConfigType, ConfigType]) => void;
  periods?: ITimePeriod[];
  disabled?: boolean;
  toDate?: Date;
  fromDate?: Date;
}

export default function DateRangeSelector({
  value,
  setValue,
  periods = [],
  disabled,
  toDate,
  fromDate,
  ...props
}: IDateRangeSelectorProps) {
  const options = removeDuplicate(
    periods
      .concat({
        value,
        label: formatRanges(...value),
      })
      .map((v) => ({ ...v, value: serialize(v.value) })),
    "value",
  );
  const [selectOpen, setSelectOpen] = React.useState(false);
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const [calendarParams, setCalendarParams] = useCalendarParams(
    value,
    setValue,
  );

  return (
    <div
      {...props}
      className={cn(
        "rounded-md border border-border bg-background flex",
        props.className,
        disabled && "opacity-60",
      )}
    >
      <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
        <PopoverTrigger
          disabled={disabled}
          className={cn(
            "px-3.5 min-h-full flex items-center justify-center relative z-10",
            popoverOpen && "rounded-l-md outline-accent outline outline-2",
          )}
        >
          <CalendarDays className="w-5 h-5" />
        </PopoverTrigger>
        <PopoverContent
          className="w-auto p-0 pointer-events-auto"
          align="start"
        >
          <Calendar
            initialFocus
            mode="range"
            toDate={toDate}
            fromDate={fromDate}
            numberOfMonths={2}
            selected={calendarParams}
            onSelect={setCalendarParams}
            defaultMonth={calendarParams?.from}
          />
        </PopoverContent>
      </Popover>
      <Select
        value={serialize(value)}
        onValueChange={(value) => {
          if (value !== "custom") return setValue(deserialize(value));
          if (!popoverOpen) setTimeout(() => setPopoverOpen(true), 300);
        }}
        disabled={disabled}
        open={selectOpen}
        onOpenChange={(newOpen) => {
          if (periods.length) return setSelectOpen(newOpen);
          if (newOpen && !popoverOpen)
            setTimeout(() => setPopoverOpen(true), 300);
        }}
      >
        <SelectTrigger
          hideIcon
          className={cn(
            "border-0 border-l rounded-l-none border-border min-w-[96px] flex justify-center bg-transparent",
            periods.length ||
              "focus:ring-offset-0 focus:ring-transparent focus:ring-0 cursor-default",
          )}
        >
          <SelectValue placeholder="Choisir une période..." />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {options.map(({ value: val, label }) => (
              <SelectItem key={val} value={val}>
                {label}
              </SelectItem>
            ))}
            <SelectItem value="custom">Choisir des dates</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}
function serialize(dates: readonly [ConfigType, ConfigType]) {
  return dates.map((d) => dayjs(d).toISOString()).join(",");
}
function deserialize(str: string) {
  return str.split(",").map((d) => dayjs(d).toDate()) as [
    ConfigType,
    ConfigType,
  ];
}
