import * as React from "react";
import { Route } from "react-router-dom";
import { AdvertisementsLoader } from "./loader";
import AdvertisementsPage from "./page";
import SingleAdvertisementRouter from "./[id]";
import NewAdvertisementRouter from "./new";
import AdvertisementsLayout from "./layout";
import { AdvertisementsAction } from "./action";

export interface IAdvertisementsRouterProps {}

export default function AdvertisementsRouter(
  props: IAdvertisementsRouterProps,
) {
  return (
    <Route Component={AdvertisementsLayout}>
      <Route
        index
        element={<AdvertisementsPage />}
        loader={AdvertisementsLoader}
        action={AdvertisementsAction}
      />
      <Route path="new">{NewAdvertisementRouter({})}</Route>
      <Route path=":advertisementId">{SingleAdvertisementRouter({})}</Route>
    </Route>
  );
}
