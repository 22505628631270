import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardFooter,
} from "@/components/ui/card";
import * as React from "react";
import { useAsyncValue, useFetcher } from "react-router-dom";
import { CategoriesDTO, FullEventAdvertisementDTO } from "@/api/definitions";
import { Badge } from "@/components/ui/badge";
import { capitalize } from "@/lib/format";
import SearchSelect from "@/components/search-select";
import { Plus, X } from "lucide-react";
import { useSet } from "@/hooks/use-set";
import { Button } from "@/components/ui/button";
import { withId } from "@/lib/array";
import { cn } from "@/lib/utils";
import FormState from "@/components/form-state";

export interface IAdvertisementCategoriesCardProps {}

export default function AdvertisementCategoriesCard(
  props: IAdvertisementCategoriesCardProps,
) {
  const [advertisement, allCategories] = useAsyncValue() as [
    FullEventAdvertisementDTO & { isOwned: boolean },
    CategoriesDTO[],
  ];
  const fetcher = useFetcher();
  const categories = useSet<number>(
    advertisement.categories
      .map((event_category) => {
        return allCategories.find(withId(event_category.category_id))?.id;
      })
      .filter(Boolean) as number[],
  );
  const isTouched = React.useMemo(() => {
    return (
      categories.size !== advertisement.categories.length ||
      Array.from(categories).some(
        (cat) => !advertisement.categories.find(withId(cat, "category_id")),
      )
    );
  }, [categories, advertisement.categories]);
  return (
    <Card className="enter">
      <CardHeader>
        <CardTitle>Catégories</CardTitle>
      </CardHeader>
      <CardContent className="flex gap-3 flex-wrap pb-6 border-b border-b-border">
        {[...categories].map((categoryId) => {
          const category = allCategories.find(withId(categoryId))!;
          return (
            <Badge
              className="cursor-pointer"
              onClick={() => categories.delete(categoryId)}
            >
              {capitalize(category.name)} <X className="w-4 h-4 ml-1.5" />
            </Badge>
          );
        })}
        {advertisement.isOwned ? (
          <SearchSelect
            key="add-category"
            onSelect={(categoryId) => categories.add(+categoryId)}
            options={allCategories
              .filter((c) => !categories.has(c.id))
              .map(({ id, name }) => ({
                value: id + "",
                label: capitalize(name.toLowerCase()),
              }))}
          >
            <Badge variant="secondary" className="cursor-pointer">
              Ajouter <Plus className="w-3 h-3 ml-1.5" />
            </Badge>
          </SearchSelect>
        ) : null}
      </CardContent>
      <fetcher.Form
        method="PUT"
        action="/app/advertisements/[id]/content?index"
        className={cn(advertisement.isOwned || "hidden")}
        onSubmit={(e) => {
          if (!advertisement.isOwned) e.preventDefault();
        }}
      >
        <FormState
          data={{
            addedCategories: [...categories].filter(
              (c) =>
                !advertisement.categories.find((ac) => ac.category_id === c),
            ),
            removedCategories: advertisement.categories
              .map((c) => c.category_id)
              .filter((c) => !categories.has(c)),
            id: advertisement.id,
            type: advertisement.type,
          }}
        />
        <CardFooter
          className={cn(
            "flex justify-end py-4",
            advertisement.isOwned || "hidden",
          )}
        >
          <Button type="submit" size="sm" disabled={!isTouched}>
            Confirmer
          </Button>
        </CardFooter>
      </fetcher.Form>
    </Card>
  );
}
