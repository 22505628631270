import { timer } from "@/lib/timer";
import * as React from "react";
import { useNavigation } from "react-router-dom";

export function createModalContext<
  T extends { closeModal?(triggerCloseEvent?: boolean): void },
>(Component: React.ComponentType<T>) {
  const context: ModalContext<T> = React.createContext<ContextType<T> | null>(
    null,
  );

  function useModalContext(this: Function) {
    const data = React.useContext<ContextType<T> | null>(context);
    if (!data)
      throw new Error(
        `${(this ?? useModalContext).name} must be used inside of ${Component.displayName} Provider`,
      );
    return data;
  }
  function Provider(props: ModalContextProviderProps) {
    const [state, setState] = React.useState<ContextProviderStateType<T>>({
      isModalOpen: false,
    });

    const eventTarget = React.useMemo(() => new EventTarget(), []);

    React.useEffect(() => {
      if (state.isModalOpen) return;
      return timer(() => {
        document.body.style.pointerEvents = "";
      }, 1).trigger().clear;
    }, [state.isModalOpen]);

    const openModal: ContextType<T>["openModal"] = (props = {}) => {
      requestIdleCallback(
        () =>
          setState({
            isModalOpen: true,
            modalProps: props as T,
          }),
        { timeout: 50 },
      );
    };
    const closeModal: ContextType<T>["closeModal"] = (
      triggerCloseEvent = true,
    ) => {
      setState({ isModalOpen: false });
      if (triggerCloseEvent)
        eventTarget.dispatchEvent(new CustomEvent("close"));
    };

    const onClose = eventTarget.addEventListener.bind(eventTarget, "close");

    const navigation = useNavigation();
    if (state.isModalOpen && navigation.state === "loading") {
      closeModal(false);
    }

    return (
      <context.Provider value={{ ...state, openModal, closeModal, onClose }}>
        {props.children}
        {state.isModalOpen ? (
          <Component {...(state.modalProps as T)} closeModal={closeModal} />
        ) : null}
      </context.Provider>
    );
  }
  return [
    useModalContext as UseModalContext<T>,
    Provider as ModalContextProvider,
    context,
  ] as const;
}

export type ContextProviderStateType<T = {}> =
  | {
      isModalOpen: true;
      modalProps: Omit<T, "closeModal">;
    }
  | {
      isModalOpen: false;
      modalProps?: never;
    };

export type ContextType<T = {}> = ContextProviderStateType<T> & {
  openModal(
    ...props: Omit<T, "closeModal"> extends Record<string, never>
      ? [props?: {} | undefined]
      : [props: Omit<T, "closeModal">]
  ): void;
  closeModal(triggerCloseEvent?: boolean): void;
  onClose(
    callback: EventListenerOrEventListenerObject,
    options?: AddEventListenerOptions | boolean,
  ): void;
};

export type ModalContext<T = {}> = React.Context<ContextType<T> | null>;
export type UseModalContext<T = {}> = () => ContextType<T>;
export type ModalContextProviderProps = { children: React.ReactNode };
export type ModalContextProvider = React.FC<ModalContextProviderProps>;
