import { DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import {
  CalendarDays,
  ExternalLink,
  EyeOff,
  FileText,
  Hash,
} from "lucide-react";
import { FullAdvertisementDTO } from "@/api/definitions";
import { cn } from "@/lib/utils";
import { ActionsMenuContent, ActionsMenuItem } from "@/components/actions-menu";
import { useCreateScheduleModal } from "@/modals/create-schedule";
import { useNavigate } from "react-router-dom";
import { useCreateBlacklistModal } from "@/modals/create-blacklist";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";
import { copyText } from "@/lib/copy";

export interface ICatalogActionsProps {
  advertisement: FullAdvertisementDTO;
}

export default function CatalogActions({
  advertisement,
}: ICatalogActionsProps) {
  const createScheduleModal = useCreateScheduleModal();
  const createBlacklistModal = useCreateBlacklistModal();
  const navigate = useSmartNavigate();
  return (
    <ActionsMenuContent className="w-56" align="start" side="left">
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={() =>
          createScheduleModal.openModal({
            advertisementId: advertisement.id,
            noRedirect: true,
          })
        }
      >
        <CalendarDays className="mr-2 h-4 w-4" />
        <span>Programmer</span>
      </ActionsMenuItem>
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={() =>
          createBlacklistModal.openModal({ advertisementId: advertisement.id })
        }
      >
        <EyeOff className="mr-2 h-4 w-4" />
        <span>Blacklister</span>
      </ActionsMenuItem>
      <DropdownMenuSeparator />
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={() => open(advertisement.url, "_blank")}
      >
        <ExternalLink className="mr-2 h-4 w-4" />
        <span>Voir en ligne</span>
      </ActionsMenuItem>
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={() =>
          navigate(`/app/advertisements/${advertisement.id}/content`)
        }
      >
        <FileText className="mr-2 h-4 w-4" />
        <span>Voir le contenu</span>
      </ActionsMenuItem>
      <ActionsMenuItem
        onClick={() => copyText(advertisement.id)}
        className="cursor-pointer"
      >
        <Hash className="mr-2 h-4 w-4" />
        <span>Copier l'ID</span>
      </ActionsMenuItem>
    </ActionsMenuContent>
  );
}
