import dayjs from "@/lib/date";
import { API_BASE, logErrorResponse } from "./common";
import {
  AdvertisementAnalyticsFiltersDTO,
  AdvertisementAnalyticsSchema,
  WidgetAnalyticsFiltersDTO,
  WidgetAnalyticsSummaryDTO,
  WidgetAnalyticsSummarySchema,
} from "./definitions";
import { objectToSearchParams } from "@/lib/query";
import { cache } from "@/lib/cache";
import { ConfigType } from "@/lib/date";
import * as z from "@/lib/zod";
import ms from "ms";

export const getWidgetStats = cache(async function getWidgetStats(
  widgetId: string,
  params: WidgetAnalyticsFiltersDTO,
  signal?: AbortSignal,
) {
  const query = objectToSearchParams(params);
  const response = await fetch(
    `${API_BASE}/proxies/analytics/v2/selection/${widgetId}/summary?${query}`,
    {
      credentials: "include",
      signal,
    },
  );
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return await WidgetAnalyticsSummarySchema.parseAsync(data);
}, ms("1 minute"));

export const getWidgetsSummaries = cache(async function getWidgetsSummaries(
  widgetId: string[],
  params: WidgetAnalyticsFiltersDTO,
  signal?: AbortSignal,
) {
  const query = objectToSearchParams({ ...params, widgetId });
  const response = await fetch(
    `${API_BASE}/proxies/analytics/v2/summaries?${query}`,
    {
      credentials: "include",
      signal,
    },
  );
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return await WidgetAnalyticsSummarySchema.merge(
    z.object({ widget: z.string() }),
  )
    .array()
    .parseAsync(data);
}, ms("1 minute"));

export const getAdvertisementStats = cache(async function getAdvertisementStats(
  advertisementId: string,
  params: AdvertisementAnalyticsFiltersDTO,
  signal?: AbortSignal,
) {
  const query = objectToSearchParams(params);
  const response = await fetch(
    `${API_BASE}/proxies/analytics/v2/advertisement/${advertisementId}?${query}`,
    {
      credentials: "include",
      signal,
    },
  );
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return await AdvertisementAnalyticsSchema.parseAsync(data);
}, ms("1 minute"));

const periodCheckForLevel = {
  hour: (a: Date, b: Date) => dayjs(a).isSame(b, "hour"),
  "6-hour": (a: Date, b: Date) => {
    const dayA = dayjs(a),
      dayB = dayjs(b);
    return dayA
      .subtract(dayA.hour() % 6, "hour")
      .isSame(dayB.subtract(dayB.hour() % 6, "hour"), "hour");
  },
  "12-hour": (a: Date, b: Date) => {
    const dayA = dayjs(a),
      dayB = dayjs(b);
    return dayA
      .subtract((dayA.hour() % 12) + 6, "hour")
      .isSame(dayB.subtract((dayB.hour() % 12) + 6, "hour"), "hour");
  },
  date: (a: Date, b: Date) => dayjs(a).isSame(b, "date"),
  week: (a: Date, b: Date) => dayjs(a).isSame(b, "week"),
  month: (a: Date, b: Date) => dayjs(a).isSame(b, "month"),
} as const;

export type Period = keyof typeof periodCheckForLevel;

export function getAggregationPeriodForDates(
  start: ConfigType,
  end: ConfigType,
): Period {
  const dayDiff = Math.abs(dayjs(start).diff(end, "days"));
  if (dayDiff <= 7) return "hour";
  if (dayDiff < 14) return "6-hour";
  if (dayDiff <= 31) return "date";
  if (dayDiff <= 120) return "week";
  return "month";
}

export function aggregateTimeSerie<
  S extends "hour" & (string & {}),
  T extends { [k in S | "hour"]: k extends "hour" ? Date : number },
>(level: Period, data: T[], range: [Date, Date]): T[] {
  const aggregatedSerie = data.reduce((acc, curr) => {
    const last = acc.at(-1);
    if (last && periodCheckForLevel[level](last.hour, curr.hour)) {
      for (const prop in curr) {
        if (prop === "hour") continue;
        // @ts-ignore
        last[prop] += curr[prop];
      }
      return acc;
    } else {
      return [...acc, { ...curr }];
    }
  }, [] as T[]);

  const filledSerie: T[] = [];
  let current = dayjs(range[0]);
  const end = dayjs(range[1]);

  const step = (() => {
    switch (level) {
      case "hour":
        return { amount: 1, unit: "hour" as const };
      case "6-hour":
        return { amount: 6, unit: "hour" as const };
      case "12-hour":
        return { amount: 12, unit: "hour" as const };
      case "date":
        return { amount: 1, unit: "day" as const };
      case "week":
        return { amount: 1, unit: "week" as const };
      case "month":
        return { amount: 1, unit: "month" as const };
      default:
        return { amount: 1, unit: "hour" as const };
    }
  })();

  let dataIndex = 0;

  while (current.isBefore(end) || current.isSame(end)) {
    const currentDate = current.toDate();
    const currentData = aggregatedSerie[dataIndex];

    if (
      currentData &&
      periodCheckForLevel[level](currentData.hour, currentDate)
    ) {
      filledSerie.push(currentData);
      dataIndex++;
    } else {
      const defaultData = { ...data[0], hour: currentDate } as T;
      for (const key in defaultData) {
        if (key !== "hour") {
          //@ts-ignore
          defaultData[key] = 0;
        }
      }
      filledSerie.push(defaultData);
    }

    current = current.add(step.amount, step.unit);
  }

  return filledSerie;
}

export const dateFormatterForPeriod: Record<Period, (value: Date) => string> = {
  hour: (value) => {
    return new Date(value).toLocaleDateString(undefined, {
      localeMatcher: "best fit",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: false,
    });
  },
  "6-hour": (_value) => {
    const value = dayjs(_value);
    const start = value.toDate(),
      end = value.add(6, "hours").toDate();
    return Intl.DateTimeFormat(undefined, {
      localeMatcher: "best fit",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: false,
    }).formatRange(start, end);
  },
  "12-hour": (_value) => {
    const value = dayjs(_value);
    const start = value.toDate(),
      end = value.add(12, "hours").toDate();
    return Intl.DateTimeFormat(undefined, {
      localeMatcher: "best fit",
      month: "short",
      day: "numeric",
      hour: "numeric",
      hour12: false,
    }).formatRange(start, end);
  },
  date: (value) => {
    return new Date(value).toLocaleDateString(undefined, {
      localeMatcher: "best fit",
      month: "short",
      day: "numeric",
    });
  },
  week: (_value) => {
    const value = dayjs(_value);
    const start = value.toDate(),
      end = value.add(1, "week").toDate();
    return Intl.DateTimeFormat(undefined, {
      localeMatcher: "best fit",
      month: "short",
      day: "numeric",
    }).formatRange(start, end);
  },
  month: (value) => {
    return new Date(value).toLocaleDateString(undefined, {
      localeMatcher: "best fit",
      month: "short",
    });
  },
};
