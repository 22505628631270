import * as React from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import OnboardingCompanyPage from "./page";
import { OnboardingCompanyLoaderLoader } from "./loader";
import { OnboardingCompanyAction } from "./action";

export interface IOnboardingCompanyRouterProps {}

export default function OnboardingCompanyRouter(
  props: IOnboardingCompanyRouterProps,
) {
  return (
    <Route>
      <Route
        index
        loader={OnboardingCompanyLoaderLoader}
        action={OnboardingCompanyAction}
        Component={OnboardingCompanyPage}
      />
    </Route>
  );
}
