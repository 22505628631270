import * as z from "@/lib/zod";
import create, { UseBoundStore } from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

export function createStore<T extends z.AnyZodObject>(
  name: string,
  schema: T,
  initialState: z.input<T>,
) {
  type StoreType = z.output<T>;
  const init = schema.parse(initialState);
  const store = create<StoreType>()(
    persist(
      immer(() => init),
      { name: `${name}:0192be87-90e8-755f-b8f5-b0ebfca5e408` },
    ),
  );
  const currentData = store.getState();
  const validation = schema.safeParse(currentData);
  if (!validation.success) store.setState(schema.parse(initialState), true);
  const _store = store as typeof store & { reset(): void };
  _store.reset = () => store.setState(init, true);
  return _store;
}
