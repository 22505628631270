import { listSchedules, reorderSchedules } from "@/api/schedules";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";
import * as z from "@/lib/zod";
import { ADVERTISEMENT_TYPE, ListSchedulesFiltersDTO } from "@/api/definitions";
import dayjs from "@/lib/date";
import { listAdvertisements } from "@/api/advertisements";
import { deepMergeAll } from "@/lib/entries";
import { removeDuplicate } from "@/lib/array";
import { getCurrentUser } from "@/api/authentication";
import { getOwnedWidget } from "@/api/widgets";

export interface IWidgetSchedulesLoaderData {
  schedules: ReturnType<typeof listSchedules>;
  advertisements: ReturnType<typeof listAdvertisements>;
}

export enum ScheduleStatus {
  PAST = "past",
  ACTIVE = "active",
  FUTURE = "future",
  ALL = "all",
}
export const WidgetScheduleLoaderQueryParamsSchema = z.object({
  status: z.nativeEnum(ScheduleStatus).default(ScheduleStatus.ACTIVE),
});

export interface WidgetScheduleLoaderQueryParamsDTO
  extends z.infer<typeof WidgetScheduleLoaderQueryParamsSchema> {}

const filtersForStatus: Record<
  ScheduleStatus,
  Omit<ListSchedulesFiltersDTO, "widgetIds">
> = {
  [ScheduleStatus.ALL]: { sort: "desc" },
  [ScheduleStatus.PAST]: {
    to: dayjs().startOf("hour").toDate(),
    ongoing: false,
    sort: "desc",
  },
  [ScheduleStatus.ACTIVE]: { ongoing: true },
  [ScheduleStatus.FUTURE]: {
    from: dayjs().startOf("hour").toDate(),
    ongoing: false,
  },
};
export async function WidgetSchedulesLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  if (!params.widgetId) throw new Error("No widget selected");
  const url = new URL(request.url);
  const query = z
    .searchParams(WidgetScheduleLoaderQueryParamsSchema, "safeParse")
    .parse(url.searchParams);
  if (!query.success) throw redirect(url.pathname);
  const widget = getOwnedWidget(params.widgetId, request.signal);
  const schedules = listSchedules(
    {
      widgetIds: [params.widgetId],
      ...filtersForStatus[query.data.status],
    },
    request.signal,
  );
  return defer({
    schedules: schedules.then((res) => {
      if (query.data.status !== ScheduleStatus.ACTIVE) return res;
      res.results = reorderSchedules(res.results);
      return res;
    }),
    advertisements: schedules.then(async (res) => {
      console.log("widget schedules loading ads");
      const ads = await Promise.all(
        Object.values(ADVERTISEMENT_TYPE).map(async (type) => {
          const schedulesWithType = res.results.filter(
            (s) => s.advertisement.type === type,
          );
          if (!schedulesWithType[0])
            return {
              meta: { count: 0 },
              filters: {},
              results: [],
            };
          return listAdvertisements(
            {
              type,
              ids: Array.from(
                new Set(
                  schedulesWithType.map((sch) => sch.advertisement_version_id),
                ),
              ) as [string, ...string[]],
              company_id: (await widget)!.company_id ?? undefined,
              all_visible: true,
            },
            request.signal,
          );
        }),
      );
      return deepMergeAll(...ads);
    }),
  } satisfies IWidgetSchedulesLoaderData);
}
