import * as React from "react";
import {
  useLoaderData,
  useRouteLoaderData,
  useSearchParams,
} from "react-router-dom";
import { ICatalogLoaderData } from "./loader";
import Awaited from "@/components/awaited";
import TabSelector from "@/components/tab-selector";
import { ADVERTISEMENT_TYPE, AdvertisementTypeSchema } from "@/api/definitions";
import { useRouteNavigation } from "@/hooks/use-route-navigation";
import CheckboxDropdown from "@/components/checkbox-dropdown";
import { entries, fromEntries } from "@/lib/entries";
import { useColumns } from "@/hooks/useColumns";
import { columnsForType } from "./(blocks)/columns";
import { Button } from "@/components/ui/button";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCaption,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import SmartPagination from "@/components/smart-pagination";
import { pick } from "@/lib/pick";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import { ContentForType, listAdvertisements } from "@/api/advertisements";
import { useRevalidateCache } from "@/hooks/use-invalidate";
import { HeaderBreadCrumb } from "@/components/header";
import CatalogRow from "./(blocks)/catalog-row";
import { TabLayoutTitle } from "@/components/tab-layout";
import SearchSelect from "@/components/search-select";
import { IRootLoaderData } from "@/pages/loader";
import { capitalize, formatZoneTree, normalize } from "@/lib/format";
import { IntegerState, useSearchState } from "@/hooks/use-search-state";
import { join } from "@/lib/promise";
import { withId } from "@/lib/array";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import DateRangeSelector from "@/components/date-range-selector";
import {
  ITimePeriod,
  useNextTimePeriods,
  useTimePeriods,
} from "@/hooks/use-time-periods";
import { ConfigType, parseDate } from "@/lib/date";
import { Dayjs } from "dayjs";

const typeTabList = fromEntries(
  entries(ContentForType).map(([k, v]) => [
    k,
    { Icon: v.icon, label: v.shortLabel },
  ]),
);

const limit = 50;
const placeholderRows = Array.from({ length: limit }, () =>
  crypto.randomUUID(),
);

export interface ICatalogPageProps {}

export default function CatalogPage(props: ICatalogPageProps) {
  const navigation = useRouteNavigation("/app/catalog");
  useRevalidateCache(listAdvertisements);
  const data = useLoaderData() as ICatalogLoaderData;
  const rootData = useRouteLoaderData("root") as IRootLoaderData;
  const [searchParams, setSearchParams] = useSearchParams();
  const [setType, type] = useSearchState<ADVERTISEMENT_TYPE>({
    key: "type",
    defaultValue: ADVERTISEMENT_TYPE.EVENT,
    // @ts-ignore
    parse: (v) => AdvertisementTypeSchema.parse(v),
    middleware(s) {
      s.set("offset", "0");
    },
  });
  const [setOffset, offset] = useSearchState({
    key: "offset",
    defaultValue: 0,
    ...IntegerState,
  });
  const [setSearch, ...search] = useSearchState<string>({
    key: "query",
    parse: normalize,
    serialize: normalize,
    delay: 300,
    middleware(s) {
      s.set("offset", "0");
    },
  });
  const [setZones, ...zones] = useSearchState({
    key: "zoneId",
    ...IntegerState,
    middleware(s) {
      s.set("offset", "0");
    },
  });
  const [setCategories, ...categories] = useSearchState({
    key: "categories",
    ...IntegerState,
    middleware(s) {
      s.set("offset", "0");
    },
  });
  const periods = useNextTimePeriods();
  const [
    setDates,
    from = periods["90j"].value[0],
    to = periods["90j"].value[1],
  ] = useSearchState<ConfigType | undefined>({
    key: "dates",
    delay: 0,
    //@ts-ignore
    parse: (_value) => parseDate(_value),
    serialize: (v: ConfigType) => parseDate(v)?.toISOString(),
    middleware(s) {
      s.set("offset", "0");
    },
  });

  const page = Math.floor(offset / limit);
  function setPage(newPage: number) {
    setOffset(newPage * limit);
  }

  const COLUMNS = React.useMemo(() => columnsForType(type), [type]);
  const [columns, columnsState, setColumnState] = useColumns(
    "catalogue",
    COLUMNS,
  );

  return (
    <>
      <HeaderBreadCrumb chunks="Catalogue" />
      <div className="top-4 bg-background z-20 sticky w-full">
        <div className="max-w-7xl mx-auto flex flex-col flex-wrap gap-4 w-full items-end pb-4 border-b border-b-border">
          <TabLayoutTitle className="text-2xl font-semibold leading-none tracking-tight">
            Catalogue d'annonces
          </TabLayoutTitle>
          {data.enabledTypes.length > 1 ? (
            <div className="flex justify-between gap-2 w-full">
              <TabSelector
                disabled={navigation.state}
                tabs={pick(typeTabList, ...data.enabledTypes)}
                value={type}
                onTabChange={setType}
              />
            </div>
          ) : null}
          <div className="flex items-center gap-2 w-full enter animation-small animation-fast">
            <Input
              className="w-96 shrink-0 bg-card"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Rechercher..."
            />
            <Awaited
              loading={navigation.state}
              resolve={data.advertisements}
              fallback={<Skeleton className="h-4 w-20 shrink-0" />}
              render={({ meta: { count } }) => (
                <span className="text-sm text-muted-foreground shrink-0 ">
                  {count} résultat{count !== 1 ? "s" : ""}
                </span>
              )}
            />
            <div className="filler" />
            <CheckboxDropdown
              items={Object.values(columns)}
              values={fromEntries(
                entries(columnsState).map(([k, s]) => [k, s.visible]),
              )}
              onChange={(column, visible) =>
                setColumnState({ state: { [column]: { visible } } })
              }
            >
              <Button variant="outline" size="sm" className="border-dashed">
                Colonnes
              </Button>
            </CheckboxDropdown>
          </div>
          <div className="flex items-center gap-2 w-full enter animation-small animation-fast">
            <Awaited
              resolve={rootData.categories}
              fallback={
                <Skeleton className="min-w-[180px] rounded-sm h-[42px] bg-muted-foreground/30" />
              }
              render={(categoryList) => (
                <CheckboxDropdown
                  searchEnabled
                  items={categoryList.map((c) => ({
                    value: c.id + "",
                    label: capitalize(c.name),
                  }))}
                  values={fromEntries(categories.map((c) => [c + "", true]))}
                  onChange={(_, __, newCategories) => {
                    console.log(
                      "newCategories",
                      entries(newCategories),
                      entries(newCategories)
                        .filter(([, checked]) => checked)
                        .map((z) => +z[0]),
                    );
                    setCategories(
                      entries(newCategories)
                        .filter(([, checked]) => checked)
                        .map((z) => +z[0]),
                    );
                  }}
                >
                  <Button
                    variant="outline"
                    size="sm"
                    {...props}
                    className="w-[180px] justify-start h-[42px]"
                  >
                    {!categories.length
                      ? "Toutes les catégories"
                      : categories.length === 1
                        ? capitalize(
                            categoryList.find(withId(categories[0]))?.name,
                          )
                        : `${categories.length} catégories...`}
                  </Button>
                </CheckboxDropdown>
              )}
            />
            <Awaited
              resolve={rootData.zoneTree}
              fallback={
                <Skeleton className="min-w-[180px] rounded-sm h-[42px] bg-muted-foreground/30" />
              }
              render={(zoneTree) => (
                <CheckboxDropdown
                  searchEnabled
                  items={
                    formatZoneTree(zoneTree, (z) => ({
                      value: z.id + "",
                      label: z.name,
                    })).children?.[0]?.children ?? []
                  }
                  values={fromEntries(zones.map((z) => [z + "", true]))}
                  onChange={(_, __, newZones) =>
                    setZones(
                      entries(newZones)
                        .filter(([, checked]) => checked)
                        .map((z) => +z[0]),
                    )
                  }
                >
                  <Button
                    variant="outline"
                    size="sm"
                    {...props}
                    className="w-[180px] justify-start h-[42px]"
                  >
                    {!zones.length ? (
                      "Toutes les zones"
                    ) : zones.length === 1 ? (
                      <Awaited
                        resolve={rootData.zones}
                        render={(zoneList) =>
                          capitalize(zoneList.find(withId(zones[0]))?.name)
                        }
                      />
                    ) : (
                      `${zones.length} zones...`
                    )}
                  </Button>
                </CheckboxDropdown>
              )}
            />
            <DateRangeSelector
              className="bg-card min-w-[200px]"
              periods={Object.values(periods) as ITimePeriod[]}
              value={[from, to]}
              setValue={setDates}
              disabled={navigation.state !== "idle"}
            />
            {searchParams.size ? (
              <Button
                variant="link"
                className="text-xs underline text-muted-foreground"
                onClick={() => setSearchParams(new URLSearchParams())}
              >
                Réinitialiser les filtres
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      <Awaited
        // loading={navigation.state}
        resolve={data.isEnabledTypes}
        render={(enable) => {
          if (!enable)
            return (
              <div
                className="w-full max-w-7xl rounded-sm mx-auto !mt-8 shrink bg-muted flex items-center justify-center"
                style={{
                  height: `calc(100vh - 64px - 8px - 153px - 32px - 32px)`,
                }}
              >
                Not enabled
              </div>
            );
        }}
      />
      <div className="mx-auto max-w-7xl w-full overflow-auto space-y-4">
        <ScrollArea className="border border-border rounded-md">
          <Table>
            <Awaited
              loading={navigation.state}
              resolve={data.advertisements}
              render={(advertisements) => {
                if (!advertisements.results.length)
                  return (
                    <TableCaption className="mb-4">Aucun résultat</TableCaption>
                  );
                return null;
              }}
            />
            <TableHeader>
              <TableRow>
                {entries(columns)
                  .filter(([key]) => columnsState[key].visible)
                  .map(([_, { value, header: Header }]) => (
                    <Header key={value} />
                  ))}
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <Awaited
                loading={navigation.state}
                resolve={data.advertisements}
                fallback={placeholderRows.map((k) => (
                  <CatalogRow.Skeleton key={k} columnState={columnsState} />
                ))}
                render={(
                  advertisements: Awaited<(typeof data)["advertisements"]>,
                ) => {
                  return advertisements.results.map((advertisement) => (
                    <CatalogRow
                      key={advertisement.id}
                      advertisement={advertisement}
                      columnState={columnsState}
                    />
                  ));
                }}
              />
            </TableBody>
          </Table>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
        <Awaited
          loading={navigation.state}
          resolve={data.advertisements}
          fallback={
            <Skeleton className="h-10 w-[430px] mx-auto max-w-screen shrink" />
          }
        >
          {({ meta: { count } }) => (
            <SmartPagination
              count={count}
              page={page}
              setPage={setPage}
              perPage={limit}
            />
          )}
        </Awaited>
      </div>
    </>
  );
}
