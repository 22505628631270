import { HeaderBreadCrumb } from "@/components/header";
import { Link, useLoaderData } from "react-router-dom";
import { IAppLoaderData } from "./loader";
import AwaitedWidgetList from "@/components/awaited-widget-list";
import Awaited from "@/components/awaited";
import CollapsibleGrid from "@/components/collapsible-grid";
import { Crown } from "lucide-react";
import { capitalize } from "@/lib/format";
import { Skeleton } from "@/components/ui/skeleton";
import CreateAdvertisementButton from "@/components/create-advertisement-button";
import dayjs from "@/lib/date";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { SUBSCRIPTION_TYPE } from "@/api/definitions";
export interface IDashboardProps {}

const placeholderCards = Array.from({ length: 4 }, () => crypto.randomUUID());

export default function Dashboard(props: IDashboardProps) {
  const data = useLoaderData() as IAppLoaderData;
  return (
    <div className="mx-auto grid grid-cols-1 w-full max-w-[59rem] auto-rows-max gap-16 pb-10">
      <HeaderBreadCrumb
        chunks={[
          { label: "Dashboard", url: "/app" },
          data.session.company!.name,
        ]}
      />
      <Awaited
        resolve={data.session}
        render={({ company }) => {
          if (
            !company ||
            company.subscription === SUBSCRIPTION_TYPE.PREMIUM ||
            !company.trialEnd ||
            dayjs().isSameOrAfter(company.trialEnd)
          )
            return null;
          return (
            <Alert>
              <Crown className="w-5 h-5" />
              <AlertTitle>Passez premium</AlertTitle>
              <AlertDescription>
                <p>
                  Vous profitez des fonctionnalités premium gratuitement
                  jusqu'au {dayjs(company?.trialEnd).format("ll")}.
                </p>
                <p>
                  Pour conserver les fonctionnalités de programmation et
                  diffusion avancée,{" "}
                  <Link to="/app/settings/subscription" className="underline">
                    souscrivez à un abonnement
                  </Link>
                  .
                </p>
              </AlertDescription>
            </Alert>
          );
        }}
      />

      <div hidden className="space-y-4">
        <h1 className="text-2xl font-semibold leading-none tracking-tight shrink-0">
          Performances
        </h1>
        <p className="tracking-wider">
          (WIP) Résumé des performances des widgets/annonces
        </p>
      </div>

      <CollapsibleGrid
        variant="small"
        title={
          <div className="flex items-center justify-between space-x-6 w-full max-sm:flex-wrap">
            <h1 className="text-2xl font-semibold leading-none tracking-tight shrink-0">
              Mes annonces
            </h1>
            <div className="w-6 h-6 font-semibold flex items-center justify-center text-sm bg-muted rounded-full shrink-0">
              <Awaited
                resolve={data.advertisements}
                render={(advertisements) => advertisements.meta.count}
              />
            </div>
            <CreateAdvertisementButton
              variant="outline"
              className="hidden sm:flex"
            />
            <div className="filler hidden sm:flex" />
            <Link
              to="/app/advertisements"
              className="shrink-0 text-muted-foreground text-sm font-medium max-sm:!mr-6"
            >
              Tout afficher
            </Link>
          </div>
        }
      >
        <Awaited
          resolve={data.advertisements}
          errorElement={<p>Error loading advertisements!</p>}
          fallback={placeholderCards.map((key) => (
            <div
              key={key}
              className="border border-border rounded-lg bg-card overflow-hidden opacity-50"
            >
              <Skeleton className="w-full aspect-[2] bg-muted-foreground rounded-sm" />
              <div className="p-4">
                <Skeleton className="my-1 w-20 h-6" />
                <Skeleton className="my-1 w-20 h-5" />
              </div>
            </div>
          ))}
          render={(advertisements) => {
            return advertisements.results.slice(0, 8).map((advertisement) => (
              <Link
                to={`/app/advertisements/${advertisement.id}`}
                className="enter animation-fast"
                key={advertisement.id}
              >
                <div className="border border-border rounded-lg bg-card overflow-hidden p-0.5">
                  <img
                    src={
                      advertisement.main_image ?? advertisement.landscape_image
                    }
                    className="w-full h-auto aspect-[2] object-cover rounded-sm border border/50"
                  />
                  <div className="px-3 pt-1 pb-2">
                    <div className="my-1 text-sm tracking-wide truncate text-medium">
                      {capitalize(advertisement.title)}
                    </div>
                    <div className="my-1 text-xs truncate text-muted-foreground">
                      {advertisement.location_name}
                    </div>
                  </div>
                </div>
              </Link>
            ));
          }}
        />
      </CollapsibleGrid>
      <AwaitedWidgetList resolve={data.widgets} max={4} />
    </div>
  );
}
