import { timer } from "@/lib/timer";
import deepEqual from "deep-equal";
import * as React from "react";
import { useDebounce } from "./use-debounce";

export interface IOptimisticUpdateFunction<T> {
  (newState: T, revert: () => void): void;
}
export function useOptimistic<T>(
  state: T,
  updateFn: IOptimisticUpdateFunction<T> = () => {},
  delay = 0,
) {
  const [optimistic, setOptimistic] = React.useState(state);
  const previousState = React.useRef<T>();
  const hasBeenModified = React.useRef(false);
  React.useEffect(() => {
    if (!deepEqual(state, optimistic)) {
      hasBeenModified.current = true;
      const { clear } = timer(() => {
        updateFn(optimistic, () => setOptimistic(state));
        hasBeenModified.current = false;
      }, delay).trigger();
      return () => {
        hasBeenModified.current = false;
        clear();
      };
    }
  }, [optimistic]);
  React.useEffect(() => {
    if (deepEqual(state, previousState.current)) return;
    setOptimistic(state);
    return () => {
      previousState.current = state;
    };
  }, [state]);
  return [
    optimistic,
    setOptimistic,
    !Object.is(state, optimistic),
    hasBeenModified.current,
  ] as const;
}
