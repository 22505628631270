import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  ArrowLeft,
  ArrowUp,
  ChevronDown,
  CogIcon,
  Home,
  Loader,
  Plus,
  RedoIcon,
  UndoIcon,
  UploadIcon,
} from "lucide-react";
import * as React from "react";
import { useFetcher, useLoaderData, useNavigate } from "react-router-dom";
import { ISingleDesignLoaderData } from "./loader";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Textarea } from "@/components/ui/textarea";
import { useAbortEffect } from "@/hooks/use-abort-effect";
import {
  aiCustomizationUpdate,
  defaultCustomizationContent,
  listCompanyCustomizations,
} from "@/api/customizations";
import { CustomizationContentDTO, CustomizationDTO } from "@/api/definitions";
import { cn } from "@/lib/utils";
import { deepMerge } from "@/lib/pick";
import { useRevalidateCache } from "@/hooks/use-invalidate";
import FormState from "@/components/form-state";
import deepEqual from "deep-equal";
import { useHistory } from "@/hooks/use-undo-state";

export interface ISingleDesignPageProps {}

export default function SingleDesignPage(props: ISingleDesignPageProps) {
  useRevalidateCache(listCompanyCustomizations);
  const { customization, customizations } =
    useLoaderData() as ISingleDesignLoaderData;
  return (
    <SingleDesignContent
      customization={customization}
      customizations={customizations}
      key={customization.id}
    />
  );
}
export interface ISingleDesignContentProps {
  customization: CustomizationDTO;
  customizations: CustomizationDTO[];
}
export function SingleDesignContent({
  customization,
  customizations,
}: ISingleDesignContentProps) {
  const {
    state: updatedCustomization,
    set,
    ...actions
  } = useHistory<CustomizationContentDTO>(() =>
    deepMerge(defaultCustomizationContent, customization.content),
  );
  const isTouched = React.useMemo(
    () => !deepEqual(customization.content, updatedCustomization),
    [updatedCustomization],
  );
  const [input, setInput] = React.useState("");
  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  useHistory;
  useAbortEffect(
    async (signal) => {
      if (!isLoading) return;
      setInput("");
      const newCustom = await aiCustomizationUpdate(
        [input].filter(Boolean),
        updatedCustomization.styles ?? {},
      ).catch(() => ({ error: true }) as const);
      signal.throwIfAborted();
      if ("error" in newCustom && newCustom.error) {
        console.log(newCustom);
        setError(true);
      } else {
        const merged = deepMerge(updatedCustomization, {
          styles: newCustom as NonNullable<
            CustomizationDTO["content"]["styles"]
          >,
        });
        console.log("newCustom", newCustom, merged);
        set(merged);
      }
      setIsLoading(false);
    },
    [isLoading, setError],
  );
  function submit() {
    if (isLoading) return;
    setError(false);
    setIsLoading(true);
  }

  React.useLayoutEffect(() => {
    try {
      (
        document.querySelector("#demo-widget") as {
          setOptions?(custom: CustomizationContentDTO, replace?: boolean): void;
        }
      )?.setOptions?.(updatedCustomization, false);
    } catch (e) {
      console.error(e);
      setError(true);
    }
  }, [updatedCustomization]);
  useAbortEffect(
    (signal) => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (
          event.key.toLowerCase() === "z" &&
          (event.ctrlKey || event.metaKey)
        ) {
          event.preventDefault();
          actions[event.shiftKey ? "redo" : "undo"]();
        }
      };

      window.addEventListener("keydown", handleKeyDown, { signal });
    },
    [actions.redo, actions.undo],
  );
  const navigate = useNavigate();
  const fetcher = useFetcher();
  return (
    <>
      <div className="w-fit max-h-full flex items-start gap-2 pointer-events-auto">
        <Button
          size="icon"
          variant="secondary"
          className="rounded-full shadow bg-card shrink-0"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft className="w-4 h-4" />
        </Button>
        <Button
          size="icon"
          variant="secondary"
          className="rounded-full shadow bg-card shrink-0"
          onClick={() => navigate("/app")}
        >
          <Home className="w-4 h-4" />
        </Button>
        <Collapsible className="group">
          <Card className="group-[state=closed]:rounded-3xl">
            <CollapsibleTrigger>
              <CardHeader className="py-3 px-4 cursor-pointer">
                {/* <CardDescription>Modifier le design</CardDescription> */}
                <CardTitle className="text-sm flex items-center gap-4 cursor-pointer">
                  {customization.name} <ChevronDown className="w-4 h-4" />
                </CardTitle>
              </CardHeader>
            </CollapsibleTrigger>
            <CollapsibleContent>
              <CardContent className="px-2 py-2 flex flex-col gap-1 border-t border-t-border">
                <Button
                  size="sm"
                  className="w-full px-2 justify-start gap-2"
                  onClick={() => navigate(`/app/design/new`)}
                >
                  <Plus className="w-4 h-4" />
                  Nouveau design
                </Button>
                {/* <Separator orientation="horizontal" className="mt-2" /> */}
                {customizations
                  .filter((c) => c.id !== customization.id)
                  .map((c) => (
                    <Button
                      key={c.id}
                      variant="ghost"
                      className="w-full px-2 justify-start gap-2"
                      onClick={() => navigate(`/app/design/${c.id}`)}
                    >
                      {c.name}
                    </Button>
                  ))}
              </CardContent>
            </CollapsibleContent>
          </Card>
        </Collapsible>
        <fetcher.Form method="PUT">
          <Button
            size="lg"
            type="submit"
            disabled={!isTouched}
            variant="accent"
          >
            <UploadIcon className="w-4 h-4 mr-2" />
            Sauvegarder
          </Button>
          <FormState
            data={{
              customizationId: customization.id,
              content: JSON.stringify(updatedCustomization),
            }}
          />
        </fetcher.Form>
        <Button
          size="icon"
          variant="outline"
          onClick={actions.undo}
          disabled={!actions.canUndo}
        >
          <UndoIcon className="w-4 h-4" />
        </Button>
        <Button
          size="icon"
          variant="outline"
          onClick={actions.redo}
          disabled={!actions.canRedo}
        >
          <RedoIcon className="w-4 h-4" />
        </Button>
      </div>
      <div className="absolute bottom-4 left-4 right-4">
        <div className="pointer-events-auto max-w-screen-sm mx-auto space-y-2">
          {error && (
            <div className="px-4 py-2 bg-destructive text-destructive-foreground rounded-full text-sm w-fit">
              Je ne suis pas en mesure de répondre à votre demande
            </div>
          )}
          <div className="flex items-end gap-2 w-full bg-card rounded-full px-2 py-2 border border-border">
            <Button
              size="icon"
              variant="ghost"
              className="rounded-full shrink-0"
            >
              <CogIcon />
            </Button>
            <Textarea
              className={cn(
                "grow h-10  min-h-0 max-h-20 bg-card flex items-center",
                input ? "border-border" : "border-none",
              )}
              placeholder="Demander à l'IA..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (
                  e.key === "Enter" &&
                  !e.shiftKey &&
                  !e.ctrlKey &&
                  !e.altKey
                ) {
                  e.preventDefault();
                  submit();
                  return null;
                }
              }}
            />
            {isLoading ? (
              <Button
                size="icon"
                className="rounded-full shrink-0 opacity-50 animate-spin"
                onClick={() => setIsLoading(false)}
              >
                <Loader />
              </Button>
            ) : (
              <Button
                size="icon"
                className="rounded-full shrink-0"
                onClick={submit}
              >
                <ArrowUp />
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
