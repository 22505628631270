import { UpdateUserSchema } from "@/api/definitions";
import { updateUser } from "@/api/users";
import { keys } from "@/lib/entries";
import { objectFromFormData } from "@/lib/query";
import { withToast } from "@/lib/with-toast";
import { ActionFunctionArgs } from "react-router-dom";
import * as z from "zod";

export interface IUserSettingsActionData {}
export async function UserSettingsAction({
  request,
}: ActionFunctionArgs): Promise<IUserSettingsActionData> {
  const body = objectFromFormData(await request.formData());
  if (request.method === "PUT") {
    await withToast(() => {
      const { userId, ...payload } = z
        .object({
          userId: z.coerce.number(),
        })
        .and(UpdateUserSchema)
        .parse(body);
      if (keys(payload).length) return updateUser(userId, payload);
    });
  }
  return {};
}
