import { getCurrentUser } from "@/api/authentication";
import { getCompanyUsers } from "@/api/companies";
import { SafeUsersDTO } from "@/api/definitions";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface ITeamSettingsLoaderData {
  users: Promise<SafeUsersDTO[]>;
}

export async function TeamSettingsLoader({ request }: LoaderFunctionArgs) {
  return defer({
    users: getCurrentUser(request.signal).then((res) => {
      if (!res) throw redirect("/auth");
      if (!res.company) throw redirect("/onboarding");
      return getCompanyUsers(res.company.id);
    }),
  } satisfies ITeamSettingsLoaderData);
}
