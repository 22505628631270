import * as React from "react";
import { Separator } from "./ui/separator";
import { cn } from "@/lib/utils";
import { Check, CheckCircle, LucideIcon } from "lucide-react";
import { entries, keys } from "@/lib/entries";
import {
  IOptimisticUpdateFunction,
  useOptimistic,
} from "@/hooks/use-optimistic";

export interface IStepperProps<T extends string>
  extends React.ComponentPropsWithoutRef<"div"> {
  steps: Record<T, { title: string; Icon: LucideIcon }>;
  completedSteps: T[];
  currentStep?: T;
  onStepChange?: IOptimisticUpdateFunction<T>;
}

export function Stepper<T extends string>({
  currentStep: current,
  steps,
  onStepChange,
  completedSteps,
  ...props
}: IStepperProps<T>) {
  const [currentStep, setCurrentStep] = useOptimistic<keyof typeof steps>(
    current ?? keys(steps)[0],
    onStepChange,
  );
  return (
    <div
      {...props}
      className={cn(
        "bg-card gap-3 mx-auto w-fit flex items-center border border-border rounded-2xl p-2 pr-3",
        props.className,
      )}
    >
      {entries(steps).map(([key, { Icon, title }], index, { length }) => {
        const isActive = key === currentStep;
        const isCompleted = completedSteps.includes(key);
        return [
          <div
            key={key}
            className={cn(
              "flex items-center group/stepper-item gap-2",
              isActive || "cursor-pointer",
            )}
            data-active={isActive}
            data-completed={isCompleted}
            onClick={() => {
              if (isActive || !onStepChange) return;
              setCurrentStep(key);
            }}
          >
            <div
              title={title}
              className={`w-11 relative h-11 p-2.5 bg-accent/5 group-data-[active=true]/stepper-item:bg-accent group-data-[active=true]/stepper-item:shadow-lg group-data-[active=true]/stepper-item:shadow-foreground/5 flex items-center justify-center rounded-full`}
            >
              <Icon className="w-full h-full shrink aspect-square text-accent group-data-[active=true]/stepper-item:text-accent-foreground" />
              <Check
                className="hidden group-data-[completed=true]/stepper-item:block w-4 h-4 absolute bottom-0 right-0 bg-accent text-accent-foreground rounded-full p-0.5"
                strokeWidth={4}
              />
            </div>
            <div className="hidden min-w-[120px] group-data-[active=true]/stepper-item:flex flex-col gap-1 font-semibold">
              <span className="text-[10px] text-accent font-bold leading-none">
                Étape {index + 1}/{length}
              </span>
              <h1 className="text-base leading-none">{title}</h1>
            </div>
          </div>,
          <Separator
            orientation="vertical"
            key={`separator${key}`}
            className="h-8 last:hidden"
          />,
        ];
      })}
    </div>
  );
}
