import { NavigateOptions, To, useNavigate } from "react-router-dom";
import { useModifierKeys } from "./use-modifier-keys";

export function useSmartNavigate() {
  const navigate = useNavigate();
  const { cmd, ctrl } = useModifierKeys();
  return ((to: To | number, options?: NavigateOptions) => {
    if (typeof to === "string" && (cmd || ctrl)) open(to, "_blank");
    else if (typeof to === "number") navigate(to);
    else navigate(to, options);
  }) as typeof navigate;
}
