import { cache } from "@/lib/cache";
import { API_BASE, logErrorResponse } from "./common";
import { ZoneTreeSchema, ZonesSchema } from "./definitions";
import ms from "ms";

export const listZones = cache(async function listZones(signal?: AbortSignal) {
  const response = await fetch(`${API_BASE}/zones`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return ZonesSchema.array().parse(data);
}, ms("1 hour"));

export const getZoneTree = cache(async function getZoneTree(
  signal?: AbortSignal,
) {
  const response = await fetch(`${API_BASE}/zones/tree`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return ZoneTreeSchema.parse(data);
}, ms("1 hour"));
