import { WidgetsDTO } from "@/api/definitions";
import { useAsyncValue } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useDeleteWidgetModal } from "@/modals/delete-widget";

export interface IWidgetDeleteProps {}

export default function WidgetDelete(props: IWidgetDeleteProps) {
  const widget = useAsyncValue() as WidgetsDTO;
  const deleteWidgetModal = useDeleteWidgetModal();
  return (
    <Card className="border-destructive enter">
      <CardHeader>
        <CardTitle>Supprimer le widget</CardTitle>
      </CardHeader>
      <CardContent className="pb-6 border-b border-b-border space-y-6">
        <p className="text-sm leading-6 tracking-wide">
          En supprimant le widget, vous n'y aurez plus accès et il ne
          s'affichera plus sur vos sites. Vous n'aurez plus accès aux
          programmations et aux performances de celles-ci, mais les liens de
          partage resteront valides jusqu'à expiration.
        </p>
        <div className="w-full flex justify-start">
          <Button
            variant="destructive"
            size="lg"
            onClick={() => deleteWidgetModal.openModal({ widgetId: widget.id })}
          >
            Supprimer
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
