import * as React from "react";
import { awaited } from "@/components/awaited";
import { IAdvertisementBuilderLoaderData } from "../loader";
import { useAsyncValue } from "react-router-dom";
import {
  StepLayout,
  StepLayoutActions,
  StepLayoutHeader,
  StepLayoutNextAction,
  StepLayoutPreviousAction,
  StepLayoutSubtitle,
  StepLayoutTitle,
} from "@/components/step-layout";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IStepProps } from "./step-props";
import { Button } from "@/components/ui/button";
import dayjs from "@/lib/date";
import { Plus } from "lucide-react";
import { useDraft } from "../../layout";
import { AdvertisementDraftSchema } from "@/api/definitions";
import ScheduleFormItem, {
  NewScheduleItem,
} from "@/components/schedule-form-item";
import { ms } from "@/lib/timer";

export const AdvertisementBuilderSchedules = awaited<
  IAdvertisementBuilderLoaderData,
  IStepProps
>(
  (d) => d.widgets,
  function AdvertisementBuilderSchedules({ next, previous }) {
    const widgets = useAsyncValue() as Awaited<
      IAdvertisementBuilderLoaderData["widgets"]
    >;
    const [{ draft }] = useDraft();
    const form = useForm({
      mode: "onTouched",
      resolver: zodResolver(AdvertisementDraftSchema),
      defaultValues: {
        ...draft,
        schedules: draft.schedules?.length
          ? draft.schedules
          : ([
              {
                widgetId: widgets.results[0].id,
                from: dayjs().add(1, "day").toDate(),
                to: dayjs().add(1, "day").toDate(),
                clic_goal: null,
                impression_goal: null,
                sponsored: true,
              },
            ] as NewScheduleItem[]),
      },
    });
    const { fields, append, remove } = useFieldArray({
      control: form.control,
      name: "schedules",
    });
    const [openedField, setOpenedField] = React.useState<string>();
    React.useEffect(() => {
      setOpenedField(fields.at(-1)?.id);
    }, [fields.length]);
    return (
      <StepLayout className="max-w-5xl">
        <StepLayoutHeader>
          <StepLayoutTitle>
            Comment souhaitez-vous diffuser votre annonce ?
          </StepLayoutTitle>
          <StepLayoutSubtitle>
            Définissez votre campagne en spécifiant des widgets et des plages de
            dates pendant lesquelles diffuser l'annonce.{" "}
            <span className="font-medium text-accent">(Optionnel)</span>
          </StepLayoutSubtitle>
        </StepLayoutHeader>
        <div className="space-y-2">
          {fields.map((field, index) => {
            const values = form.watch(`schedules.${index}`);
            return (
              <ScheduleFormItem
                key={field.id}
                isOpen={openedField === field.id}
                setOpen={(newOpen) =>
                  setOpenedField(newOpen ? field.id : undefined)
                }
                maxDate={draft.dates?.reduce(
                  (acc, cur) => (dayjs(acc).isBefore(cur.to) ? cur.to : acc),
                  new Date(Date.now() + ms("52 w")),
                )}
                data={values}
                widgets={widgets.results}
                setData={(val) => {
                  form.setValue(`schedules.${index}`, val);
                  form.trigger();
                }}
                remove={() => remove(index)}
              />
            );
          })}
        </div>
        <Button
          onClick={() => {
            let from,
              to = (from = dayjs().add(1, "day").toDate()),
              widgetId = widgets.results[0].id;
            if (fields.length) {
              const lastRow = form.watch(`schedules.${fields.length - 1}`);
              from = lastRow.from;
              to = lastRow.to;
              widgetId = lastRow.widgetId;
            }
            append({
              widgetId: widgetId,
              from: from,
              to: to,
              impression_goal: null,
              clic_goal: null,
              sponsored: true,
            });
          }}
        >
          <Plus className="w-4 h-4 mr-2" />
          Ajouter une programmation
        </Button>
        <StepLayoutActions>
          <StepLayoutPreviousAction previous={previous}>
            Retour
          </StepLayoutPreviousAction>
          <StepLayoutNextAction form={form} next={next}>
            {fields.length ? "Confirmer" : "Passer"}
          </StepLayoutNextAction>
        </StepLayoutActions>
      </StepLayout>
    );
  },
);
