import { getCurrentUser } from "@/api/authentication";
import Compose from "@/components/compose";
import Header from "@/components/header";
import Sidebar, { useSidebar } from "@/components/sidebar";
import { useRevalidateCache } from "@/hooks/use-invalidate";
import { cn } from "@/lib/utils";
import { AddOriginProvider } from "@/modals/add-origin";
import { CreateBlacklistProvider } from "@/modals/create-blacklist";
import { CreateScheduleProvider } from "@/modals/create-schedule";
import { CreateWidgetProvider } from "@/modals/create-widget";
import { DeleteCompanyProvider } from "@/modals/delete-company";
import { DeleteUserProvider } from "@/modals/delete-user";
import { DeleteWidgetProvider } from "@/modals/delete-widget";
import { InviteUserProvider } from "@/modals/invite-user";
import { KickUserProvider } from "@/modals/kick-user";
import { LimitReachedProvider } from "@/modals/limit-reached";
import { RemoveOriginProvider } from "@/modals/remove-origin";
import { SitesHelpProvider } from "@/modals/sites-help";
import { UpdateCompanyProvider } from "@/modals/update-company";
import { UpdateScheduleProvider } from "@/modals/update-schedule";
import { ViewSummaryProvider } from "@/modals/view-summary";
import * as React from "react";
import { Outlet } from "react-router-dom";

export interface IAppLayoutProps {}

export default function AppLayout(props: IAppLayoutProps) {
  useRevalidateCache(getCurrentUser);
  const sidebarOpen = useSidebar((state) => state.isOpen);
  return (
    <Compose
      providers={[
        CreateScheduleProvider,
        UpdateScheduleProvider,
        ViewSummaryProvider,
        CreateWidgetProvider,
        DeleteWidgetProvider,
        AddOriginProvider,
        RemoveOriginProvider,
        SitesHelpProvider,
        CreateBlacklistProvider,
        KickUserProvider,
        InviteUserProvider,
        DeleteCompanyProvider,
        UpdateCompanyProvider,
        DeleteUserProvider,
      ]}
    >
      <Sidebar />
      <main
        className={cn(
          "min-h-screen bg-background transition-[margin-left] ease-in-out duration-300 px-4 pb-8 space-y-2",
          sidebarOpen === false ? "lg:ml-[76px]" : "lg:ml-72",
        )}
      >
        <Header />
        <Outlet />
      </main>
    </Compose>
  );
}
