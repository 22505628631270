import { getAdvertisementById } from "@/api/advertisements";
import { getCurrentUser } from "@/api/authentication";
import { ExtractResult } from "@/lib/cache";
import { dependsOn } from "@/lib/promise";
import { LoaderFunctionArgs, defer } from "react-router-dom";

export interface ISingleAdvertisementLoaderData {
  advertisement: Promise<
    ExtractResult<typeof getAdvertisementById> & { isOwned: boolean }
  >;
}

export async function SingleAdvertisementLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  if (!params.advertisementId) throw new Error("No advertisement selected");
  const session = getCurrentUser();
  return defer({
    advertisement: getAdvertisementById(params.advertisementId, request.signal)
      .then(dependsOn(session))
      .then(async (res) => {
        console.log("company_id", res.company_id);
        const { company, isAdmin } = (await session)!;
        return {
          ...res,
          isOwned: isAdmin || res.company_id === company?.id,
        };
      }),
  } satisfies ISingleAdvertisementLoaderData);
}
