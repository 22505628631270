import { WidgetsDTO } from "@/api/definitions";
import * as React from "react";
import { Link, useAsyncValue } from "react-router-dom";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ExternalLink } from "lucide-react";
import { copyText } from "@/lib/copy";

export interface IWidgetSnippetProps {}

export default function WidgetSnippet(props: IWidgetSnippetProps) {
  const widget = useAsyncValue() as WidgetsDTO;
  const code = `<tnj-widget data-selection="${widget.name}" data-app_secret="${decodeURIComponent(widget.public_key)}" data-type="${widget.type}"></tnj-widget>`;
  const script = `<script src="https://widget.timenjoy.fr/main.js" async> </script>`;
  return (
    <Card className="enter animation-fast">
      <CardHeader>
        <CardTitle>Intégration</CardTitle>
      </CardHeader>
      <CardContent className="pb-6 border-b border-b-border space-y-6">
        <p className="text-sm leading-6 tracking-wide">
          Pour fonctionner, le widget a besoin que ces balises HTML soient sur
          votre site, à l'endroit où il doit s'afficher. Ce code est unique pour
          chaque widget.
        </p>
        <div className="mt-4 rounded-xl p-4 pt-4 bg-muted text-secondary-foreground text-sm relative">
          <p className="source-code-pro-font select-all overflow-x-auto">
            {code}
            <br />
            {script}
          </p>
          <div className="absolute bottom-1 rounded-xl right-1 max-h-full">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                copyText(code + "\n" + script);
              }}
            >
              Copier
            </Button>
          </div>
        </div>
      </CardContent>
      <CardFooter className="text-sm font-regular py-4 tracking-wide block">
        En savoir plus sur&nbsp;
        <Link to="" target="_blank" className="text-blue-500 inline">
          Comment intégrer un widget sur son site&nbsp;
          <ExternalLink className="w-4 h-4 inline" />
        </Link>
      </CardFooter>
    </Card>
  );
}
