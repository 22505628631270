import * as React from "react";
import { Route } from "react-router-dom";
import { NewDesignLoader } from "./loader";
import NewDesignEntry from "./page";

export interface INewDesignRouterProps {}

export default function NewDesignRouter(props: INewDesignRouterProps) {
  return <Route index loader={NewDesignLoader} Component={NewDesignEntry} />;
}
