import * as React from "react";
import { Route } from "react-router-dom";
import AdvertisementSchedulesPage from "./page";
import { AdvertisementSchedulesLoader } from "./loader";
import { SchedulesAction } from "@/pages/app/widgets/[id]/schedules/action";

export interface IAdvertisementSchedulesrouterProps {}

export default function AdvertisementSchedulesrouter(
  props: IAdvertisementSchedulesrouterProps,
) {
  return (
    <Route>
      <Route
        index
        Component={AdvertisementSchedulesPage}
        loader={AdvertisementSchedulesLoader}
      />
    </Route>
  );
}
