import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import * as React from "react";
import { useAsyncValue, useFetcher } from "react-router-dom";
import { ISingleAdvertisementLoaderData } from "../../loader";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { useOptimistic } from "@/hooks/use-optimistic";
import FormState from "@/components/form-state";
export interface IAdvertisementVisibilityCardProps {}

export default function AdvertisementVisibilityCard(
  props: IAdvertisementVisibilityCardProps,
) {
  const advertisement = useAsyncValue() as Awaited<
    ISingleAdvertisementLoaderData["advertisement"]
  >;
  const [is_main_version, setVisibility] = useOptimistic(
    advertisement.is_main_version,
  );
  const fetcher = useFetcher();
  if (!advertisement.isOwned) return null;
  return (
    <Card className="enter">
      <CardHeader>
        <CardTitle>Visibilité</CardTitle>
      </CardHeader>
      <CardContent className="pb-6 border-b border-b-border space-y-6">
        <p className="text-sm leading-6 tracking-wide">
          En rendant votre annonce publique, vous autorisez les autres
          utilisateurs a accéder à votre annonce dans le catalogue et l'afficher
          dans leurs widgets.
        </p>
        <div className="flex items-center gap-3">
          <Switch
            id="is_main_version"
            name="is_main_version"
            defaultChecked={advertisement.is_main_version}
            checked={is_main_version}
            onCheckedChange={setVisibility}
          />
          <Label htmlFor="is_main_version" className="font-medium">
            {is_main_version ? "Publique" : "Privée"}
          </Label>
        </div>
      </CardContent>
      <fetcher.Form
        method="PUT"
        action="/app/advertisements/[id]/content?index"
      >
        <FormState
          data={{
            id: advertisement.id,
            type: advertisement.type,
            is_main_version,
          }}
        />
        <CardFooter className="flex justify-end py-4">
          <Button
            type="submit"
            size="sm"
            disabled={is_main_version === advertisement.is_main_version}
          >
            Confirmer
          </Button>
        </CardFooter>
      </fetcher.Form>
    </Card>
  );
}
AdvertisementVisibilityCard.Skeleton = function () {
  return (
    <Card className="opacity-50">
      <CardHeader>
        <CardTitle>Visibilité</CardTitle>
      </CardHeader>
      <CardContent className="pb-6 border-b border-b-border space-y-6">
        <p className="space-y-2">
          <Skeleton className="w-full h-4" />
          <Skeleton className="w-full h-4" />
          <Skeleton className="w-full h-4" />
          <Skeleton className="w-40 h-4" />
        </p>
        <div className="flex items-center gap-3">
          <Skeleton className="w-40 h-5" />
        </div>
      </CardContent>
      <CardFooter className="flex justify-end py-4">
        <Skeleton className="w-40 h-10" />
      </CardFooter>
    </Card>
  );
};
