import * as z from "@/lib/zod";
import { API_BASE, logErrorResponse } from "./common";
import { AdvertisementVersionSchema, BlackListsSchema } from "./definitions";
import { cache } from "@/lib/cache";
import ms from "ms";

export const listCrawlers = cache(async function listBlacklists(
  signal?: AbortSignal,
) {
  const response = await fetch(`${API_BASE}/crawlers`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return z.string().array().parse(data);
}, ms("5 minutes"));

export async function runCrawler(crawlerName: string) {
  const response = await fetch(`${API_BASE}/crawlers/${crawlerName}`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: "{}",
  });
  if (!response.ok) throw await logErrorResponse(response);
  return true;
}
