import { listAdvertisements } from "@/api/advertisements";
import { getCurrentUser } from "@/api/authentication";
import {
  ADVERTISEMENT_TYPE,
  ListAdvertisementsFiltersDTO,
} from "@/api/definitions";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";
import * as z from "@/lib/zod";
import { listOwnedWidgets } from "@/api/widgets";
import { listSchedules } from "@/api/schedules";
import { join } from "@/lib/promise";
import { extractEnabledAdvertisementTypes } from "@/api/companies";
import dayjs from "@/lib/date";

export interface IAdvertisementsLoaderData {
  isEnabledTypes: boolean;
  enabledTypes: ADVERTISEMENT_TYPE[];
  advertisements: ReturnType<typeof listAdvertisements>;
  widgets: ReturnType<typeof listOwnedWidgets>;
  schedules: ReturnType<typeof listSchedules>;
}

export enum ADVERTISEMENT_STATUS {
  ONGOING = "ongoing",
  ENDED = "ended",
  ARCHIVED = "archived",
}
export const AdvertisementStatusSchema = z.nativeEnum(ADVERTISEMENT_STATUS);
export const AdvertisementsLoaderQueryParamsSchema = z.object({
  type: z.nativeEnum(ADVERTISEMENT_TYPE).default(ADVERTISEMENT_TYPE.EVENT),
  query: z
    .string()
    .transform((s) => (s.length < 2 ? undefined : s))
    .optional(),
  limit: z.coerce.number().default(10),
  offset: z.coerce.number().default(0),
  status: AdvertisementStatusSchema.default(ADVERTISEMENT_STATUS.ONGOING),
});

const filtersForStatus: Record<
  ADVERTISEMENT_STATUS,
  Partial<ListAdvertisementsFiltersDTO>
> = {
  [ADVERTISEMENT_STATUS.ARCHIVED]: { archived: true },
  [ADVERTISEMENT_STATUS.ONGOING]: {
    // ongoing: true,
    from: dayjs().subtract(1, "day").endOf("day").toDate(),
    archived: false,
  },
  [ADVERTISEMENT_STATUS.ENDED]: {
    ongoing: false,
    to: dayjs().subtract(1, "day").endOf("day").toDate(),
    archived: false,
  },
};
const typeNotEnableResponse = defer({
  isEnabledTypes: false,
  enabledTypes: [],
  advertisements: Promise.resolve({
    results: [],
    filters: {},
    meta: { count: 0 },
  }),
  widgets: Promise.resolve({
    results: [],
    filters: {},
    meta: { count: 0 },
  }),
  schedules: Promise.resolve({
    results: [],
    filters: {},
    meta: { count: 0 },
  }),
} satisfies IAdvertisementsLoaderData);

export async function AdvertisementsLoader({ request }: LoaderFunctionArgs) {
  console.log("loader");
  const url = new URL(request.url);
  const query = await z
    .searchParams(AdvertisementsLoaderQueryParamsSchema, "safeParse")
    .parseAsync(url.searchParams);
  if (!query.success) {
    throw redirect(url.pathname);
  }
  const session = await getCurrentUser(request.signal);
  if (!session) throw redirect("/auth");
  if (!session?.company?.id) throw redirect("/onboarding");
  const { status, type, ...filters } = query.data;
  if (!extractEnabledAdvertisementTypes(session.company).includes(type)) {
    return typeNotEnableResponse;
  }
  const advertisements = listAdvertisements(
    {
      ...filters,
      type,
      ...filtersForStatus[status],
      company_id: session.company.id,
    },
    request.signal,
  );
  const widgets = listOwnedWidgets(request.signal);
  return {
    isEnabledTypes: true,
    enabledTypes: extractEnabledAdvertisementTypes(session.company),
    advertisements,
    widgets,
    schedules: join(advertisements, widgets).then(([ads, wids]) => {
      if (!ads.results.length || !wids.results.length)
        return { results: [], filters: {}, meta: { count: 0 } };
      return listSchedules(
        {
          advertisementIds: ads.results.map((a) => a.id) as [
            string,
            ...string[],
          ],
          widgetIds: wids.results.map((w) => w.id) as [string, ...string[]],
          from: dayjs().subtract(1, "day").endOf("day").toDate(),
        },
        request.signal,
      );
    }),
  } satisfies IAdvertisementsLoaderData;
}
