import { cache } from "@/lib/cache";
import { getCurrentUser } from "./authentication";
import { API_BASE, logErrorResponse } from "./common";
import {
  ADVERTISEMENT_TYPE,
  CompaniesDTO,
  CompaniesSchema,
  CreateCompanyDTO,
  ListCompaniesDTO,
  SafeUsersDTO,
  SafeUsersSchema,
  UpdateCompanyDTO,
} from "./definitions";
import { objectToSearchParams } from "@/lib/query";
import { wait } from "@/lib/promise";
import { z } from "zod";
import { withId } from "@/lib/array";
import { toast } from "sonner";

export function extractEnabledAdvertisementTypes(
  company: CompaniesDTO | undefined | null,
) {
  const types: ADVERTISEMENT_TYPE[] = [];
  if (!company) return types;
  if (company.eventEnabled) types.push(ADVERTISEMENT_TYPE.EVENT);
  if (company.immoEnabled) types.push(ADVERTISEMENT_TYPE.REAL_ESTATE);
  if (company.jobEnabled) types.push(ADVERTISEMENT_TYPE.JOB);
  return types;
}

export const getCompanyById = cache(async function getCompanyById(
  companyId: CompaniesDTO["id"],
  signal?: AbortSignal,
) {
  const response = await fetch(`${API_BASE}/companies/${companyId}`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return CompaniesSchema.parse(data);
}, "1 hour");

export const getCompanyUsers = cache(async function getCompanyUsers(
  companyId: CompaniesDTO["id"],
  signal?: AbortSignal,
) {
  const response = await fetch(`${API_BASE}/companies/${companyId}/users`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return SafeUsersSchema.array().parse(data);
}, "1 hour");

export const getCompanyInvites = cache(async function getCompanyUsers(
  companyId: CompaniesDTO["id"],
  signal?: AbortSignal,
) {
  const response = await fetch(`${API_BASE}/companies/${companyId}/users`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return SafeUsersSchema.array().parse(data);
}, "1 hour");

export async function createCompany(payload: CreateCompanyDTO) {
  const response = await fetch(`${API_BASE}/companies`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    credentials: "include",
  });
  if (response.status === 409) {
    setTimeout(
      () => toast.error("Une autre entreprise utilise déjà ce nom"),
      200,
    );
    throw new Error("Une autre entreprise utilise déjà ce nom");
  }
  if (!response.ok) throw new Error("Couldn't create company");
  const data = await response.json();
  const createdCompany = CompaniesSchema.parse(data);
  getCurrentUser.invalidate();
  listCompanies.invalidate();
  getCompanyById.invalidate(([id]) => id === createdCompany.id);
  return createdCompany;
}
export async function updateCompany(
  companyId: CompaniesDTO["id"],
  payload: UpdateCompanyDTO,
) {
  const response = await fetch(`${API_BASE}/companies/${companyId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    credentials: "include",
  });
  if (!response.ok) throw new Error("Couldn't update company");
  const data = await response.json();
  const updatedCompany = CompaniesSchema.array().min(1).max(1).parse(data)[0];
  getCurrentUser.invalidate();
  listCompanies.invalidate();
  getCompanyById.invalidate(([id]) => id === companyId);
  return updatedCompany;
}

export async function kickUserFromCompany(
  userId: SafeUsersDTO["id"],
  companyId: CompaniesDTO["id"],
) {
  const response = await fetch(
    `${API_BASE}/companies/${companyId}/users/${userId}`,
    {
      method: "DELETE",
      credentials: "include",
    },
  );
  if (!response.ok) throw new Error("You are not allowed to remove this user");
  getCompanyUsers.invalidate();
  listCompanies.invalidate((_, res) => res?.find(withId(companyId)));
  getCompanyById.invalidate(([id]) => id === companyId);
  return true;
}

export async function deleteCompany(companyId: CompaniesDTO["id"]) {
  const response = await fetch(`${API_BASE}/companies/${companyId}`, {
    method: "DELETE",
    credentials: "include",
  });
  if (!response.ok) throw new Error("Couldn't delete company");
  listCompanies.invalidate((_, res) => res?.find(withId(companyId)));
  getCompanyById.invalidate(([id]) => id === companyId);
  return true;
}

export const listCompanies = cache(async function listCompanies(
  filters: ListCompaniesDTO,
  signal?: AbortSignal,
) {
  const query = objectToSearchParams(filters);
  const response = await fetch(`${API_BASE}/companies?${query}`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return CompaniesSchema.and(
    z.object({
      widgetCount: z.number(),
      userCount: z.number(),
    }),
  )
    .array()
    .parse(data);
}, "1 hour");

export const checkOrigin = cache(async function checkOrigin(
  url: string,
  signal?: AbortSignal,
) {
  try {
    new URL(url);
    const response = await fetch(`${API_BASE}/companies/origin`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url }),
      credentials: "include",
      signal,
    });
    if (!response.ok) throw await logErrorResponse(response);
    const data = await response.text();
    if (data === "false") return false;
    return data;
  } catch (e) {
    console.error(e);
    return false;
  }
}, "1 hour");
