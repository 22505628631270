import _ms from "ms";

export function timer<T extends unknown[]>(
  fn: (...args: T) => void,
  duration: number | StringTime = 1,
  signal?: AbortSignal,
) {
  let timeout: NodeJS.Timeout | undefined = undefined;
  signal?.addEventListener("abort", () => {
    clearTimeout(timeout);
  });
  const _timer = {
    trigger(...args: T) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        fn(...args);
      }, ms(duration));
      return _timer;
    },
    clear() {
      clearTimeout(timeout);
    },
  };
  return _timer;
}

export type TimeUnits =
  | "ms"
  | "s"
  | "second"
  | "seconds"
  | "m"
  | "min"
  | "mins"
  | "minute"
  | "minutes"
  | "hour"
  | "hours"
  | "d"
  | "day"
  | "days"
  | "w"
  | "week"
  | "weeks";
export type StringTime = `${number} ${TimeUnits}`;
export function ms(value: StringTime | number) {
  if (typeof value === "number") return value;
  return _ms(value);
}
