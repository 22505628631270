import { ms, StringTime } from "./timer";

export function dependsOn<T>(promise: PromiseLike<any>, errorMessage?: string) {
  return async function (result: T) {
    if (await promise) return result;
    throw new Error(errorMessage, {
      cause: "Previous promise didn't fulfilled with truthy value",
    });
  };
}

export function join<T extends readonly any[]>(
  ...promises: { [K in keyof T]: Promise<T[K]> }
): Promise<T> {
  return Promise.all(promises);
}

export function wait(duration: number | StringTime = 1_000) {
  return new Promise((r) => setTimeout(() => r(true), ms(duration)));
}
