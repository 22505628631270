import { CachedFunction } from "@/lib/cache";
import React from "react";
import { useAbortEffect } from "./use-abort-effect";

export function useCache<TParams extends unknown[], TResult>(
  fn: CachedFunction<TParams, TResult>,
  ...params: TParams
): Promise<TResult> {
  const [promise, setPromise] = React.useState(new Promise<TResult>(() => {}));
  useAbortEffect((signal) => {
    const lastValue = fn.getLast(...params);
    if (lastValue) {
      setPromise(Promise.resolve(lastValue));
      return;
    }
    const newPromise = fn(...params, signal);
    newPromise.catch(console.error);
    setPromise(newPromise);
  }, params);
  return promise;
}
