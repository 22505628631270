import { listCrawlers } from "@/api/crawlers";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface IAdminActionsLoaderData {
  crawlers: ReturnType<typeof listCrawlers>;
}

export async function AdminActionsLoader({ request }: LoaderFunctionArgs) {
  return defer({
    crawlers: listCrawlers(request.signal),
  } satisfies IAdminActionsLoaderData);
}
