import { ActionsMenu } from "@/components/actions-menu";
import Awaited from "@/components/awaited";
import { TableRow, TableCell } from "@/components/ui/table";
import { MoreHorizontal } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { formatDate } from "@/lib/date";
import { DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { ColumnsState } from "@/hooks/useColumns";
import { UserTableColumnName } from "./columns";
import { listUsers } from "@/api/users";
import UserActions from "./user-actions";
import { Switch } from "@/components/ui/switch";
import { useFetcher } from "react-router-dom";
import { objectToFormData } from "@/lib/query";

export interface IUserRowProps {
  user: Awaited<ReturnType<typeof listUsers>>[number];
  columnState: ColumnsState<UserTableColumnName>;
}

export default function UserRow({ user, columnState }: IUserRowProps) {
  const fetcher = useFetcher();
  return (
    <ActionsMenu Actions={UserActions} data={{ user }}>
      <TableRow key={user.id}>
        <TableCell
          className="font-light source-code-pro-font"
          hidden={!columnState.id.visible}
        >
          #{user.id}
        </TableCell>
        <TableCell hidden={!columnState.name.visible}>
          {user.firstname} {user.lastname}
        </TableCell>
        <TableCell hidden={!columnState.email.visible} className="space-x-2">
          {user.email}
        </TableCell>
        <TableCell hidden={!columnState.role.visible}>
          {
            [
              <Badge
                variant="outline"
                className="bg-accent text-accent-foreground border-accent"
              >
                Administrateur
              </Badge>,
              <Badge className="">Comptable</Badge>,
              <Badge variant="outline">Créateur</Badge>,
            ][(user.policyId ?? 0) - 1]
          }
        </TableCell>
        <TableCell
          hidden={!columnState.company.visible}
          className="whitespace-nowrap max-w-[300px]"
        >
          {user.companyName}
        </TableCell>
        <TableCell hidden={!columnState.active.visible}>
          <Switch
            checked={user.canLogin}
            onCheckedChange={(value) => {
              if (fetcher.state !== "idle") return;
              fetcher.submit(
                objectToFormData({
                  id: user.id,
                  canLogin: value,
                }),
                {
                  method: "PUT",
                  action: "/app/admin/users?index",
                  encType: "multipart/form-data",
                },
              );
            }}
          />
        </TableCell>
        <TableCell
          hidden={!columnState.created_at.visible}
          className="whitespace-nowrap"
        >
          {formatDate(user.createdAt, true)}
        </TableCell>
        <TableCell className="flex justify-end">
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="icon">
              <MoreHorizontal />
            </Button>
          </DropdownMenuTrigger>
        </TableCell>
      </TableRow>
    </ActionsMenu>
  );
}
