import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import * as React from "react";
import { Button } from "@/components/ui/button";
import { useAsyncValue, useFetcher } from "react-router-dom";
import FormState from "@/components/form-state";
import { ISingleAdvertisementLoaderData } from "../../loader";
import { FullEventAdvertisementDTO } from "@/api/definitions";
import { X } from "lucide-react";
import dayjs from "@/lib/date";
import DateRangePicker from "@/components/date-range-picker";
import { cn } from "@/lib/utils";
import { withId } from "@/lib/array";
import { pick } from "@/lib/pick";

export interface IAdvertisementDatesCardProps {}

export default function AdvertisementDatesCard(
  props: IAdvertisementDatesCardProps,
) {
  const advertisement = useAsyncValue() as Awaited<
    ISingleAdvertisementLoaderData["advertisement"]
  > &
    FullEventAdvertisementDTO;
  const [dates, setDates] = React.useState<
    { id?: number; from: Date; to: Date }[]
  >(
    advertisement.dates.sort(({ from: a }, { from: b }) =>
      dayjs(a).isBefore(b) ? -1 : 1,
    ),
  );
  const fetcher = useFetcher();
  // const removedDates = advertisement.dates.filter(
  //   ({ id }) => !dates.find(withId(id)),
  // );
  return (
    <Card className="enter">
      <fetcher.Form
        method="PUT"
        action="/app/advertisements/[id]/content?index"
      >
        <FormState
          data={{
            id: advertisement.id,
            type: advertisement.type,
            removedDates: JSON.stringify(
              advertisement.dates.map((d) => pick(d, "id")),
            ),
            addedDates: JSON.stringify(dates),
          }}
        />
        <CardHeader>
          <CardTitle>Dates</CardTitle>
        </CardHeader>
        <CardContent className="pb-6 border-b border-b-border space-y-3">
          {dates.map((date, idx) => {
            return (
              <DateRangePicker
                key={date.id ?? idx}
                remove={() => setDates((d) => d.filter((_, i) => i !== idx))}
                value={date}
                disabled={!advertisement.isOwned}
                onChange={(date) =>
                  setDates((d) => {
                    const newDates = [...d];
                    newDates[idx] = date;
                    return newDates;
                  })
                }
              />
            );
          })}
        </CardContent>
        <CardFooter
          className={cn(
            "flex justify-end py-4 gap-2",
            advertisement.isOwned || "hidden",
          )}
        >
          <Button
            variant="secondary"
            type="button"
            size="sm"
            onClick={() => {
              setDates((d) => [
                ...d,
                { from: dayjs().toDate(), to: dayjs().add(1, "day").toDate() },
              ]);
            }}
          >
            Ajouter une date
          </Button>
          <Button type="submit" size="sm">
            Confirmer
          </Button>
        </CardFooter>
      </fetcher.Form>
    </Card>
  );
}
