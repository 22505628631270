import { TabLayoutBreadcrumb, TabLayoutContent } from "@/components/tab-layout";
import * as React from "react";
import { useRouteLoaderData } from "react-router-dom";
import { ISettingsLoaderData } from "../loader";
import Awaited from "@/components/awaited";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { AlertTriangle, Bug, InfoIcon, Plus, Trash } from "lucide-react";
import RefPortal from "@/components/ref-portal";
import { useRemoveOriginModal } from "@/modals/remove-origin";
import { useAddOriginModal } from "@/modals/add-origin";
import { useRevalidateCache } from "@/hooks/use-invalidate";
import { getCurrentUser } from "@/api/authentication";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useSitesHelpModal } from "@/modals/sites-help";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

export interface ISettingsSitesPageProps {}

export default function SettingsSitesPage(props: ISettingsSitesPageProps) {
  useRevalidateCache(getCurrentUser);
  const data = useRouteLoaderData("settings-loader") as ISettingsLoaderData;
  const addOriginModal = useAddOriginModal();
  const removeOriginModal = useRemoveOriginModal();
  const siteHelpModal = useSitesHelpModal();
  return (
    <TabLayoutContent>
      <TabLayoutBreadcrumb>Sites</TabLayoutBreadcrumb>
      <RefPortal selector="#tab-layout-head-outlet">
        <Awaited
          resolve={data.session}
          render={(s) => (
            <Button
              onClick={() => {
                addOriginModal.openModal({
                  companyId: s.company!.id,
                  sites: s.company!.authorizedOrigins,
                });
              }}
            >
              <Plus className="h-4 w-4 inline mr-2" />
              Ajouter un site
            </Button>
          )}
        />
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="warning"
              size="icon"
              onClick={siteHelpModal.openModal}
            >
              <Bug className="w-4 h-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Un souci avec un site ?</p>
          </TooltipContent>
        </Tooltip>
      </RefPortal>

      <ScrollArea className="border border-border rounded-md">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>#</TableHead>
              <TableHead>Site</TableHead>
              <TableHead className="text-end">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <Awaited
              resolve={data.session}
              render={(s) =>
                s.company!.authorizedOrigins.map((site, i) => (
                  <TableRow key={site}>
                    <TableCell>#{i}</TableCell>
                    <TableCell>{site}</TableCell>
                    <TableCell className="flex justify-end gap-2">
                      <Button
                        variant="destructive"
                        size="icon"
                        onClick={() =>
                          removeOriginModal.openModal({
                            companyId: s.company!.id,
                            removeSite: site,
                            sites: s.company!.authorizedOrigins.filter(
                              (s) => s !== site,
                            ),
                          })
                        }
                      >
                        <Trash className="w-4 h-4" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              }
            />
          </TableBody>
        </Table>
      </ScrollArea>
      <Alert>
        <InfoIcon className="w-4 h-4" />
        <AlertTitle>Important</AlertTitle>
        <AlertDescription>
          Toute modification de l'url de votre site doit être renseignée en
          amont dans la liste ci-dessus pour éviter une interuption de service.
        </AlertDescription>
      </Alert>
    </TabLayoutContent>
  );
}
