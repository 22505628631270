import * as z from "@/lib/zod";
import { getWidgetsSummaries, getWidgetStats } from "./analytics";
import { API_BASE, logErrorResponse } from "./common";
import {
  CreateWidgetDTO,
  ListResponseSchema,
  ListWidgetsDTO,
  UpdateWidgetDTO,
  WidgetsDTO,
  WidgetsSchema,
} from "./definitions";
import dayjs from "@/lib/date";
import { cache } from "@/lib/cache";
import { withId } from "@/lib/array";
import ms from "ms";
import { objectToSearchParams } from "@/lib/query";

export const listOwnedWidgets = cache(async function listOwnedWidgets(
  signal?: AbortSignal,
) {
  const response = await fetch(`${API_BASE}/widgets`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  const parsed = ListResponseSchema(WidgetsSchema).parse(data);
  parsed.results = parsed.results.sort((a, b) => a.name.localeCompare(b.name));
  return parsed;
}, ms("5 minutes"));

export const listWidgets = cache(async function listWidgets(
  filters: ListWidgetsDTO,
  signal?: AbortSignal,
) {
  const response = await fetch(
    `${API_BASE}/widgets?${objectToSearchParams(filters)}`,
    {
      credentials: "include",
      signal,
    },
  );
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  const parsed = ListResponseSchema(WidgetsSchema).parse(data);
  // parsed.results = parsed.results.sort((a, b) => a.name.localeCompare(b.name));
  return parsed;
}, ms("5 minutes"));

export async function getOwnedWidget(widgetId: string, signal?: AbortSignal) {
  const widgets = await listOwnedWidgets(signal);
  const match = widgets.results.find(withId(widgetId));
  if (!match) throw new Error("Can't access this widget");
  return match;
}

export interface WidgetMetaData {
  origin: string | null;
  impressions: number;
  clics: number;
  interactions: number;
}
export const listWidgetsWithMetaData = cache(
  async function listWidgetsWithMetaData(
    filters: ListWidgetsDTO,
    signal?: AbortSignal,
  ) {
    const widgets = await listWidgets(filters, signal);
    try {
      const analytics = await getWidgetsSummaries(
        widgets.results.map((w) => w.id),
        {
          from: dayjs().subtract(1, "days").endOf("hour").toDate(),
          to: dayjs().endOf("hour").toDate(),
        },
        signal,
      );
      const resultsWithMetaData = widgets.results
        .map((widget) => {
          const stats = analytics.find(withId(widget.id, "widget"));
          if (!stats) return null;
          const bestOrigin = stats.impression_per_origin.sort(
            (a, b) => b.impressions - a.impressions,
          )[0]?.origin;
          const _24hago = dayjs().subtract(1, "day");
          const impressions = stats.timeSerie.reduce((acc, cur) => {
            if (dayjs(cur.hour).isSameOrAfter(_24hago))
              return acc + cur.impressions;
            return acc;
          }, 0);
          const clics = stats.timeSerie.reduce((acc, cur) => {
            if (dayjs(cur.hour).isSameOrAfter(_24hago)) return acc + cur.clics;
            return acc;
          }, 0);
          const interactions = stats.timeSerie.reduce((acc, cur) => {
            if (dayjs(cur.hour).isSameOrAfter(_24hago))
              return acc + cur.interactions;
            return acc;
          }, 0);
          return {
            ...widget,
            origin: bestOrigin || null,
            impressions,
            clics,
            interactions,
          };
        })
        .filter(Boolean) as (WidgetsDTO & WidgetMetaData)[];

      return {
        ...widgets,
        results: resultsWithMetaData.sort((a, b) => {
          if (a.clics !== b.clics) return b.clics - a.clics;
          return b.impressions - a.impressions;
        }),
      };
    } catch (e) {
      console.error(e);
      return widgets;
    }
  },
  {
    ttl: ms("1 second"),
    dependsOn: [listOwnedWidgets, getWidgetsSummaries],
  },
);

export async function createWidget(
  payload: CreateWidgetDTO,
): Promise<WidgetsDTO> {
  const response = await fetch(`${API_BASE}/widgets`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    credentials: "include",
  });
  if (!response.ok) throw new Error("Couldn't create widget");
  const data = await response.json();
  const newWidget = WidgetsSchema.array().min(1).max(1).parse(data)[0];
  listOwnedWidgets.invalidate();
  listWidgetsWithMetaData.invalidate();
  listWidgets.invalidate();
  return newWidget;
}

export async function updateWidget(
  widgetId: string,
  payload: UpdateWidgetDTO,
): Promise<WidgetsDTO> {
  const response = await fetch(`${API_BASE}/widgets/${widgetId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    credentials: "include",
  });
  if (!response.ok) throw new Error("Couldn't update widget");
  const data = await response.json();
  const newWidget = WidgetsSchema.array().min(1).max(1).parse(data)[0];
  listOwnedWidgets.invalidate();
  listWidgetsWithMetaData.invalidate();
  listWidgets.invalidate();
  return newWidget;
}

export async function refreshWidget(widgetId: string): Promise<WidgetsDTO> {
  const response = await fetch(`${API_BASE}/widgets/${widgetId}/content`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
    credentials: "include",
  });
  if (!response.ok) throw new Error("Couldn't refresh widget");
  return await response.json();
}

export async function deleteWidget(widgetId: string) {
  const response = await fetch(`${API_BASE}/widgets/${widgetId}`, {
    method: "DELETE",
    credentials: "include",
  });
  if (!response.ok) throw new Error("Couldn't delete widget");
  listOwnedWidgets.invalidate();
  getWidgetStats.invalidate(([id]) => id === widgetId);
  getWidgetsSummaries.invalidate(([ids]) => ids.includes(widgetId));
  listWidgetsWithMetaData.invalidate();
  listWidgets.invalidate();
  return true;
}

export async function regenerateAllWidgets() {
  try {
    const response = await fetch(`${API_BASE}/widgets/admin`, {
      method: "POST",
      body: "{}",
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    });
    return response.ok;
  } catch (e) {
    return false;
  }
}
