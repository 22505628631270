import { useMapsLibrary } from "@vis.gl/react-google-maps";
import * as React from "react";

interface UseAutoCompleteParams extends google.maps.places.AutocompleteOptions {
  onPlaceSelect: (results: google.maps.places.PlaceResult) => void;
  types: string[];
  fields: string[];
}
export function useAutoComplete({
  onPlaceSelect,
  types,
  fields,
}: UseAutoCompleteParams) {
  const [placeAutocomplete, setPlaceAutocomplete] =
    React.useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const places = useMapsLibrary("places");

  React.useEffect(() => {
    if (!places || !inputRef.current) return;

    const autocomplete = new places.Autocomplete(inputRef.current, {
      types,
      fields,
    });
    setPlaceAutocomplete(autocomplete);
    return autocomplete.unbindAll();
  }, [places, ...types, ...fields]);

  React.useEffect(() => {
    if (!placeAutocomplete) return;

    const listener = placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
    return () => listener.remove();
  }, [onPlaceSelect, placeAutocomplete]);
  return inputRef;
}
