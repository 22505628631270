import { cache } from "@/lib/cache";
import { API_BASE, logErrorResponse } from "./common";
import {
  ListUsersDTO,
  SafeUsersDTO,
  SafeUsersSchema,
  UpdateUserDTO,
} from "./definitions";
import { objectToSearchParams } from "@/lib/query";
import * as z from "@/lib/zod";
import { withId } from "@/lib/array";

export async function updateUser(
  userId: SafeUsersDTO["id"],
  payload: UpdateUserDTO,
  signal?: AbortSignal,
) {
  const response = await fetch(`${API_BASE}/users/${userId}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "PUT",
    credentials: "include",
    body: JSON.stringify(payload),
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  listUsers.invalidate();
  return true;
}

export const listUsers = cache(async function listCompanies(
  filters: ListUsersDTO,
  signal?: AbortSignal,
) {
  const query = objectToSearchParams(filters);
  const response = await fetch(`${API_BASE}/users?${query}`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return SafeUsersSchema.and(
    z.object({
      companyName: z.string().nullish(),
    }),
  )
    .array()
    .parse(data);
}, "1 hour");

export async function deleteUser(useRId: SafeUsersDTO["id"]) {
  const response = await fetch(`${API_BASE}/users/${useRId}`, {
    method: "DELETE",
    credentials: "include",
  });
  if (!response.ok) throw new Error("Couldn't delete user");
  listUsers.invalidate((_, res) => res?.find(withId(useRId)));
  return true;
}
