import { deleteUser } from "@/api/users";
import { objectFromFormData } from "@/lib/query";
import { withToast } from "@/lib/with-toast";
import { ActionFunctionArgs } from "react-router-dom";
import * as z from "@/lib/zod";

export interface IAdminUsersActionData {}
export async function AdminUsersAction({
  request,
}: ActionFunctionArgs): Promise<IAdminUsersActionData> {
  const body = await request.formData();
  if (request.method === "DELETE") {
    await withToast(
      async () => {
        const { id } = z
          .object({ id: z.coerce.number() })
          .parse(objectFromFormData(body));
        await deleteUser(id);
      },
      {
        loading: "Suppression en cours",
        success: "Utilisateur supprimée",
        error: "Impossible de supprimer l'utilisateur",
      },
    );
  }
  return {};
}
