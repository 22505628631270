import { timer } from "@/lib/timer";
import * as React from "react";

export function useSet<T>(initialValue?: Iterable<T> | null) {
  const [set, setSet] = React.useState(new Set(initialValue));
  return React.useMemo(() => {
    const { trigger } = timer(setSet);
    return new Proxy(set, {
      get(target, prop, receiver) {
        const value = target[prop as keyof Set<T>];
        if (["add", "delete", "clear"].includes(prop as string))
          return (arg: T) => {
            (value as Function).call(target, arg);
            trigger(new Set(target));
          };
        else if (typeof value === "function") return value.bind(target);
        else return value;
      },
    });
  }, [set, setSet]);
}
