import Awaited from "@/components/awaited";
import { join } from "@/lib/promise";
import { IRootLoaderData } from "@/pages/loader";
import { useLoaderData, useRouteLoaderData } from "react-router-dom";
import { ISingleAdvertisementLoaderData } from "../loader";
import AdvertisementCategoriesCard from "./(blocks)/categories";
import AdvertisementDatesCard from "./(blocks)/dates";
import AdvertisementDetailsCard from "./(blocks)/details";
import AdvertisementImagesCard from "./(blocks)/images";
import AdvertisementImmoCard from "./(blocks)/immo";
import AdvertisementJobCard from "./(blocks)/job";
import AdvertisementLocationCard from "./(blocks)/location";
import AdvertisementSummaryCard from "@/components/summary";
import AdvertisementVisibilityCard from "./(blocks)/visibility";
import { IAdvertisementContentLoaderData } from "./loader";
import AdvertisementAnnexeCard from "./(blocks)/annexe";
import AdvertisementArchivedCard from "./(blocks)/archive";
import { TabLayoutContent } from "@/components/tab-layout";

export interface IAdvertisementContentPageProps {}

export default function AdvertisementContentPage(
  props: IAdvertisementContentPageProps,
) {
  const { advertisement: Advertisement } = useRouteLoaderData(
    "single-advertisement-route",
  ) as ISingleAdvertisementLoaderData;
  const { summary: Summary } =
    useLoaderData() as IAdvertisementContentLoaderData;
  const { categories } = useRouteLoaderData("root") as IRootLoaderData;

  return (
    <TabLayoutContent className="space-y-0 grid grid-cols-[1fr_auto] gap-6">
      <div className="flex flex-col items-stretch justify-start gap-6">
        <Awaited
          resolve={Advertisement}
          fallback={
            <>
              <AdvertisementDetailsCard.Skeleton />
              <AdvertisementLocationCard.Skeleton />
            </>
          }
        >
          <AdvertisementDetailsCard />
          <AdvertisementLocationCard />
          {(advertisement) =>
            "dates" in advertisement ? (
              <AdvertisementDatesCard />
            ) : "surface" in advertisement ? (
              <AdvertisementImmoCard />
            ) : (
              <AdvertisementJobCard />
            )
          }
          <AdvertisementImagesCard />
          <AdvertisementArchivedCard />
        </Awaited>
      </div>
      <div className="flex flex-col items-stretch justify-start w-80 max-w-xs gap-6 sticky top-0">
        <Awaited
          resolve={Summary}
          fallback={<AdvertisementSummaryCard.Skeleton key="summary" />}
          render={(summary) => (
            <AdvertisementSummaryCard summary={summary} key="summary" />
          )}
        />
        <Awaited
          resolve={Advertisement}
          fallback={<AdvertisementVisibilityCard.Skeleton />}
        >
          <AdvertisementVisibilityCard />
        </Awaited>
        <Awaited resolve={join(Advertisement, categories)}>
          {([advertisement]) =>
            "categories" in advertisement ? (
              <AdvertisementCategoriesCard />
            ) : null
          }
        </Awaited>
        <Awaited resolve={Advertisement}>
          <AdvertisementAnnexeCard />
        </Awaited>
      </div>
    </TabLayoutContent>
  );
}
