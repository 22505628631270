import * as React from "react";
import Awaited from "@/components/awaited";
import { Outlet, useLoaderData, useSearchParams } from "react-router-dom";
import { ISingleAdvertisementLoaderData } from "./loader";
import { Skeleton } from "@/components/ui/skeleton";
import { Archive, BarChart, CalendarDays, SquarePen } from "lucide-react";
import { TabsList } from "@/lib/tabs";
import { Badge } from "@/components/ui/badge";
import { ContentForType } from "@/api/advertisements";
import {
  TabLayout,
  TabLayoutBreadcrumb,
  TabLayoutTitle,
} from "@/components/tab-layout";
import { omit } from "@/lib/pick";
import { join } from "@/lib/promise";

export interface ISingleAdvertisementLayoutProps {}

const tabList = {
  content: { Icon: SquarePen, label: "Contenu" },
  schedules: { Icon: CalendarDays, label: "Programmation" },
  analytics: { Icon: BarChart, label: "Performances" },
} satisfies TabsList<string>;

export default function SingleAdvertisementLayout(
  props: ISingleAdvertisementLayoutProps,
) {
  const data = useLoaderData() as ISingleAdvertisementLoaderData;
  return (
    <Awaited
      fallback={
        <TabLayout tabs={omit(tabList, "analytics")} defaultTab="content">
          <TabLayoutTitle>
            <Skeleton className="w-80 h-6" />
          </TabLayoutTitle>
          <TabLayoutBreadcrumb>
            <Skeleton className="w-20 h-3 max-xs:hidden" />
          </TabLayoutBreadcrumb>
          <Outlet />
        </TabLayout>
      }
      resolve={data.advertisement}
      render={(advertisement) => {
        const Icon = ContentForType[advertisement.type].icon;
        const tabData = advertisement.isOwned
          ? ({ tabs: tabList, defaultTab: "analytics" } as const)
          : ({
              tabs: omit(tabList, "analytics"),
              defaultTab: "content",
            } as const);
        return (
          <TabLayout {...tabData}>
            <TabLayoutTitle>
              <h1 className="text-2xl font-semibold leading-none tracking-tight shrink-0">
                {advertisement.title}
              </h1>
              <div className="filler hidden sm:flex" />
              {advertisement.archivedAt ? (
                <Badge
                  variant="secondary"
                  className="whitespace-nowrap text-sm flex gap-2 px-3 py-1"
                >
                  <Archive className="w-4 h-4" />
                  Archive
                </Badge>
              ) : null}
              <Badge
                variant="secondary"
                className="whitespace-nowrap text-sm flex gap-2 px-3 py-1"
              >
                <Icon className="w-4 h-4" />
                {ContentForType[advertisement.type].shortLabel}
              </Badge>
            </TabLayoutTitle>
            <TabLayoutBreadcrumb
              base={`${advertisement.title} (#${advertisement.id.slice(0, 4)})`}
            />
            <Outlet />
          </TabLayout>
        );
      }}
    />
  );
}
