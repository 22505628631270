import { awaited } from "@/components/awaited";
import SearchSelect from "@/components/search-select";
import {
  StepLayout,
  StepLayoutActions,
  StepLayoutHeader,
  StepLayoutNextAction,
  StepLayoutPreviousAction,
  StepLayoutSubtitle,
  StepLayoutTitle,
} from "@/components/step-layout";
import { Button } from "@/components/ui/button";
import { withId } from "@/lib/array";
import { capitalize } from "@/lib/format";
import { zodResolver } from "@hookform/resolvers/zod";
import { X } from "lucide-react";
import { useForm } from "react-hook-form";
import { useAsyncValue } from "react-router-dom";
import { useDraft } from "../../layout";
import { IAdvertisementBuilderLoaderData } from "../loader";
import { AdvertisementBuilderCategoriesSchema } from "./schemas";
import { IStepProps } from "./step-props";

export const AdvertisementBuilderCategories = awaited<
  IAdvertisementBuilderLoaderData,
  IStepProps
>(
  (d) => d.categories,
  function AdvertisementBuilderCategories({ next, previous }) {
    const allCategories = useAsyncValue() as Awaited<
      IAdvertisementBuilderLoaderData["categories"]
    >;
    const [{ draft }] = useDraft();
    const form = useForm({
      mode: "onChange",
      resolver: zodResolver(AdvertisementBuilderCategoriesSchema),
      defaultValues: {
        categories: (draft.categories || []) as number[],
      },
    });

    const errors = form.formState.errors;
    return (
      <StepLayout>
        <StepLayoutHeader>
          <StepLayoutTitle>
            Ajoutez des catégories à votre événement.
          </StepLayoutTitle>
          <StepLayoutSubtitle>
            Les catégories permettent de mieux indexer les événements dans le
            catalogue d'annonces.
          </StepLayoutSubtitle>
        </StepLayoutHeader>
        <div className="space-y-2">
          <div className="flex gap-3 flex-wrap items-center">
            {form.getValues("categories").map((value) => {
              const category = allCategories.find(withId(value))!;
              return (
                <Button
                  className="cursor-pointer"
                  onClick={() => {
                    form.setValue(
                      "categories",
                      (form.getValues("categories") ?? []).filter(
                        (c) => c !== value,
                      ),
                    );
                    form.trigger();
                  }}
                >
                  {capitalize(category.name)} <X className="w-4 h-4 ml-1.5" />
                </Button>
              );
            })}
          </div>

          <SearchSelect
            key="add-category"
            onSelect={(categoryId) => {
              form.setValue(
                "categories",
                (form.getValues("categories") ?? []).concat(+categoryId),
              );
              form.trigger();
            }}
            options={allCategories
              .filter(
                (c) => !(form.getValues("categories") ?? []).includes(c.id),
              )
              .map(({ id, name }) => ({
                value: id + "",
                label: capitalize(name.toLowerCase()),
              }))}
          >
            <Button variant="secondary" type="button">
              Ajouter une catégorie
            </Button>
          </SearchSelect>
        </div>
        <StepLayoutActions>
          <StepLayoutPreviousAction previous={previous}>
            Retour
          </StepLayoutPreviousAction>
          <StepLayoutNextAction form={form} next={next}>
            Continuer
          </StepLayoutNextAction>
        </StepLayoutActions>
      </StepLayout>
    );
  },
);
