export function formatBigNumbers(n: number | undefined | null) {
  if (n === undefined || n === null) return "-";
  if (n >= 1_000_000_000) return Math.floor(n / 1_000_000_000) + "b";
  if (n >= 1_000_000) return Math.floor(n / 1_000_000) + "m";
  if (n >= 1_000) return Math.floor(n / 1_000) + "k";
  return Math.floor(n) + "";
}

export function formatPercent(n: number | undefined) {
  if (n === undefined) return "-";
  return n.toPrecision(n >= 1 ? 3 : 2) + "%";
}

export function formatUrl(url: string) {
  return url.replace(/https?:\/\/(www\.)?/, "").replace(/\/$/, "");
}

const uncapitalizable = [
  "a",
  "à",
  "au",
  "ad",
  "ah",
  "ai",
  "ce",
  "ces",
  "ci",
  "de",
  "des",
  "du",
  "mais",
  "et",
  "donc",
  "or",
  "car",
  "la",
  "le",
  "les",
  "leur",
  "leurs",
  "ma",
  "me",
  "mon",
  "mes",
  "ne",
  "ni",
  "ou",
  "où",
  "pas",
  "que",
  "quel",
  "quelle",
  "quels",
  "quelles",
  "qu'il",
  "qu'elle",
  "qu'ils",
  "qu'elles",
  "qu'en",
  "qu'alors",
  "sa",
  "se",
  "si",
  "ta",
  "te",
  "ton",
  "tes",
  "y",
];
export function capitalize<T extends string | undefined>(str: T): T {
  if (!str) return str;
  return str
    .split(" ")
    .map((word, i) =>
      word
        .split("-")
        .map((part, j) => {
          if (i + j === 0 || !uncapitalizable.includes(part))
            return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
          return part;
        })
        .join("-"),
    )
    .join(" ") as T;
}

type Formatted<T> = T & { children?: Formatted<T>[] | undefined };
export function formatZoneTree<T, U extends { children?: U[] }>(
  zoneTree: U,
  transform: (t: U) => T,
): Formatted<T> {
  return {
    ...transform(zoneTree),
    children: zoneTree.children?.map((z) => formatZoneTree(z, transform)),
  };
}

export function normalize(str: string): string {
  return str
    .normalize("NFKD")
    .normalize("NFKD")
    .replace(/[\u0300-\u036f]/g, "");
}
