import {
  ADVERTISEMENT_TYPE,
  AdvertisementFluxDTO,
  AdvertisementFluxSchema,
  CategoriesDTO,
  WidgetsDTO,
  ZoneTreeDTO,
  ZonesDTO,
} from "@/api/definitions";
import * as React from "react";
import { useAsyncValue, useFetcher } from "react-router-dom";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { capitalize, formatZoneTree } from "@/lib/format";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { PlusCircle, X } from "lucide-react";
import SearchSelect from "@/components/search-select";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/lib/utils";
import { withId } from "@/lib/array";

export interface IWidgetFluxProps {}

export default function WidgetFlux(props: IWidgetFluxProps) {
  const [widget, categories, zoneTree, zones] = useAsyncValue() as [
    WidgetsDTO,
    CategoriesDTO[],
    ZoneTreeDTO,
    ZonesDTO[],
  ];
  const [advertisementsFlux, setAdvertisementsFlux] = React.useState<
    AdvertisementFluxDTO[]
  >(widget.advertisements_flux);
  const [includeOwnedAdvertisements, setIncludeOwnedAdvertisements] =
    React.useState<boolean>(widget.include_owned_advertisements);

  const isTouched = React.useMemo(() => {
    if (advertisementsFlux.length !== widget.advertisements_flux.length)
      return true;
    if (
      JSON.stringify(advertisementsFlux) !==
      JSON.stringify(widget.advertisements_flux)
    )
      return true;
    if (widget.include_owned_advertisements !== includeOwnedAdvertisements)
      return true;
    return false;
  }, [widget, advertisementsFlux, includeOwnedAdvertisements]);

  const fetcher = useFetcher();

  function pushNewFlux(f: React.FormEvent<HTMLFormElement>) {
    f.preventDefault();
    try {
      const data = new FormData(f.currentTarget);
      const newFlux = AdvertisementFluxSchema.parse({
        type: ADVERTISEMENT_TYPE.EVENT,
        ...Object.fromEntries(data),
      });
      console.log("newFlux", newFlux, Object.fromEntries(data));
      setAdvertisementsFlux((v) => [...v, newFlux]);
      f.currentTarget.reset();
    } catch (e) {
      alert("Couldn't push flux");
    }
  }

  return (
    <Card className="enter animation-fast" key="flux">
      <CardHeader>
        <CardTitle>Flux d'annonce</CardTitle>
      </CardHeader>
      <CardContent className="pb-6 border-b border-b-border space-y-6">
        <p className="text-sm leading-6 tracking-wide">
          Ajoutez des flux pour alimenter automatiquement le widget avec des
          annonces suivant des critères définis. Ces annonces se positionneront
          après celles sponsorisées et vous ne pourrez pas les modifier
          manuellement.
        </p>
        <Collapsible defaultOpen>
          <Card>
            <CardContent className="relative py-0">
              <CollapsibleTrigger asChild>
                <Label className="font-semibold text-base absolute -top-3 px-2 -mx-2 bg-card flex items-center gap-2 cursor-pointer">
                  Flux événementiels
                  <div className="aspect-square bg-foreground text-background rounded-full w-5 h-5 flex items-center justify-center">
                    {advertisementsFlux.length}
                  </div>
                </Label>
              </CollapsibleTrigger>

              <CollapsibleContent className="peer pt-8 pb-6">
                <div className="grid grid-cols-2 text-muted-foreground mb-2 ml-10 mr-20">
                  <Label>Zones</Label>
                  <Label>Catégories</Label>
                </div>
                <div className="text-sm divide-y divide-border">
                  {advertisementsFlux
                    .map((v, i) => [v, i] as const)
                    .filter(([v]) => v.type === ADVERTISEMENT_TYPE.EVENT)
                    .map(([flux, idx]) => {
                      const zone = flux.zoneId
                        ?.map((z) => zones.find(withId(z))?.name)
                        .filter(Boolean)
                        .map(capitalize)
                        .join(", ");
                      const categorie = flux.categories
                        ?.map((c) => categories.find(withId(c))?.name)
                        .filter(Boolean)
                        .map(capitalize)
                        .join(", ");
                      return (
                        <div
                          key={JSON.stringify(flux)}
                          className="py-3 flex items-center w-full"
                        >
                          <Button
                            type="button"
                            size="icon-sm"
                            variant="ghost"
                            className="mr-4"
                            onClick={() => {
                              setAdvertisementsFlux((v) =>
                                v.filter((_, i) => i !== idx),
                              );
                            }}
                          >
                            <X className="w-4 h-4" />
                          </Button>
                          <div className="grid grid-cols-2 w-full">
                            <span>{zone ?? "Toute la France"}</span>
                            <span>
                              {categorie ?? "Les meilleurs événements"}
                            </span>
                          </div>
                          <div className="w-20" />
                        </div>
                      );
                    })}
                  <form
                    key={advertisementsFlux.length}
                    onSubmit={pushNewFlux}
                    className="py-3 flex items-center w-full"
                  >
                    <PlusCircle className="w-6 h-6 mr-4" />
                    <div className="grid grid-cols-2 w-full">
                      <SearchSelect
                        name="zoneId"
                        options={
                          formatZoneTree(zoneTree, (z) => ({
                            value: z.id + "",
                            label: z.name,
                          })).children?.[0]?.children ?? []
                        }
                        placeholder="Choisir une zone..."
                      />
                      <SearchSelect
                        name="categories"
                        options={categories.map((category) => ({
                          value: category.id + "",
                          label: capitalize(category.name),
                        }))}
                        placeholder="Choisir une catégorie..."
                      />
                    </div>
                    <Button size="sm" type="submit" variant="secondary">
                      Ajouter
                    </Button>
                  </form>
                </div>
              </CollapsibleContent>
            </CardContent>
          </Card>
        </Collapsible>
        <Card>
          <CardContent className="relative py-0">
            <Label className="font-semibold text-base absolute -top-3 px-2 -mx-2 bg-card flex items-center gap-2 cursor-pointer">
              Flux propriétaire
            </Label>
            <div className="flex items-center gap-3 pt-8 pb-6">
              <Switch
                id="include_owned_advertisements"
                name="include_owned_advertisements"
                defaultChecked={widget.include_owned_advertisements}
                checked={includeOwnedAdvertisements}
                onCheckedChange={setIncludeOwnedAdvertisements}
              />
              <Label
                htmlFor="include_owned_advertisements"
                className={cn(
                  "font-medium",
                  advertisementsFlux || "opacity-60",
                )}
              >
                Inclure automatiquement les annonces que je crée
              </Label>
            </div>
          </CardContent>
        </Card>
      </CardContent>
      <CardFooter className="flex justify-end py-4 gap-4">
        {isTouched ? (
          <Button type="reset" size="sm" variant="secondary">
            Annuler
          </Button>
        ) : null}
        <fetcher.Form
          method="PUT"
          action="/app/widgets/:widgetId/configuration?index"
        >
          <input type="hidden" name="widgetId" value={widget.id} />
          <input
            type="hidden"
            name="include_owned_advertisements"
            value={includeOwnedAdvertisements + ""}
          />
          <input
            type="hidden"
            name="advertisements_flux"
            value={JSON.stringify(advertisementsFlux)}
          />
          <Button type="submit" size="sm" disabled={!isTouched}>
            Confirmer
          </Button>
        </fetcher.Form>
      </CardFooter>
    </Card>
  );
}
