import { createWidget } from "@/api/widgets";
import { ActionFunctionArgs, redirect } from "react-router-dom";
import { CreateWidgetSchema } from "@/api/definitions";
import { objectFromFormData } from "@/lib/query";
import { withToast } from "@/lib/with-toast";

export interface IWidgetsActionData {}

export async function WidgetsAction({
  request,
}: ActionFunctionArgs): Promise<IWidgetsActionData> {
  const body = objectFromFormData(await request.formData());
  if (request.method === "POST") {
    const newWidget = await withToast(() => {
      const payload = CreateWidgetSchema.parse(body);
      return createWidget(payload);
    });
    throw redirect(`/app/widgets/${newWidget!.id}/configuration`);
  }
  return {};
}
