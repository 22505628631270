import { ms, StringTime, timer } from "./timer";

type CacheKey = string;

interface CacheEntry<T> {
  promise: Promise<T>;
  expiry: number;
  controller: AbortController;
  result?: T;
}

interface CacheOptions {
  ttl?: number;
  dependsOn?: CachedFunction<any, any>[];
}
export interface CacheFunctionThisArg {
  cacheKey: string;
  preventCache: () => void;
}

export interface InvalidatePredicate<TParams, TResult> {
  (params: TParams, result: TResult | undefined): any;
}

export interface InvalidationEvent<TParams, TResult>
  extends CustomEvent<{ params: TParams; result?: TResult }> {}

export interface CachedFunction<TParams extends unknown[], TResult> {
  (...args: [...TParams, AbortSignal?]): Promise<TResult>;
  invalidate: (predicate?: InvalidatePredicate<TParams, TResult>) => void;
  waitForIdle: () => Promise<void>;
  events: EventTarget;
  push: (params: TParams, result: TResult) => void;
  getLast: (...args: TParams) => TResult | undefined;
  _raw(...args: [...TParams, AbortSignal?]): TResult | Promise<TResult>;
}

export type ExtractParams<T> =
  T extends CachedFunction<infer P, infer R> ? P : never;

export type ExtractResult<T> =
  T extends CachedFunction<infer P, infer R> ? R : never;

export const CACHE = {
  list: [] as { invalidate: () => void }[],
  invalidateAll() {
    CACHE.list.forEach((fn) => fn.invalidate());
  },
};

export function cache<TParams extends unknown[], TResult>(
  fn: (
    this: CacheFunctionThisArg,
    ...args: [...TParams, AbortSignal?]
  ) => TResult | Promise<TResult>,
  options: CacheOptions | number | StringTime = "5 seconds",
): CachedFunction<TParams, TResult> {
  const cacheMap = new Map<CacheKey, CacheEntry<TResult>>();
  const { ttl = ms("5 seconds"), dependsOn = [] } =
    typeof options === "number"
      ? { ttl: options }
      : typeof options === "string"
        ? { ttl: ms(options) }
        : options;
  let activeCalls = 0;
  let idleResolvers: (() => void)[] = [];

  function getCacheKey(params: TParams): CacheKey {
    return JSON.stringify(params);
  }

  async function cachedFn(
    ...args: [...TParams, AbortSignal?]
  ): Promise<TResult> {
    const abortSignal =
      args.at(-1) instanceof AbortSignal
        ? (args.pop() as AbortSignal)
        : undefined;
    const params = args as unknown as TParams;
    const cacheKey = getCacheKey(params);
    const now = Date.now();
    const controller = new AbortController();
    abortSignal?.addEventListener("abort", () => {
      cacheMap.delete(cacheKey);
    });
    // Check if the result is already in the cache and still valid
    if (cacheMap.has(cacheKey)) {
      const cacheEntry = cacheMap.get(cacheKey)!;
      if (cacheEntry.expiry > now) {
        try {
          const result = await cacheEntry.promise;
          abortSignal?.throwIfAborted();
          return result;
        } catch (err) {
          const cacheEntry = cacheMap.get(cacheKey);
          if (cacheEntry?.result) {
            return cacheEntry.result;
          }
          cacheMap.delete(cacheKey);
          throw err;
        }
      } else {
        // Cache expired, remove it
        cacheMap.delete(cacheKey);
      }
    }

    activeCalls++;
    let resolver: (result: TResult) => void = () => {};
    let rejecter: (error: any) => void = () => {};

    const promise = new Promise<TResult>((r, j) => {
      resolver = r;
      rejecter = j;
    });
    // defaut rejection handler
    promise.catch(() => {});
    cacheMap.set(cacheKey, {
      promise,
      expiry: now + ttl,
      controller,
    });
    try {
      const result = await fn.call<
        CacheFunctionThisArg,
        [...TParams, AbortSignal?],
        TResult | Promise<TResult>
      >(
        {
          cacheKey,
          preventCache() {
            cacheMap.delete(cacheKey);
          },
        },
        ...params,
        mergeAbortSignals(controller.signal, abortSignal),
      );
      const cacheEntry = cacheMap.get(cacheKey);
      if (cacheEntry) {
        cacheEntry.result = result;
      }
      setTimeout(() => resolver(result), 1);
      return result;
    } catch (err) {
      const cacheEntry = cacheMap.get(cacheKey);
      if (cacheEntry?.result) {
        const result = cacheEntry.result;
        setTimeout(() => resolver(result), 1);
        return result;
      }
      cacheMap.delete(cacheKey);
      setTimeout(() => rejecter(err), 1);
      throw err;
    } finally {
      activeCalls--;
      if (activeCalls === 0) {
        idleResolvers.forEach((resolve) => resolve());
        idleResolvers = [];
      }
    }
  }

  cachedFn._raw = fn;
  cachedFn.events = new EventTarget();

  cachedFn.push = (params: TParams, result: TResult): void => {
    const key = getCacheKey(params);
    const now = Date.now();
    if (cacheMap.has(key)) {
      const cacheEntry = cacheMap.get(key)!;
      cacheEntry.controller.abort();
    }
    cacheMap.set(key, {
      promise: Promise.resolve(result),
      expiry: now + ttl,
      controller: new AbortController(),
      result,
    });
  };
  cachedFn.getLast = (...params: TParams) =>
    cacheMap.get(getCacheKey(params))?.result;
  cachedFn.invalidate = (
    predicate: InvalidatePredicate<TParams, TResult> = () => true,
  ): void => {
    for (const [key, cacheEntry] of cacheMap.entries()) {
      const params = JSON.parse(key) as TParams;
      if (predicate(params, cacheEntry.result)) {
        cacheMap.delete(key);
        setTimeout(() =>
          cachedFn.events.dispatchEvent(
            new CustomEvent("invalidation", {
              detail: {
                params,
                result: cacheEntry.result,
              },
            }) as InvalidationEvent<TParams, TResult>,
          ),
        );
      }
    }
  };

  cachedFn.waitForIdle = (): Promise<void> => {
    if (activeCalls === 0) {
      return Promise.resolve();
    }
    return new Promise<void>((resolve) => {
      idleResolvers.push(resolve);
    });
  };
  CACHE.list.push(cachedFn);
  const { trigger } = timer(() => cachedFn.invalidate());
  dependsOn.forEach((fn) => {
    fn.events.addEventListener("invalidation", trigger);
  });
  return cachedFn;
}

function mergeAbortSignals(
  ..._signals: (AbortSignal | undefined)[]
): AbortSignal {
  const controller = new AbortController();
  const signals = _signals.filter(Boolean) as AbortSignal[];

  for (const signal of signals)
    if (signal.aborted) {
      controller.abort(signal.reason);
      return controller.signal;
    }

  signals.forEach((signal) =>
    signal.addEventListener(
      "abort",
      function () {
        controller.abort(this.reason);
      },
      controller,
    ),
  );

  return controller.signal;
}
