import * as React from "react";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";

export interface ISmartPaginationProps {
  page: number;
  count: number;
  perPage?: number;
  setPage: (page: number) => void;
}

export default function SmartPagination({
  page,
  count,
  setPage,
  perPage = 10,
}: ISmartPaginationProps) {
  const maxPage = Math.ceil(count / perPage);
  if (maxPage <= 1) return null;
  function changePage(newPage: number) {
    return function (e: React.MouseEvent) {
      e.stopPropagation();
      e.preventDefault();
      setPage(Math.max(0, Math.min(maxPage - 1, newPage)));
    };
  }
  return (
    <Pagination>
      <PaginationContent>
        {page !== 0 ? (
          <PaginationItem>
            <PaginationPrevious href="#" onClick={changePage(page - 1)} />
          </PaginationItem>
        ) : null}
        {page > 2 ? (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        ) : null}
        {page > 1 ? (
          <PaginationItem>
            <PaginationLink href="#" onClick={changePage(page - 2)}>
              {page - 1}
            </PaginationLink>
          </PaginationItem>
        ) : null}
        {page > 0 ? (
          <PaginationItem>
            <PaginationLink href="#" onClick={changePage(page - 1)}>
              {page}
            </PaginationLink>
          </PaginationItem>
        ) : null}
        <PaginationItem>
          <PaginationLink href="#" onClick={(e) => e.preventDefault()} isActive>
            {page + 1}
          </PaginationLink>
        </PaginationItem>
        {page < maxPage - 1 ? (
          <PaginationItem>
            <PaginationLink href="#" onClick={changePage(page + 1)}>
              {page + 2}
            </PaginationLink>
          </PaginationItem>
        ) : null}
        {page < maxPage - 2 ? (
          <PaginationItem>
            <PaginationLink href="#" onClick={changePage(page + 2)}>
              {page + 3}
            </PaginationLink>
          </PaginationItem>
        ) : null}
        {page < maxPage - 3 ? (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        ) : null}
        {page !== maxPage - 1 ? (
          <PaginationItem>
            <PaginationNext href="#" onClick={changePage(page + 1)} />
          </PaginationItem>
        ) : null}
      </PaginationContent>
    </Pagination>
  );
}
