import { ActionFunctionArgs } from "react-router-dom";

export interface IApiSettingsActionData {}
export async function ApiSettingsAction({
  request,
}: ActionFunctionArgs): Promise<IApiSettingsActionData> {
  if (request.method === "POST") {
  }
  return {};
}
