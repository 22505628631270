import * as z from "zod";
import dayjs from "./date";
import { objectFromSearchParams } from "./query";

export * from "zod";
export function dateString() {
  return z
    .string()
    .or(z.date())
    .refine((s) => dayjs(s).isValid())
    .transform((s) => dayjs(s).toDate());
}
export function oneOrMore<T extends z.ZodType>(type: T) {
  return type
    .array()
    .nonempty()
    .or(type.transform((t) => [t])) as unknown as z.ZodArray<T, "atleastone">;
}
export function json<T extends z.ZodType>(type: T) {
  return type.or(
    z
      .string()
      .transform((s) => {
        try {
          return type.parse(JSON.parse(s)) as z.infer<T>;
        } catch (e) {
          console.error("cant transform string to json", e);
          console.log(s);
          return e as Error;
        }
      })
      .refine((s): s is z.infer<T> => !(s instanceof Error)),
  );
}
export function bool() {
  return z.boolean().or(
    z
      .string()
      .or(z.coerce.number().int())
      .transform((s) => ["true", "on", 1].includes(s)),
  );
}
export function searchParams<
  T extends z.ZodType,
  S extends "safeParse" | "parse",
>(type: T, fn: S) {
  return z
    .any()
    .transform(
      (s) =>
        type[fn](objectFromSearchParams(s)) as S extends "safeParse"
          ? z.SafeParseReturnType<any, z.infer<T>>
          : z.infer<T>,
    );
}
