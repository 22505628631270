import {
  StepLayout,
  StepLayoutActions,
  StepLayoutHeader,
  StepLayoutNextAction,
  StepLayoutPreviousAction,
  StepLayoutSubtitle,
  StepLayoutTitle,
} from "@/components/step-layout";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import * as z from "@/lib/zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import { useDraft } from "../../layout";
import { AdvertisementBuilderDatesSchema } from "./schemas";
import { IStepProps } from "./step-props";
import DateRangePicker from "@/components/date-range-picker";

export default function AdvertisementBuilderDates({
  next,
  previous,
}: IStepProps) {
  const [{ draft }] = useDraft();
  const form = useForm({
    mode: "onChange",
    resolver: zodResolver(AdvertisementBuilderDatesSchema),
    defaultValues: {
      dates: draft.dates?.length
        ? draft.dates
        : [{ from: new Date(), to: new Date() }],
    } as z.infer<typeof AdvertisementBuilderDatesSchema>,
  });
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "dates",
  });

  const errors = form.formState.errors;
  return (
    <StepLayout>
      <StepLayoutHeader>
        <StepLayoutTitle>Quand se déroulera votre événement ?</StepLayoutTitle>
        <StepLayoutSubtitle>
          Choisissez les dates de l'événement.
        </StepLayoutSubtitle>
      </StepLayoutHeader>
      <Label>Dates *</Label>
      <div className="space-y-2">
        {fields.map((field, index, { length }) => {
          return (
            <DateRangePicker
              key={field.id}
              {...form.register(`dates.${index}`)}
              remove={length > 1 ? () => remove(index) : undefined}
              onChange={(newDates) => {
                form.setValue(`dates.${index}`, newDates);
                form.trigger();
              }}
              value={form.getValues(`dates.${index}`)}
            />
          );
        })}
      </div>
      <div>
        <Button
          variant="secondary"
          onClick={() => append({ from: new Date(), to: new Date() })}
        >
          Ajouter une date
        </Button>
      </div>
      <StepLayoutActions>
        <StepLayoutPreviousAction previous={previous}>
          Retour
        </StepLayoutPreviousAction>
        <StepLayoutNextAction form={form} next={next}>
          Continuer
        </StepLayoutNextAction>
      </StepLayoutActions>
    </StepLayout>
  );
}
