import { TableHead } from "@/components/ui/table";
import { ColumnsDef } from "@/hooks/useColumns";

export type UserTableColumnName =
  | "id"
  | "name"
  | "email"
  | "role"
  | "active"
  | "company"
  | "created_at";

export const UserTableColumns: ColumnsDef<UserTableColumnName> = {
  id: {
    value: "id",
    label: "Id",
    header: () => <TableHead className="w-20">#ID</TableHead>,
  },
  name: {
    value: "name",
    label: "Nom",
    header: () => <TableHead className="w-full max-w-[300px]">Nom</TableHead>,
  },
  email: {
    value: "email",
    label: "Email",
    header: () => <TableHead>Email</TableHead>,
  },
  role: {
    value: "role",
    label: "Role",
    header: () => <TableHead>Role</TableHead>,
  },
  company: {
    value: "company",
    label: "Entreprise",
    header: () => <TableHead>Entreprise</TableHead>,
  },
  active: {
    value: "active",
    label: "Actif",
    header: () => <TableHead>Actif</TableHead>,
  },
  created_at: {
    value: "created_at",
    label: "Date d'inscription",
    header: () => <TableHead className="w-max">Date d'inscription</TableHead>,
  },
} as const;
