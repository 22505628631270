import { DropdownMenuSeparator } from "@/components/ui/dropdown-menu";
import {
  ArrowUpSquare,
  CalendarDays,
  Copy,
  Edit,
  ExternalLink,
  Eye,
  EyeOff,
  Hash,
  Trash,
  TrendingUp,
} from "lucide-react";
import { useFetcher, useNavigate } from "react-router-dom";
import { FullAdvertisementDTO } from "@/api/definitions";
import { cn } from "@/lib/utils";
import { ActionsMenuContent, ActionsMenuItem } from "@/components/actions-menu";
import { useCreateScheduleModal } from "@/modals/create-schedule";
import DropdownForm from "@/components/dropdown-form-item";
import FormState from "@/components/form-state";
import { Button } from "@/components/ui/button";
import { objectToFormData } from "@/lib/query";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";
import { copyText } from "@/lib/copy";

export interface IAdvertisementActionsProps {
  advertisement: FullAdvertisementDTO;
}

export default function AdvertisementActions({
  advertisement,
}: IAdvertisementActionsProps) {
  const navigate = useSmartNavigate();
  const createScheduleModal = useCreateScheduleModal();
  const fetcher = useFetcher();
  return (
    <ActionsMenuContent className="w-56" align="start" side="left">
      <ActionsMenuItem
        onClick={() => copyText(advertisement.id)}
        className="cursor-pointer"
      >
        <Hash className="mr-2 h-4 w-4" />
        <span>Copier l'ID</span>
      </ActionsMenuItem>
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={() => open(advertisement.url, "_blank")}
      >
        <ExternalLink className="mr-2 h-4 w-4" />
        <span>Voir en ligne</span>
      </ActionsMenuItem>
      <DropdownMenuSeparator />
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={() =>
          createScheduleModal.openModal({ advertisementId: advertisement.id })
        }
      >
        <CalendarDays className="mr-2 h-4 w-4" />
        <span>Programmer</span>
      </ActionsMenuItem>
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={() =>
          navigate(`/app/advertisements/${advertisement.id}/analytics`)
        }
      >
        <TrendingUp className="mr-2 h-4 w-4" />
        <span>Statistiques</span>
      </ActionsMenuItem>
      <DropdownMenuSeparator />
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={() =>
          navigate(`/app/advertisements/${advertisement.id}/content`)
        }
      >
        <Edit className="mr-2 h-4 w-4" />
        <span>Modifier</span>
      </ActionsMenuItem>
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          fetcher.submit(
            objectToFormData({
              id: advertisement.id,
              type: advertisement.type,
              is_main_version: !advertisement.is_main_version,
            }),
            {
              method: "PUT",
              action: "/app/advertisements/[id]/content?index",
            },
          );
        }}
      >
        {advertisement.is_main_version ? (
          <>
            <EyeOff className="mr-2 h-4 w-4" />
            <span>Rendre privé</span>
          </>
        ) : (
          <>
            <Eye className="mr-2 h-4 w-4" />
            <span>Rendre public</span>
          </>
        )}
      </ActionsMenuItem>
      <ActionsMenuItem
        className={cn("cursor-pointer")}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          fetcher.submit(objectToFormData({ id: advertisement.id }), {
            method: "POST",
            action: "/app/advertisements?index",
          });
        }}
      >
        <Copy className="mr-2 h-4 w-4" />
        <span>Dupliquer</span>
      </ActionsMenuItem>
      <FormState data={{ id: advertisement.id, type: advertisement.type }} />
      <ActionsMenuItem
        asChild
        onClick={(e) => {
          fetcher.submit(
            objectToFormData({
              id: advertisement.id,
              type: advertisement.type,
            }),
            {
              method: advertisement.archivedAt ? "POST" : "DELETE",
              action: "/app/advertisements/[id]/content?index",
            },
          );
        }}
      >
        {advertisement.archivedAt ? (
          <Button
            size="sm"
            type="submit"
            variant="outline"
            className={cn("w-full justify-start cursor-pointer")}
          >
            <ArrowUpSquare className="mr-2 h-4 w-4" />
            <span>Désarchiver</span>
          </Button>
        ) : (
          <Button
            size="sm"
            type="submit"
            variant="destructive"
            className={cn("w-full justify-start cursor-pointer")}
          >
            <Trash className="mr-2 h-4 w-4" />
            <span>Archiver</span>
          </Button>
        )}
      </ActionsMenuItem>
    </ActionsMenuContent>
  );
}
