import { cn } from "@/lib/utils";
import { ChevronLeft, PanelsTopLeft } from "lucide-react";
import { Link } from "react-router-dom";
import create from "zustand";
import { persist } from "zustand/middleware";
import { Menu } from "./menu";
import { Button } from "./ui/button";

export interface IUseSidebarStore {
  isOpen: boolean;
  toggleOpen: () => void;
}

export const useSidebar = create<IUseSidebarStore>()(
  persist<IUseSidebarStore>(
    (set) => ({
      isOpen: true,
      toggleOpen: () => set((state) => ({ isOpen: !state.isOpen })),
    }),
    {
      name: `sidebar-store`,
    },
  ),
);

export interface ISidebarProps {}

export default function Sidebar(props: ISidebarProps) {
  const { isOpen, toggleOpen } = useSidebar();
  return (
    <aside
      className={cn(
        "fixed top-0 left-0 z-40 h-screen -translate-x-full lg:translate-x-0 transition-[width] ease-in-out duration-300 border-r border-r-muted bg-card",
        isOpen === false ? "w-[76px]" : "w-72",
      )}
    >
      <div className="invisible lg:visible absolute top-[16px] -right-[16px] z-40">
        <Button
          onClick={() => toggleOpen()}
          className="rounded-md w-8 h-8"
          variant="outline"
          size="icon"
        >
          <ChevronLeft
            className={cn(
              "h-4 w-4 transition-transform ease-in-out duration-700",
              isOpen === false ? "rotate-180" : "rotate-0",
            )}
          />
        </Button>
      </div>
      <div className="relative h-full flex flex-col px-2 py-4 overflow-y-auto shadow-md dark:shadow-zinc-800">
        <Button
          className={cn(
            "transition-transform ease-in-out duration-300 mb-1",
            isOpen === false ? "translate-x-1" : "translate-x-0",
          )}
          variant="link"
          asChild
        >
          <Link to="/app" className="flex items-center hover:!no-underline">
            <img
              src="/img/logo-square.png"
              className="w-10 h-10 object-contain border border-2 border-background"
            />
            <h1
              className={cn(
                "font-bold font-black cursor-pointer text-lg whitespace-nowrap transition-[transform,opacity,display] ease-in-out duration-300",
                isOpen === false
                  ? "-translate-x-96 opacity-0 hidden"
                  : "translate-x-0 opacity-100",
              )}
            >
              onnectz
            </h1>
          </Link>
        </Button>
        <Menu isOpen={isOpen} />
      </div>
    </aside>
  );
}
