import { getCurrentUser } from "@/api/authentication";
import {
  listCompanyCustomizations,
  createEmptyCustomization,
} from "@/api/customizations";
import { LoaderFunctionArgs, redirect } from "react-router-dom";

export interface INewDesignLoaderData {
  customizationId: number;
}

export async function NewDesignLoader({ request }: LoaderFunctionArgs) {
  const customizationsList = await listCompanyCustomizations(request.signal);
  const session = (await getCurrentUser(request.signal))!;
  const newCustom = await createEmptyCustomization({
    company_id: session.company!.id,
    name: `Nouveau design ${customizationsList.length}`,
  });
  return { customizationId: newCustom.id } satisfies INewDesignLoaderData;
}
