import { TabLayoutContent } from "@/components/tab-layout";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
// import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import * as React from "react";
import { Link, useLoaderData } from "react-router-dom";
import { IApiSettingsLoaderData } from "./loader";

export interface IApiSettingsPageProps {}

export default function ApiSettingsPage(props: IApiSettingsPageProps) {
  const { userId } = useLoaderData() as IApiSettingsLoaderData;
  return (
    <TabLayoutContent>
      {/* <Dialog> */}
      <Card className="max-w-2xl">
        <CardHeader>
          <CardTitle>Accéder à l'API</CardTitle>
        </CardHeader>
        <CardContent>
          <CardDescription className="leading-normal">
            Utilisez l'API pour récupérer le contenu de vos widgets et intégrer
            nos contenus de manière plus native dans vos sites web ou
            applications mobiles.
          </CardDescription>
        </CardContent>
        <CardFooter className="justify-end">
          {/* <DialogTrigger> */}
          <Link
            to={`mailto:axel@konnectz.io;emmanuel@timenjoy.fr?subject=${encodeURIComponent("Konnectz - Demande d'accès à l'api")}&body=${encodeURIComponent(`Comment souhaitez-vous utiliser l'api ?\n\n\nRéférence client: ${userId}\n\n`)}`}
          >
            <Button>Demander l'accès</Button>
          </Link>
          {/* </DialogTrigger> */}
        </CardFooter>
      </Card>
      {/* <DialogContent className="xs:max-h-[calc(100vh_-_80px)] p-0 bg-white overflow-hidden">
          <iframe
            className="w-full h-full border-none min-h-[800px]"
            src="https://calendly.com/timenjoy/konnectz?embed_domain=app.konnectz.io&amp;embed_type=Inline&amp;primary_color=cb1eb6"
            frameBorder="0"
            title="Select a Date &amp; Time - Calendly"
          />
        </DialogContent>
      </Dialog> */}
    </TabLayoutContent>
  );
}
