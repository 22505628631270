import * as React from "react";
import { IWidgetBlacklistsLoaderData } from "./loader";
import Awaited from "@/components/awaited";
import RefPortal from "@/components/ref-portal";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import BlacklistsTable from "./(blocks)/blacklists-table";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { TabLayoutContent } from "@/components/tab-layout";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";

export interface IWidgetBlasklistsPageProps {}

export default function WidgetBlasklistsPage(
  props: IWidgetBlasklistsPageProps,
) {
  const { widgetId } = useParams();
  const data = useLoaderData() as IWidgetBlacklistsLoaderData;
  const navigate = useSmartNavigate();
  return (
    <TabLayoutContent>
      <RefPortal selector="#tab-layout-head-outlet" key="catalogue-button">
        <Button
          className="enter animation-fast animation-small"
          onClick={() => navigate(`/app/catalog?defaultWidget=${widgetId}`)}
        >
          <Plus className="w-5 h-5 mr-2" /> Accéder au catalogue
        </Button>
      </RefPortal>
      <Awaited
        resolve={data.blacklists}
        fallback={<BlacklistsTable.Skeleton />}
      >
        <BlacklistsTable />
      </Awaited>
    </TabLayoutContent>
  );
}
