import * as React from "react";
import { Route } from "react-router-dom";
import WidgetBlasklistsPage from "./page";
import { WidgetBlacklistsLoader } from "./loader";

export interface IWidgetBlacklistsRouterProps {}

export default function WidgetBlacklistsRouter(
  props: IWidgetBlacklistsRouterProps,
) {
  return (
    <Route>
      <Route
        index
        Component={WidgetBlasklistsPage}
        loader={WidgetBlacklistsLoader}
      />
    </Route>
  );
}
