import * as React from "react";
import { useAsyncValue } from "react-router-dom";
import { IAdvertisementAnalyticsLoaderData } from "../loader";
import CustomChart from "@/components/custom-chart";
import {
  dateFormatterForPeriod,
  getAggregationPeriodForDates,
} from "@/api/analytics";

export interface IClicChartProps {}

export default function ClicChart(props: IClicChartProps) {
  const { data, period } = useAsyncValue() as Awaited<
    IAdvertisementAnalyticsLoaderData["analytics"]
  >;
  return (
    <CustomChart
      data={data}
      config={{
        clics: {
          label: "Clics",
          color: "hsl(var(--chart-1))",
        },
      }}
      xAxis="hour"
      formatter={
        dateFormatterForPeriod[getAggregationPeriodForDates(...period)]
      }
      areas={{
        clics: {
          type: "monotone",
        },
      }}
    />
  );
}
