import {
  BadgeCheck,
  BadgeX,
  CalendarDays,
  CalendarPlus,
  Edit,
  ExternalLink,
  Hash,
  Pause,
  Play,
  Trash,
  TrendingUp,
} from "lucide-react";
import { ROTATION_STRATEGY, SchedulesDTO } from "@/api/definitions";
import { objectToSearchParams } from "@/lib/query";
import dayjs from "dayjs";
import DropdownForm from "@/components/dropdown-form-item";
import { useUpdateScheduleModal } from "@/modals/update-schedule";
import { useCreateScheduleModal } from "@/modals/create-schedule";
import { useSmartNavigate } from "@/hooks/use-smart-navigate";
import { ActionsMenuContent, ActionsMenuItem } from "@/components/actions-menu";
import { copyText } from "@/lib/copy";

export interface IScheduleActionsProps {
  schedule: Pick<
    SchedulesDTO,
    | "id"
    | "widget_id"
    | "advertisement_version_id"
    | "from"
    | "to"
    | "rotation_strategy"
    | "enabled"
  >;
  url: string;
  close?(): void;
}

export default function ScheduleActions({
  schedule,
  url,
  close,
}: IScheduleActionsProps) {
  const isSponsored = schedule.rotation_strategy !== ROTATION_STRATEGY.FILLER;
  const hasStarted = dayjs().isAfter(schedule.from);
  const hasEnded = dayjs().isAfter(schedule.to);
  const navigate = useSmartNavigate();
  const createScheduleModal = useCreateScheduleModal();
  const updateScheduleModal = useUpdateScheduleModal();
  if (Number.isNaN(+schedule.id)) return null;
  return (
    <ActionsMenuContent className="w-56" align="start" side="left">
      <ActionsMenuItem
        onClick={() => copyText(schedule.id)}
        className="cursor-pointer"
      >
        <Hash className="mr-2 h-4 w-4" />
        <span>Copier l'ID</span>
      </ActionsMenuItem>
      <ActionsMenuItem
        onClick={() => window.open(url, "_blank")}
        className="cursor-pointer"
      >
        <ExternalLink className="mr-2 h-4 w-4" />
        <span>Voir en ligne</span>
      </ActionsMenuItem>
      {hasEnded
        ? null
        : [
            isSponsored ? (
              <DropdownForm
                key="unsponsored"
                formProps={{
                  action: "/app/widgets/:widgetId/schedules?index",
                  method: "PUT",
                }}
                data={{
                  id: schedule.id,
                  rotation_strategy: ROTATION_STRATEGY.FILLER,
                }}
              >
                <BadgeX className="mr-2 h-4 w-4" />
                <span>Ne plus ponsoriser</span>
              </DropdownForm>
            ) : (
              <DropdownForm
                key="sponsored"
                formProps={{
                  action: "/app/widgets/:widgetId/schedules?index",
                  method: "PUT",
                }}
                data={{
                  id: schedule.id,
                  rotation_strategy: ROTATION_STRATEGY.ROTATE,
                }}
              >
                <BadgeCheck className="mr-2 h-4 w-4" />
                <span>Sponsoriser</span>
              </DropdownForm>
            ),
            schedule.enabled ? (
              <DropdownForm
                key="disable"
                formProps={{
                  action: "/app/widgets/:widgetId/schedules?index",
                  method: "PUT",
                }}
                data={{
                  id: schedule.id,
                  enabled: false,
                }}
              >
                <Pause className="mr-2 h-4 w-4" />
                <span>Désactiver</span>
              </DropdownForm>
            ) : (
              <DropdownForm
                key="enable"
                formProps={{
                  action: "/app/widgets/:widgetId/schedules?index",
                  method: "PUT",
                }}
                data={{
                  id: schedule.id,
                  enabled: true,
                }}
              >
                <Play className="mr-2 h-4 w-4" />
                <span>Ré-activer</span>
              </DropdownForm>
            ),
          ]}
      <ActionsMenuItem
        className="cursor-pointer"
        onClick={() => {
          navigate(
            `/app/advertisements/${schedule.advertisement_version_id}/content`,
          );
        }}
      >
        <Edit className="mr-2 h-4 w-4" />
        <span>Modifier l'annonce</span>
      </ActionsMenuItem>
      {hasEnded ? (
        <ActionsMenuItem
          onClick={() => {
            close?.();
            createScheduleModal.openModal({
              advertisementId: schedule.advertisement_version_id,
              defaultWidget: schedule.widget_id,
            });
          }}
        >
          <CalendarPlus className="mr-2 h-4 w-4" />
          <span>Re-programmer</span>
        </ActionsMenuItem>
      ) : (
        <ActionsMenuItem
          onClick={() => {
            close?.();
            updateScheduleModal.openModal({ scheduleId: schedule.id });
          }}
        >
          <CalendarDays className="mr-2 h-4 w-4" />
          <span>Planifier</span>
        </ActionsMenuItem>
      )}
      {hasStarted ? (
        <ActionsMenuItem
          className="cursor-pointer"
          onClick={() =>
            navigate(
              `/app/advertisements/${schedule.advertisement_version_id}/analytics?${objectToSearchParams(
                {
                  widgetId: schedule.widget_id,
                  from: dayjs(schedule.from).toISOString(),
                  to: dayjs(schedule.to).toISOString(),
                },
              )}`,
            )
          }
        >
          <TrendingUp className="mr-2 h-4 w-4" />
          <span>Statistique</span>
        </ActionsMenuItem>
      ) : null}
      {hasEnded ? null : (
        <DropdownForm
          formProps={{
            action: "/app/widgets/:widgetId/schedules?index",
            method: "DELETE",
          }}
          menuItemProps={{
            className:
              "bg-destructive text-destructive-foreground hover:!text-destructive",
          }}
          data={{ id: schedule.id }}
        >
          <Trash className="mr-2 h-4 w-4" />
          <span>{hasStarted ? "Arrêter" : "Supprimer"}</span>
        </DropdownForm>
      )}
    </ActionsMenuContent>
  );
}
