import * as React from "react";
import {
  Await,
  AwaitProps,
  useLoaderData,
  useRouteLoaderData,
} from "react-router-dom";

export interface IAwaitedRenderFunction<T> {
  (value: Awaited<T>): React.ReactNode | React.ReactNode[];
}
export type IAwaitedProps<T> = {
  fallback?: React.SuspenseProps["fallback"];
  errorElement?: AwaitProps["errorElement"];
  resolve: T;
  loading?: boolean | "loading" | (string & {});
} & (
  | {
      children:
        | JSX.Element
        | IAwaitedRenderFunction<T>
        | (IAwaitedRenderFunction<T> | JSX.Element)[];
    }
  | {
      render: IAwaitedRenderFunction<T>;
    }
);

export default function Awaited<T>({ fallback, ...props }: IAwaitedProps<T>) {
  const children = React.useMemo(() => {
    if ("render" in props) return props.render;
    if (!Array.isArray(props.children))
      return props.children as React.ReactNode | IAwaitedRenderFunction<T>;
    if (props.children.length <= 1)
      return props.children[0] as React.ReactNode | IAwaitedRenderFunction<T>;
    if (!props.children.find((f) => typeof f === "function"))
      return props.children as React.ReactNode[];
    const childrenArray = props.children;
    return function (value: Awaited<T>) {
      return childrenArray.map((child) =>
        typeof child === "function" ? child(value) : child,
      );
    };
    //@ts-ignore
  }, [props.children, props.render]);
  if (props.loading === true || props.loading === "loading")
    return <>{fallback}</>;
  return (
    <React.Suspense fallback={fallback}>
      <Await {...props} children={children} />
    </React.Suspense>
  );
}

export function awaited<
  T extends Record<string, any>,
  PropType extends Record<string, any>,
>(
  selector: (v: T) => any,
  Component: React.FC<PropType> & { Skeleton?: React.FC<PropType> },
  routeId?: string,
) {
  const newComponent = React.memo(function (props: PropType) {
    const data = (routeId ? useRouteLoaderData(routeId) : useLoaderData()) as T;
    console.log({ data });
    return (
      <Awaited
        resolve={selector(data)}
        fallback={
          Component.Skeleton ? <Component.Skeleton {...props} /> : undefined
        }
      >
        <Component {...props} />
      </Awaited>
    );
  });
  newComponent.displayName = Component.displayName;
  return newComponent;
}

export const CacheComponent = React.memo(function (props: { factory(): any }) {
  const result = React.useMemo(props.factory, [props.factory]);
  return <Awaited resolve={result} render={(v) => v} />;
});
