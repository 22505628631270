import { cache } from "@/lib/cache";
import { API_BASE, logErrorResponse } from "./common";
import { objectToSearchParams } from "@/lib/query";
import { z } from "zod";
import { ListMutationLogDTO, MutationLogSchema } from "./definitions";

export const listMutationLogs = cache(async function listMutationLogs(
  filters: ListMutationLogDTO,
  signal?: AbortSignal,
) {
  const query = objectToSearchParams(filters);
  const response = await fetch(`${API_BASE}/mutation_log?${query}`, {
    credentials: "include",
    signal,
  });
  if (!response.ok) throw await logErrorResponse(response);
  const data = await response.json();
  return MutationLogSchema.array().parse(data);
}, "1 hour");
