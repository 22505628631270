import { getCurrentUser } from "@/api/authentication";
import {
  createEmptyCustomization,
  listCompanyCustomizations,
} from "@/api/customizations";
import { listOwnedWidgets, updateWidget } from "@/api/widgets";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface IAppDesignLoaderData {
  customizationId: number;
}

export async function AppDesignLoader({ request }: LoaderFunctionArgs) {
  const customizationsList = await listCompanyCustomizations(request.signal);
  if (customizationsList.length)
    return { customizationId: customizationsList[0].id };
  const session = (await getCurrentUser(request.signal))!;
  const [{ results: widgets }, newCustom] = await Promise.all([
    listOwnedWidgets(request.signal).catch(() => ({ results: [] })),
    createEmptyCustomization({
      company_id: session.company!.id,
    }),
  ]);
  // no need to wait for this
  Promise.all(
    widgets
      .filter((w) => w.company_id === session.company!.id)
      .map((w) =>
        updateWidget(w.id, {
          customization: newCustom.id,
        }),
      ),
  );
  return { customizationId: newCustom.id };
}
