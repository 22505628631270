import * as React from "react";
import RefPortal from "@/components/ref-portal";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import { useRouteNavigation } from "@/hooks/use-route-navigation";
import ImpressionChart from "./(blocks)/impressions";
import Awaited from "@/components/awaited";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { IWidgetAnalyticsLoaderData } from "./loader";
import DateRangeSelector from "@/components/date-range-selector";
import { Download } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import ClicChart from "./(blocks)/clics";
import OriginsChart from "./(blocks)/origins";
import { fromEntries } from "@/lib/entries";
import dayjs, { ConfigType, parseDate } from "@/lib/date";
import { ITimePeriod, useTimePeriods } from "@/hooks/use-time-periods";
import { objectToSearchParams } from "@/lib/query";
import { WidgetAnalyticsFiltersSchema } from "@/api/definitions";
import { TabLayoutContent } from "@/components/tab-layout";
import AdvertisementChart from "./(blocks)/advertisements";
import { formatPercent } from "@/lib/format";

export interface IWidgetAnalyticsPageProps {}

export default function WidgetAnalyticsPage(props: IWidgetAnalyticsPageProps) {
  const navigation = useRouteNavigation();
  const data = useLoaderData() as IWidgetAnalyticsLoaderData;
  const [searchParams, setSearchParams] = useSearchParams();
  const periods = useTimePeriods();
  const dates = React.useMemo(() => {
    const fromSearch = fromEntries(Array.from(searchParams.entries()));
    const parsed = WidgetAnalyticsFiltersSchema.safeParse(fromSearch);
    const { from, to } = parsed.success ? parsed.data : fromSearch;
    return [
      parseDate(from, periods["24h"].value[0]),
      parseDate(to, periods["24h"].value[1]),
    ] as [ConfigType, ConfigType];
  }, [searchParams.get("from"), searchParams.get("to")]);
  function setDates(newValue: [ConfigType, ConfigType]) {
    console.log("setSearchParams");
    setSearchParams(
      objectToSearchParams({
        from: dayjs(newValue[0]).startOf("day").toISOString(),
        to: dayjs(newValue[1]).endOf("day").toISOString(),
      }),
      { replace: true },
    );
  }
  return (
    <TabLayoutContent>
      <RefPortal selector="#tab-layout-head-outlet">
        <DateRangeSelector
          className="enter animation-fast animation-small"
          periods={Object.values(periods) as ITimePeriod[]}
          value={dates}
          setValue={setDates}
          disabled={navigation.state !== "idle"}
        />
        <Button
          className="enter animation-fast animation-small"
          variant="secondary"
          size="icon"
          disabled={navigation.state !== "idle"}
        >
          <Download className="h-5 w-5" />
        </Button>
      </RefPortal>
      <Card className="enter">
        <CardHeader className="flex items-center gap-2 border-b border-b-border sm:flex-row space-y-0 py-0 pr-0">
          <div className="grid flex-1 gap-1 text-center sm:text-left py-4">
            <CardTitle>Impressions</CardTitle>
            <CardDescription>
              Nombre de visiteurs ayant vu le widget
            </CardDescription>
          </div>
          <div className="flex flex-col justify-center items-end gap-1 border-l border-border px-6 py-4 text-right w-64 shrink sm:px-8 sm:py-6">
            <span className="text-sm text-muted-foreground">Total</span>
            <span className="text-lg font-bold leading-none sm:text-3xl">
              <Awaited
                resolve={data.analytics}
                fallback={<Skeleton className="w-16 h-5" />}
                render={(analytics) =>
                  analytics.timeSerie.reduce(
                    (acc, cur) => acc + cur.impressions,
                    0,
                  )
                }
              />
            </span>
          </div>
        </CardHeader>
        <CardContent className="px-2 pt-4 sm:px-6 sm:pt-6">
          <Awaited
            resolve={data.analytics}
            fallback={<Skeleton className="w-full h-[250px]" />}
          >
            <ImpressionChart />
          </Awaited>
        </CardContent>
      </Card>
      <Card className="enter">
        <CardHeader className="flex items-center gap-2 border-b border-b-border sm:flex-row space-y-0 py-0 pr-0">
          <div className="grid flex-1 gap-1 text-center sm:text-left py-4">
            <CardTitle>Clics</CardTitle>
            <CardDescription>
              Nombre de clics sur des annonces dans le widget
            </CardDescription>
          </div>
          <div className="flex flex-col justify-center items-end gap-1 border-l border-border px-6 py-4 text-right w-64 shrink sm:px-8 sm:py-6">
            <span className="text-sm text-muted-foreground">CTR</span>
            <span className="text-lg font-bold leading-none sm:text-3xl">
              <Awaited
                resolve={data.analytics}
                fallback={<Skeleton className="w-16 h-5" />}
                render={(analytics) =>
                  formatPercent(
                    (100 *
                      analytics.timeSerie.reduce(
                        (acc, cur) => acc + cur.clics,
                        0,
                      )) /
                      (analytics.timeSerie.reduce(
                        (acc, cur) => acc + cur.impressions,
                        0,
                      ) || 1),
                  )
                }
              />
            </span>
          </div>
          <div className="flex flex-col justify-center items-end gap-1 border-l border-border px-6 py-4 text-right w-64 shrink sm:px-8 sm:py-6">
            <span className="text-sm text-muted-foreground">Total</span>
            <span className="text-lg font-bold leading-none sm:text-3xl">
              <Awaited
                resolve={data.analytics}
                fallback={<Skeleton className="w-16 h-5" />}
                render={(analytics) =>
                  analytics.timeSerie.reduce((acc, cur) => acc + cur.clics, 0)
                }
              />
            </span>
          </div>
        </CardHeader>
        <CardContent className="px-2 pt-4 sm:px-6 sm:pt-6">
          <Awaited
            resolve={data.analytics}
            fallback={<Skeleton className="w-full h-[250px]" />}
          >
            <ClicChart />
          </Awaited>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Pages</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="border border-border rounded-md overflow-hidden">
            <Awaited
              resolve={data.analytics}
              fallback={<Skeleton className="w-16 h-5" />}
            >
              <OriginsChart />
            </Awaited>
          </div>
        </CardContent>
      </Card>
      <Awaited
        resolve={data.advertisementAnalytics}
        fallback={<Skeleton className="w-16 h-5" />}
      >
        <AdvertisementChart />
      </Awaited>
    </TabLayoutContent>
  );
}
