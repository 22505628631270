import { listCategories } from "@/api/categories";
import { healthCheck } from "@/api/common";
import { CategoriesDTO, ZoneTreeDTO, ZonesDTO } from "@/api/definitions";
import { getZoneTree, listZones } from "@/api/zones";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";

export interface IRootLoaderData {
  categories: Promise<CategoriesDTO[]>;
  zoneTree: Promise<ZoneTreeDTO>;
  zones: Promise<ZonesDTO[]>;
}

const firstHealthCheck = healthCheck();
export async function RootLoader({ request }: LoaderFunctionArgs) {
  const requests = {
    categories: listCategories(request.signal),
    zoneTree: getZoneTree(request.signal),
    zones: listZones(request.signal),
  } satisfies IRootLoaderData;
  if (!(await firstHealthCheck)) {
    throw redirect(`/maintenance`);
  }
  return defer(requests);
}
