import { listCompanies } from "@/api/companies";
import { LoaderFunctionArgs, defer, json, redirect } from "react-router-dom";
import * as z from "@/lib/zod";
import { SUBSCRIPTION_TYPE } from "@/api/definitions";
export interface IAdminCompanyLoaderData {
  companies: ReturnType<typeof listCompanies>;
}

export const AdminCompanyLoaderQueryParamsSchema = z.object({
  query: z
    .string()
    .transform((s) => (s.length < 2 ? undefined : s))
    .optional(),
  ids: z.oneOrMore(z.coerce.number()).optional(),
  subscription: z.nativeEnum(SUBSCRIPTION_TYPE).optional(),
});

export async function AdminCompanyLoader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const query = z
    .searchParams(AdminCompanyLoaderQueryParamsSchema, "safeParse")
    .parse(url.searchParams);
  if (!query.success) {
    console.log("query error", query.error);
    throw redirect(url.pathname);
  }
  return defer({
    companies: listCompanies(query.data, request.signal),
  } satisfies IAdminCompanyLoaderData);
}
