import * as React from "react";
import { Route } from "react-router-dom";
import WidgetAnalyticsPage from "./page";
import { WidgetAnalyticsLoader } from "./loader";

export interface IWidgetAnalyticsRouterProps {}

export default function WidgetAnalyticsRouter(
  props: IWidgetAnalyticsRouterProps,
) {
  return (
    <Route>
      <Route
        index
        Component={WidgetAnalyticsPage}
        loader={WidgetAnalyticsLoader}
      />
    </Route>
  );
}
