import * as React from "react";
import { Route } from "react-router-dom";
import { MutationLogLoader } from "./loader";
import MutationLogPage from "./page";

export interface IMutationLogRouterProps {}

export default function MutationLogRouter(props: IMutationLogRouterProps) {
  return (
    <Route>
      <Route index loader={MutationLogLoader} Component={MutationLogPage} />
    </Route>
  );
}
