import { ListWidgetsSchema } from "@/api/definitions";
import { listWidgetsWithMetaData } from "@/api/widgets";
import { LoaderFunctionArgs, defer, redirect } from "react-router-dom";
import * as z from "@/lib/zod";

export interface IWidgetsLoaderData {
  widgets: ReturnType<typeof listWidgetsWithMetaData>;
}

export async function WidgetsLoader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const query = z
    .searchParams(ListWidgetsSchema, "safeParse")
    .parse(url.searchParams);
  if (!query.success) {
    throw redirect(url.pathname);
  }
  return defer({
    widgets: listWidgetsWithMetaData(query.data, request.signal),
  });
}
