import * as React from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { cn } from "@/lib/utils";

export interface ICollapsibleGridProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  title: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
  disable?: boolean;
  variant?: "default" | "small";
}

export default function CollapsibleGrid({
  title,
  children,
  className,
  disable = false,
  variant,
}: ICollapsibleGridProps) {
  const [open, setOpen] = React.useState(true);
  return (
    <Collapsible
      open={disable ? true : open}
      onOpenChange={setOpen}
      className={cn("w-full", className)}
    >
      <CollapsibleTrigger className="w-full" asChild>
        {title}
      </CollapsibleTrigger>
      <CollapsibleContent
        className={cn(
          "grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6",
          variant === "small" && "grid-cols-2 sm:grid-cols-3 sm:grid-cols-4",
        )}
      >
        {children}
      </CollapsibleContent>
    </Collapsible>
  );
}
